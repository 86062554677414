import React, { FormEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Form from '@components/ui/form';
import { rejectTerms } from '@redux/reducers/terms-reducer';
import { useLocalStorage } from '@hooks/use-local-storage';
import Button from '@components/ui/button';
import * as Auth from '@models/auth';
import { FormState } from '@reducers/form-reducer';
import RecoveryFormRequest from '@requests/recovery-request';
import { useNavigate } from 'react-router-dom';
import InternationalizedInput from '@components/ui/internationalized-input';

import Checkbox from '@components/ui/checkbox';
import { MODAL_TYPES, useModal } from '@contexts/global-modal-context';
import { normalizePhoneNumber } from '@utils/string-parser';
import Select from '@components/ui/select';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { InternationalizedValue } from '@components/ui/internationalized-input/internationalized-input';
import AuthService from '@services/auth-service';
import { __COUNTER__, __FORGOT_PASSWORD__, __RETRIES__ } from '@constants/storage';
import { ForgotPasswordResponseData } from '@models/forgot-password.model';
import { FormDataType } from '@ctypes/form-data';
import delays from '@constants/delays';

function ForgotPasswordForm(): React.ReactElement {
    const dispatch = useDispatch();
    const validator = new RecoveryFormRequest();
    const navigate = useNavigate();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [checked, setChecked] = useState<boolean>(true);
    const { t, i18n } = useTranslation(['forgot-password']);
    const [language, setLanguage] = useState<string>(Cookies.get('i18next') || 'fr');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [, setForgotPasswordData] = useLocalStorage<ForgotPasswordResponseData>(__FORGOT_PASSWORD__);
    const { showModal } = useModal();
    const [, setRemaining] = useLocalStorage<number>(__COUNTER__, delays.beforeResendCode);
    const [, setTries] = useLocalStorage<number>(__RETRIES__, 2);
    const [countryValue, setCountryValue] = useState<InternationalizedValue>({
        code: 'CM',
        dialCode: '+237',
    });

    const languages = [
        {
            code: 'fr',
            value: 'fr',
            label: t('Français', { ns: 'common' }),
        },
        {
            code: 'gb',
            value: 'en',
            label: t('Anglais', { ns: 'common' }),
        },
    ];

    const [data, setData] = useState<FormDataType>({
        checked: { value: true },
        phone_number_country_code: { value: 'CM' },
        phone_number_country_dial_code: { value: '+237' },
        phone_number: { value: '' },
        language: { value: languages[0].value },
    });

    const showTerms = () => {
        showModal(MODAL_TYPES.TERMS_MODAL, {});
    };

    const onSubmit = (requestpData: any) => {
        const data: Auth.ForgotPasswordData = {
            country_code: requestpData.phone_number_country_code as string,
            phone_number: normalizePhoneNumber(
                requestpData.phone_number,
                requestpData.phone_number_country_dial_code,
                requestpData.phone_number_country_code,
            ),
        };
        setIsLoading(true);
        AuthService.registerOrLogin(data)
            .then((response) => {
                setForgotPasswordData(response);
                setRemaining(delays.beforeResendCode);
                setTries(2);
                setTimeout(() => {
                    setIsLoading(false);
                    navigate('/auth/verify-password');
                }, 1000);
            })
            .catch(() => setIsLoading(false));
    };

    const onStatusHandler = (checked: boolean) => {
        setChecked(checked);
        if (checked) {
            showTerms();
        } else {
            dispatch(rejectTerms());
        }
    };

    /**
     * On submit signup form
     * Checked if the signup form is valid before submitting data
     */
    const recoveryHandler = (state: FormState, event: FormEvent) => {
        event.preventDefault();
        if (state.validator && state.validator.valid) {
            onSubmit(state.data);
        }
    };

    /**
     * On signup form input field changed
     * Chaning the status of the sumitted button
     */
    const onChangeHandler = (state: FormState) => {
        if (state.validator) {
            setDisabled(!state.validator.valid);
        }
    };

    useEffect(() => {
        //dispatch(resetRecoveryForm());
    });

    const onChangeLanguage = (value: string) => {
        i18n.changeLanguage(value.toLowerCase());
        Cookies.set('i18next', value.toLowerCase());
        setLanguage(value);
    };

    const getDefaultLanguage = () => {
        for (const lang in languages) {
            if (languages[lang].value === language) {
                return languages[lang];
            }
        }
        return languages[0];
    };

    useEffect(() => {
        setData({
            checked: { value: checked },
            language: { value: language },
            phone_number_country_code: { value: countryValue.code || '' },
            phone_number_country_dial_code: { value: countryValue.dialCode || '' },
            phone_number: { value: countryValue.value || '' },
        });
    }, [checked, language, countryValue]);

    return (
        <Form method="POST" data={data} request={validator} onChange={onChangeHandler} onSubmit={recoveryHandler}>
            <Select
                type="lang"
                id="language"
                onChange={onChangeLanguage}
                value={getDefaultLanguage()}
                name="language"
                valueKey="value"
                label={t('language', { ns: 'settings' })}
                placeholder={t('Sélectionner une langue', { ns: 'attributes' })}
                options={languages}
            ></Select>
            <InternationalizedInput
                id="phone_number"
                name="phone_number"
                countryCode={countryValue.code}
                onChange={setCountryValue}
                label={t('Votre numéro de téléphone', { ns: 'attributes' })}
            />
            <Checkbox
                onChange={onStatusHandler}
                id="checked"
                name="checked"
                label={t("J'ai lu et j'accepte les conditions", { ns: 'signup' })}
                labelClassName="usage-condition"
            />
            <Button label={t('validate', { ns: 'actions' })} loading={isLoading} disabled={disabled}></Button>
        </Form>
    );
}

export default ForgotPasswordForm;
