// useCreatePartyModal.ts
import { useAuth } from './use-auth';
import { hideMenu } from '@redux/reducers/menu-reducer';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { MODAL_TYPES, useModal } from '@contexts/global-modal-context';
import { useTranslation } from 'react-i18next';
import { Alert } from '@utils/alert';

export const useDeposit = () => {
    const { user } = useAuth();
    const { t } = useTranslation(['deposit']);
    const dispatch = useDispatch();
    const { showModal } = useModal();
    const [phoneNumberIsVerified, setPhoneNumberIsVerified] = useState<boolean>(false);

    useEffect(() => {
        setPhoneNumberIsVerified(user?.verified === true);
    }, [user]);

    const onDeposit = (amount?: number) => {
        dispatch(hideMenu());
        if (phoneNumberIsVerified) {
            showModal(MODAL_TYPES.DEPOSIT_MODAL, { amount });
        } else {
            Alert.info(t('confirm_label', { ns: 'alert' }), t('first_deposit'), [
                {
                    text: t('cancel', { ns: 'actions' }),
                },
                {
                    text: t('verified_number'),
                    onPress: onVerifiedNumber,
                },
            ]);
        }
    };

    const onVerifiedNumber = () => {
        showModal(MODAL_TYPES.VERIFY_PHONE_NUMBER, {});
    };

    return {
        onDeposit,
    };
};
