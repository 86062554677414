import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _Toast from '@models/toast';

export interface ToastState {
    toasts: _Toast[];
}

const initialState: ToastState = {
    toasts: [],
};

const toastSlice = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        clearToasts: (state) => {
            state.toasts = [];
        },
        addToast: (state, { payload }: PayloadAction<_Toast>) => {
            state.toasts = [...state.toasts, payload];
        },

        deleteToast: (state, { payload }: PayloadAction<number>) => {
            state.toasts = state.toasts.filter((toast: _Toast) => toast.id !== payload);
        },
    },
});

export const { addToast, deleteToast, clearToasts } = toastSlice.actions;

export default toastSlice.reducer;

export const toastSelector = (state: { toastStore: ToastState }) => state.toastStore;
