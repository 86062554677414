import Modal from '@components/ui/modal';
import React, { useEffect, useState } from 'react';
import { useModal } from '@contexts/global-modal-context';
import { useTranslation } from 'react-i18next';
import { Party } from 'interfaces/party.interface';
import Icon from '@components/ui/icon';
import './waiting-party-modal.css';
import { Player } from 'interfaces/player.interface';
import defaultAvatar from '@assets/default-avatar/9.png';
import GameService from '@services/game-service';
import { useAuth } from '@hooks/use-auth';
import { Alert } from '@utils/alert';
import Swal from 'sweetalert2';
import { JoinParty } from 'interfaces/join-party.interface';
import { d, e } from '@utils/encryption-wrapper';
import Event from '@constants/events';
import { useSocket } from '@hooks/use-socket';
import { useSettings } from '@hooks/use-settings';
import Button from '@components/ui/button';

type WaitingPartyModalProps = {
    onHideModal: (data: any) => void;
    game: Party;
};
function WaitingPartyModal(props: WaitingPartyModalProps): React.ReactElement {
    const { hideModal } = useModal();
    const { t } = useTranslation(['party']);
    const { user } = useAuth();
    const [gamePassword, setGamePassword] = useState<string>();
    const [status, setStatus] = useState<string>();
    const [game, setGame] = useState<Party>(props.game);
    const [countPlayers, setCountPlayers] = useState<number>(props.game.players.length);
    const { onVibrateOrBeep } = useSettings();
    const { socket } = useSocket();
    const [isJoining, setIsJoining] = useState<boolean>(false);
    const [isStarting, setIsStarting] = useState<boolean>(false);
    const [isCancelling, setIsCancelling] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isExiting, setIsExiting] = useState<boolean>(false);

    const _onCancel = () => {
        Alert.confirm(t('confirm_label', { ns: 'alert' }), t('cancel_part_question', { ns: 'alert' }), _cancelGame, {
            cancelable: true,
        });
    };

    const _cancelGame = () => {
        if (user?.id) {
            setIsLoading(true);
            setIsCancelling(true);
            GameService.cancelParty(game.id, user.id)
                .then(() => {
                    onClose();
                    setIsLoading(false);
                    setIsCancelling(false);
                })
                .catch((error) => {
                    Alert.alert(t('global.oops'), error.message);
                    setIsLoading(false);
                    setIsCancelling(false);
                });
        }
    };

    const _startGame = () => {
        if (game.players.length < 2) {
            Alert.alert(t('inf_label', { ns: 'alert' }), t('cannot_play_alone', { ns: 'alert' }));
        } else {
            setIsLoading(true);
            setIsStarting(true);
            GameService.startGame(game.id)
                .then(() => {
                    onClose();
                    setIsLoading(false);
                    setIsStarting(false);
                })
                .catch((error) => {
                    setIsLoading(false);
                    setIsStarting(false);
                    Alert.alert(t('oops', { ns: 'global' }), error.message);
                });
        }
    };

    useEffect(() => {
        socket?.on(Event.LEAVE_GAME, onLeaveGame);
        socket?.on(Event.DISCONNECT, _onSocketDisconnect);
        socket?.on(Event.PENDING_GAME_UPDATE, onPendingGameUpdate);
        socket?.on(Event.PENDING_GAME_EXIT, onPendingGameExit);
    }, []);

    useEffect(() => {
        if (game.players.length > countPlayers) {
            onVibrateOrBeep();
            setCountPlayers(game.players.length);
        }
    }, [game]);

    const onLeaveGame = (game_id: string) => {
        game_id = d(game_id);
        if (game_id === game_id) _onKick();
    };

    const onPendingGameUpdate = (partUpdateData: string) => {
        const partUpdate: Party = d(partUpdateData);
        if (partUpdate.id === game.id) {
            setGame(partUpdate);
        }
    };

    const onClose = () => {
        hideModal();
    };

    const onPendingGameExit = (partIdData: string) => {
        if (game.id === d(partIdData)) {
            onClose();
        }
    };

    const _onKick = () => {
        if (status === 'after') {
            setStatus('before');
            onClose();
            Alert.alert(t('inf_label', { ns: 'alert' }), t('exclusion_notification', { ns: 'alert' }));
        }
    };

    const _onSocketDisconnect = () => onClose();
    const isInPart = game.players.findIndex((player) => player.user_id === user?.id) >= 0;

    const showLocationDeniedAlert = () =>
        Alert.info(t('inf_label', { ns: 'alert' }), t('cannot_join_part', { ns: 'alert' }));

    const joinPart = (password?: string, position?: GeolocationPosition) => {
        const params: JoinParty = {
            game_id: game.id,
            password: password,
            location: { latitude: position?.coords.latitude, longitude: position?.coords.longitude },
        };
        setIsLoading(true);
        setIsJoining(true);
        GameService.joinParty(params)
            .then(() => {
                setStatus('after');
                setIsLoading(false);
                setIsJoining(false);
            })
            .catch(() => {
                setIsLoading(false);
                setIsJoining(false);
            });
    };

    const handleJoinTheParty = () => {
        if (game.password?.length) {
            _requestPassword();
            return;
        }

        joinPartyProcessed();
    };

    const handleQuitTheParty = () => {
        Alert.confirm(t('confirm_label', { ns: 'alert' }), t('quit_part_question', { ns: 'alert' }), _quitTheParty, {
            cancelable: true,
        });
    };

    const _quitTheParty = () => {
        setIsLoading(true);
        setIsExiting(true);
        GameService.quitParty(game.id)
            .then(() => {
                setStatus('before');
                socket?.emit(Event.LEAVE_GAME, e(game.id));
                setIsLoading(false);
                setIsExiting(false);
                hideModal();
            })
            .catch(() => {
                hideModal();
                setIsLoading(false);
                setIsExiting(false);
            });
    };

    const joinPartyProcessed = (password?: string) => {
        if (game.max_nb_players > 1 && game.bet > 0) {
            setGamePassword(password);
            checkLocationPermission();
        } else {
            joinPart(password);
        }
    };

    const checkLocationPermission = async () => {
        try {
            // Check if Permissions API is supported
            if ('permissions' in navigator) {
                const permissionStatus = await navigator.permissions.query({
                    name: 'geolocation',
                });

                if (permissionStatus.state === 'granted') {
                    // Location permission already granted
                    requestLocation();
                } else if (permissionStatus.state === 'prompt') {
                    // Location permission not yet granted, request it
                    requestLocation();
                } else if (permissionStatus.state === 'denied') {
                    // Location permission denied
                    showLocationDeniedAlert();
                }

                // Listen for changes in permission state
                permissionStatus.onchange = () => {
                    if (permissionStatus.state === 'granted') {
                        requestLocation();
                    } else if (permissionStatus.state === 'denied') {
                        showLocationDeniedAlert();
                    }
                };
            } else {
                // If Permissions API is not supported, directly request location
                requestLocation();
            }
        } catch (error) {
            console.error('Error checking location permission:', error);
        }
    };

    const handleLocationGranted = (position: GeolocationPosition) => {
        joinPart(gamePassword, position);
    };

    const requestLocation = () => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                // Location granted
                handleLocationGranted(position);
            },
            (error) => {
                if (error.code === error.PERMISSION_DENIED) {
                    // User denied location permission
                    showLocationDeniedAlert();
                } else {
                    console.error('Error requesting location:', error);
                }
            },
        );
    };

    const _requestPassword = async () => {
        const { value: password } = await Swal.fire({
            title: t('enter_game_password', { ns: 'game' }),
            input: 'password', // 'input' should be a string like 'text', 'email', 'password', etc.
            inputPlaceholder: t('enter_game_password', { ns: 'game' }),
            inputAttributes: {
                maxLength: '100%',
                autoCapitalize: 'off',
                autoCorrect: 'off',
            },
            showCancelButton: true,
            confirmButtonText: t('join_part', { ns: 'actions' }),
            cancelButtonText: t('cancel', { ns: 'actions' }),
            preConfirm: (value) => {
                setGamePassword(value);
                if (!value) {
                    Swal.showValidationMessage('You need to write something!');
                }
                return value; // Return the value to continue with the SweetAlert2 flow
            },
        });

        if (password) {
            setGamePassword(password);
            joinPartyProcessed(password);
        }
    };

    const handleCloseModal = () => {
        if (game.creator_id == user?.id) {
            _promptCancelGame();
        } else {
            onClose();
        }
    };

    const _promptCancelGame = () => {
        Alert.alert(t('confirm_label', { ns: 'alert' }), t('cancel_part_question', { ns: 'alert' }), [
            {
                text: t('no', { ns: 'global' }),
                onPress: () => null,
            },
            {
                text: t('yes', { ns: 'global' }),
                onPress: () => _cancelGame(),
            },
        ]);
    };

    return (
        <Modal
            show={true}
            onClose={handleCloseModal}
            hideOnAreaClick={game.creator_id != user?.id}
            hideOnClose={false}
            id="party-modal"
            title="Partie en attente..."
        >
            <div className="form-container">
                <div>
                    <fieldset className="d-flex flex-row align-items-center gap-4 py-1">
                        <Icon name={'bet'} className="awaiting-game-icon"></Icon>
                        <div className="d-flex align-items-center gap-3 awaiting-g-bet">
                            <strong>{game.bet} XAF</strong>
                            <span className="notif-badge">{game.korat ? 'K x2' : ''}</span>
                            <span className="notif-badge">{game.double_korat ? '2K x4' : ''}</span>
                        </div>
                    </fieldset>
                    <fieldset className="d-flex flex-row align-items-center gap-4 py-1">
                        <Icon name={'users'} className="awaiting-game-icon"></Icon>
                        <div className="d-flex align-items-center gap-1 awaiting-g-bet">
                            <strong>{game.max_nb_players} adversaire(s) max</strong>
                        </div>
                    </fieldset>
                    <fieldset className="d-flex flex-row align-items-center gap-4 py-1">
                        <Icon name={'timer'} className="awaiting-game-icon"></Icon>
                        <div className="d-flex align-items-center gap-1 awaiting-g-bet">
                            <strong>{game.time_per_move}s par coup</strong>
                        </div>
                    </fieldset>
                    <fieldset className="d-flex flex-row align-items-center gap-4 py-1">
                        <Icon name={'security-special'} className="awaiting-game-icon"></Icon>
                        <div className="d-flex align-items-center gap-1 awaiting-g-bet">
                            {game.password.length == 0 && <strong>{t('no_password', { ns: 'game' })}</strong>}
                            {game.password.length > 0 && (
                                <strong>
                                    {game.creator_id == user?.id ? game.password : t('private_part', { ns: 'game' })}
                                </strong>
                            )}
                        </div>
                    </fieldset>
                    {game.special_mode && (
                        <fieldset className="d-flex flex-row align-items-center gap-4 py-1">
                            <Icon name={'settings'} className="awaiting-game-icon"></Icon>
                            <div className="d-flex align-items-center gap-1 awaiting-g-bet">
                                <strong>{t('stopped_balls_list', { ns: 'game' })}</strong>
                            </div>
                        </fieldset>
                    )}
                </div>
                <div className="separator light"></div>
                {game.players.map((player: Player) => {
                    return (
                        <div className="creator-infos" key={player._id}>
                            <div className="user-avatar">
                                <img src={defaultAvatar} alt="" />
                            </div>
                            <span className="user-name">
                                <b>{player.pseudo}</b>
                            </span>
                        </div>
                    );
                })}
                <div className="actions">
                    {game.creator_id == user?.id && (
                        <Button
                            className="danger text-center d-flex justify-content-center "
                            label={t('cancel', { ns: 'actions' })}
                            onClick={_onCancel}
                            loading={isCancelling}
                            disabled={isLoading}
                        />
                    )}

                    {game.creator_id == user?.id && (
                        <Button
                            type="button"
                            className="default switch-modal-trigger"
                            onClick={_startGame}
                            loading={isStarting}
                            disabled={isLoading}
                            data-modal="modal-two"
                        >
                            {t('launch', { ns: 'actions' })}
                            <span className="icon icon16">
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M20.7072 7.70711C21.0977 7.31658 21.0977 6.68342 20.7072 6.29289C20.3166 5.90237 19.6835 5.90237 19.293 6.29289L20.7072 7.70711ZM9.0001 18L8.29301 18.7071C8.68353 19.0976 9.31669 19.0976 9.70721 18.7071L9.0001 18ZM4.7071 12.2929C4.31657 11.9024 3.6834 11.9024 3.29288 12.2929C2.90236 12.6834 2.90237 13.3166 3.2929 13.7071L4.7071 12.2929ZM19.293 6.29289L8.293 17.2929L9.70721 18.7071L20.7072 7.70711L19.293 6.29289ZM3.2929 13.7071L8.29301 18.7071L9.7072 17.2928L4.7071 12.2929L3.2929 13.7071Z"
                                        fill="white"
                                    />
                                </svg>
                            </span>
                        </Button>
                    )}

                    {game.creator_id != user?.id && !isInPart && (
                        <Button
                            type="button"
                            className="default switch-modal-trigger"
                            onClick={handleJoinTheParty}
                            disabled={isLoading}
                            loading={isJoining}
                            data-modal="modal-two"
                        >
                            {t('join_part', { ns: 'actions' })}
                            <span className="icon icon16">
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M20.7072 7.70711C21.0977 7.31658 21.0977 6.68342 20.7072 6.29289C20.3166 5.90237 19.6835 5.90237 19.293 6.29289L20.7072 7.70711ZM9.0001 18L8.29301 18.7071C8.68353 19.0976 9.31669 19.0976 9.70721 18.7071L9.0001 18ZM4.7071 12.2929C4.31657 11.9024 3.6834 11.9024 3.29288 12.2929C2.90236 12.6834 2.90237 13.3166 3.2929 13.7071L4.7071 12.2929ZM19.293 6.29289L8.293 17.2929L9.70721 18.7071L20.7072 7.70711L19.293 6.29289ZM3.2929 13.7071L8.29301 18.7071L9.7072 17.2928L4.7071 12.2929L3.2929 13.7071Z"
                                        fill="white"
                                    />
                                </svg>
                            </span>
                        </Button>
                    )}

                    {game.creator_id != user?.id && isInPart && (
                        <Button
                            type="button"
                            className="danger d-flex justify-content-center "
                            onClick={handleQuitTheParty}
                            label={t('quit', { ns: 'actions' })}
                            loading={isExiting}
                            disabled={isLoading}
                        />
                    )}
                </div>
            </div>
        </Modal>
    );
}

export default WaitingPartyModal;
