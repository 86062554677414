import React from 'react';
import './game-analysis.css'; // Add this CSS for the modal and animations
import { Game } from 'interfaces/game.interface';
import Cards from '@constants/cards';
import { useTranslation } from 'react-i18next';

type GameAnalysisModalProps = {
    game: Game;
    isOpen: boolean;
    onClose: () => void;
};

function GameAnalysisModal({
    game,
    isOpen,
    onClose,
}: GameAnalysisModalProps): React.ReactElement<GameAnalysisModalProps> {
    const { t } = useTranslation(['alert']);
    return (
        <div className={`m-modal ${isOpen ? 'open' : ''}`}>
            <div className="m-modal-overlay" onClick={onClose}></div>
            <div className={`m-modal-content ${isOpen ? 'unfold' : ''}`}>
                <h5 className="text-center text-success my-3 text-header py-3">{t('card_history', { ns: 'game' })}</h5>
                <div className="players">
                    {game.players.map((player, index) => (
                        <div key={index} className="player-column">
                            <h6 className={game.beginner_token == index ? 'beginner' : ''}>
                                <b>{player.pseudo}</b>
                            </h6>
                            <div className="cards">
                                {player.playCards.map((card, idx) => (
                                    <div
                                        key={idx}
                                        className={`card animate`}
                                        style={{ animationDelay: `${idx * 0.5}s` }}
                                    >
                                        <img
                                            className={player.resigned ? 'resigned' : ''}
                                            src={Cards[card]}
                                            alt="Card 11"
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="d-flex justify-content-end">
                    <button className="close-btn" onClick={onClose}>
                        {t('close', { ns: 'actions' })}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default GameAnalysisModal;
