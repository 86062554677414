import moment from 'moment';

export const contractNumber = (number: number) => {
    if (typeof number !== 'number') {
        return number;
    }

    if (number < 1000) {
        return number;
    }

    if (number < 1000000) {
        return Math.round(number / 100) / 10 + 'k';
    }

    if (number < 1000000000) {
        return Math.round(number / 100000) / 10 + 'M';
    }

    return Math.round(number / 100000000) / 10 + 'Md';
};

export const twoDigits = (int: number) => {
    return int < 10 ? '0' + int : int;
};

// http://phpjs.org/functions/chunk_split:369
function chunk_split(body: string, chunklen: number | string = 76, end: string) {
    // Returns split line
    //
    // version: 1103.1210
    // discuss at: http://phpjs.org/functions/chunk_split
    // +   original by: Paulo Freitas
    // +      input by: Brett Zamir (http://brett-zamir.me)
    // +   bugfixed by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
    // +   improved by: Theriault
    // *     example 1: chunk_split('Hello world!', 1, '*');
    // *     returns 1: 'H*e*l*l*o* *w*o*r*l*d*!*'
    // *     example 2: chunk_split('Hello world!', 10, '*');
    // *     returns 2: 'Hello worl*d!*'
    if (typeof chunklen === 'string') {
        chunklen = parseInt(chunklen, 10);
    }
    end = end || '\r\n';

    if (chunklen < 1) {
        return false;
    }

    return body.match(new RegExp('.{0,' + chunklen + '}', 'g'))?.join(end);
}

const strrev = (str: string) => str.split('').reverse().join('');
const ltrim = (str: string, char = ' ') => str.replace(new RegExp(`^${char}+`, 'g'), '');

export const formatAmount = (amount: number, abs = false): number | string => {
    if (amount === 0) return 0;

    if (amount < 0) return (abs ? '' : '-') + formatAmount(-amount);

    return (strrev(chunk_split(strrev(ltrim(amount + '', '0')), 3, ' ') as string) || '').trim();
};

export const truncate = (str: string, max: number) => {
    if (str === undefined || str === null || str.length <= max) return str;

    return str.substr(0, max - 2) + '...';
};

export function toLowerCase(text: string) {
    return String(text).trim().toLowerCase();
}

export function toUpperCase(text: string) {
    return String(text).trim().toUpperCase();
}

export function formatTime(time: number): string {
    const intergerTime: number = Math.max(time, 0);
    const min = Math.floor(intergerTime / 60);
    const sec = intergerTime % 60;
    return twoDigits(min) + ' : ' + twoDigits(sec);
}

export function formatChatDate(date: Date): string {
    const momentDate = moment(date);

    // If the date is from today
    if (momentDate.isSame(new Date(), 'day')) {
        return momentDate.format('HH:mm');
    }

    // If the date is from yesterday
    if (momentDate.isSame(moment().subtract(1, 'day'), 'day')) {
        return `Yesterday at ${momentDate.format('HH:mm')}`;
    }

    // If the date is from within the last week
    if (momentDate.isAfter(moment().subtract(7, 'days'))) {
        return momentDate.format('dddd [at] HH:mm'); // e.g., "Tuesday at 14:35"
    }

    // For dates older than a week
    return momentDate.format('MMM D, YYYY [at] HH:mm');
}
