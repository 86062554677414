import { Validator, ValidatorValue } from './common';

export const PseudoValidator: Validator = {
    passed: (_, value: ValidatorValue, pseudo: ValidatorValue): boolean => {
        if (!value && (pseudo === null || pseudo === undefined)) {
            return true;
        }
        if (typeof pseudo === 'string') {
            return !pseudo.match(/^(fapfap|arbitre)/i) && !!pseudo.match(/^[\w]{3,14}$/i);
        }
        return false;
    },
    message: 'Le pseudo {value} est invalide',
};
