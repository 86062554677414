import { FormRequest } from './common';

export default class RegisterFormRequest extends FormRequest {
    protected rules = {
        pseudo: [
            {
                pseudo: true,
                message: 'Le pseudo est invalide.',
            },
        ],
        password: [
            {
                password: true,
                message: 'Le mot de passe doit avoir au moins 6 caractères.',
            },
            {
                confirm: true,
                message: 'Le mot de passe ne correspond pas.',
            },
        ],
    };
}
