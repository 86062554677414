import { PhoneValidator } from './phone-validator';
import { PseudoValidator } from './pseudo-validator';
import { RequiredValidator } from './required-validator';
import { PasswordValidator } from './password-validator';
import { ConfirmValidator } from './confirm-validator';
import { EmailValidator } from './email-validator';
import { MaxdValidator } from './max-validator';
import { MindValidator } from './min-validator';
import { CheckedValidator } from './checked-validator';
export type RequestValue = string | boolean | number | null | undefined;
export type ValidatorValue = string | boolean | number;
export interface Validator {
    passed(
        attribute: string,
        value: ValidatorValue,
        input: RequestValue,
        request?: { [x: string]: RequestValue },
    ): boolean;
    message: string;
}

export type ValidatorType =
    | 'phone'
    | 'pseudo'
    | 'required'
    | 'password'
    | 'confirm'
    | 'email'
    | 'min'
    | 'max'
    | 'checked';

export const validators: { [x in ValidatorType]: Validator } = {
    phone: PhoneValidator,
    pseudo: PseudoValidator,
    required: RequiredValidator,
    password: PasswordValidator,
    confirm: ConfirmValidator,
    email: EmailValidator,
    min: MindValidator,
    max: MaxdValidator,
    checked: CheckedValidator,
};
