import React, { useEffect, useRef } from 'react';
import './connexion-status-icon.css';
import { useSocket } from '@hooks/use-socket';
import { ReactComponent as WifiIcon } from '@assets/icons/wifi.svg';
import { useTranslation } from 'react-i18next';
import { useToast } from '@hooks/use-toast';
import { useAuth } from '@hooks/use-auth';
import { ConnexionStatusState } from '@enums/connexion-status-type';

const ConnectionStatusIcon: React.FunctionComponent = () => {
    const { isConnected: isSocketConnected, state: socketState } = useSocket();
    const { authed } = useAuth();
    const { t } = useTranslation('messages');
    const toast = useToast();
    const prevStateRef = useRef<ConnexionStatusState>();

    // Determine icon color based on connection statuses
    const iconColor = isSocketConnected ? 'on' : 'off';
    const statusText = isSocketConnected ? t('network_established') : t('network_broken');

    useEffect(() => {
        if (authed) {
            if (prevStateRef.current == ConnexionStatusState.OFFLINE && socketState == ConnexionStatusState.ONLINE) {
                setTimeout(() => {
                    toast?.clear();
                    toast?.success(statusText);
                }, 10);
            } else if (
                prevStateRef.current == ConnexionStatusState.ONLINE &&
                socketState == ConnexionStatusState.OFFLINE
            ) {
                setTimeout(() => {
                    toast?.error(statusText, -1);
                }, 10);
            }
        }
        prevStateRef.current = socketState;
    }, [socketState]);

    if (!authed || isSocketConnected) {
        return null;
    }

    return (
        <span className={`px-2 py-1 d-flex border-radius-13 gap-2 connexion-status ${iconColor}`}>
            <span className="icon icon12">
                <WifiIcon />
            </span>
        </span>
    );
};

export default ConnectionStatusIcon;
