import InputField from '@components/ui/input';
import Form from '@components/ui/form';
import React, { useEffect, FormEvent, useState } from 'react';
import { FormState } from '@reducers/form-reducer';
import Button from '@components/ui/button';
import { useNavigate } from 'react-router-dom';
import RegisterFormRequest from '@requests/register-request';
import { useAuth } from '@hooks/use-auth';
import { useLocalStorage } from '@hooks/use-local-storage';
import { QuickRegisterData, RegisterData } from '@models/auth';
import { useTranslation } from 'react-i18next';
import AuthService from '@services/auth-service';
import { __SIGNUP_DATA__ } from '@constants/storage';
import { DEFAULT_ROUTE } from '@config/app';
import { useSideBar } from '@hooks/use-sidebar';
import { SidebarType } from '@enums/sidebar.enum';
import { FormDataType, FormInputType } from '@ctypes/form-data';

function ConfirmPasswordForm(): React.ReactElement {
    const { t } = useTranslation(['confirm-password']);
    // Get the posts from the store
    const navigate = useNavigate();
    const { login, authed } = useAuth();
    const [session] = useLocalStorage<RegisterData>(__SIGNUP_DATA__);
    const [pseudo, setPseudo] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');
    const [referrer, setReferrer] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isRegistered, setIsRegistered] = useState<boolean>(false);
    const { setSideBar } = useSideBar();
    const [confirmationData, setConfirmationData] = useState<FormDataType>({
        phone_number: { value: session?.phone_number },
        pseudo: { value: '' },
        password: { value: '' },
        password_confirmation: { value: '' },
        referrer: { value: '' },
    });

    const validator = new RegisterFormRequest();

    useEffect(() => {
        setConfirmationData({
            phone_number: { value: session?.phone_number },
            pseudo: { value: pseudo },
            password: { value: password },
            password_confirmation: { value: passwordConfirmation },
            referrer: { value: referrer },
        });
    }, [pseudo, password, passwordConfirmation, referrer]);

    const completeInformation = (state: FormState, event: FormEvent) => {
        event.preventDefault();

        if (!state.validator || state.validator.valid) {
            onCompleteInformation(state.data);
        }
    };

    const onCompleteInformation = (state: FormInputType) => {
        const data = {
            ...state,
            country_code: session.country_code,
            phone_number: session.phone_number,
        };
        setIsLoading(true);
        AuthService.register(data as QuickRegisterData)
            .then((sessionData) => {
                const currentTimestamp = Date.now();
                setIsRegistered(true);
                login({
                    ...sessionData,
                    isFirstUsage: true,
                    logged_at: currentTimestamp,
                });
                setSideBar(SidebarType.DASHBOARD);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        if (authed && isRegistered) {
            navigate(DEFAULT_ROUTE);
        }
    }, [authed, isRegistered]);

    return (
        <Form action="/" method="POST" request={validator} onSubmit={completeInformation} data={confirmationData}>
            <InputField
                type="text"
                label={t('Pseudo', { ns: 'attributes' })}
                id="pseudo"
                name="pseudo"
                placeholder="Ex : Jojo"
                value={pseudo}
                onInput={setPseudo}
            ></InputField>
            <InputField
                type="password"
                label={t('Mot de passe', { ns: 'attributes' })}
                id="password"
                name="password"
                value={password}
                onInput={setPassword}
                placeholder={t('Votre mot de passe', { ns: 'attributes' })}
            ></InputField>
            <InputField
                type="password"
                label={t('Confirmer mot de passe', { ns: 'attributes' })}
                id="password_confirmation"
                name="password_confirmation"
                value={passwordConfirmation}
                onInput={setPasswordConfirmation}
                placeholder={t('Retapez le mot de passe', { ns: 'attributes' })}
            ></InputField>
            <InputField
                type="text"
                label={t('Code promo', { ns: 'attributes' })}
                id="referrer"
                name="referrer"
                value={referrer}
                onInput={setReferrer}
                placeholder={t('Code promo', { ns: 'attributes' })}
            ></InputField>
            <Button
                label={t('Verifie ton compte', { ns: 'attributes' })}
                loading={isLoading}
                disabled={isLoading}
                icon="security"
            ></Button>
        </Form>
    );
}

export default ConfirmPasswordForm;
