import React, { FormEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import Form from '@components/ui/form';
import Button from '@components/ui/button';
import { useToast } from '@hooks/use-toast';
import { useAuth } from '@hooks/use-auth';
import { FormState } from '@reducers/form-reducer';
import BInputField from '@components/ui/b-input';
import { useModal } from '@contexts/global-modal-context';
import SmsCodeFormRequest from '@requests/sms-code-request';
import { getAuth, signInWithPhoneNumber } from 'firebase/auth';
import { app } from '@utils/firebase';
import { useTranslation } from 'react-i18next';
import AuthService from '@services/auth-service';
import { RootState } from '@redux/store';
import { useDispatch } from 'react-redux';
import { resetState } from '@redux/reducers/verify-phone-reducer';
import { EventEmitter } from '@utils/event-emitter';
import DisplayHtml from '@components/ui/html';

function VerifyCodeForm(): React.ReactElement {
    const validator = new SmsCodeFormRequest();
    const { user, updateSession } = useAuth();
    const auth = getAuth(app);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState({
        code: { value: '' },
    });
    const { t } = useTranslation(['deposit']);
    const dispatch = useDispatch();
    // Get the posts from the store
    const { phoneNumber, password } = useSelector((state: RootState) => state.verifyPhoneStore);
    const { hideModal } = useModal();
    //const { updateSession } = useAuth();
    const toast = useToast();

    /**
     * On submit signup form
     * Checked if the signup form is valid before submitting data
     */
    const phoneVerificationCodeHandler = (state: FormState, event: FormEvent) => {
        event.preventDefault();
        if (state.validator && state.validator.valid) {
            verifyProcess();
        }
    };

    /**
     * On signup form input field changed
     * Chaning the status of the sumitted button
     */
    const onChangeHandler = (state: FormState) => {
        if (state.validator) {
            setDisabled(!state.validator.valid);
        }
    };

    const verifyProcess = () => {
        setIsLoading(true);
        const requestData = {
            code: data.code.value,
            password: password as string,
        };
        AuthService.changePhoneNumber(requestData)
            .then((responseData) => {
                setIsLoading(false);
                const successMessageCode = user?.verified ? 'update_phone_success' : 'verify_phone_success';
                updateSession({
                    user: responseData.user,
                    secret: responseData.secret,
                    isFirstUsage: false,
                });
                toast?.success(t(successMessageCode, { ns: 'alert' }));
                dispatch(resetState());
                EventEmitter.emit('updateUser');
                hideModal();
            })
            .catch((error) => {
                setIsLoading(false);
                toast?.error(t('oops', { ns: 'global' }), error);
            });
    };

    // Renvoyer le code
    const resendOTPCode = () => {
        setIsLoading(true);
        const appVerifier = window.recaptchaVerifier;
        signInWithPhoneNumber(auth, phoneNumber, appVerifier)
            .then((confirmationResult) => {
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                window.confirmationResult = confirmationResult;
                setIsLoading(false);
                toast?.success(t('Le code a été renvoyé par SMS', { ns: 'messages' }));
            })
            .catch(() => {
                // Error; SMS not sent
                toast?.error(t('Envoi SMS échoué', { ns: 'messages' }));
            });
    };

    return (
        <Form
            method="POST"
            request={validator}
            onChange={onChangeHandler}
            onSubmit={phoneVerificationCodeHandler}
            data={data}
        >
            <p>
                <DisplayHtml htmlString={t('verify code message', { ns: 'messages', phone: phoneNumber })} />
            </p>
            <BInputField
                blackTheme={true}
                type="text"
                label="Code"
                id="code"
                name="code"
                placeholder="Code"
                value={data.code.value}
                onInput={(v) => setData({ code: { value: v } })}
            ></BInputField>
            <Button
                label={t('Vérifier', { ns: 'attributes' })}
                icon="security"
                id="deposit-btn"
                disabled={disabled}
                loading={isLoading}
                className="mt-3"
            ></Button>
            <p className="text-center">
                {t('Me renvoyer le code', { ns: 'messages' })}
                <a className="emp btn-link" onClick={resendOTPCode}>
                    {t('Validate', { ns: 'attributes' })}
                </a>
            </p>
        </Form>
    );
}

export default VerifyCodeForm;
