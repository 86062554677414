import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import TransactionService from '@services/transaction-service';
import { FAILED, LOADING, SUCCESSFUL, EMPTY } from '@models/status';
import Deposit from '@models/deposit';
export const SMS_CODED_SEND = 'SMS_CODE_SEND';
export const FIREBASE_REQUEST_CODE = 'FIREBASE_REQUEST_CODE';
export const FIREBASE_VERIFY_CODE = 'FIREBASE_VERIFY_CODE';
export const FIREBASE_PHONE_VERIFIED = 'FIREBASE_PHONE_VERIFIED';
export const UNKNOWN = 'UNKNOWN';

type DepositState = {
    data: any;
    status: typeof FAILED | typeof LOADING | typeof SUCCESSFUL | typeof EMPTY | typeof SMS_CODED_SEND;
    error: any;
    step: typeof UNKNOWN | typeof FIREBASE_REQUEST_CODE | typeof FIREBASE_VERIFY_CODE | typeof FIREBASE_PHONE_VERIFIED;
    phoneNumber: string;
    countryCode: string;
};
const initialState: DepositState = {
    data: {},
    status: EMPTY,
    error: null,
    step: UNKNOWN,
    phoneNumber: '',
    countryCode: '',
};

// Handle POST request to create a new post
export const deposit: any = createAsyncThunk(
    // The name of the action
    'transaction/deposit',
    // The payload creator
    async (data: Deposit, { rejectWithValue }) => {
        try {
            const res = await TransactionService.deposit(data);
            return {
                ...res,
                ...data,
            };
        } catch (err: any) {
            return rejectWithValue({ error: err.message });
        }
    },
);

const depositSlice = createSlice({
    /* The name of the slice[this will also be used as the action type string 
      in combination with the extraReducer name i.e posts/getPosts or posts/addPost] 
    */
    name: 'deposit',
    // initialState: initialState[ES6 destructuring syntax]
    initialState,
    // Add reducers for the synchronous actions on the UI[we are not using this property for this tutorial]
    reducers: {
        resetState: (state) => {
            state.status = initialState.status;
            state.data = initialState.data;
            state.error = initialState.error;
            state.step = initialState.step;
        },
        SMSCodeHasBeenSended: (state, action) => {
            state.status = SMS_CODED_SEND;
            state.step = FIREBASE_VERIFY_CODE;
            state.phoneNumber = action.payload.phone_number;
            state.countryCode = action.payload.country_code;
        },
        setInitialStep: (state, action) => {
            state.status = SMS_CODED_SEND;
            state.step = action.payload;
        },
    },
    // Add extraReducers for the asynchronous actions on the UI
    extraReducers: {
        [deposit.pending]: (state) => {
            // When data is being fetched
            state.status = LOADING;
        },
        [deposit.fulfilled]: (state, action: any) => {
            // When data is fetched successfully
            state.status = SUCCESSFUL;
            // Concatenate the new data to the existing data in the array
            state.data = {
                ...action.payload,
                amount: action.payload.amount,
            };
        },
        [deposit.rejected]: (state, action) => {
            // When data is fetched unsuccessfully
            state.status = FAILED;

            // Update the error message for proper error handling
            state.error = action.error.message;
        },
    },
});
export const { resetState, SMSCodeHasBeenSended, setInitialStep } = depositSlice.actions;
export default depositSlice.reducer;
