import React from 'react';
import useBodyClass from '@hooks/body-class';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import GamerHeader from '@components/ui/gamer-header';
import '@assets/fonts/styles.css';
import '@assets/css/style.css';
import { LayoutProps } from '@ctypes/layout-props';
import useScript from '@hooks/use-script';
import SvgIcon from '@components/ui/svg-icon';
import { MODAL_TYPES, useModal } from '@contexts/global-modal-context';
import { Party } from 'interfaces/party.interface';
import { useDispatch } from 'react-redux';
import { setGame } from '@redux/reducers/game-reducer';

// Notice here we're using the function declaration with the interface Props
const GamerLayout: React.FunctionComponent = ({ sidebar: Sidebar }: LayoutProps) => {
    const location = useLocation();
    useBodyClass('page-dark app');
    useScript('/script/custom-elements.js');
    useScript('/script/profile.js');
    useScript('/script/app.js');
    useScript('/script/jquery-3.1.1.min.js', true, undefined, false);
    useScript('/script/jquery.totalScroll.js', true, undefined, false);
    const { showModal } = useModal();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const createNewParty = () => {
        showModal(MODAL_TYPES.CREATE_PARTY_MODAL, {}, handleOnPartyCreated);
    };

    const handleOnPartyCreated = (game?: Party) => {
        if (game) {
            if (location.pathname == '/apps/games/list') {
                dispatch(setGame(game));
                setTimeout(() => {
                    showModal(MODAL_TYPES.WAITING_PARTY_MODAL, { game });
                }, 100);
            } else {
                navigate('/apps/games/list', { state: { game } });
            }
        }
    };

    return (
        <>
            <div className="section dashboard">
                <div className="container grid-full">
                    {Sidebar && <Sidebar />}
                    <GamerHeader></GamerHeader>
                    <div className="widget-page-content">
                        <Outlet />
                    </div>
                </div>
                <div className="new-game-trigger modal-trigger" onClick={createNewParty}>
                    <span className="icon icon32 float-btn">
                        <SvgIcon name="add-party"></SvgIcon>
                    </span>
                </div>
            </div>
        </>
    );
};

export default GamerLayout;
