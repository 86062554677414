import { DependencyList, EffectCallback, useEffect, useRef } from 'react';
import useMount from './use-mount';

const useUnMount = (effect: EffectCallback, dependencies?: DependencyList) => {
    const unMounted = useRef(false);

    // with react 18 component will be mounted twice in dev mode, so setting the reference to false on the second mount
    useMount(() => {
        unMounted.current = false;
    });

    // to identify unmount
    useEffect(
        () => () => {
            unMounted.current = true;
        },
        [],
    );

    // wrap dependencies with the callback
    useEffect(
        () => () => {
            if (unMounted.current) {
                effect();
            }
        },
        [dependencies, effect],
    );
};

export default useUnMount;
