import { useEffect } from 'react';

const useScript = (url: string, isHead = false, integrity?: string, async = true, crossOrigin = 'anonymous') => {
    useEffect(() => {
        const script = document.createElement('script');

        script.src = url;

        script.async = async;

        if (integrity) {
            script.integrity = integrity;
        }

        script.crossOrigin = crossOrigin;
        if (!isHead) {
            document.body.appendChild(script);

            return () => {
                document.body.removeChild(script);
            };
        } else {
            document.getElementsByTagName('head')[0].appendChild(script);
            return () => {
                document.getElementsByTagName('head')[0].removeChild(script);
            };
        }
    }, [url, integrity, async, crossOrigin, isHead]);
};

export default useScript;
