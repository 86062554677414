import Date from '@utils/date';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './referral-user.css';
// Notice here we're using the function declaration with the interface Props
export interface WidgetProps {
    user: any;
}

const ReferralUser: React.FunctionComponent<WidgetProps> = ({ user }: WidgetProps) => {
    const { t } = useTranslation(['referral']);
    return (
        <div className="referal-user-container">
            <p>{user.pseudo}</p>
            <div className="row">
                <strong>{t('Créé le ', { ns: 'attributes' })}:</strong>
                <p>{new Date(user.register_date).toFrDatetime()}</p>
            </div>
            <div className="row">
                <strong>{t('Dernière connexion ', { ns: 'attributes' })}:</strong>
                <p>{new Date(user.last_connection).toFrDatetime()}</p>
            </div>
        </div>
    );
};

export default ReferralUser;
