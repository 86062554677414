class _Vibration {
    private vibrationAudio: HTMLAudioElement;
    private beepAudio: HTMLAudioElement;

    constructor() {
        this.vibrationAudio = new Audio('/audios/phone-vibration-96623.mp3');
        this.beepAudio = new Audio('/audios/beep-07a.mp3');
    }

    playVibrationSound(intervalMs: number) {
        const desiredDuration = intervalMs / 1000;

        if (this.vibrationAudio.readyState >= 1) {
            const originalDuration = this.vibrationAudio.duration;

            if (desiredDuration > 0 && originalDuration > 0) {
                this.vibrationAudio.playbackRate = originalDuration / desiredDuration;
            } else {
                console.warn('Invalid desiredDuration or originalDuration');
                this.vibrationAudio.playbackRate = 1;
            }

            this.vibrationAudio.play().catch((err) => {
                console.error('Error playing sound: ', err);
            });
        } else {
            console.warn('Audio is not ready yet. Wait for it to load.');
            this.vibrationAudio.addEventListener('loadedmetadata', () => {
                this.playBeepSound(intervalMs); // Retry once the audio is ready
            });
        }
    }

    playBeepSound(intervalMs: number) {
        const desiredDuration = intervalMs / 1000;

        if (this.beepAudio.readyState >= 1) {
            const originalDuration = this.beepAudio.duration;

            if (desiredDuration > 0 && originalDuration > 0) {
                this.beepAudio.playbackRate = originalDuration / desiredDuration;
            } else {
                console.warn('Invalid desiredDuration or originalDuration');
                this.beepAudio.playbackRate = 1;
            }

            this.beepAudio.play().catch((err) => {
                console.error('Error playing sound: ', err);
            });
        } else {
            console.warn('Audio is not ready yet. Wait for it to load.');
            this.beepAudio.addEventListener('loadedmetadata', () => {
                this.playBeepSound(intervalMs); // Retry once the audio is ready
            });
        }
    }

    vibrate(duration = 1000) {
        this.playVibrationSound(duration);
        if ('vibrate' in navigator) {
            navigator.vibrate([duration]);
        } else {
            console.log('Vibration not supported');
        }
    }

    beep(vibrationPattern = 1000) {
        this.playBeepSound(vibrationPattern);
    }
}

const Vibration = new _Vibration();

export default Vibration;
