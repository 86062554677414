import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Event {
    type: string;
    payload?: any;
}

export interface Subscriber {
    id: string;
    callback: (event: Event) => void;
}

interface EventsState {
    subscribers: Record<string, Subscriber[]>;
}

const initialState: EventsState = {
    subscribers: {},
};

const eventsSlice = createSlice({
    name: 'events',
    initialState,
    reducers: {
        emit: (state, action: PayloadAction<Event>) => {
            state.subscribers[action.payload.type]?.forEach((subscriber) => subscriber.callback(action.payload));
        },
        subscribe: (state, action: PayloadAction<{ eventType: string; subscriber: Subscriber }>) => {
            const { eventType, subscriber } = action.payload;
            if (!state.subscribers[eventType]) {
                state.subscribers[eventType] = [];
            }
            state.subscribers[eventType].push(subscriber);
        },
        unsubscribe: (state, action: PayloadAction<{ eventType: string; subscriberId: string }>) => {
            const { eventType, subscriberId } = action.payload;
            state.subscribers[eventType] = state.subscribers[eventType].filter(
                (subscriber) => subscriber.id !== subscriberId,
            );
        },
    },
});

export const { emit, subscribe, unsubscribe } = eventsSlice.actions;
export default eventsSlice.reducer;
