import Modal from '@components/ui/modal';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useModal } from '@contexts/global-modal-context';
import Button from '@components/ui/button';
import { useAuth } from '@hooks/use-auth';
import { useMediaScreen } from '@hooks/use-media-screen';
import { subscribe, Subscriber } from '@reducers/event-reducer';
import './profile-modal.css';
import avatar from '@assets/default-avatar/9.png';
import ChangePhomeForm from '@features/games/profile/phone-number/change-phone-form';
import ChangePseudoForm from '@features/games/profile/change-pseudo-form';
import EventType from '@ctypes/event-type';
import ResetPassword from '../reset-password';
import { useTranslation } from 'react-i18next';
import ForgotPassword from '../forgot-password';
export type TabType = 'pseudo' | 'phone' | 'password' | 'forgot_password';

type TabsType = {
    [key: string]: React.ComponentType<TabProps>;
};
export interface TabProps {
    handleTabClick: (tab: TabType, data: any) => void;
    data: any;
}

function ProfileModal(): React.ReactElement {
    const { t } = useTranslation(['profile']);
    const { user } = useAuth();
    const [isSmallScreen] = useMediaScreen();
    const [isActionMenuHidden, setIsActionMenuHidden] = useState<boolean>(false);
    const [data, setData] = useState({});
    const dispatch = useDispatch();
    const handleGoBackEvent = () => {
        setIsActionMenuHidden(false);
    };
    const goBackSubcriber: Subscriber = { id: EventType.GO_BACK, callback: handleGoBackEvent };

    //const { updateSession } = useAuth();
    const [tab, setTab] = useState<TabType>('pseudo');
    const { hideModal } = useModal();
    const TABS: TabsType = {
        password: ResetPassword,
        forgot_password: ForgotPassword,
        phone: ChangePhomeForm,
        pseudo: ChangePseudoForm,
    };

    const handleCloseModal = () => {
        hideModal();
    };

    const handleTabClick = (tab: TabType, data?: any) => {
        setTab(tab);
        setIsActionMenuHidden(true);
        setData(data || {});
    };

    const renderComponent = () => {
        const ModalComponent = TABS[tab];
        return <ModalComponent handleTabClick={handleTabClick} data={data} />;
    };

    useEffect(() => {
        dispatch(subscribe({ eventType: EventType.GO_BACK, subscriber: goBackSubcriber }));
    });

    return (
        <Modal
            show={true}
            className="md"
            onClose={handleCloseModal}
            id="party-modal"
            title={t('Modifier votre profil')}
        >
            <div className="form-container">
                <div className="container-fluid px-0 px-0 w-100">
                    <div className="row">
                        <div className="col-12 col-sm-4">
                            {(!isSmallScreen || !isActionMenuHidden) && (
                                <div className="user-profile">
                                    <div className="user-avatar">
                                        <img src={avatar} alt="" />
                                    </div>
                                    <div className="user-id">
                                        <span className="user-pseudo">@{user?.pseudo}</span>
                                        <div className="sub-infos flex-column gap-0">
                                            <Button label={t('Changer ma photo')} className="mt-3"></Button>
                                            <div className="text-center w-100 flex-row">
                                                <div className="text-center w-100 mt-3">
                                                    <a
                                                        onClick={() => handleTabClick('pseudo')}
                                                        className={`d-inline-block tab-link ${
                                                            tab === 'pseudo' ? 'text-strong text-danger' : ''
                                                        }`}
                                                    >
                                                        {t('Changer mon pseudo')}
                                                    </a>
                                                </div>
                                                <div className="text-center w-100 mt-3">
                                                    <a
                                                        onClick={() => handleTabClick('phone')}
                                                        className={`d-inline-block tab-link ${
                                                            tab === 'phone' ? 'text-strong text-danger' : ''
                                                        }`}
                                                    >
                                                        {t('Changer mon numéro')}
                                                    </a>
                                                </div>
                                                <div className="text-center w-100 mt-3">
                                                    <a
                                                        onClick={() => handleTabClick('password')}
                                                        className={`d-inline-block tab-link ${
                                                            tab === 'password' ? 'text-strong text-danger' : ''
                                                        }`}
                                                    >
                                                        {t('Changer mon mot de passe')}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        {(!isSmallScreen || isActionMenuHidden) && (
                            <div className="col-12 col-sm-8">{renderComponent()}</div>
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default ProfileModal;
