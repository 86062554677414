import { Validator, ValidatorValue } from './common';

export const MindValidator: Validator = {
    passed: (_, min: ValidatorValue, value: ValidatorValue): boolean => {
        if (value === null || value === undefined) {
            return false;
        }
        if (typeof min === 'number') {
            if (typeof value === 'string') {
                return value.length >= min;
            }
            if (typeof value === 'number') {
                return value >= min;
            }
            if (typeof value === 'boolean') {
                const booleanInteger = value ? 1 : 0;
                return booleanInteger >= min;
            }
        }

        return false;
    },
    message: 'Le champ {attribute}  est invalide.',
};
