import { _FAPFAP_API_ENDPOINT } from '@environments';
import { useAuth } from '@hooks/use-auth';
import { getLocaleLanguage } from '@utils/internalization';
import Event from '@constants/events';
import CryptoJS from 'crypto-js';
import io from 'socket.io-client';

import React, { PropsWithChildren, createContext, useEffect, useState } from 'react';
import { EventEmitter } from '@utils/event-emitter';
import { FlashMessage } from '@utils/flash-message';
import { useTranslation } from 'react-i18next';
import { Toast } from '@utils/toast-message';
import { d, e } from '@utils/encryption-wrapper';
import { useDispatch } from 'react-redux';
import { useToast } from '@hooks/use-toast';
import { usePendingGame } from '@hooks/use-pending-game';
import { useOngoingGame } from '@hooks/use-ongoing-game';
import { useBalance } from '@hooks/use-balance';
import { Alert } from '@utils/alert';
import { toggleMenu } from '@redux/reducers/menu-reducer';
import { useInformations } from '@hooks/use-information';
import { useSurvey } from '@hooks/use-survey';
import { useGame } from '@hooks/use-game';
import { setConnectedUserCount, setVersion } from '@redux/reducers/app-reducer';
import useMount from '@hooks/use-mount';
import { getSettings } from '@redux/reducers/settings-reducer';
import { useSettings } from '@hooks/use-settings';
import { ConnexionStatusState } from '@enums/connexion-status-type';
import { useSelector } from 'react-redux';
import { RootState } from '@redux/store';
import { AppReleaseNotes, AppVersion } from '@models/app-version.model';
import app from '../app.json';
import { useLocalStorage } from '@hooks/use-local-storage';
import { __RELEASE_NOTES_VIEWED__, __SHOW_TRICKS__ } from '@constants/storage';
import { SHOW_AVAILABLE_UPDATE_MODAL, SHOW_TRICKS_MODAL } from '@constants/event-emitter';
import { MODAL_TYPES } from './global-modal-context';

export type SocketContextType = {
    socket?: SocketIOClient.Socket | null;
    isUp: boolean;
    isConnected: boolean;
    state: ConnexionStatusState;
};

export const SocketContext = createContext<SocketContextType>({
    socket: null,
    isUp: false,
    isConnected: false,
    state: ConnexionStatusState.IDLE,
});

export const SocketContextProvider = ({ children }: PropsWithChildren): React.ReactElement => {
    const [socket, setSocket] = useState<SocketIOClient.Socket>();
    const [isUp, setIsUp] = useState<boolean>(false);
    const [state, setState] = useState<ConnexionStatusState>(ConnexionStatusState.IDLE);
    const [isConnected, setIsConnected] = useState<boolean>(false);
    const [showStartTricks, setShowStartTricks] = useLocalStorage<boolean>(__SHOW_TRICKS__, false);
    const [isReleaseNotesViewed] = useLocalStorage<boolean>(__RELEASE_NOTES_VIEWED__, false);

    const { authed, logout, session } = useAuth();
    const { version } = useSelector((state: RootState) => state.appStore);
    const { t } = useTranslation(['global']);
    const dispatch = useDispatch();
    const toast = useToast();
    const {
        onPendingGameAdd,
        onPendingGameExit,
        onPendingGameUpdate,
        onPendingGameSocketDisconnect,
        onPendingGameSocketReconnect,
    } = usePendingGame();
    const {
        onOngoingGameAdd,
        onOngoingGameExit,
        onOngoingGameUpdate,
        onOngoingGameSocketDisconnect,
        onOngoingGameSocketReconnect,
    } = useOngoingGame();
    const { onUpdateBalance, onUpdateBonus } = useBalance();
    const { onDisableInformations, onEnableInformations, onInformations } = useInformations();
    const { onDisableSurvey, onEnableSurvey, onSurveys } = useSurvey();
    const { onGameCanceled, onResumeGame, onStartGame, onReplayGame } = useGame();
    const { onVibrate } = useSettings();

    useEffect(() => {
        if (authed && !socket) {
            _createSocketInstance();
        } else if (authed && socket) {
            _initSocket(socket);
        }
    }, [authed, socket]);

    useMount(() => {
        dispatch(getSettings());
    });

    useEffect(() => {
        return () => {
            if (socket) {
                onComponentWillUnmount(socket);
            }
        };
    }, []);

    const _createSocketInstance = () => {
        const customTransportOptions = {
            transports: ['polling'],
            transportOptions: {
                polling: {
                    extraHeaders: {
                        'Accept-Language': getLocaleLanguage(),
                        //'User-Agent': `FapFap/Browser/${app.versionCode}`,
                    },
                },
            },
        };

        const socketInstance: SocketIOClient.Socket = io(_FAPFAP_API_ENDPOINT ?? '', customTransportOptions);
        setSocket(socketInstance);
    };

    const _initSocket = (socket: SocketIOClient.Socket) => {
        socket.on(Event.CONNECT, _onSocketConnect);
        socket.on(Event.DISCONNECT, _onSocketDisconnect);
        socket.on(Event.OK, _onEventOK); // Pong
        socket.on(Event.TOAST, _onEventTOAST);
        socket.on(Event.GAME_OVER, _onEventGameOver);
        socket.on(Event.VIBRATE, onVibrate);
        socket.on(Event.START_GAME, onStartGame);
        socket.on(Event.UPDATE_BALANCE, onUpdateBalance);
        socket.on(Event.PENDING_GAME_ADD, onPendingGameAdd);
        socket.on(Event.PENDING_GAME_EXIT, onPendingGameExit);
        socket.on(Event.PENDING_GAME_UPDATE, onPendingGameUpdate);
        socket.on(Event.ONGOING_GAME_ADD, onOngoingGameAdd);
        socket.on(Event.ONGOING_GAME_EXIT, onOngoingGameExit);
        socket.on(Event.PENDING_GAME_UPDATE, onOngoingGameUpdate);
        socket.on(Event.RESUME_GAME, onResumeGame);
        socket.on(Event.GAME_CANCELED, onGameCanceled);
        socket.on(Event.INFOS_SURVEYS, onInfosSurveys);
        socket.on(Event.KO, onKO);
        socket.on(Event.UPDATE_USER_COUNT, onUpdateUserCount);
        socket.on(Event.MOBILE_APP_VERSION, onMobileVersion);

        socket.on('error', (error: any) => {
            console.error('Socket.IO error:', error);
        });

        socket.on('connect_error', (err: any) => {
            console.error('Connection error:', err);
        });

        EventEmitter.on('disableSurveys', onDisableSurvey);
        EventEmitter.on('enableSurveys', onEnableSurvey);
        EventEmitter.on('disableInfos', onDisableInformations);
        EventEmitter.on('enableInfos', onEnableInformations);
        EventEmitter.on('replayGame', onReplayGame);

        setIsUp(true);
    };

    const onInfosSurveys = (data: string) => {
        onInformations(data);
        onSurveys(data);
    };

    const onComponentWillUnmount = (socket: SocketIOClient.Socket) => {
        socket.off(Event.OK, _onSocketReconnect);
        socket.off(Event.DISCONNECT, _onSocketDisconnect);
        socket.off(Event.UPDATE_BALANCE, onUpdateBalance);
        socket.off(Event.UPDATE_BONUS, onUpdateBonus);
        socket.off(Event.START_GAME, onStartGame);
        socket.off(Event.RESUME_GAME, onResumeGame);
        socket.off(Event.GAME_CANCELED, onGameCanceled);
        socket.off(Event.PENDING_GAME_ADD, onPendingGameAdd);
        socket.off(Event.PENDING_GAME_EXIT, onPendingGameExit);
        socket.off(Event.PENDING_GAME_UPDATE, onPendingGameUpdate);
        socket.off(Event.ONGOING_GAME_ADD, onOngoingGameAdd);
        socket.off(Event.ONGOING_GAME_EXIT, onOngoingGameExit);
        socket.off(Event.PENDING_GAME_UPDATE, onOngoingGameUpdate);
        socket.off(Event.INFOS_SURVEYS, onInfosSurveys);
        socket.off(Event.KO, onKO);
        socket.off(Event.VIBRATE, onVibrate);
        socket.off(Event.UPDATE_USER_COUNT, onUpdateUserCount);
        EventEmitter.off('disableSurveys', onDisableSurvey);
        EventEmitter.off('enableSurveys', onEnableSurvey);
        EventEmitter.off('disableInfos', onDisableInformations);
        EventEmitter.off('enableInfos', onEnableInformations);
        EventEmitter.off('replayGame', onReplayGame);
    };

    const _onSocketReconnect = () => {
        setIsConnected(true);
    };

    const _onSocketConnect = () => {
        if (!session || !socket) return;

        const { user } = session;

        const userId = user?.id || localStorage.getItem('__id__');

        if (!userId) {
            return;
        }

        const now = Date.now();
        const data = {
            timestamp: now,
            hash: CryptoJS.MD5(userId + now + d(session.secret)).toString(),
        };

        socket.emit(Event.ID, e(userId), e(data));

        EventEmitter.emit('partlist_socket_reconnect');
        setIsConnected(true);
        setState(ConnexionStatusState.ONLINE);
        onOngoingGameSocketReconnect();
        onPendingGameSocketReconnect();
    };

    const _onSocketDisconnect = () => {
        console.log('--------------Is connected to server');
        setState(ConnexionStatusState.OFFLINE);
        setIsConnected(false);
        onOngoingGameSocketDisconnect();
        onPendingGameSocketDisconnect();
        dispatch(setConnectedUserCount(undefined));
        FlashMessage.error(t('network_broken', { ns: 'alert' }));
    };

    const onUpdateUserCount = (userCount: string | number | null | undefined) => {
        if (userCount) {
            if (typeof userCount === 'number') {
                dispatch(setConnectedUserCount(userCount));
            } else {
                dispatch(setConnectedUserCount(d(userCount)));
            }
        }
    };

    // Pong
    const _onEventOK = () => {
        //FlashMessage.success(t('network_established', { ns: 'alert' }));
    };
    const _onEventTOAST = (message: string) => Toast.info(d(message));
    const _onEventGameOver = () => Toast.info(t('game_over'), 15000);

    const onKO = () => {
        Alert.info(t('info_label', { ns: 'alert' }), t('expired_session', { ns: 'alert' }), onLogout, {
            cancelable: false,
        });
    };

    const onLogout = () => {
        dispatch(toggleMenu());
        logout();
        toast?.success('Vous êtes maintenant déconnecté.');
    };

    const setAppVersionData = (version: AppVersion) => {
        dispatch(setVersion(version));
    };

    const setTricksAsAlreadyViewedStatus = () => {
        setShowStartTricks(true);
        if (!showStartTricks) {
            EventEmitter.emit(SHOW_TRICKS_MODAL, MODAL_TYPES.TIPS, { isFirstUsage: true });
        }
    };

    const showAvailableUpdateModal = (releaseNotes: AppReleaseNotes) => {
        if (!isReleaseNotesViewed) {
            EventEmitter.emit(SHOW_AVAILABLE_UPDATE_MODAL, MODAL_TYPES.AVAILABLE_UPDATE_MODAL, {
                releaseNotes,
                shouldUpdate: shouldUpdate(),
            });
        }
    };

    const isUpToDate = () => {
        return !version || parseInt(app.versionCode) >= parseInt(version.currentVersion);
    };

    const shouldUpdate = () => {
        return version && parseInt(app.versionCode) < parseInt(version.minVersion);
    };

    const onMobileVersion = (data: string) => {
        const newAppVersionCode: AppVersion = d(data);
        if (
            version &&
            version.currentVersion === newAppVersionCode.currentVersion &&
            version.minVersion === newAppVersionCode.minVersion
        ) {
            setTricksAsAlreadyViewedStatus();
            return;
        }

        setAppVersionData(newAppVersionCode);

        if (!isUpToDate()) {
            onDisableInformations();
            onDisableSurvey();
            setTimeout(() => {
                showAvailableUpdateModal(newAppVersionCode.releaseNotes);
            }, 1000);
        } else {
            setTricksAsAlreadyViewedStatus();
        }
    };

    const value = { socket, isUp, isConnected, state };

    return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>;
};
