import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { EMPTY, FAILED, LOADING, StateStatus, SUCCESSFUL } from '@models/status';
import TermsService from '@services/terms-service';

export type TermsAndPlolicyState = {
    terms?: string;
    policy?: string;
    policy_status: StateStatus;
    terms_status: StateStatus;
    error: any;
    accepted: boolean;
};
const initialState: TermsAndPlolicyState = {
    terms: undefined,
    policy: undefined,
    policy_status: EMPTY,
    terms_status: EMPTY,
    error: null,
    accepted: false,
};

// Handle POST request to create a new post
export const getTerms: any = createAsyncThunk(
    // The name of the action
    'auth/terms',
    // The payload creator
    async (_, { rejectWithValue }) => {
        try {
            return await TermsService.getTerms();
        } catch (err: any) {
            return rejectWithValue({ error: err.message });
        }
    },
);

// Handle POST request to create a new post
export const getPolicy: any = createAsyncThunk(
    // The name of the action
    'auth/policy',
    // The payload creator
    async (_, { rejectWithValue }) => {
        try {
            return await TermsService.getPolicy();
        } catch (err: any) {
            return rejectWithValue({ error: err.message });
        }
    },
);

const termsSlice = createSlice({
    /* The name of the slice[this will also be used as the action type string 
      in combination with the extraReducer name i.e posts/getPosts or posts/addPost] 
    */
    name: 'terms',
    // initialState: initialState[ES6 destructuring syntax]
    initialState,
    // Add reducers for the synchronous actions on the UI[we are not using this property for this tutorial]
    reducers: {
        acceptTerms: (state) => {
            state.accepted = true;
        },
        rejectTerms: (state) => {
            state.accepted = false;
        },
    },
    // Add extraReducers for the asynchronous actions on the UI
    extraReducers: {
        [getTerms.pending]: (state) => {
            // When data is being fetched
            state.terms_status = LOADING;
        },
        [getTerms.fulfilled]: (state, { payload }: PayloadAction<string>) => {
            // When data is fetched successfully
            state.terms_status = SUCCESSFUL;
            // Concatenate the new data to the existing data in the array
            state.terms = payload;
        },
        [getTerms.rejected]: (state, action) => {
            // When data is fetched unsuccessfully
            state.terms_status = FAILED;

            // Update the error message for proper error handling
            state.error = action.error.message;
        },
        [getPolicy.pending]: (state) => {
            // When data is being fetched
            state.policy_status = LOADING;
        },
        [getPolicy.fulfilled]: (state, { payload }: PayloadAction<string>) => {
            // When data is fetched successfully
            state.policy_status = SUCCESSFUL;

            // Concatenate the new data to the existing data in the array
            state.policy = payload;
        },
        [getPolicy.rejected]: (state, action) => {
            // When data is fetched unsuccessfully
            state.policy_status = FAILED;

            // Update the error message for proper error handling
            state.error = action.error.message;
        },
    },
});
export const { acceptTerms, rejectTerms } = termsSlice.actions;
export default termsSlice.reducer;
