import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AuthService from '@services/auth-service';
import * as Auth from '@models/auth';
import { FAILED, LOADING, SUCCESSFUL } from '@models/status';
const initialState = {
    data: {},
    status: 'idle',
    error: null,
    terms_accepted: false,
};

// Handle POST request to create a new post
export const recover: any = createAsyncThunk(
    // The name of the action
    'auth/recovery',
    // The payload creator
    async (data: Auth.RegisterData, { rejectWithValue }) => {
        try {
            return await AuthService.recover(data);
        } catch (err: any) {
            return rejectWithValue({ error: err.message });
        }
    },
);

const recoverySlice = createSlice({
    /* The name of the slice[this will also be used as the action type string 
      in combination with the extraReducer name i.e posts/getPosts or posts/addPost] 
    */
    name: 'recovery',
    // initialState: initialState[ES6 destructuring syntax]
    initialState,
    // Add reducers for the synchronous actions on the UI[we are not using this property for this tutorial]
    reducers: {
        resetRecoveryForm: (state) => {
            state.status = initialState.status;
            state.data = initialState.data;
            state.error = initialState.error;
        },
        acceptTerms: (state) => {
            state.terms_accepted = true;
        },
        rejectTerms: (state) => {
            state.terms_accepted = false;
        },
    },
    // Add extraReducers for the asynchronous actions on the UI
    extraReducers: {
        [recover.pending]: (state) => {
            // When data is being fetched
            state.status = LOADING;
        },
        [recover.fulfilled]: (state, action: any) => {
            // When data is fetched successfully
            state.status = SUCCESSFUL;

            // Concatenate the new data to the existing data in the array
            state.data = action.payload;
        },
        [recover.rejected]: (state, action) => {
            // When data is fetched unsuccessfully
            state.status = FAILED;

            // Update the error message for proper error handling
            state.error = action.error.message;
        },
    },
});
export const { resetRecoveryForm, acceptTerms, rejectTerms } = recoverySlice.actions;
export default recoverySlice.reducer;
