import React from 'react';
import './App.css';
import { Routes } from '@routes';
import { useAuth } from '@hooks/use-auth';
import './i18n';
import { initEventEmitter } from '@utils/event-emitter';

initEventEmitter();

function App(): React.ReactElement {
    const { authed } = useAuth();

    
    if (typeof authed === 'boolean') {
        return <Routes isLogged={authed} isAuthorized={authed} />;
    }

    return <></>;
}

export default App;
