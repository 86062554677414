import Modal from '@components/ui/modal';
import React, { useEffect, useState } from 'react';
import { useModal } from '@contexts/global-modal-context';
import { useTranslation } from 'react-i18next';
import { formatAmount, formatTime } from '@utils/helpers';
import { Game } from 'interfaces/game.interface';
import gameService from '@services/game-service';
import { Alert } from '@utils/alert';
import { useAuth } from '@hooks/use-auth';
import BetSrc from '@assets/images/icons/bet.png';
import IcDangerUserSrc from '@assets/images/icons/ic_user_danger.png';
import IcUserSrc from '@assets/images/icons/ic_user.png';
import Icon from '@components/ui/icon';
import { Party } from 'interfaces/party.interface';
import { RootState } from '@redux/store';
import { useSelector } from 'react-redux';
import { GamePlayerInfo } from 'interfaces/game-player.interface';

type ReplyGameWaitingModalProps = {
    oldGame: Game;
    game: Party;
    replayTimeLeft: number;
};

function ReplayGameWaitingModal(props: ReplyGameWaitingModalProps): React.ReactElement {
    const { hideModal } = useModal();
    const { t } = useTranslation(['game']);
    const { oldGame, replayTimeLeft } = props;
    const { blocked_gamers } = useSelector((state: RootState) => state.blockGamerStore);
    const { gameToReplay } = useSelector((state: RootState) => state.gameStore);
    const [game, setGame] = useState<Party>(props.game);
    const [blockedGamers, setBlockedGamersId] = useState<string[]>([]);
    const { user } = useAuth();

    useEffect(() => {
        setBlockedGamersId(blocked_gamers.map((g) => g.id));
    }, [blocked_gamers]);

    const onClose = () => {
        hideModal();
    };

    const _getTimeLeft = (): string => {
        if (replayTimeLeft && replayTimeLeft > 0) {
            return ` (${formatTime(replayTimeLeft)})`;
        }
        return '';
    };

    useEffect(() => {
        if (gameToReplay) {
            setGame(gameToReplay);
        }
    }, [gameToReplay]);

    const _onQuitGame = () => {
        gameService
            .cancelGameReplay(oldGame.part.id)
            .then(() => {
                onClose();
            })
            .catch((error) => Alert.alert(t('oops', { ns: 'global' }), error.message));
    };

    const _promptCancelGame = () => {
        Alert.alert(t('confirm_label', { ns: 'alert' }), t('quit_replay_game', { ns: 'alert' }), [
            {
                text: t('no', { ns: 'global' }),
                onPress: () => null,
            },
            {
                text: t('yes', { ns: 'global' }),
                onPress: () => _onQuitGame(),
            },
        ]);
    };

    return (
        <Modal
            hideOnAreaClick={false}
            show={true}
            onClose={onClose}
            id="replay-game-waiting-modal"
            title={t('pending_part') + '...'}
        >
            <div className="form-container d-flex flex-column justify-content-center align-items-center">
                <div className="d-flex justify-content-center align-items-center">
                    <img src={BetSrc} alt="" />
                </div>
                <p className="p-0 m-0">{formatAmount(game.bet)} F</p>
                <p className="p-0 m-0">{game.korat ? 'K x2' : ''}</p>
                <p className="p-0 m-0">{game.double_korat ? '2K x4' : ''}</p>
                <fieldset className="d-flex flex-row align-items-center gap-4 py-1">
                    <Icon name={'security-special'} className="awaiting-game-icon"></Icon>
                    <div className="d-flex align-items-center gap-1 awaiting-g-bet">
                        {game.password.length == 0 && <strong>{t('no_password', { ns: 'game' })}</strong>}
                        {game.password.length > 0 && (
                            <strong>
                                {game.creator_id == user?.id ? game.password : t('private_part', { ns: 'game' })}
                            </strong>
                        )}
                    </div>
                </fieldset>
                {game.special_mode && (
                    <fieldset className="d-flex flex-row align-items-center gap-4 py-1">
                        <Icon name={'settings'} className="awaiting-game-icon"></Icon>
                        <div className="d-flex align-items-center gap-1 awaiting-g-bet">
                            <strong>{t('stopped_balls_list', { ns: 'game' })}</strong>
                        </div>
                    </fieldset>
                )}
                <div className="separator light"></div>
                {oldGame.players.map((player: GamePlayerInfo) => {
                    return (
                        <div className="creator-infos" key={player.id}>
                            <div className="user-avatar">
                                <img src={blockedGamers.includes(player.id) ? IcDangerUserSrc : IcUserSrc} alt="" />
                            </div>
                            <span className="user-name">
                                <b>{player.pseudo}</b>
                            </span>
                        </div>
                    );
                })}
                <div className="actions">
                    <button type="button" onClick={_promptCancelGame} className="danger d-flex justify-content-center">
                        {`${t('quit', { ns: 'actions' })}${_getTimeLeft()}`}
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default ReplayGameWaitingModal;
