import { FormRequest } from './common';

export default class SmsCodeFormRequest extends FormRequest {
    protected rules = {
        code: [
            {
                required: true,
                message: 'Le code est invalide',
            },
        ],
    };
}
