import React, { ChangeEvent, FormEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideMenu, toggleMenu } from '@reducers/menu-reducer';
import { useAuth } from '@hooks/use-auth';
import { useModal, MODAL_TYPES } from '@contexts/global-modal-context';
import './game-header.css';
import { t } from 'i18next';
import Icon from '../icon';
import { useSelector } from 'react-redux';
import ConnectionStatusIcon from '../connexion-status-icon';
import { useDeposit } from '@hooks/use-deposit';
import { ReactComponent as RefreshIcon } from '@assets/icons/outline/refresh.svg';
import { RootState } from '@redux/store';

// Notice here we're using the function declaration with the interface Props
const GamerHeader: React.FunctionComponent = () => {
    const { showModal } = useModal();
    const dispatch = useDispatch();
    const { user } = useAuth();
    const { connectedUserCount } = useSelector((state: RootState) => state.appStore);
    const [amount, setAmount] = useState<number>();
    const { onDeposit } = useDeposit();

    const handleCloseMenu = () => {
        dispatch(hideMenu());
    };

    const handleToggleMenu = () => {
        dispatch(toggleMenu());
    };

    const handleOpenDepositModal = () => {
        handleCloseMenu();
        onDeposit();
    };

    const showTips = () => {
        handleCloseMenu();
        showModal(MODAL_TYPES.TIPS);
    };

    const handleDeposit = (event: FormEvent) => {
        event.preventDefault();
        handleCloseMenu();
        onDeposit(amount);
    };

    const onChangeAmount = (event: ChangeEvent<HTMLInputElement>) => {
        const value: string = event.target.value;
        if (value.length) {
            setAmount(parseInt(value));
        }
    };

    const onReload = () => {
        window.location.reload();
    };

    return (
        <div className="app-topbar game-header" id="header">
            <div className="buy-credit mobile-hide">
                <form
                    onSubmit={handleDeposit}
                    className="flex-grow-1 d-flex flex-row justify-content-between align-items-center"
                >
                    <div className="input-line">
                        <input
                            type="number"
                            name="amount"
                            id="amount"
                            value={amount}
                            placeholder={t('Montant de la recharge', { ns: 'attributes' })}
                            onInput={onChangeAmount}
                        />
                        <button className="opaced icon-button">
                            <span className="icon icon16">
                                <svg
                                    width="26"
                                    height="26"
                                    viewBox="0 0 26 26"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M9 17.5833C8.58579 17.5833 8.25 17.9191 8.25 18.3333C8.25 18.7475 8.58579 19.0833 9 19.0833V17.5833ZM17 8.41667C17.4142 8.41667 17.75 8.08088 17.75 7.66667C17.75 7.25245 17.4142 6.91667 17 6.91667V8.41667ZM12.25 21C12.25 21.4142 12.5858 21.75 13 21.75C13.4142 21.75 13.75 21.4142 13.75 21H12.25ZM13.75 5C13.75 4.58579 13.4142 4.25 13 4.25C12.5858 4.25 12.25 4.58579 12.25 5H13.75ZM24.25 13C24.25 19.2132 19.2132 24.25 13 24.25V25.75C20.0416 25.75 25.75 20.0416 25.75 13H24.25ZM13 24.25C6.7868 24.25 1.75 19.2132 1.75 13H0.25C0.25 20.0416 5.95837 25.75 13 25.75V24.25ZM1.75 13C1.75 6.7868 6.7868 1.75 13 1.75V0.25C5.95837 0.25 0.25 5.95837 0.25 13H1.75ZM13 1.75C19.2132 1.75 24.25 6.7868 24.25 13H25.75C25.75 5.95837 20.0416 0.25 13 0.25V1.75ZM11.6667 6.91667C11.1022 6.91667 10.2722 7.07984 9.56177 7.59135C8.81309 8.1304 8.25 9.02223 8.25 10.3333H9.75C9.75 9.51111 10.0758 9.0696 10.4382 8.80865C10.8389 8.52016 11.3422 8.41667 11.6667 8.41667V6.91667ZM8.25 10.3333C8.25 11.6444 8.81309 12.5363 9.56177 13.0753C10.2722 13.5868 11.1022 13.75 11.6667 13.75V12.25C11.3422 12.25 10.8389 12.1465 10.4382 11.858C10.0758 11.5971 9.75 11.1556 9.75 10.3333H8.25ZM11.6667 13.75H14.3333V12.25H11.6667V13.75ZM14.3333 13.75C14.6578 13.75 15.1611 13.8535 15.5618 14.142C15.9242 14.4029 16.25 14.8444 16.25 15.6667H17.75C17.75 14.3556 17.1869 13.4637 16.4382 12.9247C15.7278 12.4132 14.8978 12.25 14.3333 12.25V13.75ZM16.25 15.6667C16.25 16.4889 15.9242 16.9304 15.5618 17.1913C15.1611 17.4798 14.6578 17.5833 14.3333 17.5833V19.0833C14.8978 19.0833 15.7278 18.9202 16.4382 18.4087C17.1869 17.8696 17.75 16.9778 17.75 15.6667H16.25ZM14.3333 17.5833H13V19.0833H14.3333V17.5833ZM13 17.5833H9V19.0833H13V17.5833ZM17 6.91667H13V8.41667H17V6.91667ZM13 6.91667H11.6667V8.41667H13V6.91667ZM12.25 18.3333V21H13.75V18.3333H12.25ZM13.75 7.66667V5H12.25V7.66667H13.75Z"
                                        fill="white"
                                    />
                                </svg>
                            </span>
                        </button>
                    </div>
                </form>
            </div>
            <button className="open-sub-menu web-hide opaced" onClick={handleToggleMenu}>
                <span className="icon16 icon">
                    <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1 10.25C0.585786 10.25 0.25 10.5858 0.25 11C0.25 11.4142 0.585786 11.75 1 11.75V10.25ZM17 11.75C17.4142 11.75 17.75 11.4142 17.75 11C17.75 10.5858 17.4142 10.25 17 10.25V11.75ZM1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75V5.25ZM17 6.75C17.4142 6.75 17.75 6.41421 17.75 6C17.75 5.58579 17.4142 5.25 17 5.25V6.75ZM1 0.25C0.585786 0.25 0.25 0.585786 0.25 1C0.25 1.41421 0.585786 1.75 1 1.75V0.25ZM17 1.75C17.4142 1.75 17.75 1.41421 17.75 1C17.75 0.585786 17.4142 0.25 17 0.25V1.75ZM1 11.75H17V10.25H1V11.75ZM1 6.75H17V5.25H1V6.75ZM1 1.75H17V0.25H1V1.75Z"
                            fill="white"
                        />
                    </svg>
                </span>
            </button>
            <div className="mobile-hide d-flex flex-row align-items-center justify-content-center charge-btn-group-2">
                <span className="icon icon16">
                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_239_2988)">
                            <path
                                d="M16 2.86133V9.13825C16 10.5827 12.4183 11.7536 8 11.7536C3.58172 11.7536 0 10.5827 0 9.13825V2.86133"
                                fill="#FFDB2D"
                            />
                            <path
                                d="M8 2.86133V11.7536C12.4183 11.7536 16 10.5827 16 9.13825V2.86133H8Z"
                                fill="#FFAF33"
                            />
                            <path
                                d="M8 5.47684C12.4183 5.47684 16 4.3059 16 2.86147C16 1.41704 12.4183 0.246094 8 0.246094C3.58172 0.246094 0 1.41704 0 2.86147C0 4.3059 3.58172 5.47684 8 5.47684Z"
                                fill="#FFEA8A"
                            />
                            <path
                                d="M8 0.246094V5.47687C12.4183 5.47687 16 4.30593 16 2.8615C16 1.41707 12.4183 0.246094 8 0.246094Z"
                                fill="#FFDB2D"
                            />
                            <path
                                d="M8 7.8312C3.58172 7.8312 0 6.66025 0 5.21582V7.71896C0.471469 8.02015 1.073 8.30402 1.83719 8.55391C3.50706 9.0998 5.69569 9.40046 8 9.40046C10.3043 9.40046 12.493 9.0998 14.1628 8.55391C14.927 8.30408 15.5286 8.02015 16 7.71896V5.21582C16 6.66025 12.4183 7.8312 8 7.8312Z"
                                fill="#FFAF33"
                            />
                            <path
                                d="M8 7.8312V9.40043C10.3043 9.40043 12.493 9.09976 14.1628 8.55387C14.927 8.30405 15.5286 8.02012 16 7.71893V5.21582C16 6.66025 12.4183 7.8312 8 7.8312Z"
                                fill="#FF9408"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_239_2988">
                                <rect width="16" height="12" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </span>
                <span className="d-inline-block sold-amount">{user?.balance ?? 0} F</span>
            </div>
            <div className="web-hide d-flex flex-row align-items-center justify-content-center">
                <div
                    onClick={handleOpenDepositModal}
                    className="user-sold charge-btn-group web-hide justify-content-between align-item-center gap-0"
                >
                    <span className="icon icon16">
                        <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_239_2988)">
                                <path
                                    d="M16 2.86133V9.13825C16 10.5827 12.4183 11.7536 8 11.7536C3.58172 11.7536 0 10.5827 0 9.13825V2.86133"
                                    fill="#FFDB2D"
                                />
                                <path
                                    d="M8 2.86133V11.7536C12.4183 11.7536 16 10.5827 16 9.13825V2.86133H8Z"
                                    fill="#FFAF33"
                                />
                                <path
                                    d="M8 5.47684C12.4183 5.47684 16 4.3059 16 2.86147C16 1.41704 12.4183 0.246094 8 0.246094C3.58172 0.246094 0 1.41704 0 2.86147C0 4.3059 3.58172 5.47684 8 5.47684Z"
                                    fill="#FFEA8A"
                                />
                                <path
                                    d="M8 0.246094V5.47687C12.4183 5.47687 16 4.30593 16 2.8615C16 1.41707 12.4183 0.246094 8 0.246094Z"
                                    fill="#FFDB2D"
                                />
                                <path
                                    d="M8 7.8312C3.58172 7.8312 0 6.66025 0 5.21582V7.71896C0.471469 8.02015 1.073 8.30402 1.83719 8.55391C3.50706 9.0998 5.69569 9.40046 8 9.40046C10.3043 9.40046 12.493 9.0998 14.1628 8.55391C14.927 8.30408 15.5286 8.02015 16 7.71896V5.21582C16 6.66025 12.4183 7.8312 8 7.8312Z"
                                    fill="#FFAF33"
                                />
                                <path
                                    d="M8 7.8312V9.40043C10.3043 9.40043 12.493 9.09976 14.1628 8.55387C14.927 8.30405 15.5286 8.02012 16 7.71893V5.21582C16 6.66025 12.4183 7.8312 8 7.8312Z"
                                    fill="#FF9408"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_239_2988">
                                    <rect width="16" height="12" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </span>
                    <span className="d-inline-block sold-amount">{user?.balance ?? 0} F</span>
                    <svg className="plus-icon" width="24" height="24" viewBox="0 0 24 24">
                        <circle cx="12" cy="12" r="10" stroke="black" strokeWidth="2" fill="currentColor" />
                        <line x1="12" y1="8" x2="12" y2="16" stroke="black" strokeWidth="2" />
                        <line x1="8" y1="12" x2="16" y2="12" stroke="black" strokeWidth="2" />
                    </svg>
                </div>
            </div>
            <div className="topbar-links gap-0 w-auto w-sm-100 justify-content-between">
                <div>
                    <div className="d-flex align-items-center gap-2">
                        <button
                            className="w-auto reload-btn success text-center d-flex justify-content-center "
                            onClick={onReload}
                        >
                            <span className="icon icon12">
                                <RefreshIcon />
                            </span>
                        </button>
                        <ConnectionStatusIcon />
                        <a href="javascript:;" onClick={showTips} className="button opaced notification">
                            <span className="icon icon24">
                                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M12 19.5C16.1421 19.5 19.5 16.1421 19.5 12C19.5 7.85786 16.1421 4.5 12 4.5C7.85786 4.5 4.5 7.85786 4.5 12C4.5 16.1421 7.85786 19.5 12 19.5ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM12.75 15V16.5H11.25V15H12.75ZM10.5 10.4318C10.5 9.66263 11.1497 9 12 9C12.8503 9 13.5 9.66263 13.5 10.4318C13.5 10.739 13.3151 11.1031 12.9076 11.5159C12.5126 11.9161 12.0104 12.2593 11.5928 12.5292L11.25 12.7509V14.25H12.75V13.5623C13.1312 13.303 13.5828 12.9671 13.9752 12.5696C14.4818 12.0564 15 11.3296 15 10.4318C15 8.79103 13.6349 7.5 12 7.5C10.3651 7.5 9 8.79103 9 10.4318H10.5Z"
                                        fill="#FFF"
                                    />
                                </svg>
                            </span>
                            <span className="indicator indicator-sm"></span>
                        </a>
                        <span className="px-2 py-1 d-flex border-radius-13 gap-2 connected-user-count">
                            <span> {typeof connectedUserCount == 'number' ? connectedUserCount : '?'} </span>
                            <Icon name="user"></Icon>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GamerHeader;
