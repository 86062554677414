import { Tournament } from 'interfaces/tournament.interface';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TournamentInfos from '../tournament-infos';
import TournamentRegisteredUsers from '../tournament-registered';

type TournamentSideTabInfosProps = {
    tournament: Tournament;
    subscrited: boolean;
    isJoinning: boolean;
    isLeaving: boolean;
    onGoBack: () => void;
    onJoinTournament: () => void;
    onQuitTournament: () => void;
};

enum SIDE_TAB {
    DETAILS,
    REGISTERED_USERS,
}

function TournamentSideTabInfos(props: TournamentSideTabInfosProps): React.ReactElement {
    const { t } = useTranslation(['competition']);
    const { onGoBack, onJoinTournament, onQuitTournament, subscrited, isJoinning, isLeaving, tournament } = props;
    const [sideTab, setSideTab] = useState<SIDE_TAB>(SIDE_TAB.DETAILS);
    const TAB_TITLES = {
        [SIDE_TAB.REGISTERED_USERS]: t('tournament_details'),
        [SIDE_TAB.DETAILS]: t('subscribers'),
    };

    const goNext = () => {
        switch (sideTab) {
            case SIDE_TAB.DETAILS:
                setSideTab(SIDE_TAB.REGISTERED_USERS);
                break;
        }
    };

    const goPrevious = () => {
        switch (sideTab) {
            case SIDE_TAB.REGISTERED_USERS:
                setSideTab(SIDE_TAB.DETAILS);
                break;
        }
    };

    const hasNext = (): boolean => {
        return sideTab == SIDE_TAB.DETAILS;
    };

    const hasPrevious = (): boolean => {
        return sideTab == SIDE_TAB.REGISTERED_USERS;
    };

    return (
        <>
            <div className="tour-forms" id="tour-forms">
                {sideTab == SIDE_TAB.DETAILS && <TournamentInfos tournament={tournament} subscrited={subscrited} />}
                {sideTab == SIDE_TAB.REGISTERED_USERS && (
                    <TournamentRegisteredUsers
                        tournament={tournament}
                        subscrited={subscrited}
                        isJoinning={isJoinning}
                        isLeaving={isLeaving}
                        onGoBack={onGoBack}
                        onJoinTournament={onJoinTournament}
                        onQuitTournament={onQuitTournament}
                    />
                )}
            </div>
            <div className="tour-plan-action mt-4">
                <div className="tour-progression">
                    <div
                        className={`tour-progresion-item ${sideTab == SIDE_TAB.DETAILS ? 'passed' : ''}`}
                        data-index="0"
                    ></div>
                    <div
                        className={`tour-progresion-item ${sideTab == SIDE_TAB.REGISTERED_USERS ? 'passed' : ''}`}
                        data-index="1"
                    ></div>
                </div>
                <button
                    className="default formTourTrigger d-flex justify-content-between align-center "
                    data-tourposition="0"
                    onClick={hasPrevious() ? goPrevious : goNext}
                >
                    {hasPrevious() && (
                        <span className="icon icon24">
                            <svg
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M11 9L8 12M8 12L11 15M8 12H16M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </span>
                    )}
                    {TAB_TITLES[sideTab]}
                    {hasNext() && (
                        <span className="icon icon24">
                            <svg
                                width="30px"
                                height="30px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M13 15L16 12M16 12L13 9M16 12H8M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </span>
                    )}
                </button>
            </div>
        </>
    );
}

export default TournamentSideTabInfos;
