import http from '@utils/http-common';
import * as Auth from '@models/auth';
import APIRoute from '@constants/routes';
import { UserToken } from '@models/user-token.model';
import { User } from '@models/user';
import { ForgotPasswordResponseData } from '@models/forgot-password.model';

const signin = (data: Auth.LoginData) => {
    return http.post<Auth.LoginData, UserToken>('/auth/login', data);
};

const signup = (data: Auth.RegisterData) => {
    return http.post<any>('/auth/register-or-login', data);
};

const recover = (data: Auth.RegisterData) => {
    return http.post<any>(APIRoute.RESET, data);
};

const verifyOTP = (data: Auth.OtpVerificationData) => {
    return http.post<any>(APIRoute.VERIFY_TOKEN, data);
};

const requestOTP = (data: Auth.RequestOTP) => {
    return http.post<any>(APIRoute.SEND_PHONE_CHANGE_CODE, data);
};

const register = (data: Auth.QuickRegisterData) => {
    return http.post<Auth.QuickRegisterData, UserToken>('/auth/quick-register', data);
};

const simpleRegister = (data: Auth.QuickRegisterData) => {
    return http.post<Auth.QuickRegisterData, UserToken>(APIRoute.REGISTER, data);
};

const registerOrLogin = (data: Auth.ForgotPasswordData) => {
    return http.post<Auth.ForgotPasswordData, ForgotPasswordResponseData>(APIRoute.REGISTER_OR_LOGIN, data);
};

const verifyAuthCode = (data: Auth.VerifyPasswordCodeData) => {
    return http.post<Auth.VerifyPasswordCodeData, ForgotPasswordResponseData>(APIRoute.VERIFY_AUTH_CODE, data);
};

const updateProfile = (data: Auth.UpdateProfileData) => {
    return http.post<any, User>(APIRoute.UPDATE_PROFILE, data);
};

const changePhoneNumber = (data: Auth.ChangePhoneNumberData) => {
    return http.post<Auth.ChangePhoneNumberData, UserToken>(APIRoute.CHANGE_NUMBER, data);
};

const phoneNumberRequestCode = (data: Auth.PhoneNumberRequestCodeData) => {
    return http.post<any>('/account/send-phone-change-code', data);
};

const passwordRequestCode = (data: Auth.PasswordRequestCodeData) => {
    //const country = CountryService.findCountry(data.phone_number);
    return http.post<any>('/account/send-password-reset-code', data);
};

const resetPassword = (data: Auth.ResetPasswordData) => {
    return http.post<any, User>(APIRoute.UPDATE_PROFILE, data);
};

const resendOTPCode = (data: Auth.PasswordRequestCodeData) => {
    return http.post<any>('/auth/resend-code', data);
};

const logout = () => {
    return http.post<any>('/account/logout');
};

const AuthService = {
    signin,
    signup,
    verifyOTP,
    register,
    registerOrLogin,
    recover,
    updateProfile,
    logout,
    changePhoneNumber,
    phoneNumberRequestCode,
    passwordRequestCode,
    verifyAuthCode,
    resetPassword,
    resendOTPCode,
    requestOTP,
    simpleRegister,
};

export default AuthService;
