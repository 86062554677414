import React, { FormEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Form from '@components/ui/form';
import { rejectTerms } from '@redux/reducers/signup-reducer';
import { useLocalStorage } from '@hooks/use-local-storage';
import Button from '@components/ui/button';
import * as Auth from '@models/auth';
import { FormState } from '@reducers/form-reducer';
import SignupFormRequest from '@requests/signup-request';
import { useNavigate } from 'react-router-dom';
import InternationalizedInput from '@components/ui/internationalized-input';
import Checkbox from '@components/ui/checkbox';
import './signup-form.css';
import { MODAL_TYPES, useModal } from '@contexts/global-modal-context';
import { normalizePhoneNumber } from '@utils/string-parser';
import Select from '@components/ui/select';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { FormDataType } from '@ctypes/form-data';
import { InternationalizedValue } from '@components/ui/internationalized-input/internationalized-input';
import { __SIGNUP_DATA__ } from '@constants/storage';

function SignupForm(): React.ReactElement {
    const dispatch = useDispatch();
    const validator = new SignupFormRequest();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation(['signup']);
    const [language, setLanguage] = useState<string>(Cookies.get('i18next') || 'fr');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [checked, setChecked] = useState<boolean>(true);
    const [isSignedUp, setIsSignedUp] = useState<boolean>(false);
    const [session, setSession] = useLocalStorage<Auth.RegisterData | null>(__SIGNUP_DATA__);
    const [countryValue, setCountryValue] = useState<InternationalizedValue>({
        code: 'CM',
        dialCode: '+237',
    });
    const languages = [
        {
            code: 'fr',
            value: 'fr',
            label: t('Français', { ns: 'common' }),
        },
        {
            code: 'gb',
            value: 'en',
            label: t('Anglais', { ns: 'common' }),
        },
    ];

    const [signupData, setSignupData] = useState<FormDataType>({
        checked: { value: true },
        phone_number_country_code: { value: 'CM' },
        phone_number_country_dial_code: { value: '+237' },
        phone_number: { value: '' },
        language: { value: languages[0].value },
    });

    useEffect(() => {
        setSignupData({
            checked: { value: checked },
            language: { value: language },
            phone_number_country_code: { value: countryValue.code || '' },
            phone_number_country_dial_code: { value: countryValue.dialCode || '' },
            phone_number: { value: countryValue.value || '' },
        });
    }, [checked, language, countryValue]);

    const { showModal } = useModal();
    const showTerms = () => {
        showModal(MODAL_TYPES.TERMS_MODAL, {});
    };

    const onSubmit = (signupData: any) => {
        const user: Auth.RegisterData = {
            checked: true,
            country_code: signupData.phone_number_country_code,
            phone_number: normalizePhoneNumber(
                signupData.phone_number,
                signupData.phone_number_country_dial_code,
                signupData.phone_number_country_code,
            ),
            language: signupData.language,
        };
        setIsLoading(true);
        setIsSignedUp(true);
        setSession(user);
    };

    const getDefaultLanguage = () => {
        for (const lang in languages) {
            if (languages[lang].value === language) {
                return languages[lang];
            }
        }
        return languages[0];
    };

    /**
     * On submit signup form
     * Checked if the signup form is valid before submitting data
     */
    const signUpHandler = (state: FormState, event: FormEvent) => {
        event.preventDefault();
        if (state.validator && state.validator.valid) {
            onSubmit(state.data);
        }
    };

    const onStatusHandler = (checked: boolean) => {
        setChecked(checked);
        if (checked) {
            showTerms();
        } else {
            dispatch(rejectTerms());
        }
    };

    const onChangeLanguage = (value: string) => {
        i18n.changeLanguage(value.toLowerCase());
        Cookies.set('i18next', value.toLowerCase());
        setLanguage(value);
    };

    useEffect(() => {
        setSession(null);
    }, []);

    useEffect(() => {
        if (isSignedUp && session) {
            navigate('/auth/confirm-password');
        }
    }, [session, isSignedUp]);

    return (
        <Form method="POST" request={validator} onSubmit={signUpHandler} data={signupData}>
            <Select
                type="lang"
                id="language"
                onChange={onChangeLanguage}
                value={getDefaultLanguage()}
                name="language"
                valueKey="value"
                label={t('Votre numéro de téléphone', { ns: 'common' })}
                placeholder={t('Sélectionner une langue', { ns: 'attributes' })}
                options={languages}
            ></Select>
            <InternationalizedInput
                id="phone_number"
                name="phone_number"
                countryCode={countryValue.code}
                onChange={setCountryValue}
                label={t('Votre numéro de téléphone', { ns: 'attributes' })}
            />
            <Checkbox
                onChange={onStatusHandler}
                id="checked"
                name="checked"
                label={t("J'ai lu et j'accepte les conditions")}
                labelClassName="usage-condition"
            />
            <Button
                label={t('Créer mon nouveau compte')}
                disabled={isLoading}
                loading={isLoading}
                icon="account-plus"
            ></Button>
            <div id="recaptcha" />
        </Form>
    );
}

export default SignupForm;
