import React from 'react';

type Props = {
    source: { uri: string; title?: string };
};

const WebView: React.FunctionComponent<Props> = ({ source }: Props) => {
    return <iframe src={source.uri} title={source.title} style={{ width: '100%', height: '100%' }} />;
};

export default WebView;
