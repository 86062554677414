import React from 'react';

import { useTranslation } from 'react-i18next';
import Modal from '../modal';
import { isChrome, isMobile, isSafari } from 'react-device-detect';
import ChomePwaMobileInstructions from './chrome-pwa-mobile';
import SafariPwaMobileInstructions from './safari-pwa';
import ChomePwaWebInstructions from './chrome-pwa-web';
import useMobileWebInstallPrompt from '@hooks/use-mobile-install-prompt';
import { useModal } from '@contexts/global-modal-context';

export type PwaProp = {
    onClose: () => void;
};

function InstallPWA(): React.ReactElement {
    const [isMobileWebInstallPrompt, handleMobileWebInstallDeclined] = useMobileWebInstallPrompt();
    const { t } = useTranslation(['pwa']);
    const { hideModal } = useModal();
    if (!isMobileWebInstallPrompt) {
        return <></>;
    }

    const onClose = () => {
        handleMobileWebInstallDeclined();
    };

    return (
        <Modal show={true} id="party-modal" onClose={onClose} title={t('install')}>
            <div className="form-container infos">
                <div className="container-fluid px-0 px-0 w-100">
                    {isMobile && isSafari && <SafariPwaMobileInstructions onClose={hideModal} />}
                    {isMobile && isChrome && <ChomePwaMobileInstructions onClose={hideModal} />}
                    {!isMobile && isChrome && <ChomePwaWebInstructions onClose={hideModal} />}
                </div>
            </div>
        </Modal>
    );
}

export default InstallPWA;
