import React, { useEffect, useState } from 'react';
import MobileMenu from '@components/ui/mobile-menu';
import { useTranslation } from 'react-i18next';
import './header.css';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { useAuth } from '@hooks/use-auth';
import { DEFAULT_ROUTE } from '@config/app';
import { MODAL_TYPES, useModal } from '@contexts/global-modal-context';
import useMobileWebInstallPrompt from '@hooks/use-mobile-install-prompt';

// Notice here we're using the function declaration with the interface Props
const Header: React.FunctionComponent = () => {
    const [opened, setOpened] = useState<boolean>(false);
    const [mobileWebInstallPrompt] = useMobileWebInstallPrompt();
    const { showModal } = useModal();

    const { t, i18n } = useTranslation();
    const language = Cookies.get('i18next') || 'fr';
    const { authed } = useAuth();

    const onClickLanguageChange = (language: string) => {
        i18n.changeLanguage(language);
        Cookies.set('i18next', language);
    };

    const handleClick = () => {
        setOpened(!opened);
    };

    useEffect(() => {
        if (mobileWebInstallPrompt) {
            showModal(MODAL_TYPES.INSTALL_PWA_MODAL);
        }
    }, [mobileWebInstallPrompt]);

    return (
        <>
            <div className="section fixed top-header" id="topbar">
                <div className="container top-bar">
                    <Link className="logo-side" to="/">
                        <span className="logo"></span>
                    </Link>
                    <ul className="nav-link mobile-hide">
                        <li>
                            <Link to="/playing">{t('Comment jouer')}</Link>
                        </li>
                        <li>
                            <a href="#">{t('Télécharger sur mobile')}</a>
                        </li>
                        <li>
                            <a href="#">{t('Mode de paiement')}</a>
                        </li>
                    </ul>
                    <div className="app-link mobile-hide">
                        {authed && (
                            <Link className="button default w-auto max-w-auto" to={DEFAULT_ROUTE}>
                                {t("Fil d'actualité")}
                                <span className="icon user-check"></span>
                            </Link>
                        )}
                        {!authed && (
                            <>
                                <Link className="button style-link" to="/auth/signup">
                                    {t('Inscription')}
                                </Link>
                                <Link className="button default" to="/auth/signin">
                                    {t('Connexion')}
                                    <span className="icon user-check"></span>
                                </Link>
                            </>
                        )}

                        <span
                            onClick={() => onClickLanguageChange('fr')}
                            className={`language mx-3 button default d-flex justify-content-center align-items-center gap-1 ${
                                language == 'fr' ? 'active' : ''
                            }`}
                        >
                            <i className="flag fr" style={{ height: 20, width: 20, display: 'inline-block' }}></i>FR
                        </span>
                        <span
                            onClick={() => onClickLanguageChange('en')}
                            className={`language mx-3 button default d-flex justify-content-center align-items-center gap-1 ${
                                language == 'en' ? 'active' : ''
                            }`}
                        >
                            <i className="flag gb" style={{ height: 20, width: 20, display: 'inline-block' }}></i>EN
                        </span>
                    </div>
                    <div
                        className={`menu-trigger web-hide ${opened ? 'opened' : ''}`}
                        id="menu-trigger"
                        onClick={handleClick}
                    >
                        <span className="menu-css-icon"></span>
                    </div>
                </div>
            </div>
            <MobileMenu opened={opened} />
        </>
    );
};

export default Header;
