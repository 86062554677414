import tournamentService from '@services/tournament-service';
import { Tournament } from 'interfaces/tournament.interface';
import { useState } from 'react';
import { useToast } from './use-toast';
import { useDispatch } from 'react-redux';
import { getTournaments } from '@redux/reducers/tournament-reducer';
import { useTranslation } from 'react-i18next';
import { MODAL_TYPES, useModal } from '@contexts/global-modal-context';

export const useTournament = () => {
    const [isJoinning, setIsJoinning] = useState<boolean>(false);
    const [isLeaving, setIsLeaving] = useState<boolean>(false);
    const [isShowing, setIsShowing] = useState<boolean>(false);
    const toaster = useToast();
    const dispatch = useDispatch();
    const { t } = useTranslation(['competition']);
    const { showModal } = useModal();

    const joinTournament = (tournament: Tournament, callback?: (status: boolean) => void) => {
        setIsJoinning(true);
        tournamentService
            .joinTournament(tournament._id)
            .then((status: boolean) => {
                setIsJoinning(false);
                if (status) {
                    toaster?.success(t('join_success'));
                }
                callback?.(status);
                dispatch(getTournaments(1));
            })
            .catch((error) => {
                setIsJoinning(false);
                console.log(error);
            });
    };

    const leaveTournament = (tournament: Tournament, callback?: (status: boolean) => void) => {
        setIsLeaving(true);
        tournamentService
            .quitTournament(tournament._id)
            .then((status) => {
                if (status) {
                    toaster?.success(t('leave_success'));
                }
                callback?.(status);
                dispatch(getTournaments(1));
            })
            .finally(() => {
                setIsLeaving(false);
            });
    };

    const showDetails = (tournamentId: string, callback?: (tournament: Tournament) => void) => {
        setIsShowing(true);
        tournamentService
            .showTournament(tournamentId)
            .then((_tournament) => {
                callback?.(_tournament);
                showModal(MODAL_TYPES.TOURNAMENT_DETAILS_MODAL, { tournament: _tournament });
            })
            .finally(() => {
                setIsShowing(false);
            });
    };

    return {
        isJoinning,
        isLeaving,
        isShowing,
        joinTournament,
        leaveTournament,
        showDetails,
    };
};
