import { isChrome, isSafari } from 'react-device-detect';
import useShouldShowPrompt from './use-should-show-promt';

const iosInstallPromptedAt = 'iosInstallPromptedAt';

const useMobileWebInstallPrompt = (): [boolean, () => void] => {
    const [userShouldBePromptedToInstall, handleUserSeeingInstallPrompt] = useShouldShowPrompt(iosInstallPromptedAt);

    return [(isSafari || isChrome) && userShouldBePromptedToInstall, handleUserSeeingInstallPrompt];
};
export default useMobileWebInstallPrompt;
