import React, { HTMLAttributes } from 'react';
import SvgIcon from '@components/ui/svg-icon';
import { IconName } from '@components/ui/svg-icon/svg-icon';

interface Props {
    name: IconName;
    className?: string;
}

export type IconProps = HTMLAttributes<HTMLSpanElement> & Props;

// Notice here we're using the function declaration with the interface Props
const Icon: React.FunctionComponent<IconProps> = ({ name, className, ...svgProps }: IconProps) => {
    return (
        <span className={'icon icon16 ' + (className || '')} {...svgProps}>
            <SvgIcon name={name} />
        </span>
    );
};

export default Icon;
