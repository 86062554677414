import React, { ChangeEvent, useEffect, useState } from 'react';
import { FormDataState } from '@reducers/form-reducer';
import { IconName } from '@components/ui/svg-icon';
import './internationalized-input.css';
import Select from 'react-select';
export type SelectType = 'select' | 'country';
import CountryService from '@services/country-service';
import parsePhoneNumber, { CountryCode } from 'libphonenumber-js';
import { useTranslation } from 'react-i18next';
export interface Props {
    id: string;
    blackTheme?: boolean;
    icon?: IconName;
    name: string;
    placeholder?: string;
    type?: SelectType;
    label: string;
    required?: boolean;
    value?: string | number | boolean | undefined;
    parent?: FormDataState;
    onChange?(value: any): void;
    disabled?: boolean;
    countryCode?: string;
}

export type InternationalizedValue = {
    value?: string | number | boolean | undefined;
    code?: string;
    dialCode?: string;
    name?: string;
};

export type Ref = HTMLInputElement;
// Notice here we're using the function declaration with the interface Props
const InternationalizedInput = React.forwardRef<Ref, Props>(
    (
        {
            id,
            name,
            placeholder,
            blackTheme,
            countryCode,
            type,
            label,
            required,
            onChange,
            value,
            parent,
            disabled,
        }: Props,
        ref: any,
    ) => {
        //Creating compoent state
        const [error, setError] = useState('');
        const [options, setOptions] = useState<Array<any>>([]);
        const [focused, setFocused] = useState(false);
        const [defaultOption, setDefaultOption] = useState<any>();
        const [selectedCountryOption, setSelectedCountryOption] = useState<any>();
        const [inputValue, setInputValue] = useState<InternationalizedValue>({
            value: value,
        });
        const { t } = useTranslation(['messages']);
        const customStyles = {
            menu: (provided: any) => ({
                ...provided,
                width: '250px',
            }),
            control: (baseStyles: any) => ({
                ...baseStyles,
                width: '120px',
                padding: '6px',
                borderTopLeftRadius: '15px',
                borderBottomLeftRadius: '15px',
                borderToprightRadius: '0',
                borderBottomRightRadius: '0',
                borderColor: 'transparent',
                border: 'none',
                boxShadow: 'none',
            }),
        };

        const inputHandler = (value: InternationalizedValue) => {
            setInputValue(value);
            if (onChange) {
                onChange(value);
            }
        };

        // Initialize the input field with the default value
        useEffect(() => {
            const options = CountryService.fetchCountries().map((country: any) => {
                return {
                    value: country.dial_code,
                    code: country.code,
                    name: country.name.fr,
                    dial_code: country.dial_code,
                    label: country.name.fr + ` (${country.dial_code})`,
                };
            });
            setOptions(options);
            let defaultOpt;
            if (countryCode) {
                defaultOpt = options.find((option) => option.code?.toUpperCase() === countryCode.toUpperCase());
                setDefaultOption(defaultOpt ?? {});
                setSelectedCountryOption(defaultOpt);
            }
            if (value) {
                const phoneNumber = parsePhoneNumber(value + '', countryCode as CountryCode);
                if (phoneNumber) {
                    value = phoneNumber.nationalNumber;
                }
            }
            inputHandler({
                code: countryCode,
                dialCode: defaultOpt?.dial_code,
                value: value,
            });
            document.addEventListener('click', (event: any) => {
                if (event.target.id === id || event.target.classList.contains('phone_number__input-container')) {
                    setFocused(true);
                } else {
                    setFocused(false);
                }
            });
        }, []);

        useEffect(() => {
            if (parent && parent.inputs[name] && parent.inputs[name].errors !== undefined) {
                const errors = parent.inputs[name].errors;
                if (errors && errors.hasError) {
                    setError(errors.first ?? '');
                    return;
                }
            }
            setError('');
        }, [parent]);

        const formatOptionLabel = ({ code, dial_code, name }: any) => (
            <div className="d-flex align-items-center gap-1 item-select">
                <span className={`flag ${code?.toLowerCase()} d-inline-block`} style={{ height: 20, width: 20 }}></span>{' '}
                <span className="country-name">{name}</span>
                <span>({dial_code})</span>
            </div>
        );

        const checkCountryCode = (event: ChangeEvent<HTMLInputElement>) => {
            const phoneNumber = event.target.value;
            if (phoneNumber != undefined && phoneNumber != null && phoneNumber.length > 0) {
                if (phoneNumber.startsWith('+')) {
                    const selectedOption = options.find(
                        (option) => phoneNumber.includes(option.dial_code) || option.dial_code.includes(phoneNumber),
                    );
                    if (selectedOption) {
                        setSelectedCountryOption(selectedOption);
                        inputHandler({
                            code: selectedOption.code,
                            dialCode: selectedOption.dial_code,
                            value: phoneNumber,
                            name: selectedOption.name,
                        });
                    }
                }
            }
        };

        const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
            checkCountryCode(event);
            inputHandler({
                ...inputValue,
                value: event.target.value,
            });
        };

        const handleSelect = (newValue: any) => {
            setSelectedCountryOption(newValue);
            inputHandler({
                ...inputValue,
                code: newValue.code,
                dialCode: newValue.dial_code,
                name: newValue.name,
            });
        };

        return (
            <fieldset className={`input-field ${blackTheme ? 'black-theme' : ''}`}>
                <label htmlFor={id}>
                    {label} {(required === undefined || required) && <span className="form-require">*</span>}
                </label>
                <div className={`number-country ${focused ? 'focused' : ''} ${error ? 'invalid' : ''}`}>
                    {(!countryCode || (defaultOption && defaultOption.code)) && (
                        <Select
                            defaultValue={defaultOption}
                            onChange={handleSelect}
                            options={options}
                            className="select-country"
                            styles={customStyles}
                            classNamePrefix={id}
                            getOptionValue={(option: any) => option.code}
                            getOptionLabel={(option: any) => option.label}
                            formatOptionLabel={formatOptionLabel}
                            value={selectedCountryOption}
                        />
                    )}

                    <input
                        type={type ?? 'input'}
                        onChange={changeHandler}
                        //onFocus={focusHandler}
                        name={name}
                        value={typeof inputValue.value == 'boolean' ? inputValue.value + '' : inputValue.value}
                        id={id}
                        ref={ref}
                        disabled={disabled}
                        placeholder={placeholder ?? ''}
                    />
                </div>

                {error && <strong className="invalid">{t(error)}</strong>}
            </fieldset>
        );
    },
);
InternationalizedInput.displayName = 'InternationalizedInput';
InternationalizedInput.defaultProps = {
    disabled: false,
};
export default InternationalizedInput;
