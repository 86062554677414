import React, { CSSProperties, HTMLAttributes } from 'react';
import './spinner.css';

const animations = [
    { r: 0.0 },
    {
        name: 'spinners-round-filled-inner',
        r: 12.66,
    },
    {
        name: 'spinners-round-filled-center',
        r: 20.32,
    },
    {
        name: 'spinners-round-filled-outer',
        r: 27.5,
    },
];

interface Props {
    speed?: number;
    still: boolean;
    thickness: number;
}

type SpinnerProps = HTMLAttributes<SVGElement> & Props;

const Spinner = ({ speed, still, thickness, ...svgProps }: SpinnerProps): React.ReactElement => (
    <svg fill="#FFF" {...svgProps} viewBox="0 0 66 66">
        {animations.map((animation) => (
            <circle
                key={animation.name || 'still'}
                cx="33"
                cy="33"
                fill="currentColor"
                r={animation.r * (animation.name ? (thickness ?? 0) / 100 : 1)}
                style={{
                    opacity: animation.name ? 0.25 : 1,
                    transformOrigin: 'center',
                    animation:
                        !animation.name || still
                            ? 'none'
                            : `${animation.name} ${140 / (speed ?? 0)}s ease-in-out infinite`,
                }}
            />
        ))}
    </svg>
);

Spinner.defaultProps = {
    speed: 100,
    still: false,
    thickness: 100,
    color: '#38ad48' as CSSProperties['color'],
    size: 10 as CSSProperties['width'],
    style: {} as CSSProperties,
};

export default Spinner;
