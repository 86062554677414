import React, { useEffect, useState } from 'react';
import './tournament-registered.css';
import { useTranslation } from 'react-i18next';
import { Tournament } from 'interfaces/tournament.interface';
import defaultAvatar from '@assets/default-avatar/9.png';
import { useAuth } from '@hooks/use-auth';
import Cookies from 'js-cookie';
import moment from 'moment';
import Spinner from '@components/ui/spinner';
import { TournamentPlayer } from 'interfaces/tournament-player.interface';

type TournamentRegisteredUsersProps = {
    tournament: Tournament;
    subscrited: boolean;
    isJoinning: boolean;
    isLeaving: boolean;
    onGoBack: () => void;
    onJoinTournament: () => void;
    onQuitTournament: () => void;
};

function TournamentRegisteredUsers(
    props: TournamentRegisteredUsersProps,
): React.ReactElement<TournamentRegisteredUsersProps> {
    const { t } = useTranslation(['competition']);
    const { user } = useAuth();
    const { onGoBack, onJoinTournament, onQuitTournament, subscrited, isJoinning, isLeaving, tournament } = props;
    const locale = Cookies.get('i18next') || 'fr';
    const [users, setUsers] = useState<TournamentPlayer[]>([]);
    const [subscribedUser, setSubscribedUser] = useState<TournamentPlayer>();
    moment.locale(locale);

    useEffect(() => {
        setUsers(tournament.users.filter((u) => u.user_id != user?.id));
        setSubscribedUser(tournament.users.find((u) => u.user_id == user?.id));
    }, [tournament]);

    return (
        <div className="tour-form-item active-form" data-tourformindex="0">
            <div className="tour-form-header">
                <div className="modal-return opaced mobile-hide">
                    <span className="icon icon12" onClick={onGoBack}>
                        <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M9 1L1 9L9 17"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </span>
                </div>
                <h4 className="modal-title">{t('tournament_details')}</h4>
            </div>
            <div className="breadcrumb">
                <span className="feed">
                    {t('event')}
                    <span className="icon icon8">
                        <svg viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.46967 8.46967C0.176777 8.76256 0.176777 9.23744 0.46967 9.53033C0.762563 9.82322 1.23744 9.82322 1.53033 9.53033L0.46967 8.46967ZM5 5L5.53033 5.53033C5.67098 5.38968 5.75 5.19891 5.75 5C5.75 4.80109 5.67098 4.61032 5.53033 4.46967L5 5ZM1.53033 0.46967C1.23744 0.176777 0.762563 0.176777 0.46967 0.46967C0.176777 0.762563 0.176777 1.23744 0.46967 1.53033L1.53033 0.46967ZM1.53033 9.53033L5.53033 5.53033L4.46967 4.46967L0.46967 8.46967L1.53033 9.53033ZM5.53033 4.46967L1.53033 0.46967L0.46967 1.53033L4.46967 5.53033L5.53033 4.46967Z"
                                fill="white"
                                fillOpacity="0.64"
                            />
                        </svg>
                    </span>
                    {tournament.short_name}
                </span>
                <h4 className="flow-title">{t('subscribers')}</h4>
            </div>

            <div className="form-container tournament-details">
                <div className="creator w-100 tournament-current-user">
                    <div className="user">
                        <div className="user-avatar">
                            <img src={defaultAvatar} alt="" />
                        </div>
                        <div className="user-id">
                            <span className="user-name">{user?.pseudo}</span>
                            <span className="user-role">
                                {!subscribedUser && (
                                    <div className="event-time-discount">{t('not_yet_registered')}</div>
                                )}
                                {subscribedUser && (
                                    <div className="event-time-discount">
                                        {moment
                                            .duration(moment(subscribedUser.registered_at).diff(moment.now()))
                                            .humanize(true)}
                                    </div>
                                )}
                            </span>
                        </div>
                    </div>
                    <div>
                        {!subscrited && (
                            <button className="opaced" type="button" onClick={onJoinTournament}>
                                {t('i_join', { ns: 'actions' })}
                                {isJoinning && (
                                    <span className="flex-none" style={{ width: 20 }}>
                                        <Spinner color="#FFF" />
                                    </span>
                                )}
                                {!isJoinning && (
                                    <span className="icon icon12">
                                        <svg
                                            width="18"
                                            height="18"
                                            viewBox="0 0 18 18"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M13.9748 6.86366C14.2677 6.57077 14.2677 6.0959 13.9748 5.803C13.6819 5.51011 13.2071 5.51011 12.9142 5.803L13.9748 6.86366ZM7.22226 12.5556L6.69193 13.0859C6.98482 13.3788 7.4597 13.3788 7.75259 13.0859L7.22226 12.5556ZM5.08588 9.35856C4.79299 9.06566 4.31812 9.06567 4.02522 9.35856C3.73233 9.65145 3.73233 10.1263 4.02523 10.4192L5.08588 9.35856ZM12.9142 5.803L6.69193 12.0252L7.75259 13.0859L13.9748 6.86366L12.9142 5.803ZM4.02523 10.4192L6.69193 13.0859L7.75259 12.0252L5.08588 9.35856L4.02523 10.4192ZM16.25 9C16.25 13.0041 13.0041 16.25 9 16.25V17.75C13.8325 17.75 17.75 13.8325 17.75 9H16.25ZM9 16.25C4.99594 16.25 1.75 13.0041 1.75 9H0.25C0.25 13.8325 4.16751 17.75 9 17.75V16.25ZM1.75 9C1.75 4.99594 4.99594 1.75 9 1.75V0.25C4.16751 0.25 0.25 4.16751 0.25 9H1.75ZM9 1.75C13.0041 1.75 16.25 4.99594 16.25 9H17.75C17.75 4.16751 13.8325 0.25 9 0.25V1.75Z"
                                                fill="white"
                                            />
                                        </svg>
                                    </span>
                                )}
                            </button>
                        )}
                        {subscrited && (
                            <button className="btn danger" type="button" onClick={onQuitTournament}>
                                {t('quit', { ns: 'actions' })}
                                {isLeaving && (
                                    <span className="flex-none" style={{ width: 20 }}>
                                        <Spinner color="#FFF" />
                                    </span>
                                )}
                                {!isLeaving && (
                                    <span className="icon icon12">
                                        <svg
                                            width="18"
                                            height="18"
                                            viewBox="0 0 18 18"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M13.9748 6.86366C14.2677 6.57077 14.2677 6.0959 13.9748 5.803C13.6819 5.51011 13.2071 5.51011 12.9142 5.803L13.9748 6.86366ZM7.22226 12.5556L6.69193 13.0859C6.98482 13.3788 7.4597 13.3788 7.75259 13.0859L7.22226 12.5556ZM5.08588 9.35856C4.79299 9.06566 4.31812 9.06567 4.02522 9.35856C3.73233 9.65145 3.73233 10.1263 4.02523 10.4192L5.08588 9.35856ZM12.9142 5.803L6.69193 12.0252L7.75259 13.0859L13.9748 6.86366L12.9142 5.803ZM4.02523 10.4192L6.69193 13.0859L7.75259 12.0252L5.08588 9.35856L4.02523 10.4192ZM16.25 9C16.25 13.0041 13.0041 16.25 9 16.25V17.75C13.8325 17.75 17.75 13.8325 17.75 9H16.25ZM9 16.25C4.99594 16.25 1.75 13.0041 1.75 9H0.25C0.25 13.8325 4.16751 17.75 9 17.75V16.25ZM1.75 9C1.75 4.99594 4.99594 1.75 9 1.75V0.25C4.16751 0.25 0.25 4.16751 0.25 9H1.75ZM9 1.75C13.0041 1.75 16.25 4.99594 16.25 9H17.75C17.75 4.16751 13.8325 0.25 9 0.25V1.75Z"
                                                fill="white"
                                            />
                                        </svg>
                                    </span>
                                )}
                            </button>
                        )}
                    </div>
                </div>
                {users.map((player: TournamentPlayer) => {
                    return (
                        <div className="tournament-player-infos" key={player._id}>
                            <div className="player-pseudo">
                                <div className="user-avatar">
                                    <img src={defaultAvatar} alt="" />
                                </div>
                                <span className="user-name">
                                    <b>{player.pseudo}</b>
                                </span>
                            </div>
                            <span>
                                {moment.duration(moment(player.registered_at).diff(moment.now())).humanize(true)}
                            </span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default TournamentRegisteredUsers;
