import React, { useEffect } from 'react';
import Modal from '@components/ui/modal';
import { useModal } from '@contexts/global-modal-context';
import './available-update-modal.css';
import { useTranslation } from 'react-i18next';
import { __INFOS__, __RELEASE_NOTES_VIEWED__ } from '@constants/storage';
import { useLocalStorage } from '@hooks/use-local-storage';
import { AppReleaseNotes } from '@models/app-version.model';

type AvailableInfosProps = {
    releaseNotes: AppReleaseNotes;
    shouldUpdate: boolean;
};
function AvailableUpdateModal(props: AvailableInfosProps): React.ReactElement<AvailableInfosProps> {
    const { hideModal } = useModal();
    const { t } = useTranslation(['infos-modal']);
    const [, setHasAlreadySeenInfos] = useLocalStorage(__INFOS__, true);
    const { releaseNotes, shouldUpdate } = props;
    const [, setIsReleaseNotesViewed] = useLocalStorage<boolean>(__RELEASE_NOTES_VIEWED__, false);

    const handleClose = () => {
        setIsReleaseNotesViewed(true);
        hideModal();
    };

    useEffect(() => {
        setHasAlreadySeenInfos(true);
    });

    return (
        <Modal hideOnAreaClick={!shouldUpdate} show={true} id="available-modal" title={t('update', { ns: 'global' })}>
            <div className="form-container infos">
                <div className="container-fluid px-0 px-0 w-100">
                    <div className="row">
                        <div className={`col-12 tab active`} id="tab">
                            {releaseNotes.news && releaseNotes.news.length > 0 ? (
                                <div className="update-news">
                                    <h4 className="mb-2">{t('update_news', { ns: 'global' })}</h4>
                                    {releaseNotes.news.map((newItem, i) => {
                                        return (
                                            <p key={i}>
                                                <b>+ </b>
                                                {newItem}
                                            </p>
                                        );
                                    })}
                                </div>
                            ) : null}
                            {releaseNotes.fixes && releaseNotes.fixes.length > 0 ? (
                                <div className="update-fixes">
                                    <h4 className="danger mb-2">{t('update_corrections', { ns: 'global' })}</h4>
                                    {releaseNotes.fixes.map((fixItem, i) => {
                                        return (
                                            <p key={i}>
                                                <b>- </b>
                                                {fixItem}
                                            </p>
                                        );
                                    })}
                                </div>
                            ) : null}
                        </div>
                    </div>

                    <div className="actions justify-content-center d-flex">
                        {!shouldUpdate && (
                            <button className="button danger" onClick={handleClose}>
                                {t('ok', { ns: 'actions' })}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default AvailableUpdateModal;
