import Modal from '@components/ui/modal';
import React, { useState } from 'react';
import { useModal } from '@contexts/global-modal-context';
import { useTranslation } from 'react-i18next';
import Icon from '@components/ui/icon';
import './game-history-modal.css';
import { Player } from 'interfaces/player.interface';
import defaultAvatar from '@assets/default-avatar/9.png';
import Transaction from '@models/transaction';
import useMount from '@hooks/use-mount';
import gameService from '@services/game-service';
import { GameInfo } from 'interfaces/game-infos.interface';
import Spinner from '@components/ui/spinner';
import { formatAmount } from '@utils/helpers';
import { ReactComponent as CalendarIcon } from '@assets/icons/outline/calendar.svg';
import { ReactComponent as MoneyIcon } from '@assets/icons/outline/money.svg';
import { ReactComponent as CoinIcon } from '@assets/icons/outline/coin.svg';
import { ReactComponent as TagIcon } from '@assets/icons/outline/tag.svg';
import { ReactComponent as KeyIcon } from '@assets/icons/outline/key.svg';
import moment from 'moment';

type GameHistoryDetailsModalProps = {
    onHideModal: (data: any) => void;
    transaction: Transaction;
};
function GameHistoryDetailsModal(props: GameHistoryDetailsModalProps): React.ReactElement {
    const { transaction } = props;
    const { hideModal } = useModal();
    const { t } = useTranslation(['party']);
    const [game, setGame] = useState<GameInfo>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const onClose = () => {
        hideModal();
    };

    useMount(() => {
        gameService
            .getGameInfo(transaction.id)
            .then((game) => {
                setGame(game);
                setIsLoading(false);
            })
            .catch(() => setIsLoading(false));
    });
    return (
        <Modal show={true} onClose={onClose} hideOnClose={false} id="party-modal" title="Détails">
            <div className="form-container">
                {isLoading && (
                    <div className="page-section mobile-padding-security">
                        <div className="d-flex align-items-center widget info-card large opaced create-game-card  text-center">
                            <p className="text-center">{t('Chargement en cours...')}</p>
                            <span className="flex-none" style={{ width: 100 }}>
                                <Spinner color="#FFF" />
                            </span>
                        </div>
                    </div>
                )}
                {game && (
                    <>
                        <div>
                            <fieldset className="d-flex flex-row align-items-center gap-4 py-1">
                                <span className="icon icon12 custom-icon">
                                    <TagIcon />
                                </span>
                                <div className="d-flex align-items-center gap-3 awaiting-g-bet">
                                    <strong
                                        className={`text-uppercase ${
                                            transaction.amount >= 0 ? 'text-success' : 'text-danger'
                                        } `}
                                    >
                                        {transaction.operation}{' '}
                                    </strong>
                                    {game.history?.win_type_fr != 'Normal' && (
                                        <span className="notif-badge">{game.history.win_type_fr}</span>
                                    )}
                                </div>
                            </fieldset>
                            <fieldset className="d-flex flex-row align-items-center gap-4 py-1">
                                <span className="icon icon12 custom-icon">
                                    <CoinIcon />
                                </span>
                                <div className="d-flex align-items-center gap-1 awaiting-g-bet">
                                    {t('balance', { ns: 'global' })}:{' '}
                                    <strong>{formatAmount(transaction.balance) + ' F'}</strong>
                                </div>
                            </fieldset>
                            <fieldset className="d-flex flex-row align-items-center gap-4 py-1">
                                <span className="icon icon12 custom-icon">
                                    <MoneyIcon />
                                </span>
                                <div className="d-flex align-items-center gap-1 awaiting-g-bet">
                                    {t('amount', { ns: 'global' })}:{' '}
                                    <strong className={`${transaction.amount >= 0 ? 'text-success' : 'text-danger'}`}>
                                        {transaction.amount >= 0 ? ' +' : ' -'}
                                        {formatAmount(transaction.amount, true) + ' F'}
                                    </strong>
                                </div>
                            </fieldset>
                            <fieldset className="d-flex flex-row align-items-center gap-4 py-1">
                                <span className="icon icon12 custom-icon">
                                    <KeyIcon />
                                </span>
                                <div className="d-flex align-items-center gap-1 awaiting-g-bet">
                                    ID:
                                    <b>{game.history.part.unique_key.toUpperCase()}</b>
                                </div>
                            </fieldset>
                            <fieldset className="d-flex flex-row align-items-center gap-4 py-1">
                                <span className="icon icon12 custom-icon">
                                    <CalendarIcon />
                                </span>
                                <div className="d-flex align-items-center gap-1 awaiting-g-bet">
                                    {moment(transaction.performed_at).format('DD/MM/YYYY HH:mm:ss')}
                                </div>
                            </fieldset>
                        </div>
                        <div className="separator light"></div>
                        <div>
                            <fieldset className="d-flex flex-row align-items-center gap-4 py-1">
                                <Icon name={'bet'} className="awaiting-game-icon"></Icon>
                                <div className="d-flex align-items-center gap-3 awaiting-g-bet">
                                    <strong>{game.bet} XAF</strong>
                                    <span className="notif-badge">{game.korat ? 'K x2' : ''}</span>
                                    <span className="notif-badge">{game.double_korat ? '2K x4' : ''}</span>
                                </div>
                            </fieldset>
                            <fieldset className="d-flex flex-row align-items-center gap-4 py-1">
                                <Icon name={'users'} className="awaiting-game-icon"></Icon>
                                <div className="d-flex align-items-center gap-1 awaiting-g-bet">
                                    <strong>{game.max_nb_players} adversaire(s) max</strong>
                                </div>
                            </fieldset>
                            <fieldset className="d-flex flex-row align-items-center gap-4 py-1">
                                <Icon name={'timer'} className="awaiting-game-icon"></Icon>
                                <div className="d-flex align-items-center gap-1 awaiting-g-bet">
                                    <strong>{game.time_per_move}s par coup</strong>
                                </div>
                            </fieldset>
                            {game.special_mode && (
                                <fieldset className="d-flex flex-row align-items-center gap-4 py-1">
                                    <Icon name={'settings'} className="awaiting-game-icon"></Icon>
                                    <div className="d-flex align-items-center gap-1 awaiting-g-bet">
                                        <strong>{t('stopped_balls_list', { ns: 'game' })}</strong>
                                    </div>
                                </fieldset>
                            )}
                        </div>
                        <div className="separator light"></div>
                        {game.players.map((player: Player) => {
                            return (
                                <div className="creator-infos" key={player._id}>
                                    <div className="user-avatar">
                                        <img src={defaultAvatar} alt="" />
                                    </div>
                                    <span className="user-name">
                                        <b>{player.pseudo}</b>
                                    </span>
                                </div>
                            );
                        })}
                    </>
                )}

                <div className="actions">
                    <button className="danger text-center d-flex justify-content-center " onClick={onClose}>
                        {t('cloase', { ns: 'actions' })}
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default GameHistoryDetailsModal;
