// useCreatePartyModal.ts
import { d } from '@utils/encryption-wrapper';
import { MODAL_TYPES } from '@contexts/global-modal-context';
import { useEffect, useState } from 'react';
import { Information } from '@models/informations.model';
import { InfosSurveys } from '@models/info-surveys.model';
import { EventEmitter } from '@utils/event-emitter';
import { SHOW_MODAL } from '@constants/event-emitter';
import { useAuth } from './use-auth';

export const useInformations = () => {
    const [informations, setInformations] = useState<Information[]>([]);
    const [canShowInformations, setCanShowInformations] = useState<boolean>(true);
    const { authed } = useAuth();

    const onInformations = (data: string) => {
        const infosSurveys: InfosSurveys = d(data);
        setInformations(infosSurveys.infos);
        onDisplayInformations(infosSurveys.infos);
    };

    const onDisableInformations = () => {
        setCanShowInformations(false);
    };

    const onEnableInformations = () => {
        setCanShowInformations(true);
    };

    const onDisplayInformations = (infos: Information[]) => {
        if (authed && canShowInformations && infos.length > 0) {
            EventEmitter.emit(SHOW_MODAL, MODAL_TYPES.INFORMATION_MODAL, { infos });
        }
    };

    const onViewInfos = () => {
        setInformations([]);
    };

    useEffect(() => {
        onDisplayInformations(informations);
    }, [authed]);

    return {
        canShowInformations,
        informations,
        onViewInfos,
        onInformations,
        onDisableInformations,
        onEnableInformations,
    };
};
