import { Party } from 'interfaces/party.interface';
import { Tournament } from 'interfaces/tournament.interface';

export const partRequiredAmount = (part: Party) => {
    const bet = part.bet;
    if (part.double_korat) return bet * 4;
    if (part.korat) return bet * 2;
    return bet;
};

export const tournamentRequiredAmount = (toutnament: Tournament) => {
    const bet = toutnament.game_settings.bet;
    if (toutnament.game_settings.double_korat) return bet * 4;
    if (toutnament.game_settings.korat) return bet * 2;
    return bet;
};

export const partMaxWin = (part: Party) => part.max_nb_players * partRequiredAmount(part);

export const tournamentMaxWin = (tournament: Tournament) =>
    tournament.game_settings.max_nb_players * tournamentRequiredAmount(tournament);

export const canJoinParty = (part: Party, balance: number) => balance >= partRequiredAmount(part);

export const canJoinTournament = (tournament: Tournament, balance: number) =>
    balance >= tournamentRequiredAmount(tournament);
