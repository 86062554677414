export default {
    CANCEL_GAME: 'ZQVhcEkZ9T4UKujx4gu8',
    CONNECT: 'connect',
    DEPOSIT_FEEDBACK: 'fJKTXd5pWqV7dsL9rI5S',
    DISCONNECT: 'disconnect',
    GAME_CANCELED: 'kQSZop6lGcl2JjpRA6ij',
    GAME_MESSAGE: 'N2FumurOWI0pb1cvu0MP',
    GAME_OVER: 'iBsYQYOHUA9RIQNKx7H8',
    GET_GAME_DATA: 'sjYWYJAMErviHjrNkTQW',
    GLOBAL_MESSAGE: '6a2zDug6b3C364QQi9Qc',
    ID: '5mRPsNDgvtLt9nsYAmUL', // Ping
    JOIN_GAME: 'U4TFALEut1cYgxATLOWn',
    KO: '2TO0E1R4xFSatNExowIe', // Authentication problem
    LEADERBOARD_UPDATE: 'RBvUmJgmRSCeP4nq9Xhr',
    LEAVE_GAME: 'eZXUDYHIKN60Nf6kIOds',
    MOBILE_APP_VERSION: 'JmR55joeSWZ8T90C7KIu',
    OK: 'CH36it9UyQxUob5nXNKq', // Pong
    ONGOING_GAME_ADD: 'iRPqLvL6PfMKYURbFxU7',
    ONGOING_GAME_EXIT: '1tAMR5C8AX7FEgDN6AYN',
    PENDING_GAME_ADD: 'Yhr1t56So5tz1qhoa7gx',
    PENDING_GAME_EXIT: 'H4MsITdP6nOMvD4tu394',
    PENDING_GAME_UPDATE: 'u893Jh0JEzHyeoKdBsxY',
    READY: 'HEMyvsKR0NnYcTLEvYRr',
    RECEIVE_CARD: 'VzihWVBhChrUDQ3dyUTH',
    RESET_CLOCK: 'exGd8Trr1MzYljDhnU4T',
    RESIGN: 'aqr755K76BDeegkiAyOD',
    RESUME_GAME: 'k5TktzNmA28ItnVUwByq',
    SEND_CARD: 'a7WduYEfghX13ga7V8Ui',
    SEND_NEXT_CARD: 'sSDTJquUb3MbmYOouKob',
    SPECIAL_WIN: '5930fj6PfDRSqFc5ErZU',
    START_CLOCK: 'LXoSMxAs2R5WS238Z3ck',
    START_GAME: '7mgB4teqOfTTQrlcRxgp',
    SURVEYS: 'JqdrFwiw4OieONW4q2uK',
    TOAST: 'JPOHy8524ZP5JiFIp5lS',
    UPDATE_BALANCE: 'Rrh7IN9oedbKi2HpGTN9',
    UPDATE_USER_COUNT: 'Othttm0adcIqDvRtv0T7',
    USER_JOINED: 'tV4LqEojZR4RMxYLrPbY',
    USER_LEFT: 'vEauFaCNxIqq4eWFRhKn',
    VIBRATE: 'P8UxftQKAujKcCrN8StT',
    WITHDRAWAL_FEEDBACK: 'PrzJ42u2kFxUYF9sTPGE',
    UPDATE_BONUS: 'kLPuDAIQN8Wt4IWcvVRM',
    INFOS: 'YYgXq9vt73RmR5MTqAUv',
    REMATCH_GAME: '6d7qQugVLGexuZIAcoo9',
    REMATCH_GAME_CANCEL: '7vT8qV9wrSoYevqiszWr',
    REMATCH_GAME_START: 'n2KRqQ42WILeXvnzeLoX',
    REMATCH_GAME_UPDATE: 'ejCtQRr2lagbk6tPf3ku',
    PARTNER_LEADERBOARD_UPDATE: 'zyLRCQDjfUNmfu3k3VqZ',
    INFOS_SURVEYS: 'xbwAgrKyhJ3bdKqAK1i2',
    GET_ONGOING_GAMES: '7F5rIMvd5gm127WXPMxZ',
    GET_PENDING_GAMES: 'CKUQlQqJi0ivlcmlD8Me',
    ONGOING_GAME_LIST: 'nZ7H2WnoWtd3HOkSQTDp',
    PENDING_GAME_LIST: 'J4TDDl1NWV10PclRqY88',
};

// Next random unique strings (delete after usage !!!)

// xbwAgrKyhJ3bdKqAK1i2
// 7F5rIMvd5gm127WXPMxZ
// CKUQlQqJi0ivlcmlD8Me
// nZ7H2WnoWtd3HOkSQTDp
// J4TDDl1NWV10PclRqY88
// Rg53xilWwCHwQzAiPuMx
// MMuVox3ZDHRdOhTT7CtH
// Xy74emF48E5zppIkiOZG
// irPxhKWtxEk5yaFGb5Uu
// 5pbsQwwpxeGg8tgCkKfT
// 7YkooEZnhAaEodwUQ9Hs
// umN6K0jeeM1toQBpbsul
// Z1E3wK4jgLxmysIAuTkt
// z3FEPrVahDPEZ059pTBy
// un8SxvuHf5F3wxRdC6Ry
// zAZC4isjUdxPFXasKNL3
// E7bWPzQ7As0XNbzL6NMC
// EtdF5ZktxJdmdfL0gDRA
// bWBOlvtz1Fs5zB7TbYbM
// Vmdl6BDZsKDh7xStPwW1
// XfoUVo69AHnzVNi99zYl
// z1JHbNZmRZmbKGQMO7mw
// 35aXcozNb3sgLzGAtMt4
// M3mMwY6LnNNeqD1YF8e7
// GDO85QLsEszaCHCBC1Pq
// KR6qdyUi0CN4SP7LklUo
// yDl24vdPnzJeNlASywf1
// uAQ6SFnThat2FAW0vF0l
// 51wKe8xP0yz96TXM0jkF
// PX01xfaNbNbbWQuOKGsB
// tEINJCNBD5p8vePsAFz8
// 9QyKWU67fYjWHD6PZalf
// r69KVr5mxREDljIKhryF
// NO31TJD53vimXGeE3Kwc
// T340FvA9IwSe0qLKcR9k
// ffDLHDkQG5TBU85cti6W
// J7of3CdtF5NH1WqJ7iIK
// L2xBkq3lK20wGGyuddhX
// 6OvpcCOBVcehX8j1g3kI
// RY8e6on7XNG051Yx9Xpv
// rcKpX4CUIEvZZhsk3a0c
// Nn7mXbMxIaeyE8763xgz
// H46NoEahLTSOFN2iizb0
// V07i5q94xc4jj5HZmrQF
// IU3gzM4cA2ckdPqauUHn
// jkJHzwmIXLNBGRr9FRfX
// yOvc30tqnJFc4a6D8KiV
// OgXTd8wzMjotWeiiD8Xf
// IvEpE1pRHTKZhhYUCxe9
// NLn4GFtIYdbMMUceAGPN
// BF3Te1tQfQBPZsQi57ve
// NUgLPiAWRD4k5RozZo0C
// Oo0ZJSLILBJ1EkB2nCBr
// pbjZZAxxkrmvOZn17r7P
// yCsZbQnb4YuGlBhPSD5J
