import { d } from '@utils/encryption-wrapper';
import { MODAL_TYPES } from '@contexts/global-modal-context';
import { useEffect, useState } from 'react';
import { EventEmitter } from '@utils/event-emitter';
import { Survey } from '@models/survey.model';
import { InfosSurveys } from '@models/info-surveys.model';
import { LOGOUT, SHOW_MODAL } from '@constants/event-emitter';
import { useLocalStorage } from './use-local-storage';
import { __SURVEY__ } from '@constants/storage';
import { useAuth } from './use-auth';

export const useSurvey = () => {
    const [surveys, setSurveys] = useState<Survey[]>([]);
    const [canShowSurvey, setCanShowSurvey] = useState<boolean>(true);
    const [hasAlreadySeenSurveys, setHasAlreadySeenSurveys] = useLocalStorage(__SURVEY__, false);
    const { authed } = useAuth();

    const onSurveys = (data: string) => {
        EventEmitter.emit('closeTricksModal');
        const surveys: InfosSurveys = d(data);
        setSurveys(surveys.surveys);
        onDisplaySurveys(surveys.surveys);
    };

    const onDisableSurvey = () => {
        setCanShowSurvey(false);
    };

    const onEnableSurvey = () => {
        setCanShowSurvey(true);
    };

    const onClearData = () => {
        setHasAlreadySeenSurveys(false);
    };

    const onDisplaySurveys = (surveys: Survey[]) => {
        if (authed && !hasAlreadySeenSurveys && canShowSurvey && surveys.length > 0) {
            EventEmitter.emit(SHOW_MODAL, MODAL_TYPES.INFORMATION_MODAL, { surveys });
        }
    };

    useEffect(() => {
        EventEmitter.on(LOGOUT, onClearData);
    });

    useEffect(() => {
        onDisplaySurveys(surveys);
    }, [hasAlreadySeenSurveys, authed]);

    return {
        canShowSurvey,
        surveys,
        onSurveys,
        onDisableSurvey,
        onEnableSurvey,
    };
};
