import React, { ReactNode, createContext, useEffect, useState } from 'react';
import { useLocalStorage } from '@hooks/use-local-storage';
import AuthService from '@services/auth-service';
import { useDispatch } from 'react-redux';
import { setPlayerUser } from '@redux/reducers/game-reducer';
import { UserSession } from '@models/session.model';
import { User } from '@models/user';
import { __SESSION_STORE__ } from '@constants/storage';
import { setBlockedGamers } from '@redux/reducers/block-gamer-reducer';
import { EventEmitter } from '@utils/event-emitter';
import { LOGOUT } from '@constants/event-emitter';

export interface AuthProviderProps {
    children?: ReactNode;
}

export interface UserContextState {
    isAuthenticated: boolean;
    isLoading: boolean;
    id?: string;
}

export const UserStateContext = createContext<UserContextState>({} as UserContextState);

export interface AuthContextModel {
    login(session: UserSession): void;
    logout(): void;
    updateSession(session: UserSession): void;
    updateUser(user: User): void;
    destroySession(): void;
    isReferee: boolean;
    session: UserSession | null;
    user: User | null | undefined;
    authed: boolean | null;
}

export const AuthContext = React.createContext<AuthContextModel>({} as AuthContextModel);

export const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
    const [session_, setSession_] = useLocalStorage<UserSession | null>(__SESSION_STORE__);
    const [user, setUser] = useState<User>();
    const [isReferee, setIsReferee] = useState<boolean>(false);
    const [, setSession] = useState<UserSession | null>(null);
    const dispatch = useDispatch();
    // Using the useState hook to keep track of the value authed (if a
    // user is logged in)
    const [authed, setAuthed] = useState<boolean | null>(null);

    const login = (session: UserSession): void => {
        setAuthed(true);
        setSession(session);
        setUser(session.user);
        setSessionInLocalStorage(session);
    };

    const logout = (): void => {
        AuthService.logout().finally(() => {
            destroySession();
        });
    };

    const destroySession = (): void => {
        setAuthed(false);
        setSession(null);
        setSessionInLocalStorage(null);
        EventEmitter.emit(LOGOUT);
    };

    const setSessionInLocalStorage = (session: UserSession | null) => {
        setSession_(session);
        return true;
    };

    const checkIfIsReferee = function (user?: User): boolean {
        if (!user) {
            return false;
        }
        return user.roles && user.roles.includes('Referee');
    };

    const updateSession = (session: UserSession) => {
        const newUser = {
            ...(session_?.user || {}),
            ...session.user,
        };
        setSessionInLocalStorage({
            ...session_,
            ...session,
            user: newUser,
        });
        setUser(newUser);
    };

    const updateUser = (user: User) => {
        if (session_) {
            const newSession: UserSession = { ...session_ };
            newSession.user = {
                ...newSession.user,
                ...user,
            };
            setSessionInLocalStorage(newSession);
            setSession_(newSession);
            setUser(newSession.user);
        }
    };

    useEffect(() => {
        setSession(session_);
        setIsReferee(checkIfIsReferee(session_?.user));
        setAuthed(!!session_ && !!session_.secret && !!session_.user && !!session_.logged_at);
        dispatch(setPlayerUser(session_?.user));

        if (session_?.user.blacklist) {
            dispatch(setBlockedGamers(session_?.user.blacklist));
        }
    }, [session_]);

    useEffect(() => {
        setIsReferee(checkIfIsReferee(session_?.user));
        setAuthed(!!session_ && !!session_.secret && !!session_.user && !!session_.logged_at);
        dispatch(setPlayerUser(session_?.user));
        setUser(session_?.user);
    }, []);

    const values = {
        user,
        session: session_,
        login,
        authed,
        logout,
        updateSession,
        destroySession,
        updateUser,
        isReferee,
    };
    return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};
