import { JSONSimpleValue } from '@ctypes/json-value';
import { FormDataChangedType, FormDataType, FormInputType } from '@ctypes/form-data';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormRequest } from '@requests/common';

export interface FormInputState {
    inputs: FormDataType;
}

export interface FormDataState {
    inputs: FormDataType;
    data: FormInputType;
    validator?: FormRequest;
    changed: FormDataChangedType;
    old: FormInputType;
}

export type FormState = FormDataState | FormDataState;

const initialState: FormState = {
    inputs: {},
    data: {},
    changed: {},
    old: {},
};

const formSlice = createSlice({
    name: 'form',
    initialState,
    reducers: {
        formChange: (state, { payload }: PayloadAction<{ inputId: string; inputValue: JSONSimpleValue }>) => {
            state.old = {};
            Object.keys(state.inputs).forEach((inputId: string) => {
                state.old[inputId] = state.inputs[inputId].value;
            });
            state.inputs = { ...state.inputs, [payload.inputId]: { value: payload.inputValue } };
            for (const key in state.inputs) {
                state.data[key] = state.inputs[key].value;
            }
            if (state.validator) {
                state.validator.validate(state.data);
                for (const attr in state.data) {
                    state.inputs[attr] = { ...state.inputs[attr], errors: state.validator.errors.get(attr) };
                }
            }
            state.changed = {};
            Object.keys(state.inputs).forEach((inputId: string) => {
                if (state.inputs[inputId].value !== state.old[inputId]) {
                    state.changed[inputId] = { value: state.inputs[inputId].value, oldValue: state.old[inputId] };
                }
            });
        },
        setFormInputs: (state, { payload }: PayloadAction<{ inputs: FormDataType }>) => {
            state.old = {};
            Object.keys(state.inputs).forEach((inputId: string) => {
                state.old[inputId] = state.inputs[inputId].value;
            });
            state.inputs = { ...payload.inputs };
            for (const key in state.inputs) {
                state.data[key] = state.inputs[key].value;
            }
            if (state.validator) {
                state.validator.validate(state.data);
                for (const attr in state.data) {
                    state.inputs[attr] = { ...state.inputs[attr], errors: state.validator.errors.get(attr) };
                }
            }
            state.changed = {};
            Object.keys(state.inputs).forEach((inputId: string) => {
                if (state.inputs[inputId].value !== state.old[inputId]) {
                    state.changed[inputId] = { value: state.inputs[inputId].value, oldValue: state.old[inputId] };
                }
            });
        },
    },
});

export const { formChange, setFormInputs } = formSlice.actions;

export default formSlice.reducer;

export const formSelector = (state: { formStore: FormState }) => state.formStore;
