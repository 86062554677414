import { useState, useEffect } from 'react';

export const useMediaScreen = () => {
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 576) {
                setIsSmallScreen(true);
            } else {
                setIsSmallScreen(false);
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return [isSmallScreen] as const;
};
