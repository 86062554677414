import Country from '@models/country';
import Countries from '@utils/countries.json';

const findCountry = (phone_number: string) => {
    const dial_code = phone_number.substring(0, 4);
    return (Countries as Country[]).find((country: Country) => country.dial_code === dial_code);
};

const fetchCountries = () => {
    return Countries;
};

const CountryService = {
    findCountry,
    fetchCountries,
};

export default CountryService;
