import React, { FormEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import Form from '@components/ui/form';
import Button from '@components/ui/button';
import BInputField from '@components/ui/b-input';
import { useToast } from '@hooks/use-toast';
import ProfileFormRequest from '@requests/profile-request';
import { useAuth } from '@hooks/use-auth';
import { useMediaScreen } from '@hooks/use-media-screen';
import * as Auth from '@models/auth';
import { FormState } from '@reducers/form-reducer';
import { useModal } from '@contexts/global-modal-context';
import SvgIcon from '@components/ui/svg-icon';
import { emit } from '@redux/reducers/event-reducer';
import EventType from '@ctypes/event-type';
import { useTranslation } from 'react-i18next';
import { FormDataType, FormInputType } from '@ctypes/form-data';
import AuthService from '@services/auth-service';

function ChangePseudoForm(): React.ReactElement {
    const { t } = useTranslation(['profile']);
    const validator = new ProfileFormRequest();
    const { user, updateUser } = useAuth();
    const [isSmallScreen] = useMediaScreen();
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState<boolean>(false);
    const toast = useToast();
    const { hideModal } = useModal();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [profileData, setProfileData] = useState<FormDataType>({
        pseudo: { value: user?.pseudo || '' },
    });

    const onSubmit = (profileData: FormInputType) => {
        const data: Auth.UpdateProfileData = {
            pseudo: profileData.pseudo as string,
            id: user?.id as string,
        };
        setIsLoading(true);
        AuthService.updateProfile(data)
            .then((user) => {
                updateUser(user);
                toast?.success(t('update_profil_success', { ns: 'alert' }));
                setIsLoading(false);
                hideModal();
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    const handleGoBackEvent = () => {
        dispatch(emit({ type: EventType.GO_BACK }));
    };

    /**
     * On submit signup form
     * Checked if the signup form is valid before submitting data
     */
    const signUpHandler = (state: FormState, event: FormEvent) => {
        event.preventDefault();
        if (state.validator && state.validator.valid) {
            onSubmit(state.data);
        }
    };

    /**
     * On signup form input field changed
     * Chaning the status of the sumitted button
     */
    const onChangeHandler = (state: FormState) => {
        if (state.validator) {
            setDisabled(!state.validator.valid);
        }
    };

    const setPseudo = (pseudo: string) => {
        setProfileData({ pseudo: { value: pseudo } });
    };

    return (
        <Form method="POST" request={validator} onChange={onChangeHandler} onSubmit={signUpHandler} data={profileData}>
            <div className="d-flex gap-0 profile-tab w-100 text-center">
                <h4
                    className={`text-center d-inline-block ${
                        isSmallScreen ? 'd-flex justify-content-between gap-1' : ''
                    } `}
                >
                    {isSmallScreen && <SvgIcon onClick={handleGoBackEvent} name="arrow-left" height={19} width={19} />}
                    <span>{t('Modifier mon pseudo')}</span>
                </h4>
            </div>
            {!user?.pseudoChanged && (
                <div className="widget opaced progression span3 mob-span4 d-inline-block">
                    <span className="widget-name justify-content-center">{t('Description Modifier mon pseudo')}</span>
                </div>
            )}

            {user?.pseudoChanged && (
                <div className="widget opaced progression span3 mob-span4 d-inline-block">
                    <span className="widget-name justify-content-center">
                        {t('Vous avez déjà modifier votre pseudo')}.
                    </span>
                </div>
            )}

            <BInputField
                blackTheme={true}
                type="text"
                label={t('Votre pseudo', { ns: 'attributes' })}
                id="pseudo"
                value={profileData.pseudo.value}
                name="pseudo"
                onInput={setPseudo}
                placeholder="Ex : you"
            ></BInputField>
            <Button
                label={t('save', { ns: 'actions' })}
                icon="security"
                disabled={disabled}
                loading={isLoading}
                className="mt-3 py-1"
            ></Button>
        </Form>
    );
}

export default ChangePseudoForm;
