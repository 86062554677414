import Modal from '@components/ui/modal';
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { MODAL_TYPES, useModal } from '@contexts/global-modal-context';
import Select from '@components/ui/select';
import { useTranslation } from 'react-i18next';
import Form from '@components/ui/form';
import { FormState } from '@redux/reducers/form-reducer';
import BInputField from '@components/ui/b-input';
import regex from '@constants/regex';
import { Alert } from '@utils/alert';
import { getServerParams } from '@redux/reducers/app-reducer';
import { useDispatch } from 'react-redux';
import Event from '@constants/events';
import { PartyDTO } from 'interfaces/party-dto.interface';
import { Party } from 'interfaces/party.interface';
import { useSocket } from '@hooks/use-socket';
import gameService from '@services/game-service';
import { __APP_SETTINGS_STORE__, __PREVIOUS_PARTY_DATA__ } from '@constants/storage';
import { AppSettings } from '@models/app-settings.model';
import { useLocalStorage } from '@hooks/use-local-storage';
import Button from '@components/ui/button';
import useUnMount from '@hooks/use-unmount';

function CreatePartyModal(): React.ReactElement {
    const { hideModal } = useModal();
    const { t } = useTranslation(['game']);
    const [playerNumber, setPlayerNumber] = useState<number>(1);
    const [bet, setBet] = useState<number>(100);
    const [shotTime, setShotTime] = useState<number>(10);
    const [koratChecked, setKoratChecked] = useState<boolean>(false);
    const [doubleKoratChecked, setDoubleKoratChecked] = useState<boolean>(false);
    const [isStoppedBullet, setIsStoppedBullet] = useState<boolean>(false);
    const [isPrivate, setIsPrivate] = useState<boolean>(false);
    const [partPassword, setPartPassword] = useState<string>('');
    const [server_params] = useLocalStorage<AppSettings | null>(__APP_SETTINGS_STORE__);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [defautData, saveDefaultData] = useLocalStorage<PartyDTO>(__PREVIOUS_PARTY_DATA__);
    const { socket } = useSocket();
    const dispatch = useDispatch();
    const [options] = useState([
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 },
    ]);
    const handleCloseModal = (game?: Party) => {
        hideModal(MODAL_TYPES.CREATE_PARTY_MODAL, game);
    };

    const createPart = (params: PartyDTO) => {
        setIsLoading(true);
        saveDefaultData(params);
        gameService
            .createParty(params)
            .then((partyData: Party) => {
                setIsLoading(false);
                handleCloseModal(partyData);
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    const createPartyHandler = (_state: FormState, event: FormEvent) => {
        event.preventDefault();
        _onButtonPress();
    };

    const onChangePlayerNumber = (value: number) => {
        setPlayerNumber(value);
    };

    useEffect(() => {
        if (defautData) {
            setBet(defautData.bet);
            onChangePlayerNumber(defautData.max_nb_players);
            setShotTime(defautData.time_per_move);
            setKoratChecked(defautData.korat);
            setDoubleKoratChecked(defautData.double_korat);
            setIsStoppedBullet(defautData.special_mode);
            setIsPrivate(defautData.password.trim().length > 0);
            setPartPassword(defautData.password);
        }
    }, [defautData]);
    /*
    const requestLocationPermission = () => {
        return new Promise((resolve, reject) => {
            if (!navigator.geolocation) {
                reject(new Error('Geolocation is not supported by your browser'));
            } else {
                navigator.geolocation.getCurrentPosition(
                    (position) => resolve({ status: 'granted', position }),
                    (error) => {
                        if (error.code === error.PERMISSION_DENIED) {
                            resolve({ status: 'denied' });
                        } else {
                            resolve({ status: 'unknown' });
                        }
                    },
                );
            }
        });
    };*/
    /*
    const handleCheckPermission = async (data: PartyDTO) => {
        try {
            const { status, position }: any = await requestLocationPermission();

            if (status === 'granted') {
                grantedLocationCallback(data, position.coords);
            } else if (status === 'denied') {
                declinedLocationAlert();
            } else {
                declinedLocationAlert();
            }
        } catch (error) {
            console.error(error);
        }
    };
*/
    const _onButtonPress = () => {
        if (!playerNumber)
            return Alert.info(t('inf_label', { ns: 'alert' }), t('max_players_required', { ns: 'alert' }));
        if (!regex.isValidPartAmount(bet?.toString() || ''))
            return Alert.info(t('inf_label', { ns: 'alert' }), t('bet_positive', { ns: 'alert' }));
        if (server_params && !regex.isValidTimePerMove(shotTime?.toString() || '', server_params?.game.timePerMove))
            return Alert.info(t('inf_label', { ns: 'alert' }), t('incorrect_time_per_move', { ns: 'alert' }));

        const params: PartyDTO = {
            bet,
            max_nb_players: playerNumber,
            time_per_move: shotTime,
            password: partPassword?.trim() || '',
            korat: koratChecked,
            double_korat: doubleKoratChecked,
            special_mode: isStoppedBullet,
        };

        //Si partie publique et plus de deux joueurs
        createPart(params);
    };

    const onStartGame = () => {
        console.log('Game started');
    };

    /*const grantedLocationCallback = (params: PartyDTO, coords: any) => {
        params.location = coords;
        createPart(params);
    };

    const declinedLocationAlert = () => Alert.alert(t('alert.inf_label'), t('alert.cannot_create_part'));
*/
    const handleDoubleKoratChange = (event: ChangeEvent<HTMLInputElement>) => {
        setDoubleKoratChecked(event.target.checked);
    };

    const handleKoratChange = (event: ChangeEvent<HTMLInputElement>) => {
        setKoratChecked(event.target.checked);
    };

    const handleIsStoppedBulletChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsStoppedBullet(event.target.checked);
    };

    const handleIsPrivatePartyChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsPrivate(event.target.checked);
        if (!event.target.checked) {
            setPartPassword('');
        }
    };

    useEffect(() => {
        dispatch(getServerParams());
    }, []);

    useEffect(() => {
        socket?.on(Event.START_GAME, onStartGame);
    }, [socket]);

    useUnMount(() => {
        socket?.off(Event.START_GAME, onStartGame);
    });

    return (
        <Modal show={true} onClose={handleCloseModal} id="party-modal" title={t('new_part')}>
            <div className="form-container">
                <Form onSubmit={createPartyHandler}>
                    <fieldset>
                        <BInputField
                            type="number"
                            label={t('bet')}
                            id="bet"
                            name="bet"
                            placeholder="Ex : 100"
                            icon="bet"
                            value={bet}
                            onInput={setBet}
                        ></BInputField>
                    </fieldset>
                    <fieldset>
                        <label htmlFor="phone-number">
                            {t('max_players_label')}
                            <span className="form-require">*</span>
                        </label>
                        <Select
                            type="lang"
                            id="player_number"
                            onChange={onChangePlayerNumber}
                            blackTheme={true}
                            value={playerNumber}
                            name="player_number"
                            valueKey="value"
                            hideLabel={true}
                            placeholder={t('max_players_label')}
                            options={options}
                            icon="users"
                        ></Select>
                    </fieldset>
                    <fieldset>
                        <BInputField
                            type="number"
                            label={t('time_per_move')}
                            id="shotTime"
                            name="shotTime"
                            placeholder="Ex : 5"
                            icon="timer"
                            value={shotTime}
                            onInput={setShotTime}
                        ></BInputField>
                    </fieldset>
                    <fieldset className="flexcheck">
                        <div className="check-item">
                            <div className="checkbox-rect">
                                <input
                                    type="checkbox"
                                    onChange={handleKoratChange}
                                    checked={koratChecked}
                                    id="checkbox-rect1"
                                    name="check"
                                />
                                <label htmlFor="checkbox-rect1">K x2</label>
                            </div>
                        </div>
                        <div className="check-item">
                            <div className="checkbox-rect">
                                <input
                                    type="checkbox"
                                    id="checkbox-rect2"
                                    name="check"
                                    checked={doubleKoratChecked}
                                    onChange={handleDoubleKoratChange}
                                />
                                <label htmlFor="checkbox-rect2">2K x4</label>
                            </div>
                        </div>
                        <div className="check-item">
                            <div className="checkbox-rect">
                                <input
                                    type="checkbox"
                                    id="checkbox-rect3"
                                    checked={isStoppedBullet}
                                    name="check"
                                    onChange={handleIsStoppedBulletChange}
                                />
                                <label htmlFor="checkbox-rect3">{t('stopped_balls')}</label>
                            </div>
                            {isStoppedBullet && server_params && (
                                <span className="text-gray">{t('stopped_balls_list', { ns: 'game' })}</span>
                            )}
                        </div>
                    </fieldset>
                    <fieldset className="constrain">
                        <label htmlFor="">
                            <span className="htmlFor">
                                {t('restrict_game')} <br />
                                <span>({t('private_part')})</span>
                            </span>
                            <span className="icon"></span>
                        </label>
                        <div className="toggle-switch default">
                            <label>
                                <input type="checkbox" checked={isPrivate} onChange={handleIsPrivatePartyChange} />
                                <span className="slider">
                                    <span className="dark icon12 icon"></span>
                                    <span className="light icon icon12"></span>
                                </span>
                            </label>
                        </div>
                    </fieldset>
                    {isPrivate && (
                        <fieldset>
                            <BInputField
                                type="password"
                                label={t('Mot de passe pour rejoindre', { ns: 'attributes' })}
                                id="password"
                                name="password"
                                value={partPassword}
                                onInput={setPartPassword}
                                placeholder={t('Mot de passe pour rejoindre', { ns: 'attributes' })}
                            ></BInputField>
                        </fieldset>
                    )}
                </Form>
                <div className="separator"></div>
                <div className="actions">
                    <button type="button" onClick={() => hideModal()} className="opaced">
                        {t('cancel', { ns: 'actions' })}
                    </button>
                    <Button
                        type="submit"
                        className="default switch-modal-trigger"
                        onClick={_onButtonPress}
                        data-modal="modal-two"
                        icon={'check'}
                        label={t('create', { ns: 'actions' })}
                        loading={isLoading}
                        disabled={isLoading}
                    />
                </div>
            </div>
        </Modal>
    );
}

export default CreatePartyModal;
