import YoutubeVideo from '@components/ui/youtube-video';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Spinner from '@components/ui/spinner';
import RulesService from '@services/rules-service';

function Playing(): React.ReactElement {
    const { t } = useTranslation(['home']);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [rules, setRules] = useState<string>('');

    useEffect(() => {
        setIsLoading(true);
        RulesService.getRules()
            .then((response) => {
                setIsLoading(false);
                setRules(response);
            })
            .catch(() => setIsLoading(false));
    }, []);

    return (
        <>
            <div className="section padding-section hero-intro align-item-start">
                <h3 className="text-center">
                    <span>{t('Comment jouer')}?</span>
                </h3>
                <div className="container mt-4">
                    <YoutubeVideo embedId="BZo5MkVtmvQ?si=bIVzPJyM2x7PnORe" />
                </div>
                <div className="container mt-4">
                    {isLoading && (
                        <div className="spinner-container">
                            <Spinner />
                        </div>
                    )}
                    {!isLoading && rules.length > 0 && (
                        <div className="terms-container">
                            {rules.split('\n').map((line, index) => (
                                <p className="p-0" key={index}>
                                    {line}
                                </p>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            <div className="section flex padding-section hero-intro align-item-start"></div>
        </>
    );
}

export default Playing;
