import Icon from '@components/ui/icon';
import { useModal } from '@contexts/global-modal-context';
import { useAuth } from '@hooks/use-auth';
import { formatAmount } from '@utils/helpers';
import { Tournament } from 'interfaces/tournament.interface';
import React from 'react';
import { useTranslation } from 'react-i18next';
import defaultAvatar from '@assets/default-avatar/9.png';

type TournamentInfosProps = {
    tournament: Tournament;
    subscrited: boolean;
};

function TournamentInfos({ tournament, subscrited }: TournamentInfosProps): React.ReactElement {
    const { t } = useTranslation(['competition']);
    const { hideModal } = useModal();
    const { user } = useAuth();

    return (
        <div className="tour-form-item active-form" data-tourformindex="0">
            <div className="tour-form-header">
                <div className="modal-return opaced mobile-hide">
                    <span className="icon icon12" onClick={() => hideModal()}>
                        <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M9 1L1 9L9 17"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </span>
                </div>
                <h4 className="modal-title">{tournament.name}</h4>
            </div>
            <div className="breadcrumb">
                <span className="feed">
                    {t('event')}
                    <span className="icon icon8">
                        <svg viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.46967 8.46967C0.176777 8.76256 0.176777 9.23744 0.46967 9.53033C0.762563 9.82322 1.23744 9.82322 1.53033 9.53033L0.46967 8.46967ZM5 5L5.53033 5.53033C5.67098 5.38968 5.75 5.19891 5.75 5C5.75 4.80109 5.67098 4.61032 5.53033 4.46967L5 5ZM1.53033 0.46967C1.23744 0.176777 0.762563 0.176777 0.46967 0.46967C0.176777 0.762563 0.176777 1.23744 0.46967 1.53033L1.53033 0.46967ZM1.53033 9.53033L5.53033 5.53033L4.46967 4.46967L0.46967 8.46967L1.53033 9.53033ZM5.53033 4.46967L1.53033 0.46967L0.46967 1.53033L4.46967 5.53033L5.53033 4.46967Z"
                                fill="white"
                                fillOpacity="0.64"
                            />
                        </svg>
                    </span>
                    {tournament.short_name}
                </span>
                <h4 className="flow-title">{t('tournament_details')}</h4>
            </div>
            <div className="creator w-100">
                <div className="user">
                    <div className="user-avatar">
                        <img src={defaultAvatar} alt="" />
                    </div>
                    <div className="user-id">
                        <span className="user-name">{user?.pseudo}</span>
                        <span className="user-role">
                            {subscrited && <div className="event-time-discount">{t('not_yet_registered')}</div>}
                        </span>
                    </div>
                </div>
            </div>
            <div className="form-container tournament-details">
                <div>
                    <fieldset className="d-flex flex-row align-items-center gap-4 py-1">
                        <Icon name={'bet'} className="awaiting-game-icon"></Icon>
                        <div className="d-flex align-items-center gap-3 awaiting-g-bet">
                            <strong>{formatAmount(tournament.register_fees)} XAF</strong>
                            <span className="notif-badge">{tournament.game_settings.korat ? 'K x2' : ''}</span>
                            <span className="notif-badge">{tournament.game_settings.double_korat ? '2K x4' : ''}</span>
                        </div>
                    </fieldset>
                    <fieldset className="d-flex flex-row align-items-center gap-4 py-1">
                        <Icon name={'users'} className="awaiting-game-icon"></Icon>
                        <div className="d-flex align-items-center gap-1 awaiting-g-bet">
                            <strong>{tournament.game_settings.max_nb_players} adversaire(s) max</strong>
                        </div>
                    </fieldset>
                    <fieldset className="d-flex flex-row align-items-center gap-4 py-1">
                        <Icon name={'timer'} className="awaiting-game-icon"></Icon>
                        <div className="d-flex align-items-center gap-1 awaiting-g-bet">
                            <strong>{tournament.game_settings.time_per_move}s par coup</strong>
                        </div>
                    </fieldset>
                    <fieldset className="d-flex flex-row align-items-center gap-4 py-1">
                        <Icon name={'security-special'} className="awaiting-game-icon"></Icon>
                        <div className="d-flex align-items-center gap-1 awaiting-g-bet">
                            {tournament.game_settings.password.length == 0 && (
                                <strong>{t('no_password', { ns: 'game' })}</strong>
                            )}
                            {tournament.game_settings.password.length > 0 && (
                                <strong>{t('private_part', { ns: 'game' })}</strong>
                            )}
                        </div>
                    </fieldset>
                    {tournament.game_settings.special_mode && (
                        <fieldset className="d-flex flex-row align-items-center gap-4 py-1">
                            <Icon name={'settings'} className="awaiting-game-icon"></Icon>
                            <div className="d-flex align-items-center gap-1 awaiting-g-bet">
                                <strong>{t('stopped_balls_list', { ns: 'game' })}</strong>
                            </div>
                        </fieldset>
                    )}
                    <fieldset className="d-flex flex-row align-items-center gap-4 py-1">
                        <Icon name={'settings'} className="awaiting-game-icon"></Icon>
                        <div className="d-flex align-items-center gap-1 awaiting-g-bet">
                            <strong>{tournament.start_points} points de départ</strong>
                        </div>
                    </fieldset>
                </div>
            </div>
        </div>
    );
}

export default TournamentInfos;
