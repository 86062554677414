import React, { FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToast } from '@hooks/use-toast';
import { useAuth } from '@hooks/use-auth';

import {
    changePhoneNumber,
    requestCode,
    resetState,
    REQUEST_STEP,
    CHANGE_STEP,
} from '@redux/reducers/change-phone-reducer';
import * as Auth from '@models/auth';
import { FormState } from '@reducers/form-reducer';
import { LOADING, SUCCESSFUL, RESET_SUCCESSFUL } from '@models/status';
import RequestCodeForm from '@features/games/profile/phone-number/request-code-form';
import PhoneNumberForm from '@features/games/profile/phone-number/phone-number-form';
import { useModal } from '@contexts/global-modal-context';
import { normalizePhoneNumber } from '@utils/string-parser';
import { useTranslation } from 'react-i18next';

function ChangePhoneForm(): React.ReactElement {
    const { t } = useTranslation(['profile']);
    const { user } = useAuth();
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState<boolean>(false);
    const { updateSession } = useAuth();
    const toast = useToast();
    const { hideModal } = useModal();
    // Get the posts from the store
    const profileState = useSelector((state: any) => state.changePhoneStore);
    const profileInitData = {
        phone_number: { value: user?.phone_number },
    };
    const onRequestCode = (profileData: any) => {
        const data: Auth.PhoneNumberRequestCodeData = {
            id: user?.id,
            password: profileData.password,
            country_code: profileData.phone_number_country_code,
            phone_number: normalizePhoneNumber(
                profileData.phone_number,
                profileData.phone_number_country_dial_code,
                profileData.phone_number_country_code,
            ),
        };
        dispatch(requestCode(data));
    };

    const onChangePhoneNumber = (profileData: any) => {
        dispatch(changePhoneNumber({ ...profileData, id: user?.id } as Auth.ChangePhoneNumberData));
    };

    const actions: any = {
        [REQUEST_STEP]: onRequestCode,
        [CHANGE_STEP]: onChangePhoneNumber,
    };
    const VIEWS: any = {
        [REQUEST_STEP]: RequestCodeForm,
        [CHANGE_STEP]: PhoneNumberForm,
    };

    const MESSAGES: any = {
        [REQUEST_STEP]: {
            [SUCCESSFUL]: t('Le code de vérification a été envoyé avec succès.'),
        },
        [CHANGE_STEP]: {
            [SUCCESSFUL]: t('Votre numéro de téléphone a été mis à jour avec sussès.'),
        },
    };

    const renderComponent = () => {
        const ModalComponent = VIEWS[STEP];
        return (
            <ModalComponent
                onChange={onChangeHandler}
                onSubmit={submitHandler}
                data={profileInitData}
                user={user}
                disabled={disabled}
                loading={status === LOADING}
            />
        );
    };

    const { status, error, data: profileData, step: STEP } = profileState;

    /**
     * On submit signup form
     * Checked if the signup form is valid before submitting data
     */
    const submitHandler = (state: FormState, event: FormEvent) => {
        event.preventDefault();
        if (state.validator && state.validator.valid) {
            actions[STEP](state.data);
        } else if (!state.validator) {
            actions[STEP](state.data);
        }
    };

    /**
     * On signup form input field changed
     * Chaning the status of the sumitted button
     */
    const onChangeHandler = (state: FormState) => {
        if (state.validator) {
            setDisabled(!state.validator.valid);
        }
    };

    useEffect(() => {
        if (status && status === SUCCESSFUL) {
            toast?.success(MESSAGES[STEP][SUCCESSFUL]);
        }
        if (status && status === RESET_SUCCESSFUL && STEP === CHANGE_STEP && profileData.hasOwnProperty('user')) {
            updateSession(profileData);
            dispatch(resetState());
            hideModal();
        }
    }, [status, error, profileData]);

    return <div>{renderComponent()}</div>;
}

export default ChangePhoneForm;
