import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GamingType } from '@enums/gaming-type';
import './games.css';
import PendingParty from '../pending-party';
import OngoingParty from '../ongoing-party';

function GamesList(): React.ReactElement {
    const { t } = useTranslation(['party']);
    const [active, setActive] = useState<GamingType>(GamingType.PENDING);

    const handleShowPendingGames = (): void => {
        setActive(GamingType.PENDING);
    };

    const handleShowOngoingGames = (): void => {
        setActive(GamingType.ONGOING);
    };

    return (
        <div className="list-page-content games-list">
            <div className="row">
                <div className="col-12 game-links">
                    <div className={'game-link ' + (active === GamingType.PENDING ? 'active' : '')} data-target="terms">
                        <h3 className={active == GamingType.PENDING ? 'active' : ''} onClick={handleShowPendingGames}>
                            {t('pending_party')}
                        </h3>
                        <div className="separator"></div>
                    </div>
                    <div
                        className={'game-link ' + (active === GamingType.ONGOING ? 'active' : '')}
                        data-target="policy"
                    >
                        <h3 className={active == GamingType.ONGOING ? 'active' : ''} onClick={handleShowOngoingGames}>
                            {t('ongoing_party')}
                        </h3>
                        <div className="separator"></div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className={`col-12 games-body ${active === GamingType.PENDING ? 'active' : ''}`} id="terms">
                    <PendingParty />
                </div>
                <div className={`col-12 games-body ${active === GamingType.ONGOING ? 'active' : ''}`} id="policy">
                    <OngoingParty />
                </div>
            </div>
        </div>
    );
}

export default GamesList;
