import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Modal from '@components/ui/modal';
import { useModal } from '@contexts/global-modal-context';
import { getCosts } from '@redux/reducers/costs-reducer';
import { useSelector } from 'react-redux';
import { LOADING, SUCCESSFUL, FAILED, EMPTY } from '@models/status';
import './costs.css';
import Spinner from '@components/ui/spinner';
import { useTranslation } from 'react-i18next';

function CostsModal(): React.ReactElement {
    const dispatch = useDispatch();
    const { hideModal } = useModal();
    const costsState = useSelector((state: any) => state.costsStore);
    const { t } = useTranslation(['costs']);
    const handleClose = () => {
        hideModal();
    };

    useEffect(() => {
        if (costsState.status === EMPTY) {
            dispatch(getCosts());
        }
    }, []);

    return (
        <Modal show={true} id="party-modal" title={t('Frais applicables')}>
            <div className="form-container">
                <div className="container-fluid px-0 px-0 w-100">
                    <div className="row">
                        <div className={`col-12 tab active`} id="tab">
                            <div className="document-side">
                                {costsState.status === LOADING && (
                                    <div className="spinner-container">
                                        <Spinner />
                                    </div>
                                )}
                                {costsState.status === SUCCESSFUL && (
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>{t('Raison')}</th>
                                                <th>{t('Frais')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {costsState.costs.map((item: any, index: number) => (
                                                <tr key={index}>
                                                    <td>{item.reason}</td>
                                                    <th>{item.fees}</th>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                )}

                                {costsState.status === FAILED && <p className="oups">{t('Oups')}</p>}
                            </div>
                        </div>
                    </div>
                    <div className="separator"></div>
                    <div className="actions justify-content-center">
                        <button className="button default" onClick={handleClose}>
                            {t('close', { ns: 'actions' })}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default CostsModal;
