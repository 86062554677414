import { ApplicableCharge } from '@models/applicable-carge.model';
import http from '@utils/http-common';

/**
 * get Terms and usage
 * @returns
 */
const getCosts = () => {
    return http.get<any, ApplicableCharge[]>('/res/costs', {
        headers: { Accept: '*/*', 'Content-Type': 'text/html; charset=utf-8' },
    });
};

const CostsService = {
    getCosts,
};

export default CostsService;
