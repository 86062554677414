import http from '@utils/http-common';
import APIRoutes from '@constants/routes';
import { AppSettings } from '@models/app-settings.model';

export default {
    /**
     * get Terms and usage
     * @returns
     */
    getServerStatus() {
        return http.get<any, AppSettings>(APIRoutes.CHECK_SERVER_STATUS);
    },
};
