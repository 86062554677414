import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { FAILED, LOADING, SUCCESSFUL, EMPTY, StateStatus } from '@models/status';
import CostsService from '@services/costs-service';
import { ApplicableCharge } from '@models/applicable-carge.model';

export type ApplicationChargeState = {
    costs: ApplicableCharge[];
    error: any;
    status: StateStatus;
};
const initialState: ApplicationChargeState = {
    costs: [],
    error: null,
    status: EMPTY,
};

// Handle POST request to create a new post
export const getCosts: any = createAsyncThunk<ApplicableCharge[], void, { rejectValue: { error: string } }>(
    // The name of the action
    'resources/costs',
    // The payload creator
    async (_, { rejectWithValue }) => {
        try {
            return await CostsService.getCosts();
        } catch (err: any) {
            return rejectWithValue({ error: err.message });
        }
    },
);

const costsSlice = createSlice({
    /* The name of the slice[this will also be used as the action type string 
      in combination with the extraReducer name i.e posts/getPosts or posts/addPost] 
    */
    name: 'rules',
    // initialState: initialState[ES6 destructuring syntax]
    initialState,
    // Add reducers for the synchronous actions on the UI[we are not using this property for this tutorial]
    reducers: {},
    // Add extraReducers for the asynchronous actions on the UI
    extraReducers: {
        [getCosts.pending]: (state) => {
            // When data is being fetched
            state.status = LOADING;
        },
        [getCosts.fulfilled]: (state, { payload }: PayloadAction<ApplicableCharge[]>) => {
            // When data is fetched successfully
            state.status = SUCCESSFUL;

            // Concatenate the new data to the existing data in the array
            state.costs = payload;
        },
        [getCosts.rejected]: (state, action) => {
            // When data is fetched unsuccessfully
            state.status = FAILED;

            // Update the error message for proper error handling
            state.error = action.error.message;
        },
    },
});
export default costsSlice.reducer;
