import GameBoard from '@features/game/game-board';
import GameHeader from '@features/game/game-header/game-header';
import { e } from '@utils/encryption-wrapper';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Event from '@constants/events';
import { useSocket } from '@hooks/use-socket';

function Game(): React.ReactElement {
    const { partyId } = useParams();
    const { socket } = useSocket();

    useEffect(() => {
        if (partyId) {
            socket?.emit(Event.GET_GAME_DATA, e(partyId), e(Event.RESUME_GAME));
        }
    }, [partyId]);

    return (
        <>
            <GameHeader />
            <GameBoard />
            {/* <GameChat /> */}
        </>
    );
}

export default Game;
