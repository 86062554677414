import React, { FormEvent, useEffect, useState } from 'react';
import Form from '@components/ui/form';
import Button from '@components/ui/button';
import BInputField from '@components/ui/b-input';
import { FormState } from '@reducers/form-reducer';
import { useDispatch } from 'react-redux';
import { emit } from '@redux/reducers/event-reducer';
import EventType from '@ctypes/event-type';
import SvgIcon from '@components/ui/svg-icon';
import { useMediaScreen } from '@hooks/use-media-screen';
import { useAuth } from '@hooks/use-auth';
import { useToast } from '@hooks/use-toast';
import { useModal } from '@contexts/global-modal-context';
import { useTranslation } from 'react-i18next';
import { FormDataType, FormInputType } from '@ctypes/form-data';
import AuthService from '@services/auth-service';
import { ResetPasswordData } from '@models/auth';
import DisplayHtml from '@components/ui/html';
import { __COUNTER__, __FORGOT_PASSWORD__, __RETRIES__ } from '@constants/storage';
import delays from '@constants/delays';
import { Link } from 'react-router-dom';
import * as Auth from '@models/auth';
import ForgotPasswordRequest from '@requests/forgot-password-request';
import { TabProps } from '../profile-modal/profile-modal';

function ResetPassword({ data }: TabProps): React.ReactElement<TabProps> {
    const { t } = useTranslation(['profile']);
    const [isSmallScreen] = useMediaScreen();
    const dispatch = useDispatch();
    const { updateUser } = useAuth();
    const toast = useToast();
    const { hideModal } = useModal();
    const requestValidator = new ForgotPasswordRequest();
    const [formState, setFormState] = useState<FormState>();
    const [isLoading, setIsLoading] = useState<boolean>();
    const { user } = useAuth();
    const [remaining, setRemaining] = useState<number>(delays.beforeResendCode);
    const [tries, setTries] = useState<number>(2);
    const [isActive, setIsActive] = useState(false);
    // Get the posts from the store
    const [formData, setFormData] = useState<FormDataType>({
        code: { value: '' },
        password: { value: '' },
        password_confirmation: { value: '' },
    });

    const handleGoBackEvent = () => {
        dispatch(emit({ type: EventType.GO_BACK }));
    };

    const submitHandler = (state: FormState, event: FormEvent) => {
        event.preventDefault();
        setFormState(state);
        if (state.validator && state.validator.valid) {
            resetPassword(state.data);
        }
    };

    const resetPassword = (state: FormInputType) => {
        const data: ResetPasswordData = {
            password: state.password as string,
            old_password: state.old_password as string,
            code: state.code as string,
        };
        setIsLoading(true);
        AuthService.resetPassword(data)
            .then((user) => {
                updateUser(user);
                toast?.success(t('update_profil_success', { ns: 'alert' }));
                setIsLoading(false);
                hideModal();
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    const setCode = (code: string) => {
        setFormData({ ...formData, code: { value: code } });
    };

    const setNewPassword = (password: string) => {
        setFormData({ ...formData, password: { value: password } });
    };

    const setPasswordConfirmation = (password: string) => {
        setFormData({ ...formData, password_confirmation: { value: password } });
    };

    useEffect(() => {
        setIsActive(true);
    }, []);

    useEffect(() => {
        let interval: any = null;

        if (isActive && remaining > 0) {
            interval = setInterval(() => {
                setRemaining(remaining - 1);
            }, 1000);
        } else if (remaining === 0) {
            setIsActive(false);
            if (interval) {
                clearInterval(interval);
            }
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [isActive, remaining]);

    const onReset = () => {
        if (tries > 0) {
            const data: Auth.ForgotPasswordData = {
                country_code: user?.country_code as string,
                phone_number: user?.phone_number as string,
            };
            setIsLoading(true);
            AuthService.registerOrLogin(data)
                .then(() => {
                    setTries(tries - 1);
                    setRemaining(delays.beforeResendCode);
                    setIsActive(true);
                    setIsLoading(false);
                })
                .catch(() => setIsLoading(false));
        }
    };

    return (
        <Form method="POST" request={requestValidator} onSubmit={submitHandler} data={formData}>
            <div className="profile-tab w-100">
                <h4
                    className={`text-center d-inline-block ${
                        isSmallScreen ? 'd-flex justify-content-between gap-1' : ''
                    } `}
                >
                    {isSmallScreen && <SvgIcon onClick={handleGoBackEvent} name="arrow-left" height={19} width={19} />}{' '}
                    {t('reset_password_title', { ns: 'forgot-password' })}
                </h4>
                <DisplayHtml
                    htmlString={t('reset_password_hint', {
                        ns: 'forgot-password',
                        phone_number: user?.phone_number,
                        otp_channel: data.otp_channel,
                    })}
                />
            </div>
            <BInputField
                type="text"
                label={t('Code', { ns: 'attributes' })}
                id="code"
                name="code"
                placeholder={t('Code', { ns: 'attributes' })}
                value={formData.code.value}
                onInput={setCode}
                parent={formState}
            ></BInputField>
            <BInputField
                type="password"
                label={t('Nouveau mot de passe', { ns: 'attributes' })}
                id="password"
                name="password"
                value={formData.password.value}
                onInput={setNewPassword}
                placeholder={t('Votre nouveau mot de passe', { ns: 'attributes' })}
                parent={formState}
            ></BInputField>
            <BInputField
                type="password"
                label={t('Confirmer le nouveau mot de passe', { ns: 'attributes' })}
                id="password_confirmation"
                name="password_confirmation"
                value={formData.password_confirmation.value}
                onInput={setPasswordConfirmation}
                parent={formState}
                placeholder={t('Confirmer votre nouveau mot de passe', { ns: 'attributes' })}
            ></BInputField>

            {tries > 0 && (
                <p className="d-flex justify-content-between align-items-center">
                    <a onClick={onReset} href="javascript:;" className={`${remaining > 0 ? 'disabled' : ''}`}>
                        {t('resend_code', { ns: 'actions' })}
                    </a>
                    {remaining > 0 && <strong>{remaining}</strong>}
                </p>
            )}
            {tries == 0 && (
                <p>
                    <span>{t('subject', { ns: 'contact' })}</span>?{' '}
                    <b>
                        <Link to={'/contact-us'}>{t('contact_support', { ns: 'contact' })}</Link>
                    </b>
                </p>
            )}
            <Button
                label={t('save', { ns: 'actions' })}
                icon="security"
                disabled={isLoading}
                loading={isLoading}
                className="mt-3 py-1"
            ></Button>
        </Form>
    );
}

export default ResetPassword;
