import { useGamePLaying } from '@hooks/use-game-playing';
import React from 'react';
import { ReactComponent as ExitIcon } from '@assets/icons/quit.svg';
import { ReactComponent as LockIcon } from '@assets/icons/outline/lock-on.svg';
import { ReactComponent as InfoIcon } from '@assets/icons/outline/info-circle.svg';
import { MODAL_TYPES, useModal } from '@contexts/global-modal-context';
import { useSelector } from 'react-redux';
import { RootState } from '@redux/store';

function GameActions(): React.ReactElement {
    const { showModal } = useModal();
    const { onReportProblem, onResign } = useGamePLaying();
    const { game } = useSelector((state: RootState) => state.gameStore);

    const showGameInfoModal = () => {
        showModal(MODAL_TYPES.GAME_INFOS_MODAL, {
            game: game,
        });
    };
    return (
        <>
            <button className="small danger d-flex justify-content-center aligm-items-center" onClick={onResign}>
                Banquer
                <span className="icon icon12 text white">
                    <ExitIcon />
                </span>
            </button>
            <button
                type="button"
                onClick={showGameInfoModal}
                className="small success d-flex justify-content-center aligm-items-center"
            >
                Infos
                <span className="icon icon12">
                    <InfoIcon />
                </span>
            </button>
            <button
                onClick={onReportProblem}
                className="small warning d-flex justify-content-center aligm-items-center"
            >
                Signaler / Bloquer
                <span className="icon icon12">
                    <LockIcon />
                </span>
            </button>
        </>
    );
}

export default GameActions;
