import { DEFAULT_ROUTE } from '@config/app';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

export type ProtectedRouteProps = {
    isPublic?: boolean;
    isAuthorized?: boolean;
    isLogged?: boolean;
};

export function ProtectedRoute({ isPublic, isAuthorized, isLogged }: ProtectedRouteProps): React.ReactElement {
    // Check if the route is public and the user is already logged in

    if (isPublic && isLogged) {
        // If the user is logged in and the route is public, redirect to dashboard
        return <Navigate to={DEFAULT_ROUTE} />;
    }

    // If the route is public or the user is authorized, render the outlet (the component)
    if (isPublic || isAuthorized) {
        return <Outlet />;
    }

    // Otherwise, redirect the user to the sign-in page if they're not authorized
    return <Navigate to="/auth/signin" />;
}
