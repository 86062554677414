import CryptoJS from 'crypto-js';
import { _FAPFAP_ENCRYPTION_KEY } from '@environments';
import { JSONValue } from '@ctypes/json-value';

export const e = (plain: JSONValue) => {
    if (plain == null) return plain;

    if (plain.constructor !== {}.constructor) plain = { ObjectCastContainer: plain };

    return CryptoJS.AES.encrypt(JSON.stringify(plain), _FAPFAP_ENCRYPTION_KEY).toString();
};

export const d = (cipher: string) => {
    if (cipher == null) return cipher;

    const plain = JSON.parse(CryptoJS.AES.decrypt(cipher, _FAPFAP_ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8));

    if (plain.hasOwnProperty('ObjectCastContainer')) return plain.ObjectCastContainer;

    return plain;
};
