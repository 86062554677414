import http from '@utils/http-common';
import APIRoutes from '@constants/routes';
import { BlockedUser } from '@models/blocked-user.model';

export default {
    /**
     * get Terms and usage
     * @returns
     */
    searchGamers(str: string) {
        return http.get<any>(APIRoutes.BLOCKING_SEARCH_PLAYER + '?search=' + str);
    },

    blockGamer(userId: string) {
        return http.post<any>(APIRoutes.BLOCK_PLAYER, { user_id: userId });
    },

    unblockGamer(userId: string) {
        return http.post<any>(APIRoutes.UNBLOCK_PLAYER, { user_id: userId });
    },

    blockPlayers(users: string[]) {
        return http.post<string[], BlockedUser[]>(APIRoutes.BLOCK_PLAYER, { users });
    },

    getBlockedGamers() {
        return http.get<any, BlockedUser[]>(APIRoutes.BLOCKING_LIST);
    },
};
