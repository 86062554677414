import { useState, useEffect } from 'react';
import secureLocalStorage from 'react-secure-storage';

export const useLocalStorage = <T,>(key: string, defaultValue?: T) => {
    const [storedValue, setStoredValue] = useState<T>(() => {
        try {
            const item = secureLocalStorage.getItem(key);
            return item ? JSON.parse(item as string) : defaultValue;
        } catch (error) {
            console.error('Error fetching item from secure storage:', error);
            return defaultValue;
        }
    });

    const setValue = (value: T) => {
        try {
            setStoredValue(value);
            if (value == null || value === undefined) {
                secureLocalStorage.removeItem(key);
            } else {
                secureLocalStorage.setItem(key, JSON.stringify(value));
            }
        } catch (error) {
            console.error('Error setting item in secure storage:', error);
        }
    };

    useEffect(() => {
        if (storedValue !== undefined && storedValue !== null) {
            secureLocalStorage.setItem(key, JSON.stringify(storedValue));
        } else {
            secureLocalStorage.removeItem(key);
        }
    }, [key, storedValue]);

    return [storedValue, setValue] as const;
};
