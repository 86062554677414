import { validators, ValidatorValue, Validator, ValidatorType, RequestValue } from '@validators/common';

export type RequireValidatorType = FormRequestRuleTypeWithMessage & {
    required: boolean;
    message?: string;
};
export type PseudoValidatorType = FormRequestRuleTypeWithMessage & {
    pseudo: boolean;
    message?: string;
};
export type PhoneValidatorType = FormRequestRuleTypeWithMessage & {
    phone: boolean;
    message?: string;
};
export type PaternValidatorType = FormRequestRuleTypeWithMessage & {
    patern: string;
    message?: string;
};
export type PasswordValidatorType = FormRequestRuleTypeWithMessage & {
    password: boolean;
    message?: string;
};
export type ConfirmValidatorType = FormRequestRuleTypeWithMessage & {
    confirm: boolean;
    message?: string;
};
export type EmailValidatorType = FormRequestRuleTypeWithMessage & {
    email: boolean;
    message?: string;
};
export type MinValidatorType = FormRequestRuleTypeWithMessage & {
    min: number;
    message?: string;
};
export type MaxValidatorType = FormRequestRuleTypeWithMessage & {
    max: number;
    message?: string;
};
export type SizeValidatorType = FormRequestRuleTypeWithMessage & {
    size: number;
    message?: string;
};
export type CheckedValidatorType = FormRequestRuleTypeWithMessage & {
    checked: boolean;
    message?: string;
};
type RuleType = {
    [x in ValidatorType]?: ValidatorValue;
};

type FormRequestRuleTypeWithMessage = RuleType & {
    message?: string;
};

export type FormRequestRuleType =
    | RequireValidatorType
    | PseudoValidatorType
    | PhoneValidatorType
    | PaternValidatorType
    | PasswordValidatorType
    | EmailValidatorType
    | ConfirmValidatorType
    | MinValidatorType
    | CheckedValidatorType
    | MaxValidatorType
    | SizeValidatorType;

export class RequestError {
    protected errors: { [x: string]: string };

    constructor() {
        this.errors = {};
    }

    public push(criterial: string, error: string) {
        this.errors[criterial] = error;
    }

    public remove(criteria: string) {
        delete this.errors[criteria];
    }

    get all() {
        return this.errors;
    }

    get first(): string | null {
        const key: string[] = Object.keys(this.errors);
        if (key.length > 0) {
            return this.errors[key[0]] ?? null;
        }
        return null;
    }

    get last(): string | null {
        const criterias: string[] = Object.keys(this.errors);
        if (criterias.length > 0) {
            return this.errors[criterias[criterias.length - 1]] ?? null;
        }
        return null;
    }

    get hasError(): boolean {
        return this.first !== null;
    }
}

export class FormError {
    protected errors: { [x: string]: RequestError };

    constructor() {
        this.errors = {};
    }

    public push(attribute: string, criterial: string, error: string) {
        if (!this.errors[attribute]) {
            this.errors[attribute] = new RequestError();
        }
        this.errors[attribute].push(criterial, error);
    }

    public remove(attribute: string, criteria?: string) {
        if (!criteria) {
            delete this.errors[attribute];
        } else if (this.errors[attribute]) {
            this.errors[attribute].remove(criteria);
        }
    }
    public get(attribute: string): RequestError {
        return this.errors[attribute];
    }

    public has(attribute: string): boolean {
        return this.errors && this.errors.hasOwnProperty(attribute);
    }

    public all() {
        return this.errors;
    }
}

export class FormRequest {
    protected rules: { [x: string]: Array<FormRequestRuleType> };
    protected _errors: FormError;

    constructor() {
        this.rules = {};
        this._errors = new FormError();
    }

    get valid(): boolean {
        for (const attribute in this.rules) {
            if (this.errors && this.errors.has(attribute) && this.errors.get(attribute).hasError) {
                return false;
            }
        }
        return true;
    }

    get errors() {
        return this._errors;
    }

    public validate(data: { [x: string]: RequestValue }) {
        for (const attribute in this.rules) {
            const criterias: Array<FormRequestRuleType> = this.rules[attribute];
            criterias.forEach((criteria: FormRequestRuleType) => {
                for (const validatorType in criteria) {
                    if (Object.keys(validators).includes(validatorType)) {
                        const type = validatorType as ValidatorType;
                        const validator: Validator = validators[type];
                        if (validator.passed(attribute, criteria[type] as ValidatorValue, data[attribute], data)) {
                            this.errors.remove(attribute, validatorType);
                        } else {
                            this.errors.push(attribute, validatorType, criteria.message ?? validator.message);
                        }
                    }
                }
            });
        }
        return this.errors;
    }
}
