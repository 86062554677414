import { FormRequest } from './common';

export default class ProfileFormRequest extends FormRequest {
    protected rules = {
        pseudo: [
            {
                pseudo: true,
                message: 'Le pseudo est invalide.',
            },
        ],
    };
}
