import React from 'react';
import useBodyClass from '@hooks/body-class';
import { Outlet } from 'react-router-dom';
import '@assets/fonts/styles.css';
import '@assets/css/style.css';
import useScript from '@hooks/use-script';
// Notice here we're using the function declaration with the interface Props
const GameLayout: React.FunctionComponent = () => {
    useBodyClass('page-dark app');
    useScript('/script/custom-elements.js');
    useScript('/script/profile.js');
    useScript('/script/app.js');
    useScript('/script/jquery-3.1.1.min.js', true, undefined, false);
    useScript('/script/jquery.totalScroll.js', true, undefined, false);

    return (
        <div className="playing-page">
            <Outlet />
        </div>
    );
};

export default GameLayout;
