import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface InputState {
    value: string | number | boolean;
    touched?: boolean;
}

const initialState: InputState = {
    value: '',
    touched: false,
};

const inputSlice = createSlice({
    name: 'input',
    initialState,
    reducers: {
        inputChange: (state, { payload }: PayloadAction<{ val: string | number | boolean }>) => {
            state.value = payload.val;
        },
        inputTouched: (state, { payload }: PayloadAction<boolean>) => {
            state.touched = payload;
        },
    },
});

export const { inputChange, inputTouched } = inputSlice.actions;

export default inputSlice.reducer;

export const inputSelector = (state: { inputStore: InputState }) => state.inputStore;
