import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { FAILED, LOADING, SUCCESSFUL } from '@models/status';
import PartnerService from '@services/partner-stats';
const initialState = {
    data: {},
    status: 'idle',
    error: null,
    terms_accepted: false,
};

// Handle POST request to create a new post
export const getStats: any = createAsyncThunk(
    // The name of the action
    'partner/stats',
    // The payload creator
    async (_, { rejectWithValue }) => {
        try {
            return await PartnerService.getStats();
        } catch (err: any) {
            return rejectWithValue({ error: err.message });
        }
    },
);

const partnerSlice = createSlice({
    /* The name of the slice[this will also be used as the action type string 
      in combination with the extraReducer name i.e posts/getPosts or posts/addPost] 
    */
    name: 'partner',
    // initialState: initialState[ES6 destructuring syntax]
    initialState,
    // Add reducers for the synchronous actions on the UI[we are not using this property for this tutorial]
    reducers: {},
    // Add extraReducers for the asynchronous actions on the UI
    extraReducers: {
        [getStats.pending]: (state) => {
            // When data is being fetched
            state.status = LOADING;
        },
        [getStats.fulfilled]: (state, action: any) => {
            // When data is fetched successfully
            state.status = SUCCESSFUL;

            // Concatenate the new data to the existing data in the array
            state.data = action.payload;
        },
        [getStats.rejected]: (state, action) => {
            // When data is fetched unsuccessfully
            state.status = FAILED;

            // Update the error message for proper error handling
            state.error = action.error.message;
        },
    },
});
export default partnerSlice.reducer;
