import React, { FormEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Form from '@components/ui/form';
import Button from '@components/ui/button';
import { useAuth } from '@hooks/use-auth';
import { SMSCodeHasBeenSended } from '@redux/reducers/verify-phone-reducer';
import { FormState } from '@reducers/form-reducer';
import InternationalizedInput from '@components/ui/internationalized-input';
import { normalizePhoneNumber } from '@utils/string-parser';
import { useTranslation } from 'react-i18next';
import VerifyNumberFormRequest from '@requests/verify-number-request';
import BInputField from '@components/ui/b-input';
import { Alert } from '@utils/alert';
import Regex from '@utils/regex';
import { InternationalizedValue } from '@components/ui/internationalized-input/internationalized-input';
import AuthService from '@services/auth-service';
import { RequestOTP } from '@models/auth';

function RequestCodeForm(): React.ReactElement {
    const { t } = useTranslation(['deposit']);
    const validator = new VerifyNumberFormRequest();
    const { user } = useAuth();
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [password, setPassword] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<InternationalizedValue>({
        value: user?.phone_number || user?.pending_phone_change?.phone_number || '',
        code: user?.country_code || user?.pending_phone_change?.country_code,
    });
    const [countryCode, setCountryCode] = useState<string>();

    //const { updateSession } = useAuth();
    // Get the posts from the store
    const depositInitData = {
        phone_number: { value: phoneNumber.value || '' },
        phone_number_country_code: { value: phoneNumber.code || '' },
        phone_number_country_dial_code: { value: phoneNumber.dialCode || '' },
        phone_number_country_name: { value: phoneNumber.name || '' },
        password: { value: password },
    };
    /**
     * On submit signup form
     * Checked if the signup form is valid before submitting data
     */
    const requestCodeHandler = (state: FormState, event: FormEvent) => {
        event.preventDefault();
        if (state.validator && state.validator.valid) {
            const phoneNumber = {
                phone_number: normalizePhoneNumber(
                    state.data.phone_number + '',
                    state.data.phone_number_country_dial_code + '',
                    state.data.phone_number_country_code + '',
                ),
                country_code: countryCode as string,
                country_name: state.data.phone_number_country_name as string,
            };
            if (validateRequest(phoneNumber, state.data.password as string)) {
                setPassword(state.data.password as string);
                const data: RequestOTP = {
                    phone_number: phoneNumber.phone_number,
                    country_code: phoneNumber.country_code,
                    password: password as string,
                };
                setIsLoading(true);
                AuthService.requestOTP(data)
                    .then((responseData: any) => {
                        setIsLoading(false);
                        dispatch(
                            SMSCodeHasBeenSended({ ...phoneNumber, password, otp_channel: responseData.otp_channel }),
                        );
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        Alert.alert(t('oops', { ns: 'global' }), error);
                    });
            }
        }
    };

    const validateRequest = (
        phoneNumber: { phone_number: string; country_code: string; country_name: string },
        password: string,
    ) => {
        if (user?.verified && phoneNumber.phone_number === user?.phone_number) {
            Alert.alert(
                t('info', { ns: 'messages' }),
                t('Veuillez entrer un numéro de téléphone différent', { ns: 'messages' }),
                null,
                { cancelable: true },
            );
            return false;
        } else if (
            !Regex.isValidPhoneNumber({
                country_code: phoneNumber.country_code as string,
                phone_number: phoneNumber.phone_number,
            })
        ) {
            Alert.alert(
                t('info', { ns: 'messages' }),
                t('Veuillez entrer un numéro de téléphone valide', {
                    ns: 'messages',
                    country: phoneNumber.country_name,
                }),
                null,
                { cancelable: true },
            );
            return false;
        } else if (!Regex.isValidPassword(password)) {
            Alert.alert(t('info', { ns: 'messages' }), t('Mot de passe incorrect', { ns: 'messages' }));
            return false;
        }
        return true;
    };

    useEffect(() => {
        setPhoneNumber({
            value: user?.phone_number || user?.pending_phone_change?.phone_number,
            code: user?.country_code || user?.pending_phone_change?.country_code,
        });
        setCountryCode(user?.country_code || user?.pending_phone_change?.country_code);
    }, [user]);

    /**
     * On signup form input field changed
     * Chaning the status of the sumitted button
     */
    const onChangeHandler = (state: FormState) => {
        if (state.validator) {
            setDisabled(!state.validator.valid);
        }
    };

    return (
        <Form
            method="POST"
            request={validator}
            onChange={onChangeHandler}
            onSubmit={requestCodeHandler}
            data={depositInitData}
        >
            <InternationalizedInput
                id="phone_number"
                name="phone_number"
                value={phoneNumber.value}
                countryCode={phoneNumber.code}
                onChange={setPhoneNumber}
                label={t('Votre numéro de téléphone', { ns: 'attributes' })}
                blackTheme={true}
                placeholder="Ex : 6XX XXX XXX"
            />
            <BInputField
                type="password"
                label={t('Mot de passe', { ns: 'attributes' })}
                id="password"
                name="password"
                value={password as string}
                onInput={setPassword}
                placeholder={t('Votre mot de passe', { ns: 'attributes' })}
            ></BInputField>
            <Button
                label={t('Valider', { ns: 'attributes' })}
                icon="security"
                id="deposit-btn"
                disabled={disabled}
                loading={isLoading}
                className="mt-3"
            ></Button>
        </Form>
    );
}

export default RequestCodeForm;
