import { useEffect, useRef } from 'react';

function useMount(run: () => void) {
    const referencedRun = useRef(run);
    useEffect(() => {
        referencedRun.current();
    }, []);
}

export default useMount;
