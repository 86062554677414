import http from '@utils/http-common';
import CountryService from '@services/country-service';
import Deposit from '@models/deposit';
import Withdrawal from '@models/withdrawal';
import APIRoutes from '@constants/routes';
import ConfirmDeposit from '@models/confirm-deposit';
import TransactionList from '@models/transaction-list';
import { DepositResponse } from 'interfaces/deposit-response.interface';

const deposit = (data: Deposit) => {
    const country = CountryService.findCountry(data.phone_number);
    return http.post<any, DepositResponse>('/transaction/deposit', { ...data, country_code: country?.code });
};

const confirmDeposit = (data: ConfirmDeposit) => {
    return http.post<any, DepositResponse>(APIRoutes.CONFIRM_DEPOSIT, data);
};

const withdrawal = (data: Withdrawal) => {
    return http.post<any>(APIRoutes.WITHDRAWAL, data);
};

const getTransactions = (isReferee: boolean, page = 1) => {
    const url = isReferee ? APIRoutes.REFERREE_HISTORY : APIRoutes.HISTORY;
    return http.get<any, TransactionList>(url, { params: { page } });
};

const TransactionService = {
    deposit,
    withdrawal,
    confirmDeposit,
    getTransactions,
};

export default TransactionService;
