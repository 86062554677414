import EventEmitter3 from 'eventemitter3';

import _ from 'lodash';

export let EventEmitter: BaseEventEmitter;

export const initEventEmitter = () => {
    if (!EventEmitter) {
        EventEmitter = new BaseEventEmitter();
    }
    return EventEmitter;
};

class BaseEventEmitter {
    private eventEmitter: EventEmitter3;
    /**
     * Initiate the event emitter
     */
    constructor() {
        this.eventEmitter = new EventEmitter3();
    }

    /**
     * Adds the @listener function to the end of the listeners array
     * for the event named @eventName
     * Will ensure that only one time the listener added for the event
     *
     * @param {string} eventName
     * @param {function} listener
     */
    on(eventName: string, listener: any) {
        this.eventEmitter.on(eventName, listener);
    }

    /**
     * Will temove the specified @listener from @eventname list
     *
     * @param {string} eventName
     * @param {function} listener
     */
    off(eventName: string, listener?: any) {
        this.eventEmitter.removeListener(eventName, listener);
    }

    /**
     * Will emit the event on the evetn name with the @payload
     * and if its an error set the @error value
     *
     * @param {string} event
     * @param {object} payload
     * @param {boolean} error
     */
    emit(event: string, ...payload: any) {
        this.eventEmitter.emit(event, ...payload);
    }

    /**
     * Returns the event emitter
     * Used for testing purpose and avoid using this during development
     */
    getEventEmitter(): EventEmitter3 {
        return this.eventEmitter;
    }
}
