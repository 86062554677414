import parsePhoneNumber, { CountryCode } from 'libphonenumber-js';

export function normalizePhoneNumber(phoneNumber: string, dialCode?: string, countryCode?: string): string {
    let normalizedDialcode = dialCode || '+237';
    let normaizedPhoneNumber = phoneNumber;
    if (phoneNumber.startsWith(normalizedDialcode)) {
        return normaizedPhoneNumber;
    }
    normalizedDialcode = normalizedDialcode.substring(1, normalizedDialcode.length);
    if (phoneNumber.startsWith(normalizedDialcode)) {
        normaizedPhoneNumber = '+' + normaizedPhoneNumber;
    }
    let phoneNumberObject = parsePhoneNumber(normaizedPhoneNumber, (countryCode || 'CM') as CountryCode);
    if (phoneNumberObject?.isValid()) {
        return '+' + phoneNumberObject.countryCallingCode + phoneNumberObject.nationalNumber;
    }
    phoneNumberObject = parsePhoneNumber(phoneNumber, (countryCode || 'CM') as CountryCode);

    if (phoneNumberObject?.isValid()) {
        return '+' + phoneNumberObject.countryCallingCode + phoneNumberObject.nationalNumber;
    }
    return phoneNumber;
}

export function normalizeNationalPhoneNumber(phoneNumber: string, dialCode?: string, countryCode?: string): string {
    let normalizedDialcode = dialCode || '+237';
    let normaizedPhoneNumber = phoneNumber;

    if (!phoneNumber.startsWith(normalizedDialcode)) {
        return normaizedPhoneNumber;
    }

    normalizedDialcode = normalizedDialcode.substring(1, normalizedDialcode.length);
    if (phoneNumber.startsWith(normalizedDialcode)) {
        normaizedPhoneNumber = '+' + normaizedPhoneNumber;
    }

    let phoneNumberObject = parsePhoneNumber(normaizedPhoneNumber, (countryCode || 'CM') as CountryCode);

    if (phoneNumberObject?.isValid()) {
        return phoneNumberObject.nationalNumber;
    }

    phoneNumberObject = parsePhoneNumber(phoneNumber, (countryCode || 'CM') as CountryCode);

    if (phoneNumberObject?.isValid()) {
        return phoneNumberObject.nationalNumber;
    }

    return phoneNumber;
}
