import React, { FormEvent, useEffect, useState } from 'react';
import Form from '@components/ui/form';
import Button from '@components/ui/button';
import BInputField from '@components/ui/b-input';
import { FormRequest } from '@requests/common';
import { FormDataType } from '@ctypes/form-data';
import { FormState } from '@reducers/form-reducer';
import SvgIcon from '@components/ui/svg-icon';
import { useMediaScreen } from '@hooks/use-media-screen';
import { useDispatch } from 'react-redux';
import { emit } from '@redux/reducers/event-reducer';
import EventType from '@ctypes/event-type';
import { useTranslation } from 'react-i18next';
import AuthService from '@services/auth-service';
import { RootState } from '@redux/store';
import { useSelector } from 'react-redux';
import { useAuth } from '@hooks/use-auth';
import { useToast } from '@hooks/use-toast';
import { resetState } from '@redux/reducers/change-phone-reducer';
import { EventEmitter } from '@utils/event-emitter';
import { useModal } from '@contexts/global-modal-context';
import SmsCodeFormRequest from '@requests/sms-code-request';
export type RequestCodeProps = {
    validator?: FormRequest;
    onChange(any: any): void;
    onSubmit(state: FormState, event: FormEvent): void;
    data: FormDataType;
    loading: boolean;
    disabled: boolean;
};
function PhoneNumberForm({}: RequestCodeProps): React.ReactElement {
    const { t } = useTranslation(['profile']);
    const [isSmallScreen] = useMediaScreen();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const { password } = useSelector((state: RootState) => state.changePhoneStore);
    const [code, setCode] = useState<string>('');
    const toast = useToast();
    const { updateSession, user } = useAuth();
    const handleGoBackEvent = () => {
        dispatch(emit({ type: EventType.GO_BACK }));
    };
    const { hideModal } = useModal();
    const validator = new SmsCodeFormRequest();
    const [data, setData] = useState<FormDataType>({});
    const [formDataState, setFormDataState] = useState<FormState>();
    /**
     * On submit signup form
     * Checked if the signup form is valid before submitting data
     */
    const changePhoneNumberHandler = (state: FormState, event: FormEvent) => {
        event.preventDefault();
        if (state.validator && state.validator.valid) {
            changePhoneNumber();
        }
    };

    /**
     * On signup form input field changed
     * Chaning the status of the sumitted button
     */
    const onChangeHandler = (state: FormState) => {
        setFormDataState(state);
        if (state.validator) {
            setIsDisabled(!state.validator.valid);
        }
    };

    const changePhoneNumber = () => {
        setIsLoading(true);
        const requestData = {
            code: code,
            password: password as string,
        };
        AuthService.changePhoneNumber(requestData)
            .then((responseData) => {
                setIsLoading(false);
                const successMessageCode = user?.verified ? 'update_phone_success' : 'verify_phone_success';
                updateSession({
                    user: responseData.user,
                    secret: responseData.secret,
                    isFirstUsage: false,
                });
                toast?.success(t(successMessageCode, { ns: 'alert' }));
                dispatch(resetState());
                EventEmitter.emit('updateUser');
                hideModal();
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        setData({ code: { value: code } });
    }, [code]);

    return (
        <Form
            method="POST"
            request={validator}
            onChange={onChangeHandler}
            onSubmit={changePhoneNumberHandler}
            data={data}
        >
            <div className="d-flex gap-0 profile-tab w-100">
                <h4
                    className={`text-center d-inline-block ${
                        isSmallScreen ? 'd-flex justify-content-between gap-1' : ''
                    } `}
                >
                    {isSmallScreen && <SvgIcon onClick={handleGoBackEvent} name="arrow-left" height={19} width={19} />}
                    <span>{t('Changer mon numéro de téléhone')}</span>
                </h4>
            </div>
            <BInputField
                blackTheme={true}
                type="text"
                label={t('Code reçu par SMS')}
                id="code"
                name="code"
                value={code}
                onInput={setCode}
                parent={formDataState}
                placeholder="Ex : XXX XXX"
            ></BInputField>
            <Button
                label={t('Modifier', { ns: 'common' })}
                icon="security"
                disabled={isDisabled}
                loading={isLoading}
                className="mt-3 py-1"
            ></Button>
        </Form>
    );
}

export default PhoneNumberForm;
