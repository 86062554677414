import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Modal from '@components/ui/modal';
import { useModal } from '@contexts/global-modal-context';
import { getTerms, getPolicy } from '@redux/reducers/terms-reducer';
import { useSelector } from 'react-redux';
import { LOADING, SUCCESSFUL, FAILED } from '@models/status';
import './terms.css';
import Spinner from '@components/ui/spinner';
import { useTranslation } from 'react-i18next';
import { RootState } from '@redux/store';

export const POLICY = 'policy';
export const TERMS = 'terms';

type TermsType = typeof POLICY | typeof TERMS;

type Props = {
    type?: TermsType;
};
function TermsModal({ type }: Props): React.ReactElement {
    const [active, setActive] = useState<TermsType>(type || TERMS);
    const dispatch = useDispatch();
    const { hideModal } = useModal();
    const { t } = useTranslation(['terms']);
    const termsState = useSelector((state: RootState) => state.termsStore);

    const handleClose = () => {
        hideModal();
    };

    const handleShowTerms = () => {
        setActive(TERMS);
    };

    const handleShowPolicy = () => {
        setActive(POLICY);
    };

    useEffect(() => {
        if (active === TERMS) {
            dispatch(getTerms());
        } else {
            dispatch(getPolicy());
        }
    }, [active]);

    return (
        <Modal show={true} id="party-modal" title="FapFap">
            <div className="form-container">
                <div className="container-fluid px-0 px-0 w-100">
                    <div className="row">
                        <div
                            className={'col-6 term-policy-link ' + (active === TERMS ? 'active' : '')}
                            data-target="terms"
                        >
                            <h5 className={active == TERMS ? 'active' : ''} onClick={handleShowTerms}>
                                {t('use_conditions')}
                            </h5>
                            <div className="separator"></div>
                        </div>
                        <div
                            className={'col-6 term-policy-link ' + (active === POLICY ? 'active' : '')}
                            data-target="policy"
                        >
                            <h5 className={active == POLICY ? 'active' : ''} onClick={handleShowPolicy}>
                                {t('privacy_policy')}
                            </h5>
                            <div className="separator"></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className={`col-12 term-policy ${active === TERMS ? 'active' : ''}`} id="terms">
                            <div className="document-side">
                                {termsState.terms_status === LOADING && (
                                    <div className="spinner-container">
                                        <Spinner />
                                    </div>
                                )}

                                {termsState.terms && termsState.terms_status === SUCCESSFUL && (
                                    <div className="terms-policy-container">
                                        {termsState.terms.split('\n').map((line, index) => (
                                            <p className="p-0" key={index}>
                                                {line}
                                            </p>
                                        ))}
                                    </div>
                                )}

                                {termsState.terms_status === FAILED && (
                                    <p className="oups">{t('use_conditions_not_found')}</p>
                                )}
                            </div>
                        </div>
                        <div className={`col-12 term-policy ${active === POLICY ? 'active' : ''}`} id="policy">
                            <div className="document-side">
                                {termsState.policy_status === FAILED && (
                                    <p className="oups">{t('privacy_policy_not_found')}</p>
                                )}
                                {termsState.policy_status === LOADING && (
                                    <div className="spinner-container">
                                        <Spinner />
                                    </div>
                                )}
                                {termsState.policy && termsState.policy_status === SUCCESSFUL && (
                                    <div className="terms-policy-container">
                                        {termsState.policy.split('\n').map((line, index) => (
                                            <p className="p-0" key={index}>
                                                {line}
                                            </p>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="separator"></div>
                    <div className="actions justify-content-center">
                        <button className="button default" onClick={handleClose}>
                            {t('close', { ns: 'actions' })}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
TermsModal.defaultValue = {
    type: 'terms',
};

export default TermsModal;
