import axios, { AxiosInstance } from 'axios';
import { _FAPFAP_API_ENDPOINT } from '@environments';
import App from '../app.json';
import { getLocaleLanguage } from './internalization';

//const BASE_URL = process.env.NODE_ENV !== 'production' ? '/api' : process.env.REACT_APP_FAPFAP_PORT;
const _axios: AxiosInstance = axios.create({
    baseURL: _FAPFAP_API_ENDPOINT,
    headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        'User-Agent': `FapFap/${window.navigator.platform}/${App.versionCode}`,
        'Accept-Language': getLocaleLanguage(),
        'Device-ID': window.navigator.userAgent,
    },
});
export default _axios;
