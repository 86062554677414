import { createSlice } from '@reduxjs/toolkit';
import { FAILED, LOADING, SUCCESSFUL, EMPTY } from '@models/status';
export const SMS_CODED_SEND = 'SMS_CODE_SEND';
export const REQUEST_CODE = 'REQUEST_CODE';
export const VERIFY_CODE = 'VERIFY_CODE';
export const PHONE_VERIFIED = 'PHONE_VERIFIED';

type DepositState = {
    data: any;
    status: typeof FAILED | typeof LOADING | typeof SUCCESSFUL | typeof EMPTY | typeof SMS_CODED_SEND;
    error: any;
    step: typeof REQUEST_CODE | typeof VERIFY_CODE | typeof PHONE_VERIFIED;
    phoneNumber: string;
    countryCode: string;
    code: string;
    password?: string;
    otp_channel?: string;
};
const initialState: DepositState = {
    data: {},
    status: EMPTY,
    error: null,
    step: REQUEST_CODE,
    phoneNumber: '',
    countryCode: '',
    code: '',
    password: '',
};

const depositSlice = createSlice({
    /* The name of the slice[this will also be used as the action type string 
      in combination with the extraReducer name i.e posts/getPosts or posts/addPost] 
    */
    name: 'deposit',
    // initialState: initialState[ES6 destructuring syntax]
    initialState,
    // Add reducers for the synchronous actions on the UI[we are not using this property for this tutorial]
    reducers: {
        resetState: (state) => {
            state.status = initialState.status;
            state.data = initialState.data;
            state.error = initialState.error;
            state.step = REQUEST_CODE;
        },
        SMSCodeHasBeenSended: (state, action) => {
            state.status = SMS_CODED_SEND;
            state.step = VERIFY_CODE;
            state.phoneNumber = action.payload.phone_number;
            state.countryCode = action.payload.country_code;
            state.password = action.payload.password;
            state.otp_channel = action.payload.otp_channel;
        },
        phoneVerified: (state, action) => {
            state.status = SUCCESSFUL;
            state.data.message = 'Votre numéro de téléphone a été vérifié avec succès';
            state.data.code = action.payload;
        },
        setInitialStep: (state, action) => {
            state.status = SMS_CODED_SEND;
            state.step = action.payload;
        },
    },
    // Add extraReducers for the asynchronous actions on the UI
    extraReducers: {},
});
export const { resetState, SMSCodeHasBeenSended, setInitialStep, phoneVerified } = depositSlice.actions;
export default depositSlice.reducer;
