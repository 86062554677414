import TournamentRoundWidget from '@components/widgets/tournament-round/tournament-round';
import { Tournament } from 'interfaces/tournament.interface';
import React from 'react';

type TournamentRoundListProps = {
    tournament: Tournament;
};

function TournamentRoundList({ tournament }: TournamentRoundListProps): React.ReactElement {
    return (
        <div className="list-grid">
            {tournament.automation.rounds.map((round) => (
                <TournamentRoundWidget key={round._id} tournament={tournament} round={round} />
            ))}
        </div>
    );
}

export default TournamentRoundList;
