import React, { PropsWithChildren, createContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SidebarType } from '@enums/sidebar.enum';
import { useLocalStorage } from '@hooks/use-local-storage';
import { __SIDEBAR_STORE__ } from '@constants/storage';
import { useSelector } from 'react-redux';
import { RootState } from '@redux/store';
import { setSideBar } from '@redux/reducers/sidebar-reducer';

export type SideBarContextType = {
    sidebar: SidebarType;
    setSideBar: (sidebar: SidebarType) => void;
};

export interface SideBarContextModel {
    sidebar: SidebarType;
    setSideBar: (sidebar: SidebarType) => void;
}

export const SideBarContext = createContext<SideBarContextType>({} as SideBarContextType);

export const SideBarContextProvider = ({ children }: PropsWithChildren): React.ReactElement => {
    const [_sideBar, _setSideBar] = useLocalStorage<SidebarType>(__SIDEBAR_STORE__, SidebarType.DASHBOARD);
    const { sidebar } = useSelector((state: RootState) => state.sidebarStore);
    const dispatch = useDispatch();
    useEffect(() => {
        if (_sideBar) {
            dispatch(setSideBar(_sideBar));
        }
    });

    const updateSideBar = (sidebar: SidebarType): void => {
        _setSideBar(sidebar);
        dispatch(setSideBar(sidebar));
    };

    const value = { sidebar, setSideBar: updateSideBar };

    return <SideBarContext.Provider value={value}>{children}</SideBarContext.Provider>;
};
