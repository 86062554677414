// useCreatePartyModal.ts
import { useTranslation } from 'react-i18next';
import Settings from '@models/settings';
import { useLocalStorage } from './use-local-storage';
import { __PREFERENCE_STORE__ } from '@constants/storage';
import Cookies from 'js-cookie';
import { useToast } from './use-toast';
import Vibration from '@services/vibrate';

export const useSettings = () => {
    const { t, i18n } = useTranslation(['settings']);
    const [settings, setSettings] = useLocalStorage<Settings | undefined>(__PREFERENCE_STORE__);
    const toast = useToast();

    const onSaveSettings = (data: any) => {
        const settingData: Settings = {
            song: data.song,
            silence: data.silence,
            language: data.language,
            vibrate: data.vibrate,
        };
        setSettings(settingData);
        i18n.changeLanguage(data.language);
        Cookies.set('i18next', data.language);
        toast?.success(t('Paramètres sauvegardés avec succès.'));
    };

    const onVibrate = () => {
        if (!settings || (!settings.silence && settings.vibrate)) {
            Vibration.vibrate();
        }
    };

    const onBeep = () => {
        if (!settings || (!settings.silence && settings.song)) {
            Vibration.beep();
        }
    };

    const onVibrateOrBeep = () => {
        if (!settings || (!settings.silence && settings.vibrate)) {
            Vibration.vibrate();
        } else if (!settings || (!settings.silence && settings.song)) {
            Vibration.beep();
        }
    };

    return {
        settings,
        setSettings,
        onSaveSettings,
        onVibrateOrBeep,
        onVibrate,
        onBeep,
    };
};
