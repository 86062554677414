import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@redux/store';
import GameBoardPlayer from '../game-board-player';
import { useAuth } from '@hooks/use-auth';
import { d, e } from '@utils/encryption-wrapper';
import { useSocket } from '@hooks/use-socket';
import { useDispatch } from 'react-redux';
import { setGame, setGameTimerStatus } from '@redux/reducers/game-reducer';
import Event from '@constants/events';
import { useModal } from '@contexts/global-modal-context';
import { EventEmitter } from '@utils/event-emitter';
import './game-board.css';
import { useGamePLaying } from '@hooks/use-game-playing';
import GameFooter from '../game-footer';
const CLOSE_MODAL_TIMEOUT = 350;

function GameBoard(): React.ReactElement {
    const { hideModal } = useModal();
    const { session } = useAuth();
    const { game, opponentPlayers, currentPlayer } = useSelector((state: RootState) => state.gameStore);
    const [_, setIsLoading] = useState<boolean>(false);
    const [canReplayGame, setCanReplayGame] = useState<boolean>(true);
    const { onReplayGameListeners, offReplayGameListeners, showEnGameModal, onQuit, onResign } = useGamePLaying();

    const { socket } = useSocket();
    const dispatch = useDispatch();
    //console.log(isLoading);
    // Listen for browser refresh or close

    // Block navigation when user tries to navigate away (like back button)

    useEffect(() => {
        onComponentDidMount();
        return () => onComponentWillUnmount();
    }, []);

    useEffect(() => {
        if (!canReplayGame) {
            _onEndModalOpen();
        }
    }, [canReplayGame]);

    const _onSocketDisconnect = () => {
        if (canReplayGame) {
            setCanReplayGame(false);
        }
    };

    const _onEndModalOpen = () => {
        hideModal();
        setTimeout(() => {
            if (game && game.isFinish) {
                showEnGameModal(game);
            }
        }, CLOSE_MODAL_TIMEOUT);
    };

    const onComponentDidMount = () => {
        EventEmitter.on('__quitGame__', onResign);
        onReplayGameListeners();
    };

    const onComponentWillUnmount = () => {
        //this._removeFocusListener()
        socket?.off(Event.OK, _onSocketReconnect);
        socket?.off(Event.DISCONNECT, _onSocketDisconnect);
        socket?.off(Event.RECEIVE_CARD, onReceiveCards);
        offReplayGameListeners();
        EventEmitter.off('RESIGN', onResign);
        EventEmitter.off('quitGameboardScreen', onQuit);
        //this.backHandler && this.backHandler.remove();
        //BackHandler.removeListener('hardwareBackPress', this._quit)
        // activateKeepAwake();
        EventEmitter.emit('enableSurveys');
    };

    const _onSocketReconnect = () => {
        if (game) {
            socket?.emit(Event.GET_GAME_DATA, e(game.part.id));
        }
    };

    useEffect(() => {
        if (session && game) {
            _onGameUpdate();
        }
    }, [session, game]);

    useEffect(() => {
        socket?.on(Event.RECEIVE_CARD, onReceiveCards);
    }, [socket]);

    const onReceiveCards = (data: string) => {
        dispatch(setGame(d(data)));
    };

    const _loadingTimeout = () => {
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    };

    const _onGameUpdate = () => {
        if (game && game.isFinish) {
            dispatch(setGameTimerStatus(false));
            showEnGameModal(game);
        }

        _loadingTimeout();
    };

    return (
        <>
            <div className="table-space">
                <div className="game-side">
                    <div className="game-zone">
                        {opponentPlayers.map((player, index) => (
                            <GameBoardPlayer
                                player={player}
                                token={index + 1}
                                key={index}
                                totalPlayers={opponentPlayers.length + 1}
                            />
                        ))}
                        {currentPlayer && (
                            <GameBoardPlayer
                                player={currentPlayer}
                                token={0}
                                key={0}
                                totalPlayers={opponentPlayers.length + 1}
                            />
                        )}
                    </div>
                </div>
            </div>
            <div>
                <GameFooter />
            </div>
        </>
    );
}

export default GameBoard;
