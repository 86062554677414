import Modal from '@components/ui/modal';
import React from 'react';
import { useModal } from '@contexts/global-modal-context';
import { useTranslation } from 'react-i18next';
import DisplayHtml from '@components/ui/html';
import { GamePlayerInfo } from 'interfaces/game-player.interface';

type ReplyGameModalProps = {
    creator: GamePlayerInfo;
    timer: string;
    onReplayGame: () => void;
};

function ReplayGameModal(props: ReplyGameModalProps): React.ReactElement {
    const { hideModal } = useModal();
    const { t } = useTranslation(['replay-game']);
    const { creator, timer, onReplayGame } = props;

    const onClose = () => {
        hideModal();
    };

    const onOk = () => {
        hideModal();
        onReplayGame();
    };

    return (
        <Modal hideOnAreaClick={false} show={true} onClose={onClose} id="loser-modal" title={t('title') + '...'}>
            <div className="form-container d-flex flex-column justify-content-center align-items-center">
                <p className="p-0 m-0">
                    <DisplayHtml htmlString={t('hint', { pseudo: creator.pseudo })} />
                </p>
                <h4 className="p-0 m-0 text-center text-success">{timer}</h4>
                <div className="actions">
                    <button type="button" onClick={onClose} className="danger d-flex justify-content-center">
                        {t('no', { ns: 'global' })}
                    </button>
                    <button type="button" onClick={onOk} className="default d-flex justify-content-center">
                        {t('yes', { ns: 'global' })}
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default ReplayGameModal;
