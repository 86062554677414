import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { EMPTY, FAILED, LOADING, SUCCESSFUL } from '@models/status';
import ReferralService from '@services/referral-service';
import Actions from '@constants/actions';

type StateProps = {
    stats: any[];
    users: any;
    status: typeof EMPTY | typeof FAILED | typeof LOADING | typeof SUCCESSFUL;
    error: any;
    action: typeof Actions.GET_STATS | typeof Actions.GET_REFFERED_USERS | typeof Actions.UNKNOWN;
};

const initialState: StateProps = {
    stats: [],
    users: {
        active: [],
        inactive: [],
    },
    status: EMPTY,
    error: null,
    action: Actions.UNKNOWN,
};

// Handle POST request to create a new post
export const getReferralStats: any = createAsyncThunk(
    // The name of the action
    'referral/get',
    // The payload creator
    async (_, { rejectWithValue }) => {
        try {
            return await ReferralService.getStats();
        } catch (err: any) {
            return rejectWithValue({ error: err.message });
        }
    },
);

export const getReferredUsers: any = createAsyncThunk(
    // The name of the action
    'referral/get-users',
    // The payload creator
    async (_, { rejectWithValue }) => {
        try {
            return await ReferralService.getRefferedUsers();
        } catch (err: any) {
            return rejectWithValue({ error: err.message });
        }
    },
);

const referralSlice = createSlice({
    /* The name of the slice[this will also be used as the action type string 
      in combination with the extraReducer name i.e posts/getPosts or posts/addPost] 
    */
    name: 'referral',
    // initialState: initialState[ES6 destructuring syntax]
    initialState,
    // Add reducers for the synchronous actions on the UI[we are not using this property for this tutorial]
    reducers: {
        resetState: (state) => {
            state.status = initialState.status;
            state.stats = initialState.stats;
            state.users = initialState.users;
            state.error = initialState.error;
            state.action = initialState.action;
        },
    },
    // Add extraReducers for the asynchronous actions on the UI
    extraReducers: {
        [getReferralStats.pending]: (state) => {
            // When data is being fetched
            state.status = LOADING;
            state.action = Actions.GET_STATS;
        },
        [getReferralStats.fulfilled]: (state, action: any) => {
            // When data is fetched successfully
            state.status = SUCCESSFUL;

            // Concatenate the new data to the existing data in the array
            state.stats = action.payload;
        },
        [getReferralStats.rejected]: (state, action) => {
            // When data is fetched unsuccessfully
            state.status = FAILED;

            // Update the error message for proper error handling
            state.error = action.error.message;
        },
        [getReferredUsers.pending]: (state) => {
            // When data is being fetched
            state.status = LOADING;
            state.action = Actions.GET_REFFERED_USERS;
        },
        [getReferredUsers.fulfilled]: (state, action: any) => {
            // When data is fetched successfully
            state.status = SUCCESSFUL;

            // Concatenate the new data to the existing data in the array
            state.users = action.payload;
        },
        [getReferredUsers.rejected]: (state, action) => {
            // When data is fetched unsuccessfully
            state.status = FAILED;

            // Update the error message for proper error handling
            state.error = action.error.message;
        },
    },
});

export const { resetState } = referralSlice.actions;

export default referralSlice.reducer;
