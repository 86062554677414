import { FormRequest } from './common';

export default class PhoneFormRequest extends FormRequest {
    protected rules = {
        phone_number: [
            {
                phone: true,
                message: 'Le numéro de téléphone est invalide',
            },
        ],
    };
}
