import React from 'react';

type DisplayHtmlProps = {
    htmlString: string;
};
const DisplayHtml: React.FunctionComponent<DisplayHtmlProps> = ({ htmlString }: DisplayHtmlProps) => {
    return <span dangerouslySetInnerHTML={{ __html: htmlString }} />;
};

export default DisplayHtml;
