import React from 'react';
import './share-modal.css';
import Modal from '@components/ui/modal';
import {
    FacebookIcon,
    FacebookShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from 'react-share';
import { MODAL_TYPES, useModal } from '@contexts/global-modal-context';
// Notice here we're using the function declaration with the interface Props
export interface ShareModalProps {
    imageUrl: string;
    url: string;
    title: string;
}

const ShareModal: React.FunctionComponent<ShareModalProps> = ({ imageUrl, url, title }: ShareModalProps) => {
    const { hideModal } = useModal();

    const handleCloseModal = () => {
        hideModal(MODAL_TYPES.SHARE_MODAL);
    };

    return (
        <Modal show={true} onClose={handleCloseModal} id="loser-modal" title="">
            <div className="form-container d-flex flex-column justify-content-center align-items-center">
                <div className="d-flex justify-content-center align-items-center">
                    <img src={imageUrl} alt="" width={'100%'} />
                </div>
                <div className="d-flex gap-2">
                    <FacebookShareButton url={url} title={title}>
                        <FacebookIcon round={true} size={45} />
                    </FacebookShareButton>
                    <TwitterShareButton url={url} title={title}>
                        <TwitterIcon round={true} size={45} />
                    </TwitterShareButton>
                    <WhatsappShareButton url={url} title={title}>
                        <WhatsappIcon round={true} size={45} />
                    </WhatsappShareButton>
                </div>
            </div>
        </Modal>
    );
};

export default ShareModal;
