import { FormRequest } from './common';

export default class DepositFormRequest extends FormRequest {
    protected rules = {
        amount: [
            {
                required: true,
                message: 'Le montant de la recharge est requis',
            },
        ],
    };
}
