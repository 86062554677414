import Modal from '@components/ui/modal';
import React from 'react';
import { useModal } from '@contexts/global-modal-context';
import { useTranslation } from 'react-i18next';
import { Party } from 'interfaces/party.interface';
import Icon from '@components/ui/icon';
import './ongoing-party-modal.css';
import { Player } from 'interfaces/player.interface';
import defaultAvatar from '@assets/default-avatar/9.png';
import { useAuth } from '@hooks/use-auth';
import { e } from '@utils/encryption-wrapper';
import Event from '@constants/events';
import { useSocket } from '@hooks/use-socket';
import useUnMount from '@hooks/use-unmount';

type OngoingPartyModalProps = {
    onHideModal: (data: any) => void;
    game: Party;
};

function OngoingPartyModal(props: OngoingPartyModalProps): React.ReactElement {
    const { hideModal } = useModal();
    const { game } = props;
    const { t } = useTranslation(['party']);
    const { user } = useAuth();
    const { socket } = useSocket();

    const _startGame = () => {
        onClose();
        socket?.emit(Event.GET_GAME_DATA, e(props.game.id), e(Event.RESUME_GAME));
    };

    useUnMount(() => {
        socket?.off(Event.GET_GAME_DATA);
    });

    const onClose = () => {
        hideModal();
    };

    const isInPart = game.players.findIndex((player) => player.user_id === user?.id) >= 0;

    return (
        <Modal
            show={true}
            onClose={onClose}
            hideOnAreaClick={game.creator_id != user?.id}
            hideOnClose={false}
            id="party-modal"
            title={t('ongoing_part', { ns: 'game', suffix: 's' }) + '...'}
        >
            <div className="form-container">
                <div>
                    <fieldset className="d-flex flex-row align-items-center gap-4 py-1">
                        <Icon name={'bet'} className="awaiting-game-icon"></Icon>
                        <div className="d-flex align-items-center gap-3 awaiting-g-bet">
                            <strong>{game.bet} XAF</strong>
                            <span className="notif-badge">{game.korat ? 'K x2' : ''}</span>
                            <span className="notif-badge">{game.double_korat ? '2K x4' : ''}</span>
                        </div>
                    </fieldset>
                    <fieldset className="d-flex flex-row align-items-center gap-4 py-1">
                        <Icon name={'users'} className="awaiting-game-icon"></Icon>
                        <div className="d-flex align-items-center gap-1 awaiting-g-bet">
                            <strong>{game.max_nb_players} adversaire(s) max</strong>
                        </div>
                    </fieldset>
                    <fieldset className="d-flex flex-row align-items-center gap-4 py-1">
                        <Icon name={'timer'} className="awaiting-game-icon"></Icon>
                        <div className="d-flex align-items-center gap-1 awaiting-g-bet">
                            <strong>{game.time_per_move}s par coup</strong>
                        </div>
                    </fieldset>
                    <fieldset className="d-flex flex-row align-items-center gap-4 py-1">
                        <Icon name={'security-special'} className="awaiting-game-icon"></Icon>
                        <div className="d-flex align-items-center gap-1 awaiting-g-bet">
                            {game.password.length == 0 && <strong>{t('no_password', { ns: 'game' })}</strong>}
                            {game.password.length > 0 && (
                                <strong>
                                    {game.creator_id == user?.id ? game.password : t('private_part', { ns: 'game' })}
                                </strong>
                            )}
                        </div>
                    </fieldset>
                    {game.special_mode && (
                        <fieldset className="d-flex flex-row align-items-center gap-4 py-1">
                            <Icon name={'settings'} className="awaiting-game-icon"></Icon>
                            <div className="d-flex align-items-center gap-1 awaiting-g-bet">
                                <strong>{t('stopped_balls_list', { ns: 'game' })}</strong>
                            </div>
                        </fieldset>
                    )}
                </div>
                <div className="separator light"></div>
                {game.players.map((player: Player) => {
                    return (
                        <div className="creator-infos" key={player._id}>
                            <div className="user-avatar">
                                <img src={defaultAvatar} alt="" />
                            </div>
                            <span className="user-name">
                                <b>{player.pseudo}</b>
                            </span>
                        </div>
                    );
                })}
                <div className="actions">
                    <button
                        type="button"
                        className="default switch-modal-trigger"
                        onClick={isInPart ? _startGame : onClose}
                        data-modal="modal-two"
                    >
                        {isInPart ? t('return_to_game', { ns: 'actions' }) : t('close', { ns: 'actions' })}
                        <span className="icon icon16">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M20.7072 7.70711C21.0977 7.31658 21.0977 6.68342 20.7072 6.29289C20.3166 5.90237 19.6835 5.90237 19.293 6.29289L20.7072 7.70711ZM9.0001 18L8.29301 18.7071C8.68353 19.0976 9.31669 19.0976 9.70721 18.7071L9.0001 18ZM4.7071 12.2929C4.31657 11.9024 3.6834 11.9024 3.29288 12.2929C2.90236 12.6834 2.90237 13.3166 3.2929 13.7071L4.7071 12.2929ZM19.293 6.29289L8.293 17.2929L9.70721 18.7071L20.7072 7.70711L19.293 6.29289ZM3.2929 13.7071L8.29301 18.7071L9.7072 17.2928L4.7071 12.2929L3.2929 13.7071Z"
                                    fill="white"
                                />
                            </svg>
                        </span>
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default OngoingPartyModal;
