import parsePhoneNumber from 'libphonenumber-js';
import { RequestValue, Validator, ValidatorValue } from './common';

export const PhoneValidator: Validator = {
    passed: (
        attribute: string,
        value: ValidatorValue,
        phone: ValidatorValue,
        request?: { [x: string]: RequestValue },
    ): boolean => {
        if (!value && (phone === null || phone === undefined)) {
            return true;
        }
        if (typeof phone === 'string') {
            const tovalidate = [phone];
            if (!phone.startsWith('+')) {
                tovalidate.push('+' + phone);
            }
            if (!phone.startsWith('+') && request && request[attribute + '_country_dial_code']) {
                tovalidate.push(request[attribute + '_country_dial_code'] + phone);
            }
            for (let i = 0; i < tovalidate.length; i++) {
                const phoneNumber = parsePhoneNumber(tovalidate[i]);
                if (phoneNumber && phoneNumber.isValid()) {
                    return true;
                }
            }
        }
        return false;
    },
    message: 'Le numéro de téléphone {value} est invalide.',
};
