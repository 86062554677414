import Modal from '@components/ui/modal';
import React from 'react';
import { useModal } from '@contexts/global-modal-context';
import './game-actions-modal.css';
import GameActions from '../game-actions/game-actions';

function GameActionsModal(): React.ReactElement {
    const { hideModal } = useModal();

    const onClose = () => {
        hideModal();
    };

    return (
        <Modal show={true} onClose={onClose} id="party-modal" title="">
            <div className="form-container">
                <GameActions />
            </div>
        </Modal>
    );
}

export default GameActionsModal;
