import axios from '@utils/http-common';
import { AxiosResponse } from 'axios';
import React from 'react';
import { useToast } from '@hooks/use-toast';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import CryptoJS from 'crypto-js';
import { _FAPFAP_ENCRYPTION_KEY } from '@environments';
import { d } from '@utils/encryption-wrapper';
//import { useAuth } from '@hooks/use-auth';
import translator from '@utils/translators';
import allowRoutesMiddleware from '../middlewares/routes';
import secureLocalStorage from 'react-secure-storage';
import { useAuth } from '@hooks/use-auth';
import { __SESSION_STORE__ } from '@constants/storage';
import { UserSession } from '@models/session.model';

const AxiosInterceptorContextProvider = ({ children }: React.PropsWithChildren): React.ReactElement => {
    const toast = useToast();
    const navigate = useNavigate();
    const { destroySession } = useAuth();
    //console.log(session);
    useEffect(() => {
        const resInterceptor = (response: AxiosResponse) => {
            if (response.data.data) {
                return response.data.data;
            } else if (response.data) {
                return response.data;
            }
            return response;
        };

        /**
         * Detect request errors and dispay it on a notification message
         * @param error
         * @returns
         */
        const errInterceptor = (error: any) => {
            let returnPrimise = true;
            if (error.response && error.response.status === 504 && 0) {
                toast?.error(translator.errors.e504);
            } else if (error.response && error.response.status === 401) {
                destroySession();
                navigate('/auth/signin');
                returnPrimise = false;
            } else if (error.response && error.response.data && error.response.data.message) {
                toast?.error(error.response.data.message);
            } else if (error.config.url != '/account/logout') {
                toast?.error(error.message);
            }
            if (returnPrimise) {
                return Promise.reject(error);
            }
        };

        axios.interceptors.request.use((config) => {
            const now = Date.now();
            const sessionData = secureLocalStorage.getItem(__SESSION_STORE__);

            config.headers['Authorization-Timestamp'] = now;
            config.headers['Authorization-Hash'] = CryptoJS.MD5(now + _FAPFAP_ENCRYPTION_KEY);
            const isProtected = typeof config.url === 'string' && !allowRoutesMiddleware.includes(config.url);

            if (sessionData && typeof sessionData === 'string') {
                const session: UserSession = JSON.parse(sessionData);
                if (session && session.secret && isProtected) {
                    config.headers['Authorization-ID'] = session.user.id;
                    config.headers['Authorization-Hash'] = CryptoJS.MD5(session.user.id + now + d(session.secret));
                }
            }

            return config;
        });
        const interceptor = axios.interceptors.response.use(resInterceptor, errInterceptor);

        return () => axios.interceptors.response.eject(interceptor);
    }, [navigate]);
    return <>{children}</>;
};

export { AxiosInterceptorContextProvider };
