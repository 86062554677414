import Card11 from '@assets/images/cards/11.png';
import Card12 from '@assets/images/cards/12.png';

import Card21 from '@assets/images/cards/21.png';
import Card22 from '@assets/images/cards/22.png';

import Card31 from '@assets/images/cards/31.png';
import Card32 from '@assets/images/cards/32.png';
import Card33 from '@assets/images/cards/33.png';
import Card34 from '@assets/images/cards/34.png';

import Card41 from '@assets/images/cards/41.png';
import Card42 from '@assets/images/cards/42.png';
import Card43 from '@assets/images/cards/43.png';
import Card44 from '@assets/images/cards/44.png';

import Card51 from '@assets/images/cards/51.png';
import Card52 from '@assets/images/cards/52.png';
import Card53 from '@assets/images/cards/53.png';
import Card54 from '@assets/images/cards/54.png';

import Card61 from '@assets/images/cards/61.png';
import Card62 from '@assets/images/cards/62.png';
import Card63 from '@assets/images/cards/63.png';
import Card64 from '@assets/images/cards/64.png';

import Card71 from '@assets/images/cards/71.png';
import Card72 from '@assets/images/cards/72.png';
import Card73 from '@assets/images/cards/73.png';
import Card74 from '@assets/images/cards/74.png';

import Card81 from '@assets/images/cards/81.png';
import Card82 from '@assets/images/cards/82.png';
import Card83 from '@assets/images/cards/83.png';
import Card84 from '@assets/images/cards/84.png';

import Card91 from '@assets/images/cards/91.png';
import Card92 from '@assets/images/cards/92.png';
import Card93 from '@assets/images/cards/93.png';
import Card94 from '@assets/images/cards/94.png';

import Card101 from '@assets/images/cards/101.png';
import Card102 from '@assets/images/cards/102.png';
import Card103 from '@assets/images/cards/103.png';

const Cards = {
    11: Card11,
    12: Card12,

    21: Card21,
    22: Card22,

    31: Card31,
    32: Card32,
    33: Card33,
    34: Card34,

    41: Card41,
    42: Card42,
    43: Card43,
    44: Card44,

    51: Card51,
    52: Card52,
    53: Card53,
    54: Card54,

    61: Card61,
    62: Card62,
    63: Card63,
    64: Card64,

    71: Card71,
    72: Card72,
    73: Card73,
    74: Card74,

    81: Card81,
    82: Card82,
    83: Card83,
    84: Card84,

    91: Card91,
    92: Card92,
    93: Card93,
    94: Card94,

    101: Card101,
    102: Card102,
    103: Card103,
};

export default Cards;

export type CardIndex = keyof typeof Cards;
