import React from 'react';
import DisplayHtml from '../html';
import useMobileInstallPrompt from '@hooks/use-mobile-install-prompt';
import { useTranslation } from 'react-i18next';
import { PwaProp } from './install-pwa';

function SafariPwaMobileInstructions({ onClose }: PwaProp): React.ReactElement<PwaProp> {
    const [, handleIOSInstallDeclined] = useMobileInstallPrompt();
    const { t } = useTranslation(['pwa']);

    const onAction = () => {
        handleIOSInstallDeclined();
        onClose();
    };

    return (
        <div className="mb-3">
            <div className="justify-content-center">
                <h5>{t('title')}</h5>
                <p>{t('description')}</p>
                <ol>
                    <li>
                        <DisplayHtml htmlString={t('step1')} />
                    </li>
                    <li>
                        <DisplayHtml htmlString={t('step2')} />
                    </li>
                </ol>
                <div className="d-flex justify-content-center align-items-center mt-2">
                    <button className="button default mt-2" type="button" onClick={onAction}>
                        {t('close', { ns: 'actions' })}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default SafariPwaMobileInstructions;
