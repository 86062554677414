import React from 'react';
import Spinner from '@components/ui/spinner';
import SvgIcon from '@components/ui/svg-icon';
import { IconName } from '@components/ui/svg-icon';
import './button.css';
type InputType = 'submit' | 'reset' | 'button';

interface Props extends React.PropsWithChildren {
    id?: string;
    type?: InputType;
    label?: string;
    loading?: boolean;
    icon?: IconName | { name: IconName; height: number; width: number };
    loadingMessage?: string;
    disabled?: boolean;
    className?: string;
    onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
}

// Notice here we're using the function declaration with the interface Props
const Button: React.FunctionComponent<Props> = ({
    id,
    type,
    label,
    onClick,
    loading,
    loadingMessage,
    icon,
    className,
    disabled,
    children,
}: Props) => {
    return (
        <button
            className={`button default ${disabled ? 'disabled' : ''} ${className} ${
                icon || loading ? 'justify-content-between' : ''
            }`}
            type={type}
            id={id}
            onClick={onClick}
            disabled={disabled || loading}
        >
            {label && !children && <span>{loading ? loadingMessage : label}</span>}
            {children && children}
            {!loading && icon && typeof icon !== 'object' && (
                <span className="icon icon-16">
                    <SvgIcon name={icon} />
                </span>
            )}
            {!loading && icon && typeof icon === 'object' && (
                <span className="icon icon-16">
                    <SvgIcon name={icon.name} height={icon.height} width={icon.width} />
                </span>
            )}
            {loading && (
                <span className="flex-none" style={{ width: 20 }}>
                    <Spinner color="#FFF" />
                </span>
            )}
        </button>
    );
};

Button.defaultProps = {
    loading: false,
    type: 'submit' as InputType,
    loadingMessage: 'En cours ...',
    className: '',
};

export default Button;
