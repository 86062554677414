import { Validator, ValidatorValue } from './common';

export const RequiredValidator: Validator = {
    passed: (_, value: ValidatorValue, filed: ValidatorValue): boolean => {
        if (!value) {
            return true;
        }
        return filed !== null && filed !== undefined;
    },
    message: 'Le champ {attribute} est requis.',
};
