import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

import resources from '@translations/index';

import Cookies from 'js-cookie';
const currentLocale = Cookies.get('i18next') || 'fr';
i18n.on('missingKey', () => {
    // Do nothing
});
i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        debug: false,
        fallbackLng: currentLocale,
        defaultNS: 'common',
        lng: currentLocale,
        interpolation: {
            escapeValue: false,
        },
        detection: {
            order: ['path', 'localStorage', 'htmlTag', 'cookie'],
            caches: ['localStorage', 'cookie'], // cache user language on
        },
    });
export default i18n;
