import Storage from '@utils/storage';
import Settings from '@models/settings';
import Cookies from 'js-cookie';
import { LanguageType } from '@ctypes/langage';

export default {
    /**
     * get Terms and usage
     * @returns
     */
    updateSettings(settings: any) {
        return Storage.set('settings', settings);
    },

    getSettings() {
        const defaultSetting: Settings = {
            silence: false,
            song: true,
            vibrate: true,
            language: (Cookies.get('i18next') || 'fr') as LanguageType,
        };
        return Storage.get('settings', defaultSetting);
    },
};
