import http from '@utils/http-common';
import APIRoutes from '@constants/routes';
import { JoinParty } from 'interfaces/join-party.interface';
import { PartyDTO } from 'interfaces/party-dto.interface';
import { Party } from 'interfaces/party.interface';
import { Game } from 'interfaces/game.interface';
import { GameInfo } from 'interfaces/game-infos.interface';

export default {
    /**
     * get Terms and usage
     * @returns
     */
    getPendingGames() {
        return http.get<any>('/game/pending-games');
    },
    /**
     * get Terms and usage
     * @returns
     */
    getOngoingGames() {
        return http.get<any>('/game/ongoing-games');
    },

    createParty(params: PartyDTO) {
        return http.post<PartyDTO, Party>(APIRoutes.CREATE_GAME, params);
    },

    cancelParty(gameId: string, playerId: string) {
        return http.post<any>(APIRoutes.CANCEL, { game_id: gameId, player_id: playerId });
    },

    joinParty(data: JoinParty) {
        return http.post<any>(APIRoutes.JOIN, data);
    },

    quitParty(gameId: string) {
        return http.post<any>(APIRoutes.QUIT, { game_id: gameId });
    },

    startGame(gameId: string) {
        return http.post<any, Game>(APIRoutes.START, { game_id: gameId });
    },

    reportProblem(gameId: string, players: string[], message: string) {
        return http.post<any>(APIRoutes.PROBLEM_REPORT, { game_id: gameId, players, message });
    },

    replayGame(gameId: string) {
        return http.post<string, Party>(APIRoutes.REMATCH, { game_id: gameId });
    },

    cancelGameReplay(gameId: string) {
        return http.post<any>(APIRoutes.REMATCH_QUIT, { game_id: gameId });
    },
    getGameInfo(gameId: string) {
        return http.get<any, GameInfo>(APIRoutes.GAME_INFOS, { params: { game_id: gameId } });
    },
};
