import http from '@utils/http-common';

const getStats = () => {
    return http.get<any>('/partner/stats');
};

const PartnerService = {
    getStats,
};

export default PartnerService;
