import React, { useEffect, useState } from 'react';

import Modal from '@components/ui/modal';
import { useModal } from '@contexts/global-modal-context';
import './block-gamer.css';
import BInputField from '@components/ui/b-input';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
    blockGamer,
    getBlockedGamers,
    resetState,
    searchGamers,
    unblockGamer,
} from '@redux/reducers/block-gamer-reducer';
import { Alert } from '@utils/alert';
import { useTranslation } from 'react-i18next';
import { FAILED, LOADING, SUCCESSFUL } from '@models/status';
import Button from '@components/ui/button';
import Checkbox from '@components/ui/checkbox';
import actions from '@constants/actions';
import { useAuth } from '@hooks/use-auth';
import { areBlockedListsEqual } from '@utils/list.util';

const SEARCH_TAB = 'SEARCH_TAB';
const BLOCKED_TAB = 'BLOCKED_TAB';
type TAB = typeof SEARCH_TAB | typeof BLOCKED_TAB;

function BlockGamerModal(): React.ReactElement {
    const { hideModal } = useModal();
    const [tab, setTab] = useState<TAB>(SEARCH_TAB);
    const [query, setQuery] = useState<string>('');
    const [player, setPlayer] = useState<any>(null);
    const { status, error, gamers, action, blocked_gamers } = useSelector((state: any) => state.blockGamerStore);
    const dispatch = useDispatch();
    const { t } = useTranslation(['setting']);
    const [isChecked, setIsChecked] = useState(false);
    const { user, updateUser } = useAuth();

    const handleClose = () => {
        hideModal();
    };

    const handleShowSearchGamers = () => {
        setTab(SEARCH_TAB);
    };

    const handleShowBlockedGamers = () => {
        setTab(BLOCKED_TAB);
    };

    useEffect(() => {
        if (status === FAILED) {
            Alert.alert(t('oops', { ns: 'global' }), error);
        }
        if (status === SUCCESSFUL && action == actions.BLOCK) {
            Alert.success('Info', t('block_success', { ns: 'alert', pseudo: player?.pseudo }));
            dispatch(getBlockedGamers());
        } else if (status === SUCCESSFUL && action == actions.UNBLOCK) {
            Alert.success('Info', t('unblock_success', { ns: 'alert', pseudo: player?.pseudo }));
            dispatch(getBlockedGamers());
            if (query && query.length) {
                dispatch(searchGamers(query));
            }
        }
    }, [status, error]);

    useEffect(() => {
        dispatch(getBlockedGamers());
        return () => {
            dispatch(resetState());
        };
    }, []);

    useEffect(() => {
        if (user && blocked_gamers) {
            if (areBlockedListsEqual(blocked_gamers, user.blacklist)) {
                updateUser({
                    ...user,
                    blacklist: blocked_gamers,
                });
            }
        }
    }, [user, blocked_gamers]);

    const handleSearchGamers = () => {
        const search_text = String(query).trim();
        if (search_text != '') {
            dispatch(searchGamers(search_text));
        } else {
            Alert.alert(t('warning_label', { ns: 'alert' }), t('empty_search_text_error', { ns: 'alert' }));
        }
    };

    const _blockPlayer = (player: any) => {
        dispatch(blockGamer(player.id));
        setPlayer(player);
    };

    const _unblockPlayer = (player: any) => {
        dispatch(unblockGamer(player.id));
        setPlayer(player);
    };

    const handleBlockPlayer = (checked: boolean, player: any) => {
        setIsChecked(false);
        if (checked) {
            Alert.confirm(
                t('confirm_label', { ns: 'messages' }),
                t('block_question', { ns: 'messages', pseudo: player.pseudo }),
                () => _blockPlayer(player),
                { cancelable: true },
            );
        }
    };

    const handleUnblockPlayer = (checked: boolean, player: any) => {
        if (checked) {
            Alert.confirm(
                t('confirm_label', { ns: 'messages' }),
                t('unblock_question', { ns: 'messages', pseudo: player.pseudo }),
                [
                    {
                        text: t('no', { ns: 'messages' }),
                        onPress: () => null,
                    },

                    {
                        text: t('yes', { ns: 'messages' }),
                        onPress: () => {
                            _unblockPlayer(player);
                        },
                    },
                ],
                { cancelable: true },
            );
        }
    };

    return (
        <Modal show={true} id="party-modal" title="Bloquer des joueurs">
            <div className="form-container">
                <div className="container-fluid px-0 px-0 w-100">
                    <div className="row">
                        <div
                            className={'col-6 term-policy-link ' + (tab === SEARCH_TAB ? 'active' : '')}
                            data-target="terms"
                        >
                            <h5 onClick={handleShowSearchGamers}>Rechercher un joueur</h5>
                            <div className="separator"></div>
                        </div>
                        <div
                            className={'col-6 term-policy-link ' + (tab === BLOCKED_TAB ? 'active' : '')}
                            data-target="policy"
                        >
                            <h5 onClick={handleShowBlockedGamers}>Liste des joueurs bloqués</h5>
                            <div className="separator"></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className={`col-12 term-policy ${tab === SEARCH_TAB ? 'active' : ''}`} id="terms">
                            <div className="document-side">
                                <div className="mt-3 search-btn">
                                    <BInputField
                                        type="text"
                                        id="q"
                                        name="q"
                                        placeholder="Pseudo du joueur..."
                                        onInput={setQuery}
                                    ></BInputField>
                                    <Button
                                        icon="search"
                                        id="search"
                                        disabled={status === LOADING}
                                        loading={status === LOADING}
                                        onClick={handleSearchGamers}
                                    ></Button>
                                </div>
                                <div>
                                    <p className="mt-3">Résult de la recherche ({gamers?.length || 0})</p>

                                    {Array.from(gamers).map((player: any) => (
                                        <div
                                            key={player.id}
                                            className="d-flex align-items-center justify-content-between w-100"
                                        >
                                            <Checkbox
                                                onChange={(checked) => handleBlockPlayer(checked, player)}
                                                id={'gamer-' + player.id}
                                                name="silence"
                                                label={player.pseudo}
                                                checked={isChecked}
                                                blackTheme={true}
                                                align="left"
                                                labelClassName="usage-condition"
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className={`col-12 term-policy ${tab === BLOCKED_TAB ? 'active' : ''}`} id="policy">
                            <div className="document-side pb-3">
                                {!blocked_gamers.length && (
                                    <p className="my-3 text-center">{t('empty_block_list', { ns: 'messages' })}</p>
                                )}

                                {Array.from(blocked_gamers).map((player: any) => (
                                    <div key={player.id} className="mt-3">
                                        <Checkbox
                                            onChange={(checked) => handleUnblockPlayer(checked, player)}
                                            id={'gamer-' + player.id}
                                            name="silence"
                                            label={player.pseudo}
                                            checked={isChecked}
                                            blackTheme={true}
                                            align="left"
                                            labelClassName="usage-condition"
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="separator"></div>
                    <div className="actions justify-content-center">
                        <button className="button default" onClick={handleClose}>
                            {t('close', { ns: 'actions' })}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default BlockGamerModal;
