import React, { FormEvent, useState } from 'react';
import Form from '@components/ui/form';
import Button from '@components/ui/button';
import BInputField from '@components/ui/b-input';
import { FormState } from '@reducers/form-reducer';
import { useDispatch } from 'react-redux';
import { emit } from '@redux/reducers/event-reducer';
import EventType from '@ctypes/event-type';
import SvgIcon from '@components/ui/svg-icon';
import { useMediaScreen } from '@hooks/use-media-screen';
import { useAuth } from '@hooks/use-auth';
import ResetPasswordRequest from '@requests/reset-password-request';
import { useToast } from '@hooks/use-toast';
import { useModal } from '@contexts/global-modal-context';
import { useTranslation } from 'react-i18next';
import { FormDataType, FormInputType } from '@ctypes/form-data';
import AuthService from '@services/auth-service';
import { PasswordRequestCodeData, ResetPasswordData } from '@models/auth';
import { TabProps } from '../profile-modal/profile-modal';
import { Alert } from '@utils/alert';
import Spinner from '@components/ui/spinner';

function ResetPassword({ handleTabClick }: TabProps): React.ReactElement<TabProps> {
    const { t } = useTranslation(['profile']);
    const [isSmallScreen] = useMediaScreen();
    const dispatch = useDispatch();
    const { updateUser } = useAuth();
    const toast = useToast();
    const { hideModal } = useModal();
    const requestValidator = new ResetPasswordRequest();
    const [formState, setFormState] = useState<FormState>();
    const [isLoading, setIsLoading] = useState<boolean>();
    const [isRequestingCode, setIsRequestingCode] = useState<boolean>();
    const { user } = useAuth();
    // Get the posts from the store
    const [formData, setFormData] = useState<FormDataType>({
        old_password: { value: '' },
        password: { value: '' },
        password_confirmation: { value: '' },
    });

    const handleGoBackEvent = () => {
        dispatch(emit({ type: EventType.GO_BACK }));
    };

    const submitHandler = (state: FormState, event: FormEvent) => {
        event.preventDefault();
        setFormState(state);
        if (state.validator && state.validator.valid) {
            resetPassword(state.data);
        }
    };

    const resetPassword = (state: FormInputType) => {
        const data: ResetPasswordData = {
            password: state.password as string,
            old_password: state.old_password as string,
        };
        setIsLoading(true);
        AuthService.resetPassword(data)
            .then((user) => {
                updateUser(user);
                toast?.success(t('update_profil_success', { ns: 'alert' }));
                setIsLoading(false);
                hideModal();
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    const setOldPassword = (password: string) => {
        setFormData({ ...formData, old_password: { value: password } });
    };

    const setNewPassword = (password: string) => {
        setFormData({ ...formData, password: { value: password } });
    };

    const setPasswordConfirmation = (password: string) => {
        setFormData({ ...formData, password_confirmation: { value: password } });
    };

    const onForgotPassword = () => {
        const data: PasswordRequestCodeData = {
            phone_number: user?.phone_number as string,
            country_code: user?.country_code,
        };
        setIsRequestingCode(true);
        AuthService.passwordRequestCode(data)
            .then((responseData: any) => {
                setIsRequestingCode(false);
                handleTabClick('forgot_password', { otp_channel: responseData.otp_channel });
            })
            .catch((error) => {
                setIsRequestingCode(false);
                Alert.alert(t('oops', { ns: 'global' }), error);
            });
    };

    return (
        <Form method="POST" request={requestValidator} onSubmit={submitHandler} data={formData}>
            <div className="d-flex gap-0 profile-tab w-100">
                <h4
                    className={`text-center d-inline-block ${
                        isSmallScreen ? 'd-flex justify-content-between gap-1' : ''
                    } `}
                >
                    {isSmallScreen && <SvgIcon onClick={handleGoBackEvent} name="arrow-left" height={19} width={19} />}{' '}
                    {t('Changer mon mot de passe')}
                </h4>
            </div>
            <BInputField
                type="password"
                label={t('Ancien mot de passe', { ns: 'attributes' })}
                id="old_password"
                name="old_password"
                placeholder={t('Ancien mot de passe', { ns: 'attributes' })}
                value={formData.old_password.value}
                onInput={setOldPassword}
                parent={formState}
            ></BInputField>
            <BInputField
                type="password"
                label={t('Nouveau mot de passe', { ns: 'attributes' })}
                id="password"
                name="password"
                value={formData.password.value}
                onInput={setNewPassword}
                placeholder={t('Votre nouveau mot de passe', { ns: 'attributes' })}
                parent={formState}
            ></BInputField>
            <BInputField
                type="password"
                label={t('Confirmer le nouveau mot de passe', { ns: 'attributes' })}
                id="password_confirmation"
                name="password_confirmation"
                value={formData.password_confirmation.value}
                onInput={setPasswordConfirmation}
                parent={formState}
                placeholder={t('Confirmer votre nouveau mot de passe', { ns: 'attributes' })}
            ></BInputField>
            <fieldset className="form-mention">
                <a
                    href="javascript:;"
                    onClick={onForgotPassword}
                    aria-disabled={isRequestingCode}
                    className="link text-success d-flex gap-1"
                >
                    <b>{t('forgot_password', { ns: 'forgot-password' })}</b>
                    {isRequestingCode && (
                        <span className="flex-none" style={{ width: 10 }}>
                            <Spinner color="#FFF" />
                        </span>
                    )}
                </a>
            </fieldset>
            <Button
                label={t('save', { ns: 'actions' })}
                icon="security"
                disabled={isLoading || isRequestingCode}
                loading={isLoading}
                className="mt-3 py-1"
            ></Button>
        </Form>
    );
}

export default ResetPassword;
