import React, { useEffect, useState } from 'react';
import Modal from '@components/ui/modal';
import { useModal } from '@contexts/global-modal-context';
import './tips.css';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import images from '../../assets/base64.json';
import Colors from '../../constants/colors';

type LanguateType = 'fr' | 'en';
type TipsType = { [x in LanguateType]: string[] };
type TipsProps = {
    isFirstUsage?: boolean;
};
function TipsModal(props: TipsProps): React.ReactElement<TipsProps> {
    const { hideModal } = useModal();
    const { t } = useTranslation(['tips']);
    const [index, setIndex] = useState<number>(0);
    const [tips, setTips] = useState<string[]>([]);
    const language: LanguateType = (Cookies.get('i18next') as LanguateType) || 'fr';
    const { isFirstUsage } = props;

    const handleClose = () => {
        hideModal();
    };

    const allTricks: TipsType = {
        fr: [
            `Tu peux <em>créer une partie</em> dans l'application en cliquant sur l'icone <img alt="" src='${images.create_part_button}'/> en bas à droite de l'application`,
            `Tu peux <em>changer la langue</em> de l'application en cliquant sur l'icône <img alt="" src='${images.setting_icon}'/> dans le menu de l'application`,
            `Tu peux <em>activer ou désactiver</em> les effets sonnores dans l'application en cliquant sur l'icône <img alt="" src='${images.setting_icon}'/> dans le menu de l'application`,
            `En cas de souci, ou si tu as besoin d'aide, tu peux <a href="https://wa.me/237694351158">contacter un(e) assistant(e)</a> en cliquant sur le lien : <a href="https://wa.me/237694351158">https://wa.me/237694351158</a> ou sur un lien <em>WhatsApp</em> dans le chat de l'application.`,
            `Pour accéder au menu de l'application tu peux cliquer sur le bouton <img alt="" src='${images.game_menu_button}'/> situé à l'entête de l'application ou <em>glisser ton doigt</em> sur l'écran de <em>la gauche vers la droite</em>.`,
            `Au début de la partie tu peux gagner par balle arrêtée <em>(Carré d'as, Tiya, Couleur, 333, 777)</em> en cliquant sur le bouton <img alt="" src='${images.stopped_balls_button}'/> et ensuite confirmer l'action.`,
            `Tu peux cliquer sur <em>Astuces</em> situé dans le menu de l'application pour consulter un <em>ensemble d'astuces</em> qui vont te permettre d'avoir une meilleure expérience dans <em>l'application</em>`,
            `Pendant la partie, tu peux <em>réserver une carte</em> en cliquant sur la carte avant que ce soit ton tour de jouer.`,
            `Tu peux <a href="https://t.me/joinchat/Q_vnw1XQAuSmaa-hShNTCQ">rejoindre la communauté</a> des joueurs en cliquant sur le lien <em>Telegram</em> présent dans le chat de l'application.`,
            `L'icône rouge <img alt="" src='${images.wifi_icon}'/> qui apparaît en haut de l'application signifie que tu n'es pas connecté au serveur de la plateforme.`,
            `Pendant la partie, tu peux voir les informations en cliquant sur le bouton d'actions <img alt="" src='${images.game_menu_button}'/>, puis sur le bouton d'infos <img alt="" src='${images.game_info_button}'/>`,
            `Tu peux activer ou désactiver <em>le vibreur</em> en cliquant sur <em>Paramètres</em> dans le menu de l'application`,
            `Pendant la partie, le jeton vert <img alt="" src='${images.player_token}'/> indique qui doit jouer et le jeton rouge <img alt="" src='${images.control_token}'/> indique qui a le contrôle.`,
            `Pendant la partie, le pseudo de celui qui a dégagé est de <em>couleur verte</em> sur le tatami et blanche dans l'historique du jeu.`,
            `Celui qui dégage hier (partie précédente), ne dégage pas aujourd'hui (partie actuelle).`,
            `Tu peux créer des parties de mise <em>0 F</em> pour apprendre à jouer ou pour te distraire sans parier.`,
            `Si tu as des problèmes de connexion pendant la partie, tu peux fermer l'application en arrière plan et relancer rapidement pour continuer à jouer tranquillement.`,
            `Si tu as oublié ton mot de passe, tu peux en créer un nouveau en cliquant sur <em>'Modifier mon profil'</em> dans le menu, puis <em>'Mot de passe oublié ?'</em>.`,
            `Si tu te banques sans jouer ta deuxième carte, tu ne paies pas le korat.`,
            `Si tu soupçonnes une complicité entre tes adversaires, tu peux les signaler à la fin de la partie en cliquant sur <em>'Signaler un problème'</em>.`,
        ],
        en: [
            `You can <em>create a game</em> in the application by clicking on the icon <img alt="" src='${images.create_part_button}'/> at the bottom right of the application`,
            `You can <em>change the language</em> of the application by clicking on the icon <img alt="" src='${images.setting_icon}'/> in the application menu`,
            `You can <em>enable or disable</em> sound effects in the application by clicking on the icon <img alt="" src='${images.setting_icon}'/> in the application menu`,
            `If you have any concerns, or need help, you can <a href="https://wa.me/237694351158">contact an assistant</a> by clicking on the link: <a href="https://wa.me/237694351158">https://wa.me/237694351158</a> or on a <em>WhatsApp</em> link in the app chat.`,
            `To access the application menu you can click on the <img alt="" src='${images.game_menu_button}'/> button located at the application header or <em>slide your finger</em> across the screen from <em>left to right</em>.`,
            `At the beginning of the game you can win by stopped ball <em>(Ace Square, Tiya, Color, 333, 777)</em> by clicking the <img alt="" src='${images.stopped_balls_button}'/> button and then confirm the action.`,
            `You can click on <em>Tips</em> located in the app menu to check out a <em>set of tips</em> that will help you have a better experience in <em>the app</em>`,
            `During the game you can <em>reserve a card</em> by clicking on the card before it is your turn to play.`,
            `You can <a href="https://t.me/joinchat/Q_vnw1XQAuSmaa-hShNTCQ">join the player community</a> by clicking on the <em>Telegram</em> link present in the app's chat.`,
            `The red <img alt="" src='${images.wifi_icon}'/> icon that appears at the top of the application means that you are not connected to the platform server.`,
            `During the game, you can see the information by clicking on the actions button <img alt="" src='${images.game_menu_button}'/>, then on the info button <img alt="" src='${images.game_info_button}'/>`,
            `You can turn <em>the vibrator</em> on or off by clicking <em>Settings</em> in the app menu`,
            `During the game, the green token <img alt="" src='${images.player_token}'/> indicates who should play and the red token <img alt="" src='${images.control_token}'/> indicates who is in control`,
            `During the game, the nickname of the one who cleared is <em>green color</em> on the tatami and white in the game history.`,
            `Whoever clears yesterday (previous game), does not clear today (current game)`,
            `You can create <em>0 F</em> bet games to learn how to play or to entertain yourself without betting`,
            `If you have connection problems during the game, you can close the application in the background and quickly restart to continue playing quietly`,
            `If you forgot your password, you can create a new one by clicking on <em>'Edit my profile'</em> in the menu, then <em>'Forgot password?'</em>.`,
            `If you bank without playing your second card, you do not pay the korat.`,
            `If you suspect collusion between your opponents, you can report them at the end of the game by clicking on <em>'Report a problem'</em>.`,
        ],
    };

    const hasNext = () => {
        return index < tips.length - 1;
    };

    const hasPrevious = () => {
        return index > 0;
    };

    const handleNext = () => {
        setIndex(Math.min(index + 1, tips.length - 1));
    };

    const handlePrevious = () => {
        setIndex(Math.max(index - 1, 0));
    };

    const _setSelectedTricks = () => {
        let selected_tricks;
        const _allTricks: string[] = [...allTricks[language]];
        if (isFirstUsage === true) {
            selected_tricks = _allTricks.splice(0, 5);
        } else {
            selected_tricks = _allTricks;
        }
        setTips(selected_tricks);
        setIndex(0);
    };

    useEffect(() => {
        _setSelectedTricks();
    }, []);

    useEffect(() => {
        _setSelectedTricks();
    }, [language]);

    return (
        <Modal show={true} id="party-modal" title={t('title')}>
            <div className="form-container tips">
                <div className="container-fluid px-0 px-0 w-100">
                    <div className="row">
                        <div className={`col-12 tab active`} id="tab">
                            <div className="document-side d-flex align-items-center">
                                <div
                                    className="text-center"
                                    dangerouslySetInnerHTML={{
                                        __html: tips[index],
                                    }}
                                ></div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center gap-2 mb-3">
                        <strong style={{ color: Colors.primaryColor }}>
                            {index < 9 ? '0' : ''} {index + 1}
                        </strong>
                        <strong className="text-white">/ {tips.length}</strong>
                    </div>
                    <div className="separator"></div>
                    <div className="actions justify-content-center d-flex">
                        {hasPrevious() && (
                            <button className="button danger" onClick={handlePrevious}>
                                {t('previous', { ns: 'actions' })}
                            </button>
                        )}

                        {hasNext() && (
                            <button className="button default" onClick={handleNext}>
                                {t('next', { ns: 'actions' })}
                            </button>
                        )}

                        {!hasNext() && (
                            <button className="button default" onClick={handleClose}>
                                {t('close', { ns: 'actions' })}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default TipsModal;
