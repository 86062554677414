import React, { FormEvent, useEffect, useState } from 'react';
import InputField from '@components/ui/input';
import Form from '@components/ui/form';
import Button from '@components/ui/button';
import { FormState } from '@reducers/form-reducer';
import * as Auth from '@models/auth';
import { useToast } from '@hooks/use-toast';
import { useAuth } from '@hooks/use-auth';
import { Link, useNavigate } from 'react-router-dom';
import Select from '@components/ui/select';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { FormDataType } from '@ctypes/form-data';
import AuthService from '@services/auth-service';
import { UserToken } from '@models/user-token.model';
import appService from '@services/app-service';
import { useLocalStorage } from '@hooks/use-local-storage';
import { AppSettings } from '@models/app-settings.model';
import { __APP_SETTINGS_STORE__ } from '@constants/storage';
import { DEFAULT_ROUTE } from '@config/app';
import { useSideBar } from '@hooks/use-sidebar';
import { SidebarType } from '@enums/sidebar.enum';

function SigninForm(): React.ReactElement {
    const [pseudo, setPseudo] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [language, setLanguage] = useState<string>(Cookies.get('i18next') || 'fr');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [_, appSettings] = useLocalStorage<AppSettings | null>(__APP_SETTINGS_STORE__);
    const { setSideBar } = useSideBar();

    const [loginData, setLoginData] = useState<FormDataType>({
        pseudo: { value: pseudo },
        password: { value: password },
        language: { value: language },
    });
    const toast = useToast();
    const { login, authed } = useAuth();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation(['signin']);
    const languages = [
        {
            code: 'fr',
            value: 'fr',
            label: t('Français', { ns: 'common' }),
        },
        {
            code: 'gb',
            value: 'en',
            label: t('Anglais', { ns: 'common' }),
        },
    ];

    useEffect(() => {
        setLoginData({
            pseudo: { value: pseudo },
            password: { value: password },
        });
    }, [pseudo, password]);

    const onSubmit = (signinData: any) => {
        const data: Auth.LoginData = {
            ...signinData,
            language: signinData.language,
        };
        setIsLoading(true);
        AuthService.signin(data)
            .then((loginData: UserToken) => {
                const { user, secret } = loginData;
                toast?.success(t('Bienvenue', { ns: 'common', pseudo: user.pseudo }));
                const currentTimestamp = Date.now();
                appService.getServerStatus().then((params) => {
                    appSettings(params);
                });
                login({
                    secret: secret,
                    logged_at: currentTimestamp,
                    user: user,
                });
                setSideBar(SidebarType.DASHBOARD);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    const signinHandler = (_state: FormState, event: FormEvent) => {
        event.preventDefault();
        onSubmit({ pseudo, password });
    };

    useEffect(() => {
        if (authed) {
            navigate(DEFAULT_ROUTE);
        }
    }, [authed]);

    const onChangeLanguage = (value: string) => {
        i18n.changeLanguage(value);
        Cookies.set('i18next', value);
        setLanguage(value);
    };

    return (
        <Form onSubmit={signinHandler} data={loginData} method="POST">
            <Select
                type="lang"
                id="language"
                onChange={onChangeLanguage}
                value={language}
                valueKey="value"
                name="language"
                label={t('language', { ns: 'settings' })}
                placeholder={t('Sélectionner une langue', { ns: 'common' })}
                options={languages}
            ></Select>
            <InputField
                type="text"
                label={t('Pseudo', { ns: 'attributes' })}
                id="pseudo"
                name="pseudo"
                placeholder={t('Votre pseudo')}
                value={pseudo}
                onInput={setPseudo}
            ></InputField>
            <InputField
                type="password"
                label={t('Mot de passe', { ns: 'attributes' })}
                id="password"
                name="password"
                placeholder={t('Votre mot de passe', { ns: 'attributes' })}
                value={password}
                onInput={setPassword}
            ></InputField>
            <fieldset className="form-mention">
                <Link to="/auth/forgot-password">{t("J'ai oublié mon mot de passe")}</Link>
            </fieldset>
            <Button
                label={t('Me connecter à mon compte')}
                disabled={isLoading}
                loading={isLoading}
                icon="account-plus"
            ></Button>
        </Form>
    );
}

export default SigninForm;
