import React, { useEffect, useState } from 'react';

import Modal from '@components/ui/modal';
import { useModal } from '@contexts/global-modal-context';
import './affiliate.css';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import { Alert } from '@utils/alert';
import { useTranslation } from 'react-i18next';
import { FAILED, LOADING, SUCCESSFUL } from '@models/status';
import Spinner from '@components/ui/spinner';
import { getReferredUsers, resetState } from '@redux/reducers/referral-reducer';
import Date from '@utils/date';
import ReferralUser from '@components/widgets/referral-user';

const ACTIVE_TAB = 'ACTIVE_TAB';
const INACTIVE_TAB = 'INACTIVE_TAB';
type TAB = typeof ACTIVE_TAB | typeof INACTIVE_TAB;

function AffiliateModal(): React.ReactElement {
    const { hideModal } = useModal();
    const [tab, setTab] = useState<TAB>(ACTIVE_TAB);
    const { status, error, users } = useSelector((state: any) => state.referralStore);
    const dispatch = useDispatch();
    const { t } = useTranslation(['referral']);
    const handleClose = () => {
        hideModal();
    };

    const handleShowActive = () => {
        setTab(ACTIVE_TAB);
    };

    const handleShowInactive = () => {
        setTab(INACTIVE_TAB);
    };

    useEffect(() => {
        if (status === FAILED) {
            Alert.alert(t('global.oops'), error);
        }
    }, [status, error]);

    useEffect(() => {
        dispatch(getReferredUsers());

        return () => {
            dispatch(resetState());
        };
    }, []);

    const _dispalyTotal = (key: string) => (users ? users[key]?.length || 0 : '');
    const _compareUsersFunc = (a: any, b: any) => (new Date(a.last_connection) >= new Date(b.last_connection) ? 1 : -1);

    return (
        <Modal show={true} id="party-modal" title={t('Filleuls')}>
            <div className="form-container">
                <div className="container-fluid px-0 px-0 w-100">
                    <div className="row">
                        <div
                            className={'col-6 term-policy-link ' + (tab === ACTIVE_TAB ? 'active' : '')}
                            data-target="terms"
                        >
                            <h5 onClick={handleShowActive}>
                                {t('Actifs')} ({_dispalyTotal('active')})
                            </h5>
                            <div className="separator"></div>
                        </div>
                        <div
                            className={'col-6 term-policy-link ' + (tab === INACTIVE_TAB ? 'active' : '')}
                            data-target="policy"
                        >
                            <h5 onClick={handleShowInactive}>
                                {t('Inactifs')} ({_dispalyTotal('inactive')})
                            </h5>
                            <div className="separator"></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className={`col-12 term-policy ${tab === ACTIVE_TAB ? 'active' : ''}`} id="terms">
                            <div className="document-side">
                                <div className="mt-3">
                                    {status === LOADING && (
                                        <div className="spinner-container">
                                            <Spinner />
                                        </div>
                                    )}
                                    {status === SUCCESSFUL && !users.active?.length && (
                                        <p className="text-center">
                                            {t('Aucune donnée à afficher', { ns: 'messages' })}
                                        </p>
                                    )}

                                    {users.active.sort(_compareUsersFunc).map((user: any, index: number) => (
                                        <ReferralUser user={user} key={index} />
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className={`col-12 term-policy ${tab === INACTIVE_TAB ? 'active' : ''}`} id="policy">
                            <div className="document-side pb-3 mt-3">
                                {status === LOADING && (
                                    <div className="spinner-container">
                                        <Spinner />
                                    </div>
                                )}
                                {status === SUCCESSFUL && !users.inactive?.length && (
                                    <p className="text-center">{t('Aucune donnée à afficher', { ns: 'messages' })}</p>
                                )}
                                {users.inactive.sort(_compareUsersFunc).map((user: any, index: number) => (
                                    <ReferralUser user={user} key={index} />
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="separator"></div>
                    <div className="actions justify-content-center">
                        <button className="button default" onClick={handleClose}>
                            {t('close', { ns: 'actions' })}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default AffiliateModal;
