// useCreatePartyModal.ts
import { BlockedUser } from '@models/blocked-user.model';
import { LOADING, SUCCESSFUL } from '@models/status';
import { addGames, removeGame, setGames, setLoading, updateGame } from '@redux/reducers/pending-game-reducer';
import { RootState } from '@redux/store';
import { d } from '@utils/encryption-wrapper';
import { Party } from 'interfaces/party.interface';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

export const usePendingGame = () => {
    const { blocked_gamers } = useSelector((state: RootState) => state.blockGamerStore);
    const dispatch = useDispatch();

    const onPendingGameAdd = (partListData: string) => {
        const partList: Party[] = d(partListData);
        _setPartListData(partList);
    };

    const setPartListData = (games: Party[]) => {
        dispatch(addGames(games));
    };

    const onPendingGameExit = (partIdData: string) => {
        const partyId: string = d(partIdData);
        dispatch(removeGame(partyId));
    };

    const onPendingGameUpdate = (partUpdateData: string) => {
        const partUpdate: Party = d(partUpdateData);
        dispatch(updateGame(partUpdate));
    };

    const _setPartListData = (partList: Party[]) => {
        const newStatePartList = partList.filter((part) => {
            return (
                part.bet === 0 ||
                part.max_nb_players === 1 ||
                !blocked_gamers.some((el: BlockedUser) => el.id === part.creator_id)
            );
        });
        setPartListData(newStatePartList);
    };

    const onPendingGameSocketDisconnect = () => {
        setPartListData([]);
        dispatch(setLoading(LOADING));
    };

    const onPendingGameSocketReconnect = () => {
        dispatch(setLoading(SUCCESSFUL));
    };

    const onGetPendingGames = (data: string) => {
        const partyList: Party[] = d(data);
        dispatch(setGames(partyList));
    };

    return {
        onPendingGameExit,
        onPendingGameUpdate,
        onPendingGameAdd,
        onPendingGameSocketDisconnect,
        onPendingGameSocketReconnect,
        onGetPendingGames,
    };
};
