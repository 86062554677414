import { ContextType } from '@contexts/toast-context';
import Type from '@ctypes/toast';
import { addToast, clearToasts, deleteToast } from '@redux/reducers/toast-reducer';
import { AppDispatch } from '@redux/store';

export let Toast: ContextType;

export default function (dispatch: AppDispatch) {
    const add = (type: Type, message: string) => {
        const id = Math.floor(Math.random() * 10000000);
        dispatch(addToast({ type, message, id }));
        return id;
    };
    // Remove existing toast by his id
    const remove = (id: number) => {
        dispatch(deleteToast(id));
    };

    const clear = () => {
        dispatch(clearToasts());
    };

    Toast = {
        // Publish successfull message
        success: (message: string) => {
            return add('success', message);
        },
        // Publish warning message
        warning: (message: string) => {
            return add('warning', message);
        },
        // Publish information message
        info: (message: string) => {
            return add('info', message);
        },
        // Publish message when errors
        error: (message: string) => {
            return add('error', message);
        },
        remove,
        clear,
    };
}
