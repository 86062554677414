import React from 'react';
import DisplayHtml from '../html';
import { useTranslation } from 'react-i18next';
import logoScreen from '@assets/images/logo192.png';
import useWebInstallPrompt from '@hooks/use-web-install-prompt';
import { PwaProp } from './install-pwa';

function ChomePwaWebInstructions({ onClose }: PwaProp): React.ReactElement<PwaProp> {
    const [, handleWebInstallDeclined] = useWebInstallPrompt();
    const { t } = useTranslation(['pwa']);

    const onAction = () => {
        handleWebInstallDeclined();
        onClose();
    };

    return (
        <div className="mb-3">
            <div className="justify-content-center">
                <h5>{t('title')}</h5>
                <div className="mt-3">
                    <p>
                        <DisplayHtml htmlString={t('chrome_tap')} />
                        <svg
                            width="20px"
                            height="20px"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle cx="12" cy="6" r="1.5" fill="#FFF" />
                            <circle cx="12" cy="12" r="1.5" fill="#FFF" />
                            <circle cx="12" cy="18" r="1.5" fill="#FFF" />
                        </svg>
                        <DisplayHtml htmlString={t('chrome_then')} />
                        <DisplayHtml htmlString={t('chrome_save_and_share')} />
                        <DisplayHtml htmlString={t('chrome_then')} />
                        <img src={logoScreen} height={'20px'} />
                        <DisplayHtml htmlString={t('install_fapfap')} />
                    </p>
                </div>
                <div className="d-flex justify-content-center align-items-center mt-2">
                    <button className="button default mt-2" type="button" onClick={onAction}>
                        {t('close', { ns: 'actions' })}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ChomePwaWebInstructions;
