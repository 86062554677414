import Modal from '@components/ui/modal';
import React, { useEffect, useState } from 'react';
import { useModal } from '@contexts/global-modal-context';
import Spinner from '@components/ui/spinner';
import { useAuth } from '@hooks/use-auth';
import { useToast } from '@hooks/use-toast';
import { useNavigate } from 'react-router-dom';

function Logout(): React.ReactElement {
    //const toast = useToast();
    const { hideModal } = useModal();

    const { session } = useAuth();
    const [process, setProcess] = useState<string>('Déconnexion en cours...');
    const [showModal, setShowModal] = useState<boolean>(true);
    const toast = useToast();
    const navigate = useNavigate();
    const handleCloseModal = () => {
        hideModal();
    };

    useEffect(() => {
        if (session === null) {
            navigate('/auth/signin');
            setShowModal(false);
        }
    }, [session]);

    useEffect(() => {
        toast?.success('Vous êtes maintenant déconnecté.');
        setProcess('Redirection en cours...');
    }, []);

    return (
        <Modal
            show={showModal}
            hideOnAreaClick={false}
            hideHeader={true}
            onClose={handleCloseModal}
            id="logout-modal"
            title="Nouvelle partie"
        >
            <div className="form-container gap-0 text-center d-flex align-items-center flex-column">
                <div className="w-100">
                    <Spinner color="#FFF" speed={100} style={{ height: 100 }} />
                </div>
                <h5 className="mt-3">{process}</h5>
            </div>
        </Modal>
    );
}

export default Logout;
