import http from '@utils/http-common';

/**
 * get Terms and usage
 * @returns
 */
const getTips = () => {
    return http.get<any>('/user/info/view', {
        headers: { Accept: '*/*', 'Content-Type': 'text/html; charset=utf-8' },
    });
};

const TipsService = {
    getTips,
};

export default TipsService;
