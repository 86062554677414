import http from '@utils/http-common';
import APIRoute from '@constants/routes';
/**
 * get Terms and usage
 * @returns
 */
const getTerms = () => {
    return http.get<any>(APIRoute.TERMS, {
        headers: { Accept: '*/*', 'Content-Type': 'text/html; charset=utf-8' },
    });
};

/**
 * Get policy
 * @returns
 */
const getPolicy = () => {
    return http.get<any>(APIRoute.POLICY, {
        headers: { Accept: '*/*', 'Content-Type': 'text/html; charset=utf-8' },
    });
};

const TermsService = {
    getTerms,
    getPolicy,
};

export default TermsService;
