import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Spinner from '@components/ui/spinner';
import Transaction from '@models/transaction';
import './history.css';
import moment from 'moment';
import { MODAL_TYPES, useModal } from '@contexts/global-modal-context';
import { useAuth } from '@hooks/use-auth';
import { ReactComponent as RefreshIcon } from '@assets/icons/outline/refresh.svg';
import { ReactComponent as PlusIcon } from '@assets/icons/outline/plus.svg';
import { ReactComponent as ArrowIcon } from '@assets/icons/outline/arrow-right.svg';
import TransactionService from '@services/transaction-service';

function History(): React.ReactElement {
    const { t } = useTranslation(['transaction']);
    const { showModal } = useModal();
    const { isReferee } = useAuth();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [transactions, setTransactions] = useState<Transaction[]>([]);
    const [hasMore, setHasMore] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);

    useEffect(() => {
        _getTransactions(isReferee);
    }, []);

    const _getTransactions = (isReferee: boolean, page = 1, onSeeMore = false) => {
        setIsLoading(true);
        TransactionService.getTransactions(isReferee, page)
            .then((response) => {
                setIsLoading(false);
                setPage(page);
                if (!onSeeMore) {
                    setTransactions(response.history);
                } else {
                    setTransactions([...transactions, ...response.history]);
                }
                setHasMore(response.more);
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    const onShowHistoryDetails = (transaction: Transaction) => {
        if (transaction.type === 'Game') {
            showModal(MODAL_TYPES.GAME_HISTORY_DETAILS, { transaction });
        }
    };

    const onSeeMore = () => {
        _getTransactions(isReferee, page + 1, true);
    };

    const onRefresh = () => {
        _getTransactions(isReferee);
    };

    return (
        <div className="list-page-content w-100 history-list">
            <div className="page-intro w-100">
                <h2 className="page-title">{t('history_menu')}</h2>
            </div>
            {!isLoading && transactions?.length > 0 && (
                <div className="page-section">
                    <table className="history-table">
                        <tbody>
                            {transactions.map((transaction: Transaction) => (
                                <tr
                                    className={`${transaction.amount >= 0 ? 'msg-success' : 'msg-danger'}`}
                                    key={transaction.id}
                                    onClick={() => onShowHistoryDetails(transaction)}
                                >
                                    <td>
                                        <p>{moment(transaction.performed_at).format('DD/MM/YYYY')}</p>
                                        <p>{moment(transaction.performed_at).format('HH:mm:ss')}</p>
                                    </td>
                                    <td>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <span>
                                                <b className="uppercase">{transaction.operation}</b> <br /> Solde:{' '}
                                                {transaction.balance}
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <p className={`${transaction.amount >= 0 ? 'text-success' : 'text-danger'}`}>
                                            <b className="uppercase d-flex justify-content-end align-items-center gap-1 ">
                                                {transaction.amount} F
                                                {transaction.type === 'Game' && (
                                                    <span className="icon icon12 text-success">
                                                        <ArrowIcon />
                                                    </span>
                                                )}
                                            </b>
                                        </p>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
            {isLoading && (
                <div className="page-section mobile-padding-security mt-4">
                    <div className="d-flex align-items-center widget info-card large opaced create-game-card  text-center">
                        <p className="text-center">{t('Chargement en cours...')}</p>
                        <span className="flex-none" style={{ width: 100 }}>
                            <Spinner color="#FFF" />
                        </span>
                    </div>
                </div>
            )}
            {!isLoading && !transactions?.length && (
                <div className="page-section mobile-padding-security">
                    <div className="d-flex align-items-center widget info-card large opaced create-game-card  text-center">
                        <p className="text-center">{t('La liste est vide...')}</p>
                    </div>
                </div>
            )}
            {!isLoading && (
                <div className="d-flex justify-content-center gap-2">
                    <button className="w-auto success text-center d-flex justify-content-center " onClick={onRefresh}>
                        {t('refresh', { ns: 'actions' })}
                        <span className="icon icon12">
                            <RefreshIcon />
                        </span>
                    </button>
                    {hasMore && (
                        <button
                            className="w-auto default text-center d-flex justify-content-center "
                            onClick={onSeeMore}
                        >
                            {t('see_more', { ns: 'actions' })}
                            <span className="icon icon12">
                                <PlusIcon />
                            </span>
                        </button>
                    )}
                </div>
            )}
        </div>
    );
}

export default History;
