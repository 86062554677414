import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useAuth } from '@hooks/use-auth';
import { resetState } from '@redux/reducers/verify-phone-reducer';
import Modal from '@components/ui/modal';
import { SUCCESSFUL } from '@models/status';
import RequestCodeForm from './request-code-form';
import VerifyCodeForm from './verify-code-form';
import { useTranslation } from 'react-i18next';
import { RootState } from '@redux/store';

type Props = {
    currentStep: any;
};

const VIEWS: any = {
    REQUEST_CODE: RequestCodeForm,
    VERIFY_CODE: VerifyCodeForm,
};

const ValidatePhoneNumberComponent = ({ currentStep }: Props) => {
    if (VIEWS.hasOwnProperty(currentStep)) {
        const ModalComponent = VIEWS[currentStep];
        return <ModalComponent />;
    }
    return <></>;
};

function ValidatePhoneNumber(): React.ReactElement {
    const { user } = useAuth();
    const dispatch = useDispatch();
    const { t } = useTranslation(['deposit']);
    // Get the posts from the store
    const { step: currentStep, status } = useSelector((state: RootState) => state.verifyPhoneStore);

    useEffect(() => {
        if (status && status === SUCCESSFUL) {
            dispatch(resetState());
        }
    });

    return (
        <Modal show={true} id="party-modal" title={t('Vérification du numéro')}>
            <div className="form-container">
                <div className="container-fluid px-0 px-0 w-100">
                    {user?.verified && (
                        <div className="row">
                            <div className="col-12 alert alert-success d-flex justify-content-between align-items-center">
                                <svg
                                    className="svg-success toggle"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 64 64"
                                    style={{ height: '30px' }}
                                >
                                    <circle
                                        className="solid"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeWidth="4"
                                        strokeMiterlimit="10"
                                        cx="32"
                                        cy="32"
                                        r="30"
                                    />
                                    <circle
                                        className="animation"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeWidth="4"
                                        strokeMiterlimit="10"
                                        cx="32"
                                        cy="32"
                                        r="30"
                                    />
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="6"
                                        fill="none"
                                        stroke="#000"
                                        d="M47.91 23.39L26.7 44.61 16.09 34"
                                    />
                                </svg>
                                <p>{t('Numéro vérifié')}</p>
                            </div>
                        </div>
                    )}
                    {!user?.verified && <ValidatePhoneNumberComponent currentStep={currentStep} />}
                </div>
            </div>
        </Modal>
    );
}

export default ValidatePhoneNumber;
