import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import TransactionService from '@services/transaction-service';
import { FAILED, LOADING, SUCCESSFUL, EMPTY } from '@models/status';
import ConfirmDeposit from '@models/confirm-deposit';
export const SMS_CODED_SEND = 'SMS_CODE_SEND';
export const FIREBASE_REQUEST_CODE = 'FIREBASE_REQUEST_CODE';
export const FIREBASE_VERIFY_CODE = 'FIREBASE_VERIFY_CODE';
export const FIREBASE_PHONE_VERIFIED = 'FIREBASE_PHONE_VERIFIED';
export const UNKNOWN = 'UNKNOWN';

type DepositState = {
    data: any;
    status: typeof FAILED | typeof LOADING | typeof SUCCESSFUL | typeof EMPTY | typeof SMS_CODED_SEND;
    error: any;
};

const initialState: DepositState = {
    data: {},
    status: EMPTY,
    error: null,
};

// Handle POST request to create a new post
export const confirmDeposit: any = createAsyncThunk(
    // The name of the action
    'transaction/confirm-deposit',
    // The payload creator
    async (data: ConfirmDeposit, { rejectWithValue }) => {
        try {
            const res = await TransactionService.confirmDeposit(data);
            console.log('========================RES', res);
            return {
                ...res,
                ...data,
            };
        } catch (err: any) {
            return rejectWithValue({ error: err.message });
        }
    },
);

const confirmDepositSlice = createSlice({
    /* The name of the slice[this will also be used as the action type string 
      in combination with the extraReducer name i.e posts/getPosts or posts/addPost] 
    */
    name: 'confirm-deposit',
    // initialState: initialState[ES6 destructuring syntax]
    initialState,
    // Add reducers for the synchronous actions on the UI[we are not using this property for this tutorial]
    reducers: {},
    // Add extraReducers for the asynchronous actions on the UI
    extraReducers: {
        [confirmDeposit.pending]: (state) => {
            // When data is being fetched
            state.status = LOADING;
        },
        [confirmDeposit.fulfilled]: (state, action: any) => {
            // When data is fetched successfully
            state.status = SUCCESSFUL;
            // Concatenate the new data to the existing data in the array
            state.data = action.payload;
        },
        [confirmDeposit.rejected]: (state, action) => {
            // When data is fetched unsuccessfully
            state.status = FAILED;

            // Update the error message for proper error handling
            state.error = action.error.message;
        },
    },
});
export default confirmDepositSlice.reducer;
