import React from 'react';
import flattenDeep from 'lodash/flattenDeep';
import { Route, Routes as ReactRoutes } from 'react-router-dom';
import { ProtectedRoute } from '@routes/protected';
import { LayoutProps } from '@ctypes/layout-props';

export interface RouteProps {
    isAuthorized: boolean;
    isLogged?: boolean;
}

export interface IRoute {
    path?: string;
    component?: React.FC;
    title: string;
    isPublic?: boolean;
    name: string;
    routes?: IRoute[];
    hasSiderLink?: boolean;
}

export interface IRoutes {
    layout: React.FunctionComponent<LayoutProps>;
    sidebar?: React.FunctionComponent;
    routes: IRoute[];
}

const generateFlattenRoutes = (routes: IRoute[]): any[] => {
    if (!routes) return [];
    return flattenDeep(routes.map(({ routes: subRoutes, ...rest }: any) => [rest, generateFlattenRoutes(subRoutes)]));
};

export const renderRoutes = (mainRoutes: IRoutes[]) => {
    const Routes = ({ isAuthorized, isLogged }: RouteProps): React.ReactElement => {
        const layouts = mainRoutes.map(({ layout: Layout, sidebar: Sidebar, routes }: IRoutes, index: number) => {
            const subRoutes = generateFlattenRoutes(routes);
            return (
                <Route key={index} element={<Layout sidebar={Sidebar} />}>
                    <Route element={<ProtectedRoute isLogged={isLogged} isAuthorized={isAuthorized} isPublic={true} />}>
                        {subRoutes
                            .filter(({ isPublic }) => isPublic)
                            .map(({ component: Component, path, name }: IRoute) => {
                                return Component && path && <Route key={name} path={path} element={<Component />} />;
                            })}
                    </Route>
                    <Route
                        element={<ProtectedRoute isLogged={isLogged} isAuthorized={isAuthorized} isPublic={false} />}
                    >
                        {subRoutes
                            .filter(({ isPublic }) => !isPublic)
                            .map(({ component: Component, path, name }: IRoute) => {
                                return Component && path && <Route key={name} path={path} element={<Component />} />;
                            })}
                    </Route>
                </Route>
            );
        });
        return <ReactRoutes>{layouts}</ReactRoutes>;
    };

    return Routes;
};
