import http from '@utils/http-common';
import APIRoute from '@constants/routes';
import { Tournament } from 'interfaces/tournament.interface';
import Leaderboard from 'interfaces/leaderboard';

export default {
    /**
     * get All tournament for the current page
     * @returns
     */
    getTournaments(page = 1) {
        return http.get<any, Tournament[]>(APIRoute.LIST_TOURNAMENTS + '?page=' + page);
    },

    /**
     * Join a tournament
     * @returns
     */
    joinTournament(tournamentId: string) {
        return http.post<any, boolean>(APIRoute.JOIN_TOURNAMENT, { tournament_id: tournamentId });
    },

    /**
     * Join a tournament
     * @returns
     */
    showTournament(tournamentId: string) {
        return http.get<any, Tournament>(APIRoute.SHOW_TOURNAMENT + '?tournament_id=' + tournamentId);
    },

    /**
     * Join a tournament
     * @returns
     */
    quitTournament(tournamentId: string) {
        return http.post<any, boolean>(APIRoute.QUIT_TOURNAMENT, { tournament_id: tournamentId });
    },

    /**
     * Join a tournament
     * @returns
     */
    getActiveLeaderboard() {
        return http.get<any, Leaderboard>(APIRoute.ACTIVE_LEADERBOARD);
    },
};
