import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Modal from '@components/ui/modal';
import { MODAL_TYPES, useModal } from '@contexts/global-modal-context';
import { useSelector } from 'react-redux';
import { LOADING, SUCCESSFUL, FAILED } from '@models/status';
import './referral.css';
import Spinner from '@components/ui/spinner';
import { useTranslation } from 'react-i18next';
import { getReferralStats } from '@redux/reducers/referral-reducer';
import { resetState } from '@redux/reducers/referral-reducer';
import { useAuth } from '@hooks/use-auth';
import { isPartner } from '@utils/index';
import { useLocalStorage } from '@hooks/use-local-storage';
import { getServerParams } from '@redux/reducers/app-reducer';

function ReferralModal(): React.ReactElement {
    const dispatch = useDispatch();
    const { hideModal } = useModal();
    const { status, stats } = useSelector((state: any) => state.referralStore);
    const { server_params } = useSelector((state: any) => state.appStore);
    const { t } = useTranslation(['referral']);
    const { showModal } = useModal();
    const [params, setPrams] = useLocalStorage<any>('params');
    const { user } = useAuth();
    const [text, setText] = useState<string>('');
    const [link, setLink] = useState<string>('');

    const handleDisburse = () => {
        if (isPartner(user)) {
        } else {
            window.location.href = link;
        }
    };

    useEffect(() => {
        dispatch(getReferralStats());

        return () => {
            dispatch(resetState());
        };
    }, []);

    useEffect(() => {
        if (!params?.contacts) {
            dispatch(getServerParams());
        } else {
            console.log('=================================', params);
            setLink(`https://wa.me/237${params.contacts.daySupport}?text=${text}`);
        }
    }, [params]);

    useEffect(() => {
        if (server_params) {
            setPrams(server_params);
        }
    }, [server_params]);

    useEffect(() => {
        if (isPartner(user)) {
            setText(t('Yo katika', { ns: 'messages' }));
        } else {
            setText(t('Avoir mon code'));
        }
    }, [user]);

    const handleReferral = () => {
        if (isPartner(user)) {
            showModal(MODAL_TYPES.AFFILIATE_MODAL, {});
        } else {
            hideModal();
        }
    };

    return (
        <Modal show={true} id="party-modal" title={t('Parrainage')}>
            <div className="form-container">
                <div className="container-fluid px-0 px-0 w-100">
                    <div className="row">
                        {isPartner(user) && (
                            <div className={`col-12 tab active`} id="tab">
                                <div className="document-side">
                                    {status === LOADING && (
                                        <div className="spinner-container">
                                            <Spinner />
                                        </div>
                                    )}
                                    {status === SUCCESSFUL && (
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>{t('Libelé')}</th>
                                                    <th>{t('Valeur')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {stats.map((item: any, index: number) => (
                                                    <tr key={index}>
                                                        <td>{item.label}</td>
                                                        <th>{item.value}</th>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    )}

                                    {status === FAILED && <p className="oups">{t('Oups')}</p>}
                                </div>
                            </div>
                        )}
                        {!isPartner(user) && (
                            <div className={`col-12 tab active`} id="tab">
                                <div className="document-side">
                                    <p className="mt-3">{t('nonEligible', { ns: 'messages' })}</p>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="separator"></div>
                    <div className="actions justify-content-center">
                        <button className="button default opaced" onClick={handleReferral}>
                            {isPartner(user) ? t('Filleul') : t('close', { ns: 'actions' })}
                        </button>
                        <button className="button default" onClick={handleDisburse}>
                            {isPartner(user) ? t('Decaisser', { ns: 'attributes' }) : t('Obtenir le code')}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default ReferralModal;
