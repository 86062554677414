export const __SIGNUP_DATA__ = '__signup__';
export const __SESSION_STORE__ = '__session__';
export const __BLOCK_LIST_STORE__ = '__blocklist__';
export const __APP_SETTINGS_STORE__ = '__app__';
export const __PREFERENCE_STORE__ = '__settings__';
export const __SIDEBAR_STORE__ = '__sidebar__';
export const __INFOS__ = '__infos__';
export const __SURVEY__ = '__surveys__';
export const __SHOW_TRICKS__ = '__show_tricks__';
export const __RELEASE_NOTES_VIEWED__ = '__release_notes__';
export const __FORGOT_PASSWORD__ = '__forgot_password__';
export const __RETRIES__ = '__retries__';
export const __COUNTER__ = '__counter__';
export const __FIRST_TIME__ = '__is_first_time__';
export const __PREVIOUS_PARTY_DATA__ = '__previous_partie_data__';
