import React, { useState } from 'react';
import SvgIcon from '@components/ui/svg-icon';
import { __SIGNUP_DATA__ } from '@constants/storage';
import ConfirmPasswordForm from '@features/signup/confirm-password-form';
import { useLocalStorage } from '@hooks/use-local-storage';
import { resetSignupForm } from '@redux/reducers/signup-reducer';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { RegisterData } from '@models/auth';

function ConfirmPassword(): React.ReactElement {
    const { t } = useTranslation(['confirm-password']);
    const dispatch = useDispatch();
    const [session] = useLocalStorage<RegisterData | null>(__SIGNUP_DATA__);

    useState(() => {
        dispatch(resetSignupForm());
    });

    return (
        <div className="section full-page">
            <div className="container grid-full auth-screen">
                <div className="form-side">
                    <div className="form-container colored">
                        <div className="form-header">
                            <div className="form-icon">
                                <SvgIcon name="security-special" />
                            </div>
                            <h5 className="form-subtitle">{t('other_infos')}</h5>
                            <p className="form-description">
                                {t('other_infos_hint')}
                                {session?.phone_number}.
                            </p>
                        </div>
                        <ConfirmPasswordForm />
                    </div>
                </div>
                <div className="auth-scene-side">
                    <div className="auth-scene-content">
                        <div className="scene-title">
                            <h3>{t('Devenez le top Korateur')}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ConfirmPassword;
