// useCreatePartyModal.ts
import { d } from '@utils/encryption-wrapper';
import { useAuth } from './use-auth';
import { useTranslation } from 'react-i18next';
import { useToast } from './use-toast';
import { formatAmount } from '@utils/helpers';
import { User } from '@models/user';

export const useBalance = () => {
    const { user, updateUser } = useAuth();
    const { t } = useTranslation(['global']);
    const toast = useToast();

    const onUpdateBalance = (balance: string) => {
        const newBalance: number = d(balance);
        const oldBalance: number = user?.balance || 0;
        if ((newBalance || newBalance === 0) && newBalance !== oldBalance) {
            let amount = newBalance - oldBalance;
            let operation = t('balance_credited');
            if (amount < 0) {
                amount = -amount;
                operation = t('balance_debited');
            }
            toast?.info(`${t('balance_update', { operation, amount })}`, 15000);
            if (user) {
                const newUser: User = { ...user, balance: newBalance };
                updateUser(newUser);
            }
        }
    };

    const onUpdateBonus = (bonus: string) => {
        const newBonus: number = d(bonus);
        const oldBonus: number = user?.bonus || 0;
        if ((newBonus || newBonus === 0) && newBonus !== oldBonus) {
            let amount: number = newBonus - oldBonus;
            if (amount < 0) {
                amount = -amount;
            }
            toast?.info(t('bonus_update', { amount: formatAmount(amount) }), 15000);
            if (user) {
                const newUser: User = { ...user, bonus: newBonus };
                updateUser(newUser);
            }
        }
    };

    return {
        onUpdateBalance,
        onUpdateBonus,
    };
};
