import http from '@utils/http-common';

/**
 * get Terms and usage
 * @returns
 */
const getRules = () => {
    return http.get<any, string>('/res/rules', {
        headers: { Accept: '*/*', 'Content-Type': 'text/html; charset=utf-8' },
    });
};

const RulesService = {
    getRules,
};

export default RulesService;
