import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { EMPTY, FAILED, LOADING, SUCCESSFUL } from '@models/status';
import AppService from '@services/app-service';
import { AppSettings } from '@models/app-settings.model';
import { AppVersion } from '@models/app-version.model';

export type AppSettingState = {
    server_params: AppSettings | null;
    connectedUserCount?: number;
    version?: AppVersion;
    status: typeof EMPTY | typeof FAILED | typeof LOADING | typeof SUCCESSFUL;
    error: any;
};

const initialState: AppSettingState = {
    status: EMPTY,
    error: null,
    server_params: null,
    connectedUserCount: 0,
    version: undefined,
};

// Handle POST request to create a new post
export const getServerParams: any = createAsyncThunk(
    // The name of the action
    'app/status',
    // The payload creator
    async (_, { rejectWithValue }) => {
        try {
            return await AppService.getServerStatus();
        } catch (err: any) {
            return rejectWithValue({ error: err.message });
        }
    },
);

const appSlice = createSlice({
    /* The name of the slice[this will also be used as the action type string 
      in combination with the extraReducer name i.e posts/getPosts or posts/addPost] 
    */
    name: 'app',
    // initialState: initialState[ES6 destructuring syntax]
    initialState,
    // Add reducers for the synchronous actions on the UI[we are not using this property for this tutorial]
    reducers: {
        resetState: (state) => {
            state.status = initialState.status;
            state.error = initialState.error;
            state.server_params = initialState.server_params;
        },
        setConnectedUserCount: (state, action) => {
            state.connectedUserCount = action.payload;
        },
        setVersion: (state, { payload }: PayloadAction<AppVersion>) => {
            state.version = payload;
        },
    },
    // Add extraReducers for the asynchronous actions on the UI
    extraReducers: {
        [getServerParams.pending]: (state) => {
            // When data is being fetched
            state.status = LOADING;
        },
        [getServerParams.fulfilled]: (state, action: any) => {
            // When data is fetched successfully
            state.status = SUCCESSFUL;

            // Concatenate the new data to the existing data in the array
            state.server_params = action.payload;
        },
        [getServerParams.rejected]: (state, action) => {
            // When data is fetched unsuccessfully
            state.status = FAILED;

            // Update the error message for proper error handling
            state.error = action.error.message;
        },
    },
});

export const { resetState, setConnectedUserCount, setVersion } = appSlice.actions;

export default appSlice.reducer;
