import http from '@utils/http-common';
import APIRoutes from '@constants/routes';
import { ReferalStat } from '@models/referal-stat.model';
import { ReferalUsers } from '@models/referal-users.model';

export default {
    /**
     * get Terms and usage
     * @returns
     */
    getStats() {
        return http.get<any, ReferalStat[]>(APIRoutes.REFERRAL_STATS);
    },
    getRefferedUsers() {
        return http.get<any, ReferalUsers>(APIRoutes.REFERRED_USERS);
    },
};
