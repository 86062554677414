import Avatar1 from '@assets/default-avatar/1.png';
import Avatar2 from '@assets/default-avatar/2.png';
import Avatar3 from '@assets/default-avatar/3.png';
import Avatar4 from '@assets/default-avatar/4.png';
import Avatar8 from '@assets/default-avatar/8.png';
import Avatar22 from '@assets/default-avatar/22.png';

const Avatars: any = {
    1: Avatar1,
    2: Avatar2,
    3: Avatar3,
    4: Avatar4,
    8: Avatar8,
    22: Avatar22,
};

export const getRandomAvatar = (index?: number) => {
    if (index != undefined) {
        return Avatars[index];
    }
    const keys = Object.keys(Avatars);
    const randomKey = keys[Math.floor(Math.random() * keys.length)];
    return Avatars[randomKey];
};

export default Avatars;
