import Modal from '@components/ui/modal';
import React from 'react';
import { useModal } from '@contexts/global-modal-context';
import { useTranslation } from 'react-i18next';
import awardSrc from '@assets/images/sad.png';
import { formatAmount } from '@utils/helpers';
import { useAuth } from '@hooks/use-auth';
import DisplayHtml from '@components/ui/html';

type LoserModalProps = {
    winType: string;
    winner: string;
    loss: number;
    onReportProblem: () => void;
    onQuitGame: () => void;
    onReplayGame: () => void;
};

function LoserModal(props: LoserModalProps): React.ReactElement {
    const { hideModal } = useModal();
    const { t } = useTranslation(['game']);
    const { isReferee } = useAuth();
    const handleCloseModal = () => {
        hideModal();
    };

    const reportProblem = () => {
        hideModal();
        props.onReportProblem();
    };

    const handleQuitTheParty = () => {
        hideModal();
        props.onQuitGame();
    };

    /*const handleReplayTheParty = () => {
        props.onReplayGame();
    };*/

    return (
        <Modal show={true} onClose={handleCloseModal} id="loser-modal" title="">
            <div className="form-container d-flex flex-column justify-content-center align-items-center">
                <div className="d-flex justify-content-center align-items-center">
                    <img src={awardSrc} alt="" height={100} />
                </div>
                <h4 className="p-0 m-0 text-center text-white">
                    <DisplayHtml htmlString={t('winner_message', { winner: props.winner })} />
                    {props.winType && props.winType !== 'Normal' ? ` par ${props.winType}` : ''}.
                </h4>
                {!isReferee && (
                    <p className="p-0 m-0 text-center">
                        <DisplayHtml htmlString={t('loss_amount', { amount: formatAmount(props.loss, true) })} />
                        {props.winType && props.winType !== 'Normal' ? ` par ${props.winType}` : ''}.
                    </p>
                )}
                <div className="actions">
                    <button type="button" onClick={reportProblem} className="opaced d-flex justify-content-center">
                        {t('report_problem', { ns: 'actions' })}
                    </button>
                </div>
                <div className="actions">
                    <button
                        type="button"
                        className="danger d-flex justify-content-center "
                        onClick={handleQuitTheParty}
                        data-modal="modal-two"
                    >
                        {t('quit', { ns: 'actions' })}
                    </button>
                    {/* <button
                        type="button"
                        className="default switch-modal-trigger"
                        onClick={handleReplayTheParty}
                        data-modal="modal-two"
                    >
                        {t('replay', { ns: 'actions' })}
                    </button> */}
                </div>
            </div>
        </Modal>
    );
}

export default LoserModal;
