import React from 'react';
import { Outlet } from 'react-router-dom';
import useBodyClass from '@hooks/body-class';
import '@assets/fonts/styles.css';
import '@assets/css/style.css';
import { Link } from 'react-router-dom';
// Notice here we're using the function declaration with the interface Props
const AppLayout: React.FunctionComponent = () => {
    useBodyClass('page-light');
    return (
        <>
            <div className="fixed" id="topbar">
                <div className="container-fluid top-bar logo-auth">
                    <Link className="logo-side" to="/">
                        <span className="logo"></span>
                    </Link>
                </div>
            </div>
            <Outlet />
        </>
    );
};

export default AppLayout;
