import { Validator, ValidatorValue } from './common';

export const PasswordValidator: Validator = {
    passed: (_, value: ValidatorValue, password: ValidatorValue): boolean => {
        if (!value && (password === null || password === undefined)) {
            return true;
        }
        if (password !== undefined && password !== null) {
            return (password + '').length > 4;
        }
        return false;
    },
    message: 'Le mot de passe est invalide.',
};
