import Modal from '@components/ui/modal';
import React from 'react';
import { useModal } from '@contexts/global-modal-context';
import { useTranslation } from 'react-i18next';
import Icon from '@components/ui/icon';
import './game-infos-modal.css';
import defaultAvatar from '@assets/default-avatar/9.png';
import { Game } from 'interfaces/game.interface';
import { GamePlayerInfo } from 'interfaces/game-player.interface';

type GameInfoModalProps = {
    game: Game;
};

function GameInfoModal(props: GameInfoModalProps): React.ReactElement {
    const { hideModal } = useModal();
    const { t } = useTranslation(['party']);
    const { game } = props;

    const onClose = () => {
        hideModal();
    };

    return (
        <Modal show={true} onClose={hideModal} id="party-modal" title="Partie en attente...">
            <div className="form-container">
                <div>
                    <fieldset className="d-flex flex-row align-items-center gap-4 py-1">
                        <Icon name={'bet'} className="awaiting-game-icon"></Icon>
                        <div className="d-flex align-items-center gap-3 awaiting-g-bet">
                            <strong>{game.part.bet} XAF</strong>
                            <span className="notif-badge">{game.part.korat ? 'K x2' : ''}</span>
                            <span className="notif-badge">{game.part.double_korat ? '2K x4' : ''}</span>
                        </div>
                    </fieldset>
                    <fieldset className="d-flex flex-row align-items-center gap-4 py-1">
                        <Icon name={'users'} className="awaiting-game-icon"></Icon>
                        <div className="d-flex align-items-center gap-1 awaiting-g-bet">
                            <strong>{game.players.length} adversaire(s) max</strong>
                        </div>
                    </fieldset>
                    <fieldset className="d-flex flex-row align-items-center gap-4 py-1">
                        <Icon name={'timer'} className="awaiting-game-icon"></Icon>
                        <div className="d-flex align-items-center gap-1 awaiting-g-bet">
                            <strong>{game.part.time_out}s par coup</strong>
                        </div>
                    </fieldset>
                    {game.part.special_mode && (
                        <fieldset className="d-flex flex-row align-items-center gap-4 py-1">
                            <Icon name={'settings'} className="awaiting-game-icon"></Icon>
                            <div className="d-flex align-items-center gap-1 awaiting-g-bet">
                                <strong>{t('stopped_balls_list', { ns: 'game' })}</strong>
                            </div>
                        </fieldset>
                    )}
                </div>
                <div className="separator light"></div>
                {game.players.map((player: GamePlayerInfo) => {
                    return (
                        <div className="creator-infos" key={player.id}>
                            <div className="user-avatar">
                                <img src={defaultAvatar} alt="" />
                            </div>
                            <span className="user-name">
                                <b>{player.pseudo}</b>
                            </span>
                        </div>
                    );
                })}
                <div className="actions">
                    <button
                        type="button"
                        className="default switch-modal-trigger"
                        onClick={onClose}
                        data-modal="modal-two"
                    >
                        {t('close', { ns: 'actions' })}
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default GameInfoModal;
