import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { FAILED, LOADING, SUCCESSFUL, EMPTY } from '@models/status';
import TipsService from '@services/tips-service';
const initialState = {
    tips: null,
    error: null,
    status: EMPTY,
};

// Handle POST request to create a new post
export const getTips: any = createAsyncThunk(
    // The name of the action
    'user/tips',
    // The payload creator
    async (_, { rejectWithValue }) => {
        try {
            return await TipsService.getTips();
        } catch (err: any) {
            return rejectWithValue({ error: err.message });
        }
    },
);

const tipsSlice = createSlice({
    /* The name of the slice[this will also be used as the action type string 
      in combination with the extraReducer name i.e posts/getPosts or posts/addPost] 
    */
    name: 'rules',
    // initialState: initialState[ES6 destructuring syntax]
    initialState,
    // Add reducers for the synchronous actions on the UI[we are not using this property for this tutorial]
    reducers: {},
    // Add extraReducers for the asynchronous actions on the UI
    extraReducers: {
        [getTips.pending]: (state) => {
            // When data is being fetched
            state.status = LOADING;
        },
        [getTips.fulfilled]: (state, action: any) => {
            // When data is fetched successfully
            state.status = SUCCESSFUL;

            // Concatenate the new data to the existing data in the array
            state.tips = action.payload;
        },
        [getTips.rejected]: (state, action) => {
            // When data is fetched unsuccessfully
            state.status = FAILED;

            // Update the error message for proper error handling
            state.error = action.error.message;
        },
    },
});
export default tipsSlice.reducer;
