import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AuthService from '@services/auth-service';
import * as Auth from '@models/auth';
import { FAILED, LOADING, SUCCESSFUL, CODE_SEND_SUCCESSFUL } from '@models/status';
const initialState = {
    data: {},
    status: 'idle',
    error: null,
};

// Handle POST request to create a new post
export const verifyOTP: any = createAsyncThunk(
    // The name of the action
    'auth/opt-verification',
    // The payload creator
    async (data: Auth.OtpVerificationData, { rejectWithValue }) => {
        try {
            return await AuthService.verifyOTP(data);
        } catch (err: any) {
            return rejectWithValue({ error: err.message });
        }
    },
);

export const resendOTPCode: any = createAsyncThunk(
    // The name of the action
    'auth/opt-resend-code',
    // The payload creator
    async (data: Auth.PasswordRequestCodeData, { rejectWithValue }) => {
        try {
            return await AuthService.resendOTPCode(data);
        } catch (err: any) {
            return rejectWithValue({ error: err.message });
        }
    },
);

const otpSlice = createSlice({
    /* The name of the slice[this will also be used as the action type string 
      in combination with the extraReducer name i.e posts/getPosts or posts/addPost] 
    */
    name: 'optverification',
    // initialState: initialState[ES6 destructuring syntax]
    initialState,
    // Add reducers for the synchronous actions on the UI[we are not using this property for this tutorial]
    reducers: {},
    // Add extraReducers for the asynchronous actions on the UI
    extraReducers: {
        [verifyOTP.pending]: (state: any) => {
            // When data is being fetched
            state.status = LOADING;
            state.error = null;
        },
        [verifyOTP.fulfilled]: (state: any, action: any) => {
            // When data is fetched successfully
            state.status = SUCCESSFUL;

            // Concatenate the new data to the existing data in the array
            state.data = action.payload;
            state.error = null;
        },
        [verifyOTP.rejected]: (state: any, action: any) => {
            // When data is fetched unsuccessfully
            state.status = FAILED;

            // Update the error message for proper error handling
            state.error = action.error.message;
        },
        [resendOTPCode.pending]: (state: any) => {
            // When data is being fetched
            state.status = LOADING;
            state.error = null;
        },
        [resendOTPCode.fulfilled]: (state: any, action: any) => {
            // When data is fetched successfully
            state.status = CODE_SEND_SUCCESSFUL;

            // Concatenate the new data to the existing data in the array
            state.data = action.payload;
            state.error = null;
        },
        [resendOTPCode.rejected]: (state: any, action: any) => {
            // When data is fetched unsuccessfully
            state.status = FAILED;

            // Update the error message for proper error handling
            state.error = action.error.message;
        },
    },
});

export default otpSlice.reducer;
