export const SUCCESSFUL = 'successful';
export const LOADING = 'loading';
export const FAILED = 'failed';
export const EMPTY = 'idle';
export const RESET_SUCCESSFUL = 'RESET_SUCCESSFUL';
export const CODE_SEND_SUCCESSFUL = 'CODE_SEND_SUCCESSFUL';

export type StateStatus =
    | typeof SUCCESSFUL
    | typeof LOADING
    | typeof FAILED
    | typeof EMPTY
    | typeof RESET_SUCCESSFUL
    | typeof CODE_SEND_SUCCESSFUL;
