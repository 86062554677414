// useCreatePartyModal.ts
import { d } from '@utils/encryption-wrapper';
import { useModal } from '@contexts/global-modal-context';
import { Game } from 'interfaces/game.interface';
import { useDispatch } from 'react-redux';
import { setGame } from '@redux/reducers/game-reducer';
import { useNavigate } from 'react-router-dom';
import { Alert } from '@utils/alert';
import { useTranslation } from 'react-i18next';
import { useSettings } from './use-settings';

export const useGame = () => {
    const { hideModal } = useModal();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation(['global']);
    const { onVibrate } = useSettings();

    const onStartGame = (data: string) => {
        const game: Game = d(data);
        hideModal();
        dispatch(setGame(game));
        onVibrate();
        navigate(`/apps/game/paying/${game.part.id}`);
    };

    const onGameCanceled = () => {
        Alert.alert(t('inf_label', { ns: 'alert' }), t('game_cancelled', { ns: 'game' }));
    };

    const onResumeGame = (data: string) => {
        onStartGame(data);
    };

    const onReceiveCards = (data: string) => {
        data = d(data);
        dispatch(setGame(data));
    };

    const onReplayGame = (data: string) => {
        setTimeout(() => {
            onStartGame(data);
        }, 10);
    };

    return {
        onGameCanceled,
        onResumeGame,
        onReceiveCards,
        onStartGame,
        onReplayGame,
    };
};
