import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SidebarType } from '@enums/sidebar.enum';

export type SideBarState = {
    sidebar: SidebarType;
};

const initialState: SideBarState = {
    sidebar: SidebarType.DASHBOARD,
};

const sideBarSlice = createSlice({
    name: 'app/sidebar',
    initialState,
    reducers: {
        setSideBar: (state, { payload }: PayloadAction<SidebarType>) => {
            state.sidebar = payload;
        },
    },
    extraReducers: {},
});

export const { setSideBar } = sideBarSlice.actions;

export default sideBarSlice.reducer;
