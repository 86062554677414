const tintColor = '#2f95dc';

export default {
    tintColor,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColor,
    tabBar: '#fefefe',
    statusBar: '#149138',
    errorBackground: 'red',
    errorText: '#fff',
    warningBackground: '#EAEB5E',
    warningText: '#666804',
    warningMedium: '#b0bf1a',
    noticeBackground: tintColor,
    noticeText: '#fff',
    primaryColor: '#0DC342',
    primaryColorAlpha(alpha: number) {
        return `rgba(13, 195, 66, ${alpha})`;
    },
    darkPrimaryColor: '#079B38',
    dangerColor: '#C30D4B',
    linkColor: '#5A7AFF',
    linkColorLight: '#9FB3FF',
    disabledLinkColor: '#999999',
    partItemBackgroundColor: '#c7ffd7',
    partItemSecondaryColor: '#EEE',
    partItemCompetitionColor: '#e4ed7e',
    defaultIconColor: '#757575',
    activeBarIconColor: '#ffffff',
    inactiveBarIconColor: '#80d89b',
    playerGameColor: '#c7ffd7',
    darkColor: '#444',
    lightGray: '#ccc',
    defaultTextColor: '#000',
    cellBackColor: 'transparent',
    cellHeaderBackColor: '#80d89b',
    cellSelectedBackColor: '#000',
    cellHeaderColor: '#149138',
    modalBackdrop: 'rgba(0, 0, 0, 0.7)',
    drawerInactiveTint: 'rgba(0,0,0,0.75)',
    fromString(str: string) {
        if (str === 'FapFap') {
            return this.primaryColor;
        }

        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }

        const color = (hash & 0x00ffffff).toString(16).toUpperCase();

        return '#' + '00000'.substring(0, 6 - color.length) + color;
    },
};
