import { FormRequest } from './common';

export default class SettingFormRequest extends FormRequest {
    protected rules = {
        language: [
            {
                required: true,
                message: 'La langue est requise',
            },
        ],
    };
}
