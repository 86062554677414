export const twoDigits = (_int: number) => {
    return _int < 10 ? '0' + _int : _int;
};

export const isSimplePlayer = function (user: any) {
    return user && user.roles && user.roles.length == 0;
};

export const isReferee = function (user: any) {
    return user && user.roles && user.roles.includes('Referee');
};

export const isPartner = function (user: any) {
    return user && user.roles && user.roles.includes('Partner');
};

export const hasMadeFirstDeposit = function (user: any) {
    return user && user.madeFirstDeposit;
};
