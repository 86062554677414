import { CardIndex } from '@constants/cards';
import { Message } from '@models/message.model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GamePlayerInfo } from 'interfaces/game-player.interface';
import { Game } from 'interfaces/game.interface';
import { Party } from 'interfaces/party.interface';

type PlayerCard = {
    card: CardIndex;
    active?: boolean;
};

export type GameType = {
    game?: Game;
    oldGame?: Game;
    gameToReplay?: Party;
    currentPlayer?: GamePlayerInfo;
    opponentPlayers: GamePlayerInfo[];
    tableOfCards: {
        [key: string]: PlayerCard;
    };
    plaryerCards: PlayerCard[];
    user?: any;
    currentPlayerIndex: number;
    isPlayerReferer: boolean;
    isTimerActive: boolean;
    messages: Message[];
};

const initialState: GameType = {
    game: undefined,
    gameToReplay: undefined,
    oldGame: undefined,
    tableOfCards: {},
    plaryerCards: [{ card: 11 }, { card: 11 }, { card: 11 }, { card: 11 }, { card: 11 }],
    opponentPlayers: [],
    currentPlayer: undefined,
    currentPlayerIndex: 0,
    isPlayerReferer: false,
    isTimerActive: false,
    messages: [],
};

type PlayCardAction = {
    card: CardIndex;
    player: GamePlayerInfo | null | undefined;
};
const gameSlice = createSlice({
    /* The name of the slice[this will also be used as the action type string 
      in combination with the extraReducer name i.e posts/getPosts or posts/addPost] 
    */
    name: 'game',
    // initialState: initialState[ES6 destructuring syntax]
    initialState,
    // Add reducers for the synchronous actions on the UI[we are not using this property for this tutorial]
    reducers: {
        addChatMessage: (state, { payload }: PayloadAction<Message>) => {
            state.messages = [...state.messages, payload];
        },
        setPlayerUser: (state, action) => {
            state.user = action.payload;
        },
        setGameTimerStatus: (state, { payload }: PayloadAction<boolean>) => {
            state.isTimerActive = payload;
        },
        setGame: (state, action) => {
            state.game = action.payload;
            state.opponentPlayers = state.game?.players.filter((p) => p.id !== state.user.id) || [];
            const player = state.game?.players.find((p) => p.id === state.user.id);

            state.currentPlayer = player;
            const isReferer = state.user?.roles?.includes('Referee');

            if (!isReferer) {
                const index = state.game?.players.findIndex((player) => player.id === state.user?.id);
                state.currentPlayerIndex = index || 0;
            } else {
                state.currentPlayerIndex = 0;
            }
        },
        setGameToReplay: (state, action) => {
            state.gameToReplay = action.payload;
        },
        resetGame: (state) => {
            state.game = initialState.game;
            state.tableOfCards = initialState.tableOfCards;
            state.plaryerCards = initialState.plaryerCards;
            state.currentPlayer = initialState.currentPlayer;
            state.currentPlayerIndex = initialState.currentPlayerIndex;
            state.isTimerActive = initialState.isTimerActive;
            state.opponentPlayers = initialState.opponentPlayers;
        },
        playCard: (state, { payload }: PayloadAction<PlayCardAction>) => {
            if (payload?.player?.id) {
                state.tableOfCards[payload.player.id] = { card: payload.card };
                const cards = [...state.plaryerCards];
                cards.splice(
                    cards.findIndex((e) => (e.card = payload.card)),
                    1,
                );
            }
        },
    },
    // Add extraReducers for the asynchronous actions on the UI
    extraReducers: {},
});
export const { setGame, playCard, resetGame, setPlayerUser, setGameTimerStatus, setGameToReplay, addChatMessage } =
    gameSlice.actions;
export default gameSlice.reducer;
