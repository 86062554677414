import { ActionType, Callback } from '@components/modals/alert/alert';
import Swal from 'sweetalert2';
import i18n from 'i18next';
const translate = i18n.t;

export interface AlertType {
    alert(title: string, message: string, actions?: ActionType[] | Callback | null, options?: any): void;
    success(title: string, message: string, actions?: ActionType[] | Callback | null, options?: any): void;
    warning(title: string, message: string, actions?: ActionType[] | Callback | null, options?: any): void;
    info(title: string, message: string, actions?: ActionType[] | Callback | null, options?: any): void;
    confirm(title: string, message: string, actions?: ActionType[] | Callback | null, options?: any): void;
    error(title: string, message: string, actions?: ActionType[] | Callback | null, options?: any): void;
}

type IconType = 'error' | 'question' | 'success' | 'info';

const showAlert = (
    icon: IconType,
    title: string,
    message: string,
    actions?: ActionType[] | Callback | null,
    options?: any,
) => {
    const hasCancelBtn = actions && Array.isArray(actions) && actions.length > 1;
    Swal.fire({
        title: title || 'Error!',
        text: message,
        icon: icon,
        showCloseButton: true,
        showCancelButton: !!hasCancelBtn,
        focusConfirm: false,
        confirmButtonText:
            Array.isArray(actions) && actions.length > 1
                ? actions[1].text
                : icon === 'question'
                ? translate('yes', { ns: 'messages' })
                : 'OK',
        cancelButtonText: hasCancelBtn ? actions[0].text : '',
    }).then((result: { isConfirmed: boolean; isDenied: boolean }) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            if (typeof actions === 'function') {
                actions();
            } else if (Array.isArray(actions) && actions.length > 0) {
                if (hasCancelBtn && actions[1].onPress) {
                    actions[1].onPress();
                } else if (!options?.cancelable && actions.length > 0 && actions[0].onPress) {
                    actions[0].onPress();
                }
            }
        } else if (result.isDenied) {
            if (Array.isArray(actions) && actions.length > 0 && actions[0].onPress) {
                actions[0].onPress();
            }
        }
    });
};
export const Alert: AlertType = {
    alert: (title: string, message: string, actions?: ActionType[] | Callback | null, options?: any) => {
        showAlert('error', title, message, actions, options);
    },
    success: (title: string, message: string, actions?: ActionType[] | Callback | null, options?: any) => {
        showAlert('success', title, message, actions, options);
    },
    // Publish warning message
    warning: (title: string, message: string, actions?: ActionType[] | Callback | null, options?: any) => {
        showAlert('error', title, message, actions, options);
    },
    // Publish information message
    info: (title: string, message: string, actions?: ActionType[] | Callback | null, options?: any) => {
        showAlert('info', title, message, actions, options);
    },
    confirm: (title: string, message: string, actions?: ActionType[] | Callback | null, options?: any) => {
        showAlert('question', title, message, actions, options);
    },
    // Publish message when errors
    error: (title: string, message: string, actions?: ActionType[] | Callback | null, options?: any) => {
        showAlert('error', title, message, actions, options);
    },
};
