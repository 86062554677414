import React from 'react';
import '@assets/fonts/styles.css';
import '@assets/css/style.css';
import { SidebarType } from '@enums/sidebar.enum';
import DashboardSidebar from '../dashboard-sidebar';
import ProfileSidebar from '../profile-sidebar';
import { useSideBar } from '@hooks/use-sidebar';

// Notice here we're using the function declaration with the interface Props
const DefaultSidebar: React.FunctionComponent = () => {
    const { sidebar } = useSideBar();

    if (sidebar == SidebarType.PROFILE) {
        return <ProfileSidebar />;
    } else {
        return <DashboardSidebar />;
    }
};

export default DefaultSidebar;
