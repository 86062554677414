import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    opened: false,
};

const menuSlice = createSlice({
    /* The name of the slice[this will also be used as the action type string 
      in combination with the extraReducer name i.e posts/getPosts or posts/addPost] 
    */
    name: 'menu',
    // initialState: initialState[ES6 destructuring syntax]
    initialState,
    // Add reducers for the synchronous actions on the UI[we are not using this property for this tutorial]
    reducers: {
        toggleMenu: (state) => {
            state.opened = !state.opened;
        },
        hideMenu: (state) => {
            state.opened = false;
        },
    },
    // Add extraReducers for the asynchronous actions on the UI
    extraReducers: {},
});
export const { toggleMenu, hideMenu } = menuSlice.actions;
export default menuSlice.reducer;
