import { useCallback, useReducer } from 'react';
import formReducer, { formChange, FormState, setFormInputs } from '@reducers/form-reducer';
import { JSONSimpleValue } from '@ctypes/json-value';
import { FormDataType } from '@ctypes/form-data';
import { FormRequest } from '@requests/common';

export const useForm = (
    initialInput: FormDataType,
    validator?: FormRequest,
): [FormState, (id: string, value: JSONSimpleValue) => void, (inputsData: FormDataType) => void] => {
    const [formState, dispatch] = useReducer(formReducer, {
        inputs: initialInput,
        data: {},
        changed: {},
        old: {},
        validator: validator,
    });

    const inputHandler = useCallback((id: string, value: JSONSimpleValue) => {
        dispatch(formChange({ inputId: id, inputValue: value }));
    }, []);

    const setFormData = useCallback((inputsData: FormDataType) => {
        dispatch(setFormInputs({ inputs: inputsData }));
    }, []);

    return [formState, inputHandler, setFormData];
};
