import { Validator, ValidatorValue } from './common';

export const EmailValidator: Validator = {
    passed: (_, value: ValidatorValue, email: ValidatorValue): boolean => {
        if (value === false && (email === undefined || email === null)) {
            return true;
        }
        const regex =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return value !== null && value !== undefined && regex.test(email as string);
    },
    message: 'Le champ {attribute} est requis.',
};
