import { inputChange, inputTouched } from '@redux/reducers/input-reducer';
import React, { useEffect, useState, useReducer } from 'react';
import { FormDataState } from '@reducers/form-reducer';
import inputReducer from '@reducers/input-reducer';
import './checkbox.css';
export type InputType = 'text' | 'password' | 'email' | 'tel' | 'button' | 'date' | 'number';

export interface Props {
    id: string;
    blackTheme?: boolean;
    name: string;
    label: string;
    value?: string | number | boolean | undefined;
    children?: React.ReactNode;
    parent?: FormDataState;
    onInput?(name: string, value: string | boolean | number | undefined): void;
    onChange?(checked: boolean): void;
    disabled?: boolean;
    checked?: boolean;
    align?: 'left' | 'right';
    labelClassName?: string;
}

export type Ref = HTMLInputElement;
// Notice here we're using the function declaration with the interface Props
const CheckboxField = React.forwardRef<Ref, Props>(
    (
        {
            id,
            name,
            checked,
            label,
            labelClassName,
            blackTheme,
            onChange,
            align,
            onInput,
            value,
            parent,
            disabled,
        }: Props,
        ref: any,
    ) => {
        //Creating compoent state
        const defaultChecked = checked ? checked : false;
        const defaultValue = value ? value : defaultChecked;
        const isDefaultValue = value && (value + '').length !== 0;
        const [error, setError] = useState('');
        const [focused, setFocused] = useState(false);
        const [inputState, dispatch] = useReducer(inputReducer, { value: '' });
        const { value: inputValue, touched } = inputState;
        const [isChecked, setIsChecked] = useState(defaultChecked);

        // Initialize the input field with the default value
        useEffect(() => {
            dispatch(inputChange({ val: defaultChecked ? defaultValue : '' }));
        }, []);

        useEffect(() => {
            onInput ? onInput(name, inputValue) : {};
        }, [name, inputValue, onInput]);

        useEffect(() => {
            if (parent && parent.inputs[name] && parent.inputs[name].errors !== undefined) {
                const errors = parent.inputs[name].errors;
                if (errors && errors.hasError) {
                    setError(errors.first ?? '');
                    return;
                }
            }
            setError('');
        }, [parent]);

        useEffect(() => {
            onChange ? onChange(isChecked) : {};
            dispatch(inputChange({ val: isDefaultValue ? defaultValue : isChecked }));
        }, [isChecked]);

        useEffect(() => {
            if (typeof checked === 'boolean') {
                setIsChecked(checked);
            }
        }, [checked]);

        const changeHandler = () => {
            setIsChecked((prev) => !prev);
        };

        const focusHandler = () => {
            setFocused(!focused);
            dispatch(inputTouched(true));
        };

        return (
            <fieldset className={`checkbox-wrapper ${blackTheme ? 'black-theme' : ''}`}>
                <div className="checkbox-label">
                    {align === 'left' && (
                        <label htmlFor={id} className={` ${labelClassName ?? ''} `}>
                            {label}
                        </label>
                    )}
                    <input
                        type="checkbox"
                        className={` ${error && touched ? 'invalid' : ''}`}
                        disabled={disabled}
                        ref={ref}
                        name={name}
                        value={typeof inputValue == 'boolean' ? inputValue + '' : inputValue}
                        checked={isChecked}
                        onChange={changeHandler}
                        onFocus={focusHandler}
                    />
                    {align !== 'left' && (
                        <label htmlFor={id} className={` ${labelClassName ?? ''} `}>
                            {label}
                        </label>
                    )}
                </div>
                {error && touched && <strong className="invalid">{error}</strong>}
            </fieldset>
        );
    },
);
CheckboxField.displayName = 'CheckboxField';
CheckboxField.defaultProps = {
    disabled: false,
    checked: false,
};
export default CheckboxField;
