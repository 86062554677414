import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '@components/ui/footer';
import Header from '@components/ui/header';
import useBodyClass from '@hooks/body-class';
import '@assets/fonts/styles.css';
import '@assets/css/style.css';

// Notice here we're using the function declaration with the interface Props
const MainLayout: React.FunctionComponent = () => {
    useBodyClass('page-dark');
    return (
        <>
            <Header></Header>
            <Outlet />
            <Footer></Footer>
        </>
    );
};

export default MainLayout;
