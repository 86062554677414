import { Validator, ValidatorValue } from './common';

export const CheckedValidator: Validator = {
    passed: (_, ruleValue: ValidatorValue, value: ValidatorValue): boolean => {
        if (value === null || value === undefined) {
            return false;
        }
        if (typeof value === 'number' && typeof ruleValue === 'number') {
            return ruleValue === value;
        }

        return value === true;
    },
    message: 'Le champ {attribute}  est invalide.',
};
