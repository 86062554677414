import Modal from '@components/ui/modal';
import React, { ChangeEvent, useState } from 'react';
import { useModal } from '@contexts/global-modal-context';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '@redux/store';
import Checkbox from '@components/ui/checkbox';
import { GamePlayerInfo } from 'interfaces/game-player.interface';
import { Alert } from '@utils/alert';
import gamerService from '@services/gamer-service';
import { useDispatch } from 'react-redux';
import { setBlockedGamers } from '@redux/reducers/block-gamer-reducer';
import gameService from '@services/game-service';
import Button from '@components/ui/button';
import { BlockedUser } from '@models/blocked-user.model';
import { useAuth } from '@hooks/use-auth';

function ReportProblemModal(): React.ReactElement {
    const { game, opponentPlayers } = useSelector((state: RootState) => state.gameStore);
    const [cheaterPlayers, setCheaterPlayers] = useState<string[]>([]);
    const [message, setMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const dispatch = useDispatch();
    const { hideModal } = useModal();
    const { t } = useTranslation(['alert']);
    const { user, updateUser } = useAuth();
    const handleCloseModal = () => {
        hideModal();
    };

    const setCheater = (checked: boolean, player: GamePlayerInfo) => {
        let _cheaterPlayers = cheaterPlayers;
        if (!checked) {
            _cheaterPlayers = cheaterPlayers.filter((cheater_id) => cheater_id !== player.id);
        } else {
            _cheaterPlayers.push(player.id);
        }
        setCheaterPlayers(_cheaterPlayers);
    };

    const onBlockPlayers = () => {
        Alert.confirm(
            t('confirm_label'),
            t('all_block_question'),
            [
                {
                    text: t('no', { ns: 'global' }),
                    onPress: () => null,
                },
                {
                    text: t('yes', { ns: 'global' }),
                    onPress: onBlockPlayersConfirmed,
                },
            ],
            { cancelable: true },
        );
    };

    const updateBlockUsers = (_blockList: BlockedUser[]) => {
        _blockList = _blockList.map((block) => {
            block.block = true;
            return block;
        });
        if (user) {
            updateUser({
                ...user,
                blacklist: _blockList,
            });
        }
        dispatch(setBlockedGamers(_blockList));
    };

    const onBlockPlayersConfirmed = () => {
        if (cheaterPlayers.length > 0) {
            setIsLoading(true);
            gamerService
                .blockPlayers(cheaterPlayers)
                .then((block_list: BlockedUser[]) => {
                    setIsLoading(false);
                    updateBlockUsers(block_list);
                    hideModal();
                    Alert.alert(t('success_label'), t('report_block_problem_success'));
                })
                .catch((error) => {
                    setIsLoading(false);
                    Alert.alert(t('oops', { ns: 'global' }), error.message);
                });
        } else {
            Alert.alert(t('oops', { ns: 'global' }), t('report_players_required'));
        }
    };

    const onReportProblem = () => {
        if (game?.part.id && (cheaterPlayers.length > 0 || message.trim() != '')) {
            setIsLoading(true);
            gameService
                .reportProblem(game?.part.id, cheaterPlayers, message)
                .then(() => {
                    setIsLoading(false);
                    hideModal();
                    Alert.alert(t('success_label'), t('report_problem_success'));
                })
                .catch((error) => {
                    setIsLoading(false);
                    Alert.alert(t('oops', { ns: 'global' }), error.message);
                });
        } else {
            Alert.alert(t('oops', { ns: 'global' }), t('report_reason_players_required'));
        }
    };

    const inputMessageHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(event.target.value);
    };

    return (
        <Modal
            show={true}
            onClose={handleCloseModal}
            id="loser-modal"
            title={t('block_title', { ns: 'report-problem' })}
        >
            <div className="form-container d-flex flex-column justify-content-center align-items-center">
                <p className="m-0 p-0">{t('hint', { ns: 'report-problem' })}</p>
                {Array.from(opponentPlayers).map((player) => (
                    <div key={player.id} className="d-flex align-items-center justify-content-between w-100">
                        <Checkbox
                            onChange={(checked) => setCheater(checked, player)}
                            id={'gamer-' + player.id}
                            name="silence"
                            label={player.pseudo}
                            checked={cheaterPlayers.includes(player.id)}
                            blackTheme={true}
                            align="left"
                            labelClassName="usage-condition"
                        />
                    </div>
                ))}
                <fieldset className="input-field w-100">
                    <div className="icon-input">
                        <textarea
                            name="message"
                            id="message"
                            className="w-100"
                            onInput={inputMessageHandler}
                            placeholder={t('reason', { ns: 'forms' })}
                        >
                            {message}
                        </textarea>
                    </div>
                </fieldset>

                <div className="actions">
                    <button type="button" onClick={onBlockPlayers} className="danger d-flex justify-content-center">
                        {t('block_report', { ns: 'actions' })}
                    </button>
                    <Button
                        label={t('report', { ns: 'actions' })}
                        loading={isLoading}
                        disabled={isLoading}
                        type="button"
                        onClick={onReportProblem}
                        className="default d-flex justify-content-center"
                    />
                </div>
            </div>
        </Modal>
    );
}

export default ReportProblemModal;
