import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import {
    _FAPFAP_APP_FIREBASE_API_KEY,
    _FAPFAP_APP_FIREBASE_PROJECT_ID,
    _FAPFAP_APP_FIREBASE_AUTH_DOMAIN,
    _FAPFAP_APP_FIREBASE_APP_ID,
    _FAPFAP_APP_FIREBASE_MESSAGING_SENDER_ID,
    _FAPFAP_APP_FIREBASE_STORAGE_BUCKET,
    _FAPFAP_FIREBASE_MEASUREMENT_ID,
} from '@environments';
import {
    getAuth,
    signInWithPhoneNumber,
    signInWithCredential,
    PhoneAuthProvider,
    updatePhoneNumber,
    User,
    RecaptchaVerifier,
} from 'firebase/auth';
import Storage from '@utils/storage';
import i18n from '@i18n/index';
import { EventEmitter } from './event-emitter';

const firebaseConfig = {
    apiKey: _FAPFAP_APP_FIREBASE_API_KEY,
    authDomain: _FAPFAP_APP_FIREBASE_AUTH_DOMAIN,
    projectId: _FAPFAP_APP_FIREBASE_PROJECT_ID,
    storageBucket: _FAPFAP_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: _FAPFAP_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: _FAPFAP_APP_FIREBASE_APP_ID,
    measurementId: _FAPFAP_FIREBASE_MEASUREMENT_ID,
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const translate = i18n.t;
export { app, analytics };

let unsubscribe: any;

export default {
    getUser() {
        return auth.currentUser;
    },

    onAuthStateChanged(callback: any) {
        unsubscribe = auth.onAuthStateChanged(callback);
    },

    clear() {
        unsubscribe && unsubscribe();
    },

    authPhoneNumber(phoneNumber: string, showProgress = true) {
        if (showProgress) EventEmitter.emit('showProgress');
        const appVerifier = window.recaptchaVerifier;
        return new Promise((resolve, reject) => {
            signInWithPhoneNumber(auth, phoneNumber, appVerifier)
                .then((confirmResult: any) => {
                    Storage.set('firebase', { verificationId: confirmResult.verificationId });
                    resolve(confirmResult);
                })
                .catch((err) => {
                    if (err.code === 'auth/too-many-requests') reject({ message: translate('alert.too_many_attemps') });
                    else reject(err);
                })
                .then(() => showProgress && EventEmitter.emit('closeProgress'));
        });
    },

    signIn(verificationCode: string, showProgress = true) {
        if (showProgress) EventEmitter.emit('showProgress');

        return new Promise((resolve, reject) => {
            Storage.get('firebase')
                .then((firebase: any) => {
                    const credentials = PhoneAuthProvider.credential(firebase.verificationId, verificationCode);
                    signInWithCredential(auth, credentials)
                        .then((userCredential) => resolve(userCredential.user))
                        .catch((err) => {
                            if (err.code === 'auth/invalid-verification-code')
                                reject({ message: translate('alert.invalid_verification_code') });
                            else if (err.code === 'auth/session-expired')
                                reject({
                                    message: translate('alert.expired_session_error', {
                                        action: translate('actions.resend_code'),
                                    }),
                                });
                            else reject(err);
                        })
                        .then(() => showProgress && EventEmitter.emit('closeProgress'));
                })
                .catch((err) => {
                    if (showProgress) EventEmitter.emit('closeProgress');
                    reject(err);
                });
        });
    },

    signOut(showProgress = true) {
        if (showProgress) EventEmitter.emit('showProgress');

        return new Promise((resolve, reject) => {
            auth.signOut()
                .then(resolve)
                .catch(reject)
                .then(() => showProgress && EventEmitter.emit('closeProgress'));
        });
    },

    generateFirebaseRecaptcha() {
        if (!window.recaptchaVerifier) {
            EventEmitter.emit('recaptcha', false);
            window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
                size: 'invisible',
                callback: () => {
                    EventEmitter.emit('recaptcha', true);
                },
            });
            window.recaptchaVerifier.verify();
        }
    },

    verifyPhoneNumber(phoneNumber: string) {
        return new Promise((resolve, reject) => {
            const provider = new PhoneAuthProvider(auth);
            const appVerifier = window.recaptchaVerifier;
            provider.verifyPhoneNumber(phoneNumber, appVerifier).then((snapshot: any) => {
                if (!snapshot) {
                    reject(snapshot);
                }
                EventEmitter.emit('verified', true);
                resolve(snapshot);
            });
        });
    },

    updatePhoneNumber(verificationCode: string, showProgress = true) {
        if (showProgress) EventEmitter.emit('showProgress');

        return new Promise((resolve, reject) => {
            Storage.get('firebase')
                .then((firebase: any) => {
                    const credentials = PhoneAuthProvider.credential(firebase.verificationId, verificationCode);
                    updatePhoneNumber(this.getUser() as User, credentials)
                        .then(resolve)
                        .catch((err: any) => {
                            if (err.code === 'auth/invalid-verification-code')
                                reject({ message: translate('alert.invalid_verification_code') });
                            else reject(err);
                        })
                        .then(() => showProgress && EventEmitter.emit('closeProgress'));
                })
                .catch((err) => {
                    if (showProgress) EventEmitter.emit('closeProgress');
                    reject(err);
                });
        });
    },
};
