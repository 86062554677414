import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FAILED, LOADING, SUCCESSFUL, EMPTY } from '@models/status';
import { Party } from 'interfaces/party.interface';

export type GameType = {
    games: Array<Party>;
    selectedWaitingGame?: Party;
    error: string | null;
    status: typeof FAILED | typeof LOADING | typeof SUCCESSFUL | typeof EMPTY;
};
const initialState: GameType = {
    games: [],
    error: null,
    status: EMPTY,
};

const gamesSlice = createSlice({
    /* The name of the slice[this will also be used as the action type string 
      in combination with the extraReducer name i.e posts/getPosts or posts/addPost] 
    */
    name: 'games',
    // initialState: initialState[ES6 destructuring syntax]
    initialState,
    // Add reducers for the synchronous actions on the UI[we are not using this property for this tutorial]
    reducers: {
        setLoading: (state, action) => {
            state.status = action.payload;
        },
        setGames: (state, { payload }: PayloadAction<Party[]>) => {
            state.games = [...payload];
            state.status = SUCCESSFUL;
        },
        clearGames: (state) => {
            state.games = [];
            state.status = LOADING;
        },
        updateGame: (state, { payload }: PayloadAction<Party>) => {
            state.games = state.games.map((game) => {
                if (game.id === payload.id) {
                    return { ...game, ...payload };
                }
                return game;
            });
            state.status = SUCCESSFUL;
        },
        addGames: (state, { payload }: PayloadAction<Party[]>) => {
            const newStatePartList = state.games.filter(
                (newGame: Party) => payload.findIndex((oldGame: Party) => oldGame.id === newGame.id) < 0,
            );
            state.games = [...newStatePartList, ...payload];
            state.status = SUCCESSFUL;
        },
        removeGame: (state, { payload }: PayloadAction<string>) => {
            state.games = state.games.filter((game: Party) => payload != game.id);
            state.status = SUCCESSFUL;
        },
        removeGames: (state, { payload }: PayloadAction<string[]>) => {
            state.games = state.games.filter((game: Party) => !payload.includes(game.id));
            state.status = SUCCESSFUL;
        },
        setSelectedWaitingGame: (state, action) => {
            state.selectedWaitingGame = action.payload;
        },
    },
    // Add extraReducers for the asynchronous actions on the UI
    extraReducers: {},
});
export const {
    setLoading,
    addGames,
    updateGame,
    removeGame,
    removeGames,
    setGames,
    setSelectedWaitingGame,
    clearGames,
} = gamesSlice.actions;
export default gamesSlice.reducer;
