import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import TransactionService from '@services/transaction-service';
import { FAILED, LOADING, SUCCESSFUL, EMPTY } from '@models/status';
import Transaction from '@models/transaction';
import TransactionList from '@models/transaction-list';

type TransactionQuery = {
    page?: number;
    isReferee: boolean;
    onSeeMore?: boolean;
};

type TransactionState = {
    transactions: Transaction[];
    hasMore: boolean;
    status: typeof FAILED | typeof LOADING | typeof SUCCESSFUL | typeof EMPTY;
    error: any;
    page: number;
};
const initialState: TransactionState = {
    transactions: [],
    status: EMPTY,
    error: null,
    hasMore: false,
    page: 0,
};

// Handle POST request to create a new post
export const getTransactions: any = createAsyncThunk<TransactionList, TransactionQuery>(
    // The name of the action
    'transactions/getTransactions',
    // The payload creator
    async (data: TransactionQuery, { rejectWithValue }) => {
        try {
            const transactionList = await TransactionService.getTransactions(data.isReferee, data.page);
            transactionList.onSeeMore = data.onSeeMore;
            return transactionList;
        } catch (err: any) {
            return rejectWithValue({ error: err.message });
        }
    },
);

const transactionSlice = createSlice({
    /* The name of the slice[this will also be used as the action type string 
      in combination with the extraReducer name i.e posts/getPosts or posts/addPost] 
    */
    name: 'transactions',
    // initialState: initialState[ES6 destructuring syntax]
    initialState,
    // Add reducers for the synchronous actions on the UI[we are not using this property for this tutorial]
    reducers: {},
    // Add extraReducers for the asynchronous actions on the UI
    extraReducers: {
        [getTransactions.pending]: (state) => {
            // When data is being fetched
            state.status = LOADING;
        },
        [getTransactions.fulfilled]: (state, { payload }: PayloadAction<TransactionList>) => {
            // When data is fetched successfully
            state.status = SUCCESSFUL;
            // Concatenate the new data to the existing data in the array
            const transactions = payload.history ?? [];

            state.transactions = payload.onSeeMore ? [...state.transactions, ...transactions] : transactions;

            state.hasMore = payload.more || false;
        },
        [getTransactions.rejected]: (state, action) => {
            // When data is fetched unsuccessfully
            state.status = FAILED;

            // Update the error message for proper error handling
            state.error = action.error.message;
        },
    },
});
export default transactionSlice.reducer;
