import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AuthService from '@services/auth-service';
import * as Auth from '@models/auth';
import { FAILED, LOADING, SUCCESSFUL, EMPTY, RESET_SUCCESSFUL } from '@models/status';
export const REQUEST_STEP = 'request';
export const CHANGE_STEP = 'change';
export type PhoneNumberStep = typeof REQUEST_STEP | typeof CHANGE_STEP;
type PhoneNumberStateType = {
    data: any;
    status: typeof FAILED | typeof LOADING | typeof SUCCESSFUL | typeof EMPTY | typeof RESET_SUCCESSFUL;
    step: PhoneNumberStep;
    error: any;
    phoneNumber: string;
    countryCode: string;
    code: string;
    password?: string;
    otp_channel?: string;
};
const initialState: PhoneNumberStateType = {
    data: {},
    status: EMPTY,
    step: REQUEST_STEP,
    error: null,
    phoneNumber: '',
    countryCode: '',
    code: '',
    password: '',
};

// Handle POST request to create a new post
export const changePhoneNumber: any = createAsyncThunk(
    // The name of the action
    'auth/change-phone-number',
    // The payload creator
    async (data: Auth.ChangePhoneNumberData, { rejectWithValue }) => {
        try {
            return await AuthService.changePhoneNumber(data);
        } catch (err: any) {
            return rejectWithValue({ error: err.message });
        }
    },
);

export const requestCode: any = createAsyncThunk(
    // The name of the action
    'auth/phone-request-code',
    // The payload creator
    async (data: Auth.PhoneNumberRequestCodeData, { rejectWithValue }) => {
        try {
            return AuthService.phoneNumberRequestCode(data);
        } catch (err: any) {
            return rejectWithValue({ error: err.message });
        }
    },
);

const changePhoneNumberSlice = createSlice({
    /* The name of the slice[this will also be used as the action type string 
      in combination with the extraReducer name i.e posts/getPosts or posts/addPost] 
    */
    name: 'change-phone-number',
    // initialState: initialState[ES6 destructuring syntax]
    initialState,
    // Add reducers for the synchronous actions on the UI[we are not using this property for this tutorial]
    reducers: {
        resetState: (state) => {
            state.status = initialState.status;
            state.data = initialState.data;
            state.error = initialState.error;
            state.step = initialState.step;
        },
        smsCodeHasBeenSended: (state, action) => {
            state.step = CHANGE_STEP;
            state.phoneNumber = action.payload.phone_number;
            state.countryCode = action.payload.country_code;
            state.password = action.payload.password;
            state.otp_channel = action.payload.otp_channel;
        },
    },
    // Add extraReducers for the asynchronous actions on the UI
    extraReducers: {
        [changePhoneNumber.pending]: (state) => {
            // When data is being fetched
            state.status = LOADING;
        },
        [changePhoneNumber.fulfilled]: (state, action: any) => {
            // When data is fetched successfully
            state.status = RESET_SUCCESSFUL;
            // Concatenate the new data to the existing data in the array
            console.log(action.payload);
            state.data = action.payload;
        },
        [changePhoneNumber.rejected]: (state, action) => {
            // When data is fetched unsuccessfully
            state.status = FAILED;

            // Update the error message for proper error handling
            state.error = action.error.message;
        },
        [requestCode.pending]: (state) => {
            // When data is being fetched
            state.status = LOADING;
        },
        [requestCode.fulfilled]: (state, action: any) => {
            // When data is fetched successfully
            state.status = SUCCESSFUL;
            state.step = CHANGE_STEP;
            // Concatenate the new data to the existing data in the array
            state.data = action.payload;
        },
        [requestCode.rejected]: (state, action) => {
            // When data is fetched unsuccessfully
            state.status = FAILED;

            // Update the error message for proper error handling
            state.error = action.error.message;
        },
    },
});
export const { resetState, smsCodeHasBeenSended } = changePhoneNumberSlice.actions;
export default changePhoneNumberSlice.reducer;
