import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface DropdowState {
    element: string;
    active: boolean;
}

const initialState: DropdowState = {
    active: false,
    element: '',
};

const dropdownSlice = createSlice({
    name: 'dropdown',
    initialState,
    reducers: {
        activeDropdown: (state, { payload }: PayloadAction<{ active: boolean; id: string }>) => {
            state.active = payload.active;
            state.element = payload.id;
        },
    },
});

export const { activeDropdown } = dropdownSlice.actions;

export default dropdownSlice.reducer;

export const dropdownSelector = (state: { dropdownStore: DropdowState }) => state.dropdownStore;
