import { FormRequest } from './common';

export default class ForgotPasswordRequest extends FormRequest {
    protected rules = {
        code: [
            {
                max: 6,
                min: 6,
                message: 'Code de 6 chiffres requis.',
            },
        ],
        password: [
            {
                password: true,
                message: 'Le mot de passe doit avoir au moins 4 caractères.',
            },
            {
                confirm: true,
                message: 'Le mot de passe ne correspond pas.',
            },
        ],
    };
}
