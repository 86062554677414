import { Validator, ValidatorValue, validators } from './common';

export const ConfirmValidator: Validator = {
    passed: (_, value: ValidatorValue, password: ValidatorValue, request): boolean => {
        if (value === false && (password === undefined || password === null)) {
            return true;
        }
        const validator: Validator = validators['password'];
        if (request && validator.passed(_, value, password)) {
            if (!request['password_confirmation']) {
                return false;
            } else if (request['password_confirmation'] !== undefined && request['password_confirmation'] != null) {
                return request['password_confirmation'] + '' === password + '';
            } else {
                return false;
            }
        }

        return true;
    },
    message: 'Le mot de passe ne correspond pas.',
};
