import { createSlice } from '@reduxjs/toolkit';
//import AuthService from '@services/auth-service';
const initialState = {
    data: {},
    status: 'idle',
    error: null,
    terms_accepted: false,
};

const signupSlice = createSlice({
    /* The name of the slice[this will also be used as the action type string 
      in combination with the extraReducer name i.e posts/getPosts or posts/addPost] 
    */
    name: 'signup',
    // initialState: initialState[ES6 destructuring syntax]
    initialState,
    // Add reducers for the synchronous actions on the UI[we are not using this property for this tutorial]
    reducers: {
        resetSignupForm: (state) => {
            console.log('====');
            state.status = initialState.status;
            state.data = initialState.data;
            state.error = initialState.error;
        },
        acceptTerms: (state) => {
            state.terms_accepted = true;
        },
        rejectTerms: (state) => {
            state.terms_accepted = false;
        },
    },
    // Add extraReducers for the asynchronous actions on the UI
    extraReducers: {},
});
export const { resetSignupForm, acceptTerms, rejectTerms } = signupSlice.actions;
export default signupSlice.reducer;
