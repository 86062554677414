import React, { FormEvent, useEffect } from 'react';
import './form.css';
import { useForm } from '@hooks/use-form';
import { FormState } from '@reducers/form-reducer';
import { FormDataType } from '@ctypes/form-data';
import { FormRequest } from '@requests/common';

type Method = 'GET' | 'POST' | 'DELETE' | 'PUT';
interface Props {
    method?: Method;
    action?: string;
    onSubmit(state: FormState, event?: FormEvent): void;
    onChange?(state: FormState): void;
    children?: React.ReactNode;
    data?: FormDataType;
    request?: FormRequest;
}

const Form: React.FunctionComponent<Props> = ({
    method,
    action,
    onSubmit,
    children,
    onChange,
    data,
    request,
}: Props) => {
    const [formState, _inputHandler, setFormData] = useForm(data ?? {}, request);

    const inputHandlers: { [x: string]: any } = {};

    const submitHandler = (event: FormEvent) => {
        onSubmit(formState as FormState, event);
    };

    useEffect(() => {
        if (data) {
            setFormData(data);
        }
    }, [data]);

    useEffect(() => {
        const _formState: FormState = formState as FormState;
        onChange ? onChange(_formState) : {};

        Object.keys(inputHandlers).forEach((inputId) => {
            if (inputHandlers.hasOwnProperty(inputId) && inputHandlers[inputId] && _formState.changed[inputId]) {
                inputHandlers[inputId](_formState.changed[inputId].value);
            }
        });
    }, [formState]);

    return (
        <form action={action} method={method} onSubmit={submitHandler}>
            {children}
        </form>
    );
};
export default Form;
