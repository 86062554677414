import React from 'react';
import './youtube-video.css';
import PropTypes from 'prop-types';

type Props = {
    embedId: string;
};

const YoutubeVideo: React.FunctionComponent<Props> = ({ embedId }: Props) => {
    return (
        <div className="video-responsive">
            <iframe
                width="853"
                height="480"
                src={`https://www.youtube.com/embed/${embedId}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
            />
        </div>
    );
};
YoutubeVideo.propTypes = {
    embedId: PropTypes.string.isRequired,
};

export default YoutubeVideo;
