import parsePhoneNumber, { CountryCode } from 'libphonenumber-js';

export default {
    isEmpty(str: string) {
        return !(str && (str + '').trim().length);
    },

    parsePhoneNumber({ country_code = '', phone_number = '' }) {
        const result = parsePhoneNumber(phone_number, country_code as CountryCode);
        if (result && result.isValid())
            return {
                country_code: result.country,
                dial_code: `+${result.countryCallingCode}`,
                phone_number: result.number.replace(new RegExp(`^\\+${result.countryCallingCode}`), ''),
                full_phone_number: result.number,
                national: result.formatNational(),
                international: result.formatInternational(),
            };

        return null;
    },

    isValidPhoneNumber({ country_code = '', phone_number = '' }) {
        return !!this.parsePhoneNumber({ country_code, phone_number });
    },

    isCMPhoneNumber({ phone_number }: { phone_number: string }) {
        return phone_number && phone_number.match(/^((00|\+)?237)?6[5789][0-9]{7}$/);
    },

    isMTNPhoneNumber({ phone_number }: { phone_number: string }) {
        return phone_number && phone_number.match(/^((\+|00)?237)?6([78][0-9]|5[0-4])[0-9]{6}$/);
    },

    isORGPhoneNumber({ phone_number }: { phone_number: string }) {
        return phone_number && phone_number.match(/^((\+|00)?237)?6(9[0-9]|5[5-9])[0-9]{6}$/);
    },

    isValidAuthenticationCode(str: string) {
        str += '';
        return !!str.match(/^[0-9]{4,6}$/);
    },

    isValidPseudo(str: string) {
        str += '';
        return !str.match(/^(fapfap|arbitre)/i) && str.match(/^[a-z0-9]{3,12}$/i);
    },

    isValidPassword(str: string) {
        str += '';
        return str.length >= 4;
    },

    isValidPartAmount(str: string) {
        str += '';
        return !!str.match(/^[0-9]+$/);
    },

    isValidTimePerMove(str: string, time_per_move_config: { min: number; max: number }) {
        const value = parseInt(str);
        return value >= time_per_move_config.min && value <= time_per_move_config.max;
    },

    isValidDepositAmount(str: string) {
        str += '';
        const amount = parseInt(str);
        return str.match(/^[0-9]+$/) && amount > 0 && amount <= 500000 && amount % 100 === 0;
    },

    isValidWithdrawalAmount(str: string) {
        str += '';
        const amount = parseInt(str);
        //return str.match(/^[0-9]+$/) && amount >= 50 && amount <= 100000 && amount % 50 === 0 // ToDo: switch to line below
        return str.match(/^[0-9]+$/) && amount >= 500 && amount <= 100000 && amount % 100 === 0;
    },
};
