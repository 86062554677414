import { FormRequest } from './common';

export default class VerifyNumberFormRequest extends FormRequest {
    protected rules = {
        phone_number: [
            {
                phone: true,
                message: 'Le numéro de téléphone est invalide',
            },
        ],
        password: [
            {
                required: true,
                message: 'Le mot de passe est incorrect',
            },
        ],
    };
}
