import Settings from '@models/settings';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import SettingsService from '@services/settings-service';
import Cookies from 'js-cookie';
import { FAILED, LOADING, SUCCESSFUL, EMPTY } from '@models/status';
import { LanguageType } from '@ctypes/langage';

export type SettingsType = {
    settings: Settings;
    status: typeof EMPTY | typeof LOADING | typeof FAILED | typeof SUCCESSFUL;
    error: null;
    operation?: 'save' | 'get' | 'empty';
};

const initialState: SettingsType = {
    settings: {
        silence: false,
        song: true,
        vibrate: true,
        language: (Cookies.get('i18next') || 'fr') as LanguageType,
    },
    status: EMPTY,
    error: null,
    operation: 'empty',
};

export const updateSettings: any = createAsyncThunk(
    // The name of the action
    'settings/update',
    // The payload creator
    async (setting: Settings, { rejectWithValue }) => {
        try {
            SettingsService.updateSettings(setting);
            return setting;
        } catch (err: any) {
            console.log(err);
            return rejectWithValue({ error: err.message });
        }
    },
);

export const getSettings: any = createAsyncThunk(
    // The name of the action
    'settings/load',
    // The payload creator
    async (_, { rejectWithValue }) => {
        try {
            const res = await SettingsService.getSettings();
            return res;
        } catch (err: any) {
            console.log(err);
            return rejectWithValue({ error: err.message });
        }
    },
);

const settingsSlice = createSlice({
    /* The name of the slice[this will also be used as the action type string 
      in combination with the extraReducer name i.e posts/getPosts or posts/addPost] 
    */
    name: 'settings',
    // initialState: initialState[ES6 destructuring syntax]
    initialState,
    // Add reducers for the synchronous actions on the UI[we are not using this property for this tutorial]
    reducers: {},
    // Add extraReducers for the asynchronous actions on the UI
    extraReducers: {
        [getSettings.pending]: (state: any) => {
            // When data is being fetched
            state.status = LOADING;
            state.operation = 'get';
        },
        [getSettings.fulfilled]: (state, action: any) => {
            // When data is fetched successfully
            state.settings = action.payload;
            state.status = SUCCESSFUL;
            state.operation = 'get';
        },
        [getSettings.rejected]: (state, action) => {
            // When data is fetched unsuccessfully
            state.status = FAILED;
            state.error = action.error.message;
            state.operation = 'get';
        },
        [updateSettings.pending]: (state: any) => {
            // When data is being fetched
            state.status = LOADING;
            state.operation = 'save';
        },
        [updateSettings.fulfilled]: (state, action: any) => {
            // When data is fetched successfully
            state.settings = action.payload;
            state.status = SUCCESSFUL;
            state.operation = 'save';
        },
        [updateSettings.rejected]: (state, action) => {
            // When data is fetched unsuccessfully
            state.status = FAILED;
            state.error = action.error.message;
            state.operation = 'save';
        },
    },
});

export default settingsSlice.reducer;
