import { Validator, ValidatorValue } from './common';

export const MaxdValidator: Validator = {
    passed: (_, max: ValidatorValue, value: ValidatorValue): boolean => {
        if (value === null || value === undefined) {
            return false;
        }
        if (typeof max === 'number') {
            if (typeof value === 'string') {
                return value.length <= max;
            }
            if (typeof value === 'number') {
                return value <= max;
            }
            if (typeof value === 'boolean') {
                const booleanInteger = value ? 1 : 0;
                return booleanInteger <= max;
            }
        }

        return false;
    },
    message: 'Le champ {attribute} est invalide.',
};
