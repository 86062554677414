import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    authenticate: false,
};

const authenticateSlice = createSlice({
    /* The name of the slice[this will also be used as the action type string 
      in combination with the extraReducer name i.e posts/getPosts or posts/addPost] 
    */
    name: 'authenticate',
    // initialState: initialState[ES6 destructuring syntax]
    initialState,
    // Add reducers for the synchronous actions on the UI[we are not using this property for this tutorial]
    reducers: {
        withAuthentication: (state) => {
            state.authenticate = true;
        },
        withoutAuthentication: (state) => {
            state.authenticate = false;
        },
    },
    // Add extraReducers for the asynchronous actions on the UI
    extraReducers: {},
});
export const { withAuthentication, withoutAuthentication } = authenticateSlice.actions;
export default authenticateSlice.reducer;
