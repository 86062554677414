import React, { FormEvent, useEffect, useState } from 'react';
import Event from '@constants/events';
import { useAuth } from '@hooks/use-auth';
import Modal from '@components/ui/modal';
import DepositFormRequest from '@requests/deposit-request';
import { useModal } from '@contexts/global-modal-context';
import Deposit from '@models/deposit';
import { FormState } from '@redux/reducers/form-reducer';
import Form from '@components/ui/form';
import BInputField from '@components/ui/b-input';
import Button from '@components/ui/button';
import { normalizePhoneNumber } from '@utils/string-parser';
import { useTranslation } from 'react-i18next';
import Regex from '@constants/regex';
import { d } from '@utils/encryption-wrapper';
import { Alert } from '@utils/alert';
import Select from '@components/ui/select';
import Icon from '@components/ui/icon';
import { toUpperCase } from '@utils/helpers';
import WebView from '@components/ui/web-view';
import { useSocket } from '@hooks/use-socket';
import useUnMount from '@hooks/use-unmount';
import TransactionService from '@services/transaction-service';
import { DepositResponse } from 'interfaces/deposit-response.interface';
import { EventEmitter } from '@utils/event-emitter';
import DisplayHtml from '@components/ui/html';

type DepositModalProps = {
    amount?: number;
};

function DepositModal({ amount: defaultAmount }: DepositModalProps): React.ReactElement {
    const { t } = useTranslation(['deposit']);
    const { user } = useAuth();
    const [reloadMode, setReloadMode] = useState('Voucher');
    const [options, setOptions] = useState([{ label: 'Coupon', value: 'Voucher' }]);
    const [displayCountryHint, setDisplayCountryHint] = useState(false);
    const [feedbackReceived, setFeedbackReceived] = useState(false);
    const [payment, setPayment] = useState<DepositResponse>();
    const [amount, setAmount] = useState<number>(defaultAmount ?? 100);
    const [code, setCode] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { socket } = useSocket();
    // Get the posts from the store

    const validator = new DepositFormRequest();

    const [disabled, setDisabled] = useState<boolean>(false);

    const { hideModal } = useModal();
    //const { updateSession } = useAuth();

    const depositInitData = {
        phone_number: { value: user?.pseudo || '' },
        amount: { value: amount },
        code: { value: code },
    };

    const initPayment = (data: any) => {
        setFeedbackReceived(false);
        const depositData: Deposit = {
            phone_number: normalizePhoneNumber(
                data.phone_number,
                data.phone_number_country_dial_code,
                data.phone_number_country_code,
            ),
        };
        if (data.reload_mode === 'Default') {
            depositData.amount = data.amount;
        } else {
            depositData.code = data.coupon;
        }
        EventEmitter.emit('showProgress');
        setIsLoading(true);
        TransactionService.deposit(data)
            .then((data: DepositResponse) => {
                if (data.require_otp || data.ussd || data.payment_url) {
                    setPayment(data);
                } else {
                    close();
                    Alert.alert('Information', data.message || '');
                }
                setIsLoading(false);
            })
            .catch((error) => {
                Alert.alert(t('oops', { ns: 'global' }), error.message);
                setIsLoading(false);
            })
            .then(() => payment?.payment_url || EventEmitter.emit('closeProgress'));
    };

    /**
     * On submit signup form
     * Checked if the signup form is valid before submitting data
     */
    const depositHandler = (state: FormState, event: FormEvent) => {
        event.preventDefault();
        if (state.validator && state.validator.valid) {
            onDeposit(state.data);
        } else if (!state.validator) {
            onDeposit(state.data);
        }
    };

    /**
     * On signup form input field changed
     * Chaning the status of the sumitted button
     */
    const onChangeHandler = (state: FormState) => {
        if (state.validator) {
            setDisabled(!state.validator.valid);
        }
    };

    useEffect(() => {
        console.log('==========================SOCKET', socket);
        socket?.on(Event.DEPOSIT_FEEDBACK, onDepositFeedback);
        EventEmitter.on('updateUser', initPayment);
    }, [socket]);

    useUnMount(() => {
        socket?.off(Event.DEPOSIT_FEEDBACK, onDepositFeedback);
        EventEmitter.off('updateUser');
    });

    useEffect(() => {
        const phoneNumber = user?.pending_phone_change?.phone_number || user?.phone_number;
        if (phoneNumber && Regex.isCMPhoneNumber({ phone_number: phoneNumber })) {
            setReloadMode('Default');
            setOptions(getOptions());
        } else {
            setDisplayCountryHint(true);
        }
    }, [user]);

    const getOptions = () => {
        const phone_number = user?.pending_phone_change?.phone_number || user?.phone_number;
        const options = [{ label: 'Coupon', value: 'Voucher' }];
        if (phone_number && Regex.isCMPhoneNumber({ phone_number })) {
            options.unshift({
                label: Regex.isORGPhoneNumber(user) ? 'Orange Money' : 'MTN Mobile Money',
                value: 'Default',
            });
        }
        return options;
    };

    const onDepositFeedback = (data: string) => {
        if (!feedbackReceived) {
            setFeedbackReceived(true);
            const feedBack = d(data);
            Alert.info(feedBack.title, feedBack.message, close, { cancelable: false });
        }
        close();
    };

    const authorizePayment = (data: any) => {
        setFeedbackReceived(false);
        setIsLoading(true);
        TransactionService.confirmDeposit(data)
            .then((data: DepositResponse) => {
                if (data.ussd || data.payment_url) {
                    setPayment({
                        ...(payment || {}),
                        require_otp: false,
                        ussd: data.ussd,
                        payment_url: data.payment_url,
                    });
                } else {
                    close();
                    Alert.alert(t('inf_label', { ns: 'alert' }), data.message || '');
                }
                setIsLoading(false);
            })
            .catch((error) => {
                Alert.alert(t('oops', { ns: 'global' }), error.message);
                setIsLoading(false);
            });
    };

    const close = () => {
        console.log('================');
        setPayment(undefined);
        setReloadMode('Voucher');
        hideModal();
    };

    const onDeposit = (data: any) => {
        console.log(data);
        if (data.reload_mode === 'Voucher') {
            if (Regex.isEmpty(data.coupon)) {
                Alert.alert(t('inf_label', { ns: 'alert' }), t('coupon_code_required', { ns: 'alert' }));
            } else {
                initPayment(data);
            }
        } else {
            if (payment?.require_otp) {
                if (Regex.isEmpty(data.code)) {
                    Alert.alert(t('inf_label', { ns: 'alert' }), t('autorisation_code_required', { ns: 'alert' }));
                } else {
                    authorizePayment(data);
                }
            } else if (!Regex.isValidDepositAmount(data.amount)) {
                Alert.alert(t('inf_label', { ns: 'alert' }), t('incorrect_reload_amount', { ns: 'alert' }));
            } else {
                payment?.ussd ? close() : initPayment(data);
            }
        }
    };

    const onchangeReloadMode = (value: string) => {
        setReloadMode(value);
    };

    const getLabel = (): string => {
        if (payment?.ussd) {
            return toUpperCase(t('ok', { ns: 'actions' }));
        } else {
            return toUpperCase(t('validate', { ns: 'actions' }));
        }
    };

    return (
        <Modal show={true} hideOnAreaClick={false} id="party-modal" title={t('Recharger mon compte')}>
            <div className="form-container">
                <div className="container-fluid px-0 px-0 w-100">
                    <Form
                        method="POST"
                        request={validator}
                        onSubmit={depositHandler}
                        onChange={onChangeHandler}
                        data={depositInitData}
                    >
                        {payment?.require_otp && (
                            <>
                                <BInputField
                                    type="number"
                                    label={t('autorisation_code', { ns: 'forms' })}
                                    id="code"
                                    name="code"
                                    placeholder={t('autorisation_code', { ns: 'forms' })}
                                    onInput={setCode}
                                ></BInputField>
                                <p>{t('autorisation_code_hint', { ns: 'forms' })}</p>
                            </>
                        )}
                        {!payment?.require_otp && payment?.payment_url && (
                            <>
                                <WebView source={{ uri: payment.payment_url }}></WebView>
                            </>
                        )}
                        {!payment?.require_otp && payment?.ussd && (
                            <>
                                <p>
                                    <DisplayHtml htmlString={t('ussd_no_display_hint', { ussd: payment.ussd })} />
                                </p>
                            </>
                        )}
                        {!payment?.payment_url && !payment?.ussd && (
                            <>
                                <Select
                                    type="lang"
                                    id="reload_mode"
                                    onChange={onchangeReloadMode}
                                    blackTheme={true}
                                    value={reloadMode}
                                    name="reload_mode"
                                    valueKey="value"
                                    label={t('payment_method')}
                                    placeholder={t('Sélectionner une langue', { ns: 'attributes' })}
                                    options={options}
                                ></Select>
                                {reloadMode === 'Default' && (
                                    <>
                                        <BInputField
                                            type="number"
                                            label={t('Montant de la recharge', { ns: 'attributes' })}
                                            id="amount"
                                            name="amount"
                                            placeholder="Ex : 100"
                                            value={amount}
                                            onInput={setAmount}
                                        ></BInputField>
                                        <p>{t('reload_amount_hint', { ns: 'forms' })}</p>
                                    </>
                                )}
                                {reloadMode !== 'Default' && (
                                    <>
                                        <BInputField
                                            type="text"
                                            label={t('coupon_code', { ns: 'attributes' })}
                                            id="coupon"
                                            name="coupon"
                                            placeholder={t('coupon_code', { ns: 'forms' })}
                                        ></BInputField>
                                        <p>{t('reload_amount_hint', { ns: 'forms' })}</p>
                                    </>
                                )}
                                {displayCountryHint && (
                                    <div>
                                        <Icon name="info" />
                                        <p>{t('no_available_region_payment_reload', { ns: 'alert' })}</p>
                                    </div>
                                )}
                            </>
                        )}

                        {!payment?.payment_url && (
                            <Button
                                label={getLabel()}
                                icon="security"
                                id="deposit-btn"
                                disabled={disabled}
                                loading={isLoading}
                                className="mt-3"
                            ></Button>
                        )}
                    </Form>
                </div>
            </div>
        </Modal>
    );
}

export default DepositModal;
