import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface InputState {
    id: string;
    opened?: boolean;
}

const initialState: InputState = {
    id: '',
    opened: false,
};

const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        showModal: (state, { payload }: PayloadAction<{ id: string; show: boolean }>) => {
            state.id = payload.id;
            state.opened = payload.show;
        },
    },
});

export const { showModal } = modalSlice.actions;

export default modalSlice.reducer;

export const modalSelector = (state: { modalStore: InputState }) => state.modalStore;
