import { FormRequest } from './common';

export default class SignupFormRequest extends FormRequest {
    protected rules = {
        phone_number: [
            {
                phone: true,
                message: 'Le numéro de téléphone est invalide',
            },
        ],
        checked: [
            {
                checked: true,
                message: "Vous devez accepter les termes et condition d'utilisation",
            },
        ],
        language: [
            {
                required: true,
                message: 'La langue est requise',
            },
        ],
    };
}
