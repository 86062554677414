import React, { FormEvent, useEffect, useState } from 'react';
import Form from '@components/ui/form';
import Button from '@components/ui/button';
import BInputField from '@components/ui/b-input';
import { FormRequest } from '@requests/common';
import { FormState } from '@reducers/form-reducer';
import InternationalizedInput from '@components/ui/internationalized-input';
import { useMediaScreen } from '@hooks/use-media-screen';
import SvgIcon from '@components/ui/svg-icon';
import { useDispatch } from 'react-redux';
import { emit } from '@redux/reducers/event-reducer';
import EventType from '@ctypes/event-type';
import { useTranslation } from 'react-i18next';
import { InternationalizedValue } from '@components/ui/internationalized-input/internationalized-input';
import { User } from '@models/user';
import { normalizePhoneNumber } from '@utils/string-parser';
import { RequestOTP } from '@models/auth';
import AuthService from '@services/auth-service';
import { smsCodeHasBeenSended } from '@redux/reducers/change-phone-reducer';
import { FormDataType } from '@ctypes/form-data';
import PhoneFormRequest from '@requests/phone-request';

export type RequestCodeProps = {
    validator?: FormRequest;
    user?: User;
};
function RequestCodeForm({ user }: RequestCodeProps): React.ReactElement {
    const { t } = useTranslation(['profile']);
    const [isSmallScreen] = useMediaScreen();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const dispatch = useDispatch();
    const handleGoBackEvent = () => {
        dispatch(emit({ type: EventType.GO_BACK }));
    };
    const [phoneNumberValue, setPhoneNumberValue] = useState<InternationalizedValue>({
        code: user?.country_code || user?.pending_phone_change?.country_code,
        dialCode: '+237',
        value: user?.phone_number || user?.pending_phone_change?.phone_number || '',
    });
    const [password, setPassword] = useState<string>('');
    const [formData, setFormData] = useState<FormDataType>({});
    const validator = new PhoneFormRequest();
    const [formDataState, setFormDataState] = useState<FormState>();
    const onPhoneNumberChange = (value: InternationalizedValue) => {
        //formData.phone_number.value = value.value as string;
        setFormData({ ...formData, phone_number: { value: value.value as string } });
        setPhoneNumberValue(value);
    };

    useEffect(() => {
        setFormData({
            phone_number: { value: phoneNumberValue.value as string },
            password: { value: password },
            phone_number_country_dial_code: { value: phoneNumberValue.dialCode as string },
        });
    }, [password, phoneNumberValue]);

    const onChangeHandler = (state: FormState) => {
        setFormDataState(state);
    };

    const requestCodeHandler = (state: FormState, event: FormEvent) => {
        event.preventDefault();
        if (state.validator && state.validator.valid) {
            const phoneNumber = {
                phone_number: normalizePhoneNumber(
                    phoneNumberValue.value as string,
                    phoneNumberValue.dialCode,
                    phoneNumberValue.code,
                ),
                country_code: phoneNumberValue.code as string,
                country_name: phoneNumberValue.name as string,
            };
            const data: RequestOTP = {
                phone_number: phoneNumber.phone_number,
                country_code: phoneNumber.country_code,
                password: state.data.password as string,
            };
            setIsLoading(true);
            AuthService.requestOTP(data)
                .then((responseData: any) => {
                    setIsLoading(false);
                    dispatch(
                        smsCodeHasBeenSended({
                            ...phoneNumber,
                            password: data.password,
                            otp_channel: responseData.otp_channel,
                        }),
                    );
                })
                .catch(() => {
                    setIsLoading(false);
                });
        }
    };

    return (
        <Form
            method="POST"
            onChange={onChangeHandler}
            request={validator}
            onSubmit={requestCodeHandler}
            data={formData}
        >
            <div className="d-flex gap-0 profile-tab w-100">
                <h4
                    className={`text-center d-inline-block ${
                        isSmallScreen ? 'd-flex justify-content-between gap-1' : ''
                    } `}
                >
                    {isSmallScreen && <SvgIcon onClick={handleGoBackEvent} name="arrow-left" height={19} width={19} />}
                    <span>{t('Changer mon numéro de téléhone')}</span>
                </h4>
            </div>
            <InternationalizedInput
                id="phone_number"
                name="phone_number"
                value={phoneNumberValue.value}
                countryCode={phoneNumberValue.code}
                label={t('Votre numéro de téléphone', { ns: 'attributes' })}
                blackTheme={true}
                onChange={onPhoneNumberChange}
                parent={formDataState}
                placeholder="Ex : 6XX XXX XXX"
            />
            <BInputField
                type="password"
                label={t('Mot de passe', { ns: 'attributes' })}
                id="password"
                name="password"
                value={password}
                onInput={setPassword}
                placeholder={t('Votre mot de passe', { ns: 'attributes' })}
            ></BInputField>
            <Button
                label={t('Modifier', { ns: 'common' })}
                icon="security"
                disabled={isLoading}
                loading={isLoading}
                className="mt-3 py-1"
            ></Button>
            <fieldset className="form-mention">
                <p>{t('Tu recevras une code par SMS')}</p>
            </fieldset>
        </Form>
    );
}

export default RequestCodeForm;
