import { EventEmitter } from './event-emitter';
import { MODAL_TYPES } from '@contexts/global-modal-context';

export interface FlashMessageType {
    success(message: string): void;
    warning(message: string): void;
    info(message: string): void;
    error(message: string): void;
}

export const FlashMessage: FlashMessageType = {
    success: (message: string) => {
        EventEmitter.emit('flash', {
            modalType: MODAL_TYPES.ALERT_MODAL,
            modalProps: {
                type: 'success',
                message: message,
            },
        });
    },
    // Publish warning message
    warning: (message: string) => {
        EventEmitter.emit('flash', {
            modalType: MODAL_TYPES.ALERT_MODAL,
            modalProps: {
                type: 'warning',
                message: message,
            },
        });
    },
    // Publish information message
    info: (message: string) => {
        EventEmitter.emit('flash', {
            modalType: MODAL_TYPES.ALERT_MODAL,
            modalProps: {
                type: 'info',
                message: message,
            },
        });
    },
    // Publish message when errors
    error: (message: string) => {
        EventEmitter.emit('flash', {
            modalType: MODAL_TYPES.ALERT_MODAL,
            modalProps: {
                type: 'error',
                message: message,
            },
        });
    },
};
