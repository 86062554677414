import Modal from '@components/ui/modal';
import React from 'react';
import { useModal } from '@contexts/global-modal-context';
import { useTranslation } from 'react-i18next';
import CongratulationSrc from '@assets/images/congrats.png';
import { formatAmount } from '@utils/helpers';
import DisplayHtml from '@components/ui/html';

type WinnerModalProps = {
    winType: string;
    gain: number;
    commissions: number;
    onReportProblem: () => void;
    onReplayGame: () => void;
    onQuitGame: () => void;
};

function WinnerModal(props: WinnerModalProps): React.ReactElement {
    const { hideModal } = useModal();
    const { t } = useTranslation(['game']);

    const handleCloseModal = () => {
        hideModal();
    };

    const reportProblem = () => {
        hideModal();
        props.onReportProblem();
    };

    const handleQuitTheParty = () => {
        hideModal();
        props.onQuitGame();
    };

    /*const handleReplayTheParty = () => {
        props.onReplayGame();
    };*/

    return (
        <Modal show={true} onClose={handleCloseModal} id="winner-modal" title="">
            <div className="form-container d-flex flex-column justify-content-center align-items-center">
                <div className="d-flex justify-content-center align-items-center">
                    <img src={CongratulationSrc} alt="" height={100} />
                </div>
                <h4 className="p-0 m-0 text-center text-primary">
                    <DisplayHtml htmlString={t('win_message')} />
                    {props.winType && props.winType !== 'Normal' ? ` par ${props.winType}` : ''}.
                </h4>
                <p className="p-0 m-0 text-center">
                    <DisplayHtml htmlString={t('win_earning', { amount: formatAmount(props.gain) })} />
                </p>
                <p className="p-0 m-0 text-center">
                    <DisplayHtml htmlString={t('win_commissions', { commissions: formatAmount(props.commissions) })} />
                </p>

                <div className="actions">
                    <button type="button" onClick={reportProblem} className="opaced d-flex justify-content-center">
                        {t('report_problem', { ns: 'actions' })}
                    </button>
                </div>
                <div className="actions">
                    <button
                        type="button"
                        className="danger d-flex justify-content-center "
                        onClick={handleQuitTheParty}
                        data-modal="modal-two"
                    >
                        {t('quit', { ns: 'actions' })}
                    </button>
                    {/* <button
                        type="button"
                        className="default switch-modal-trigger"
                        onClick={handleReplayTheParty}
                        data-modal="modal-two"
                    >
                        {t('replay', { ns: 'actions' })}
                    </button> */}
                </div>
            </div>
        </Modal>
    );
}

export default WinnerModal;
