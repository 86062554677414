import React, { FormEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Modal from '@components/ui/modal';
import { useToast } from '@hooks/use-toast';
import { FormState } from '@redux/reducers/form-reducer';
import Form from '@components/ui/form';
import Button from '@components/ui/button';
import { useTranslation } from 'react-i18next';
import Select from '@components/ui/select';
import Cookies from 'js-cookie';
import Checkbox from '@components/ui/checkbox';
import Settings from '@models/settings';
import { RootState } from '@redux/store';
import SettingFormRequest from '@requests/settings-request';
import { MODAL_TYPES, useModal } from '@contexts/global-modal-context';
import { useLocalStorage } from '@hooks/use-local-storage';
import { __PREFERENCE_STORE__ } from '@constants/storage';

function SettingsModal(): React.ReactElement {
    const { t, i18n } = useTranslation(['settings']);
    const [isLoading, setIsLoading] = useState(false);
    const [settings, setSettings] = useLocalStorage<Settings | undefined>(__PREFERENCE_STORE__);
    const [song, setSong] = useState<boolean>(settings ? settings.song : true);
    const [silence, setSilence] = useState<boolean>(settings ? settings.silence : false);
    const [vibrate, setVibrate] = useState<boolean>(settings ? settings.vibrate : true);
    const [language, setLanguage] = useState<string>(settings ? settings.language : 'fr');
    const { showModal, hideModal } = useModal();
    const { blocked_gamers } = useSelector((state: RootState) => state.blockGamerStore);

    const languages = [
        {
            code: 'fr',
            value: 'fr',
            label: t('Français', { ns: 'common' }),
        },
        {
            code: 'gb',
            value: 'en',
            label: t('Anglais', { ns: 'common' }),
        },
    ];

    // Get the posts from the store
    const validator = new SettingFormRequest();

    const [disabled, setDisabled] = useState<boolean>(false);

    //const { updateSession } = useAuth();
    const toast = useToast();

    const settingInitData = {
        song: { value: song },
        silence: { value: silence },
        vibrate: { value: vibrate },
        language: { value: language },
    };

    useEffect(() => {
        if (vibrate) {
            setSilence(false);
        }
    }, [vibrate]);

    useEffect(() => {
        if (song) {
            setSilence(false);
        }
    }, [song]);

    useEffect(() => {
        if (silence) {
            setSong(false);
            setVibrate(false);
        }
    }, [silence]);

    const onSave = (data: any) => {
        const settingData: Settings = {
            song: data.song,
            silence: data.silence,
            language: data.language,
            vibrate: data.vibrate,
        };
        setSettings(settingData);
        i18n.changeLanguage(data.language);
        Cookies.set('i18next', data.language);
        toast?.success(t('Paramètres sauvegardés avec succès.'));
    };
    /**
     * On submit signup form
     * Checked if the signup form is valid before submitting data
     */
    const saveHandler = (state: FormState, event: FormEvent) => {
        event.preventDefault();
        if (state.validator && state.validator.valid) {
            setIsLoading(true);
            onSave(state.data);
            setTimeout(() => {
                setIsLoading(false);
                hideModal();
            }, 1000);
        }
    };

    /**
     * On signup form input field changed
     * Chaning the status of the sumitted button
     */
    const onChangeHandler = (state: FormState) => {
        if (state.validator) {
            setDisabled(!state.validator.valid);
        }
    };

    const onChangeLanguage = (value: string) => {
        setLanguage(value.toLowerCase());
    };

    const getDefaultLanguage = () => {
        for (const lang in languages) {
            if (languages[lang].value === settings?.language) {
                return languages[lang];
            }
        }
        return languages[0];
    };

    const handleBlockGamers = () => {
        showModal(MODAL_TYPES.BLOCK_GAMERS);
    };

    return (
        <Modal show={true} hideOnAreaClick={false} id="party-modal" title={t('Paramètres')}>
            <div className="form-container">
                <div className="container-fluid px-0 px-0 w-100">
                    <Form
                        method="POST"
                        request={validator}
                        onChange={onChangeHandler}
                        onSubmit={saveHandler}
                        data={settingInitData}
                    >
                        <Select
                            type="lang"
                            id="language"
                            onChange={onChangeLanguage}
                            blackTheme={true}
                            value={getDefaultLanguage()}
                            name="language"
                            valueKey="value"
                            label={t('Langue', { ns: 'attributes' })}
                            placeholder={t('Sélectionner une langue', { ns: 'attributes' })}
                            options={languages}
                        ></Select>
                        <Checkbox
                            onChange={setVibrate}
                            id="checked"
                            name="song"
                            label={`${t('vibration')} ${
                                vibrate
                                    ? t('f_activate', { ns: 'global', suffix: 's' })
                                    : t('f_desactivate', { ns: 'global', suffix: 's' })
                            }`}
                            checked={vibrate}
                            blackTheme={true}
                            labelClassName="usage-condition"
                        />
                        <Checkbox
                            onChange={setSong}
                            id="checked"
                            name="song"
                            label={`${t('sound_effects')} ${
                                song
                                    ? t('f_activate', { ns: 'global', suffix: 's' })
                                    : t('f_desactivate', { ns: 'global', suffix: 's' })
                            }`}
                            checked={song}
                            blackTheme={true}
                            labelClassName="usage-condition"
                        />
                        <Checkbox
                            onChange={setSilence}
                            id="checked"
                            name="silence"
                            label={`${t('silence')} ${
                                silence
                                    ? t('m_activate', { ns: 'global', suffix: '' })
                                    : t('m_desactivate', { ns: 'global', suffix: '' })
                            }`}
                            checked={silence}
                            blackTheme={true}
                            labelClassName="usage-condition"
                        />
                        <div className="d-flex justify-content-between align-items-center w-100">
                            {blocked_gamers.length == 0 && <span>{t('empty_block_users')}</span>}
                            {blocked_gamers.length > 0 && (
                                <span>
                                    {t('block_users', {
                                        nb: blocked_gamers.length,
                                        suffix: blocked_gamers.length > 1 ? 's' : '',
                                    })}
                                </span>
                            )}

                            <Button
                                label={blocked_gamers.length > 0 ? t('show_list') : t('block_user')}
                                id="deposit-btn"
                                className="btn-sm"
                                type="button"
                                onClick={handleBlockGamers}
                            ></Button>
                        </div>
                        <Button
                            label={t('Enregistrer', { ns: 'attributes' })}
                            icon="security"
                            id="deposit-btn"
                            disabled={disabled}
                            loading={isLoading}
                            className="mt-3"
                        ></Button>
                    </Form>
                </div>
            </div>
        </Modal>
    );
}

export default SettingsModal;
