import { useEffect } from 'react';

const addBodyClass = (className: string) => {
    className.split(' ').forEach((cName) => document.body.classList.add(cName));
};
const removeBodyClass = (className: string) => {
    className.split(' ').forEach((cName) => document.body.classList.remove(cName));
};

export default function useBodyClass(className: string | string[]) {
    useEffect(() => {
        // Set up
        className instanceof Array ? className.map(addBodyClass) : addBodyClass(className);

        // Clean up
        return () => {
            className instanceof Array ? className.map(removeBodyClass) : removeBodyClass(className);
        };
    }, [className]);
}
