import React, { FormEvent, useEffect, useState } from 'react';
import Form from '@components/ui/form';
import { useLocalStorage } from '@hooks/use-local-storage';
import Button from '@components/ui/button';
import * as Auth from '@models/auth';
import { FormState } from '@reducers/form-reducer';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AuthService from '@services/auth-service';
import { __APP_SETTINGS_STORE__, __COUNTER__, __FORGOT_PASSWORD__, __RETRIES__ } from '@constants/storage';
import { ForgotPasswordResponseData } from '@models/forgot-password.model';
import { FormDataType } from '@ctypes/form-data';
import InputField from '@components/ui/input';
import delays from '@constants/delays';
import regex from '@constants/regex';
import SmsCodeFormRequest from '@requests/sms-code-request';
import { useAuth } from '@hooks/use-auth';
import { SidebarType } from '@enums/sidebar.enum';
import { DEFAULT_ROUTE } from '@config/app';
import { useSideBar } from '@hooks/use-sidebar';
import { useToast } from '@hooks/use-toast';
import appService from '@services/app-service';
import { AppSettings } from '@models/app-settings.model';

function VerifyResetPasswordCodeForm(): React.ReactElement {
    const validator = new SmsCodeFormRequest();
    const navigate = useNavigate();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [code, setCode] = useState<string>();
    const { t } = useTranslation(['forgot-password']);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [forgotPasswordData, setForgotPasswordData] = useLocalStorage<ForgotPasswordResponseData | undefined>(
        __FORGOT_PASSWORD__,
    );
    const [remaining, setRemaining] = useLocalStorage<number>(__COUNTER__, delays.beforeResendCode);
    const [tries, setTries] = useLocalStorage<number>(__RETRIES__, 2);
    const [isActive, setIsActive] = useState(false);
    const [error, setError] = useState<string>();
    const { login, authed } = useAuth();
    const { setSideBar } = useSideBar();
    const [isRegistered, setIsRegistered] = useState<boolean>(false);
    const toast = useToast();
    const [_, appSettings] = useLocalStorage<AppSettings | null>(__APP_SETTINGS_STORE__);

    const [data, setData] = useState<FormDataType>({
        code: { value: '' },
    });

    useEffect(() => {
        setIsActive(true);
    }, []);

    useEffect(() => {
        let interval: any = null;

        if (isActive && remaining > 0) {
            interval = setInterval(() => {
                setRemaining(remaining - 1);
            }, 1000);
        } else if (remaining === 0) {
            setIsActive(false);
            if (interval) {
                clearInterval(interval);
            }
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [isActive, remaining]);

    const onSubmit = (requestpData: any) => {
        const data: Auth.VerifyPasswordCodeData = {
            country_code: forgotPasswordData?.user.country_code as string,
            phone_number: forgotPasswordData?.user.phone_number as string,
            code: requestpData.code,
        };
        if (!regex.isValidAuthenticationCode(requestpData.code)) {
            setError(t('incorrect_verification_code', { ns: 'alert' }));
        } else {
            setIsLoading(true);
            AuthService.verifyAuthCode(data)
                .then((response) => {
                    const { user, secret } = response;
                    toast?.success(t('Bienvenue', { ns: 'common', pseudo: user.pseudo }));
                    const currentTimestamp = Date.now();
                    appService.getServerStatus().then((params) => {
                        appSettings(params);
                    });
                    login({
                        secret: secret as string,
                        logged_at: currentTimestamp,
                        user: user,
                    });
                    setIsRegistered(true);
                    setSideBar(SidebarType.DASHBOARD);
                    setIsLoading(false);
                    setForgotPasswordData(undefined);
                })
                .catch(() => setIsLoading(false));
        }
    };

    /**
     * On submit signup form
     * Checked if the signup form is valid before submitting data
     */
    const recoveryHandler = (state: FormState, event: FormEvent) => {
        event.preventDefault();
        if (state.validator && state.validator.valid) {
            onSubmit(state.data);
        }
    };

    /**
     * On signup form input field changed
     * Chaning the status of the sumitted button
     */
    const onChangeHandler = (state: FormState) => {
        if (state.validator) {
            setDisabled(!state.validator.valid);
            if (!regex.isValidAuthenticationCode(state.data.code as string)) {
                setError(t('incorrect_verification_code', { ns: 'alert' }));
                setDisabled(false);
            }
        }
    };

    const onReset = () => {
        if (tries > 0) {
            const data: Auth.ForgotPasswordData = {
                country_code: forgotPasswordData?.user.country_code as string,
                phone_number: forgotPasswordData?.user.phone_number as string,
            };
            setIsLoading(true);
            AuthService.registerOrLogin(data)
                .then(() => {
                    setTries(tries - 1);
                    setRemaining(delays.beforeResendCode);
                    setIsActive(true);
                    setIsLoading(false);
                })
                .catch(() => setIsLoading(false));
        }
    };

    useEffect(() => {
        setError(undefined);
        setData({
            code: { value: code || '' },
        });
    }, [code]);

    useEffect(() => {
        if (authed && isRegistered) {
            navigate(DEFAULT_ROUTE);
        }
    }, [authed, isRegistered]);

    return (
        <Form method="POST" data={data} request={validator} onChange={onChangeHandler} onSubmit={recoveryHandler}>
            <InputField
                type="tel"
                label={t('code_placeholder', { ns: 'forms' })}
                id="code"
                name="code"
                value={code}
                onInput={setCode}
                error={error}
                placeholder="Ex : XXX XXX"
            ></InputField>
            {tries > 0 && (
                <p className="d-flex justify-content-between align-items-center">
                    <a onClick={onReset} href="javascript:;" className={`${remaining > 0 ? 'disabled' : ''}`}>
                        {t('resend_code', { ns: 'actions' })}
                    </a>
                    {remaining > 0 && <strong>{remaining}</strong>}
                </p>
            )}
            {tries == 0 && (
                <p>
                    <span>{t('subject', { ns: 'contact' })}</span>?{' '}
                    <b>
                        <Link to={'/contact-us'}>{t('contact_support', { ns: 'contact' })}</Link>
                    </b>
                </p>
            )}
            ;<Button label={t('validate', { ns: 'actions' })} loading={isLoading} disabled={disabled}></Button>
        </Form>
    );
}

export default VerifyResetPasswordCodeForm;
