import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { EMPTY, FAILED, LOADING, SUCCESSFUL } from '@models/status';
import GamersService from '@services/gamer-service';
import Actions from '@constants/actions';
import { BlockedUser } from '@models/blocked-user.model';

type StateProps = {
    gamers: any[];
    blocked_gamers: BlockedUser[];
    status: typeof EMPTY | typeof FAILED | typeof LOADING | typeof SUCCESSFUL;
    error: any;
    action: typeof Actions.BLOCK | typeof Actions.UNBLOCK | typeof Actions.SEARCH | typeof Actions.UNKNOWN;
};

const initialState: StateProps = {
    gamers: [],
    blocked_gamers: [],
    status: EMPTY,
    error: null,
    action: Actions.UNKNOWN,
};

// Handle POST request to create a new post
export const searchGamers: any = createAsyncThunk(
    // The name of the action
    'gamers/query',
    // The payload creator
    async (q: string, { rejectWithValue }) => {
        try {
            const res = await GamersService.searchGamers(q);
            console.log(res);
            return res.data;
        } catch (err: any) {
            return rejectWithValue({ error: err.message });
        }
    },
);

// Handle POST request to create a new post
export const blockGamer: any = createAsyncThunk(
    // The name of the action
    'gamers/block',
    // The payload creator
    async (id: string, { rejectWithValue }) => {
        try {
            const res = await GamersService.blockGamer(id);
            console.log(res.data);
            return id;
        } catch (err: any) {
            return rejectWithValue({ error: err.message });
        }
    },
);

// Handle POST request to create a new post
export const getBlockedGamers: any = createAsyncThunk(
    // The name of the action
    'gamers/blocked',
    // The payload creator
    async (_, { rejectWithValue }) => {
        try {
            return await GamersService.getBlockedGamers();
        } catch (err: any) {
            return rejectWithValue({ error: err.message });
        }
    },
);

// Handle POST request to create a new post
export const unblockGamer: any = createAsyncThunk(
    // The name of the action
    'gamers/unblock',
    // The payload creator
    async (id: string, { rejectWithValue }) => {
        try {
            const res = await GamersService.unblockGamer(id);
            console.log(res.data);
            return id;
        } catch (err: any) {
            return rejectWithValue({ error: err.message });
        }
    },
);

const gamerSlice = createSlice({
    /* The name of the slice[this will also be used as the action type string 
      in combination with the extraReducer name i.e posts/getPosts or posts/addPost] 
    */
    name: 'gamers',
    // initialState: initialState[ES6 destructuring syntax]
    initialState,
    // Add reducers for the synchronous actions on the UI[we are not using this property for this tutorial]
    reducers: {
        resetState: (state) => {
            state.status = initialState.status;
            state.gamers = initialState.gamers;
            state.error = initialState.error;
        },
        setBlockedGamers: (state, { payload }: PayloadAction<BlockedUser[]>) => {
            state.blocked_gamers = payload;
        },
    },
    // Add extraReducers for the asynchronous actions on the UI
    extraReducers: {
        [searchGamers.pending]: (state) => {
            // When data is being fetched
            state.status = LOADING;
            state.action = Actions.SEARCH;
        },
        [searchGamers.fulfilled]: (state, action: any) => {
            // When data is fetched successfully
            state.status = SUCCESSFUL;

            // Concatenate the new data to the existing data in the array
            state.gamers = action.payload.data;
        },
        [searchGamers.rejected]: (state, action) => {
            // When data is fetched unsuccessfully
            state.status = FAILED;

            // Update the error message for proper error handling
            state.error = action.error.message;
        },
        [blockGamer.pending]: (state) => {
            // When data is being fetched
            state.status = LOADING;
            state.action = Actions.BLOCK;
        },
        [blockGamer.fulfilled]: (state, action: any) => {
            // When data is fetched successfully
            state.status = SUCCESSFUL;

            // Concatenate the new data to the existing data in the array
            state.gamers = state.gamers.filter((gamer: any) => {
                gamer.id != action.payload;
            });
        },
        [blockGamer.rejected]: (state, action) => {
            // When data is fetched unsuccessfully
            state.status = FAILED;

            // Update the error message for proper error handling
            state.error = action.error.message;
        },
        [unblockGamer.pending]: (state) => {
            // When data is being fetched
            state.status = LOADING;
            state.action = Actions.UNBLOCK;
        },
        [unblockGamer.fulfilled]: (state, action: any) => {
            // When data is fetched successfully
            state.status = SUCCESSFUL;

            // Concatenate the new data to the existing data in the array
            state.gamers = action.payload;
        },
        [unblockGamer.rejected]: (state, action) => {
            // When data is fetched unsuccessfully
            state.status = FAILED;

            // Update the error message for proper error handling
            state.error = action.error.message;
        },
        [getBlockedGamers.pending]: (state) => {
            // When data is being fetched
            state.status = LOADING;
            state.action = Actions.SEARCH;
        },
        [getBlockedGamers.fulfilled]: (state, action: any) => {
            // When data is fetched successfully
            state.status = SUCCESSFUL;

            // Concatenate the new data to the existing data in the array
            state.blocked_gamers = action.payload;
        },
        [getBlockedGamers.rejected]: (state, action) => {
            // When data is fetched unsuccessfully
            state.status = FAILED;

            // Update the error message for proper error handling
            state.error = action.error.message;
        },
    },
});

export const { resetState, setBlockedGamers } = gamerSlice.actions;

export default gamerSlice.reducer;
